import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { HeatQueryParams } from '../../../main/content/pages/championship/heats/enums/heat-query.params';
import { KeyValue } from '../../../main/content/pages/championship/heats/model/key-value.model';
import { CompetitionService } from '../competition/competition.service';
import { Competition } from '../../model/competition';
import { Competitor } from '../../model/competitor';
import { Heat } from '../../../main/content/pages/championship/heats/model/heat.model';
import { HeatCompetitor } from '../../../main/content/pages/championship/heats/model/heat-competitor.model';

@Injectable({
  providedIn: 'root'
})
export class HeatsHttpService extends Subject<Heat[]> implements OnDestroy {

  private subscriptions = new Subscription();
  private _competitionsObservable = new Subject<Competition[]>();
  private _competitorsObservable = new BehaviorSubject<Competitor[]>(null);
  private _heatsSavedSubject = new Subject<boolean>();
  private _heatsDeletedSubject = new Subject<boolean>();
  private _fetchHeatsByChampionshipIdBehaviorSubject = new BehaviorSubject(null);

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private competitionService: CompetitionService
  ) {
    super();
  }

  private _heatsUpdatedObservable = new Subject<Heat[]>();

  // @ts-ignore
  get heatsUpdatedObservable(): Observable<Heat[]> {
    return this._heatsUpdatedObservable.asObservable();
  }
  fetchHeatsByChampionshipIdBehaviorObservable(): Observable<any> {
    return this._fetchHeatsByChampionshipIdBehaviorSubject.asObservable();
  }

  competitionsObservable(): Observable<Competition[]> {
    return this._competitionsObservable.asObservable();
  }

  heatsSavedObservable(): Observable<boolean> {
    return this._heatsSavedSubject.asObservable();
  }

  // GETS
  fetchHeatsWithChampionship(championshipId: number): Observable<PublicHeat> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const baseUrl = [
      'public',
      'heats',
      'championship',
      championshipId
    ];

    const url = this.router.createUrlTree(baseUrl);

    const parsedUrl = url.toString().substring(1, url.toString().length);
    return this.httpClient.get(parsedUrl, { responseType })
      .map((response: PublicHeat) => {
        return response;
      });
  }

  fetchHeatsWithChampionshipAndType(championshipId: number): Observable<Heat[]> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const baseUrl = [
      'public',
      'heats',
      'championship',
      championshipId,
      'event'
    ];

    const url = this.router.createUrlTree(baseUrl);

    const parsedUrl = url.toString().substring(1, url.toString().length);
    return this.httpClient.get(parsedUrl, { responseType })
      .map((response: Heat[]) => {
        return response;
      });
  }

  fetchCompetitors(params: Array<KeyValue>): void {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const baseUrl = [
      'admin',
      'competitors'
    ];
    const queryParams = {};

    params.forEach((param) => {
      if (param.key === HeatQueryParams.TYPE) {
        queryParams[HeatQueryParams.TYPE] = param.value;
      }

      if (param.key === HeatQueryParams.WODS_ID) {
        queryParams[HeatQueryParams.WODS_ID] = param.value;
      }

      if (param.key === HeatQueryParams.CHAMPIONSHIP_ID) {
        queryParams[HeatQueryParams.CHAMPIONSHIP_ID] = param.value;
      }

      if (param.key === HeatQueryParams.COMPETITION_ID) {
        queryParams[HeatQueryParams.COMPETITION_ID] = param.value;
      }

      if (param.key === HeatQueryParams.EXCLUDE_TEAM_ID) {
        queryParams[HeatQueryParams.EXCLUDE_TEAM_ID] = param.value;
      }

      if (param.key === HeatQueryParams.NAME) {
        queryParams[HeatQueryParams.NAME] = param.value;
      }
    });

    const url = this.router.createUrlTree(baseUrl, {
        queryParams: queryParams
      }
    );
    const parsedUrl = url.toString().substring(1, url.toString().length);
    const subscription = this.httpClient
      .get(parsedUrl, {responseType})
      .subscribe((competitors: Competitor[]) => {
          this._competitorsObservable.next(competitors);
        },
        () => {
          this._competitorsObservable.next([]);
        });

    this.subscriptions.add(subscription);
  }

  fetchHeats(params: Array<KeyValue>): Observable<Heat[]> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const baseUrl = [
      'admin',
      'heats'
    ];
    const queryParams = {};

    params.forEach((param) => {
      if (param.key === HeatQueryParams.CHAMPIONSHIP_ID) {
        queryParams[HeatQueryParams.CHAMPIONSHIP_ID] = param.value;
      }

      if (param.key === HeatQueryParams.TYPE) {
        queryParams[HeatQueryParams.TYPE] = param.value;
      }
    });

    const url = this.router.createUrlTree(baseUrl, {
        queryParams: queryParams
      }
    );
    const parsedUrl = url.toString().substring(1, url.toString().length);
    this.httpClient
      .get(parsedUrl, {responseType})
      .subscribe((data: Heat[]) => {
        this.next(data);
      }, (error) => {
        this.next(null);
      });
    return this;
  }

  updateHeats(heatsData: Heat[]): void {

    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const baseUrl = [
      'admin',
      'heats'
    ];
    const url = this.router.createUrlTree(baseUrl, {});

    const parsedUrl = url.toString().substring(1, url.toString().length);
    this.httpClient
      .put(parsedUrl, heatsData, {responseType})
      .subscribe((data: Heat[]) => {
        return this._heatsUpdatedObservable.next(data);
      });

  }

  deletedHeats(heatsData: Heat[]): void {
    let ids: string;
    heatsData.forEach((heat: Heat) => {
      if (!ids) {
        ids = heat.id.toString();
      } else {
        if (heat.id) {
          ids = `${ids}, ${heat.id}`;
        }
      }
    });

    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const baseUrl = [
      'admin',
      'heats',
      ids
    ];
    const url = this.router.createUrlTree(baseUrl, {});

    const parsedUrl = url.toString().substring(1, url.toString().length);
    this.httpClient
      .delete(parsedUrl, {responseType})
      .subscribe(() => {
          return this._heatsDeletedSubject.next(true);
        },
        () => {
          return this._heatsDeletedSubject.next(false);
        });

  }

  fetchCompetitions(championshipId: number, type: string): void {
    const subscription = this.competitionService
      .getCompetititonsByType(championshipId, type)
      .subscribe((competitions: Competition[]) => {
        this._competitionsObservable.next(competitions);
      }, () => {
        this._competitionsObservable.next([]);
      });

    this.subscriptions.add(subscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  createHeats(data: Array<Heat>, championshipId?: string): void {
    const baseUrl = [
      'admin',
      'heats'
    ];

    const url = this.router.createUrlTree(baseUrl);

    const parsedUrl = url.toString().substring(1, url.toString().length);

    this.httpClient
      .post(parsedUrl, data)
      .subscribe(() => {
        this._heatsSavedSubject.next(true);
      }, (error) => {
        this._heatsSavedSubject.next(false);
      });
  }

  updateHeatCompetitors(data: Array<HeatCompetitor>) {
  }

  fetchHeatsByChampionshipId(id: number): void {

    const url = `public/heats/championship/${id}?basic=${true}`;

    const subscription = this.httpClient
      .get(url)
      .subscribe((data) => {
        this._fetchHeatsByChampionshipIdBehaviorSubject.next(data);
      });

    this.subscriptions.add(subscription);
  }

}

export class PublicHeat {
  single: Array<Heat>;
  team: Array<Heat>;
}
