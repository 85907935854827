import {Component, OnInit} from '@angular/core';
import {FuseConfigService} from '../../../../../core/services/config.service';
import {EventPageService} from '../../../../../core/services/event-page/event-page.service';
import {MatDialog} from '@angular/material';
import {EventGuard} from '../../../../../core/guards/event.guard';
import { Event } from './../../../../../core/model/event';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { locale as english } from './i18n/en-US';
import { locale as portugues } from './i18n/pt-BR';
import {requiredWithConditional} from '../../../../../core/utils/custom-validators';
import {FuseTranslationLoaderService} from '../../../../../core/services/translation-loader.service';
import {TranslateService} from '@ngx-translate/core';
import {Championship} from '../../../../../core/model/championship';
import {Subscription} from 'rxjs';
import {WodService} from '../../../../../core/services/wod/wod.service';
import {environment} from '../../../../../../environments/environment';
import {Competition} from '../../../../../core/model/competition';
import {Wod} from '../../../../../core/model/wod';
import {DomSanitizer} from '@angular/platform-browser';
import {Utils} from '../../../../../core/utils';
import {WodType} from '../../../../../core/model/wod-type';

@Component({
  selector: 'app-workout',
  templateUrl: './workout.component.html',
  styleUrls: ['./workout.component.scss']
})
export class WorkoutComponent implements OnInit {
  private translate: any;
  private readonly subscriptions = new Subscription();
  
  event: Event = null;
  workoutForm: FormGroup;
  competitions: Competition[] = []
  wodIdOpen: number = null
  isLoading: boolean = false;
  private isFirstLoad: boolean = false;
  
  constructor(
    private fuseConfig: FuseConfigService,
    private eventPageService: EventPageService,
    private eventGuard: EventGuard,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private wodService: WodService,
    private sanitizer: DomSanitizer,
  ) {
    this.translationLoader.loadTranslations(english, portugues);

    this.translateService.get("WORKOUT").subscribe((res: any) => {
      this.translate = res
    })
    
    // Get Event
    this.event = this.eventPageService.event
  }

  ngOnInit() {
    this.createForm()
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  
  // Create form
  createForm() {
    this.workoutForm = this.formBuilder.group({
      championship: this.event.championships.length > 0 ? this.event.championships[0].id : ''
    });

    const subscription = this.workoutForm.get('championship').valueChanges.subscribe(val => {
      this.getWodsWithChampionship(val);
    });
    this.subscriptions.add(subscription);
  }

  getWodsWithChampionship(championshipId: number) {
    if (championshipId) {
      this.isLoading = true;
      this.wodService.getPublicWodWithChampionship(championshipId)
        .subscribe(
          result => {
            this.competitions = result
            this.isLoading = false;
          }, err => {
            this.isLoading = false;
          }
        )
    } 
  }
  
  setWodId(wodId: number) {
    this.wodIdOpen = this.wodIdOpen === null || this.wodIdOpen !== wodId ? wodId : null;
  }
  
  getVideoUrl(wod: Wod) {
    const videoId = Utils.getYoutubeVideoId(wod.video_url);
    const url = `https://www.youtube.com/embed/${videoId}`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  
  getUnitPerId(resultType: WodType): string
  {
    return Utils.getUnitPerId(resultType.id, this.translateService.currentLang).name
  }
  
  public tabWorkoutChanged(): void
  {
    if (!this.isFirstLoad) {
      this.isFirstLoad = true;
      this.getWodsWithChampionship(this.event.championships[0].id)
    }
  }
}
