import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/map';
import { SubscriptionPayment } from './../../../../core/model/subscription-payment';
import { ModalConfirmPaymentComponent } from './registration/modal-confirm-payment/modal-confirm-payment.component';
import { Utils } from './../../../../core/utils';
import { Lot } from './../../../../core/model/lot';
import { Subscription } from './../../../../core/model/subscription';
import { ModalLotsListComponent } from './modal-lots-list/modal-lots-list.component';
import { environment } from './../../../../../environments/environment';
import { GooglePlacesService } from './../../../../core/services/google-places.service';
import { Event } from './../../../../core/model/event';
import { SEOService } from '../../../../core/services/seo-service/seo.service';
import { EventPageService } from './../../../../core/services/event-page/event-page.service';
import {Component, OnInit, HostListener, ElementRef, NgZone, ViewChild, ViewEncapsulation, SecurityContext} from '@angular/core';
import { FuseConfigService } from '../../../../core/services/config.service';
import { Title, DomSanitizer } from '@angular/platform-browser';
import { FuseTranslationLoaderService } from '../../../../core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthGuard } from '../../../../core/guards/auth.guard';
import { Router, NavigationEnd } from '@angular/router';
import {MatIconRegistry, MatDialog, MatTabGroup} from '@angular/material';
import { EventsService } from '../../../../core/services/event/events.service';
import { BreadcrumbsService } from '../../../../core/components/breadcrumbs/breadcrumbs.service';
import { EventGuard } from '../../../../core/guards/event.guard';
import { UserService } from '../../../../core/services/user/user.service';
import { locale as english } from './i18n/en-US';
import { locale as portugues } from './i18n/pt-BR';
import * as moment from 'moment';
import { Observable, fromEvent } from 'rxjs';
import 'rxjs/add/observable/fromEvent';
import { SubscriptionService } from '../../../../core/services/subscription/subscription.service';
import { CrossXDialogComponent } from '../../../../core/components/cross-x-dialog/cross-x-dialog.component';
import { config } from '../../../../core/config/config';
import {HeatsComponent} from './heats/heats.component';
import {WorkoutComponent} from './workout/workout.component';

@Component({
  selector: 'app-event-page',
  templateUrl: './event-page.component.html',
  styleUrls: ['./event-page.component.scss']
})
export class EventPageComponent implements OnInit {
  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  @ViewChild('heats') heats: HeatsComponent;
  @ViewChild('workouts') workouts: WorkoutComponent;
  
  event: Event = null;
  slug: string;
  eventUrl: string;
  eventTextShare: string;
  user?: any = null;

  lat: any;
  lng: any;

  headerPlaceholder = './assets/images/backgrounds/background-myprofile.jpg';
  isLoading = false;
  isLotLoading = false;
  hideNavBar = true;
  translate: any;
  private element: HTMLElement;
  private domain: string = environment.domain;
  private selectedTabIndex = 0;

  constructor(
    private fuseConfig: FuseConfigService,
    private eventPageService: EventPageService,
    private titleService: Title,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private authGuard: AuthGuard,
    private router: Router,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private eventsService: EventsService,
    private breadcrumbsService: BreadcrumbsService,
    private eventGuard: EventGuard,
    public dialog: MatDialog,
    public userService: UserService,
    private seoService: SEOService,
    private googlePlacesService: GooglePlacesService,
    private el: ElementRef,
    private zone: NgZone,
    private subscriptionService: SubscriptionService
  ) { 

    this.translationLoader.loadTranslations(english, portugues);

    this.translateService.get('EVENT').subscribe((res: any) => {
      this.translate = res;
    });

    this.fuseConfig.setSettings({
      layout: {
        navigation: 'none',
        toolbar: 'below',      // 'above', 'below', none
        footer: 'none'
      }
    });

    // Get Slug event
    this.slug = this.eventPageService.getSlug();
    if (this.slug === null) { this.gotoHome(); }
    
    this.user = this.authGuard.getMe().user;

    this.authGuard.watchStorage().subscribe((data: any) => {
      this.user = this.authGuard.getMe().user;
    });
    // verify user logged
    // this.authGuard.canActivateWithoutRedirect()

    // Icon Info
    iconRegistry.addSvgIcon(
      'ic-info',
      sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/myprofile/ic-profile.svg')
    );

    this.breadcrumbsService.removeAll();
  }

  ngOnInit() {
    this.getEventDetail();
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          if (event.url === '/event') {
            this.getEventDetail(); 
          }
        }
      });

    // Scroll to show navbar
    const isMobile = /Android|iPhone/i.test(window.navigator.userAgent);
    if (!isMobile) {
      this.element = document.getElementById('scrollBar');
      this.zone.runOutsideAngular(() => {
        fromEvent(this.element, 'scroll')
          .debounceTime(1)
          .subscribe(res => {
            this.onScroll(res);
          });
      });
    }
  }

  // Event Detail
  getEventDetail() {
    this.isLoading = true;
    this.eventPageService.getEventDetail(this.slug)
      .subscribe(
        result => {

          if (result && !result.published) {
            window.location.href = environment.dashUrl;
            return;
          }
          /*else if (result && !result.enabled_subscription && result.enabled_leaderboard && result.leaderboard_published) {
            window.location.href = '/leaderboard'
            return
          }*/

          this.event = result;
          console.log( this.event,'event');
          this.populateEvent();
          this.isLoading = false;
        }, err => {
          this.isLoading = false;
        }
      ); 
  }

  populateEvent() {
   
    this.eventUrl = encodeURI(`${window.location}`);
    this.eventTextShare = encodeURI(`${this.event.name} - ${window.location}`);

    this.titleService.setTitle(`${this.event.name} - ${config.titleBase}`);
    this.seoService.addMetaTags({
      title: this.event.name,
      image: this.event.email_logo,
      url: `${window.location}`,
    });

    if (this.event.place_id && this.event.place_id.length > 0) {
      this.googlePlacesService.placeIdDetail(this.event.place_id)
        .subscribe(
          result => {
            this.lat = result.geometry.location.lat();
            this.lng = result.geometry.location.lng();
          }, err => {

          }
        );
    }
  }

  // Show Lots
  showLotsList() {
    console.log('event', this.event);
    if ((this.event.lots === null || this.event.lots.length === 0) && !this.event.lot_invitation) {
      return;
    }

    this.showModalListLot();
  }

  cancelOldSubscription(subscription: any, lot: Lot, coupon: any) {
    this.subscriptionService.cancelOld(subscription.id)
      .subscribe(
        result => {
          this.eventPageService.setLot(lot);
          this.eventPageService.setCoupon(coupon);
          this.router.navigate(['/event-registration']);
        }, err => {
          this.isLotLoading = false;
          // this.showModalListLot()
        }
      ); 
  }

  showModalListLot() {
    const modalRef = this.dialog.open(ModalLotsListComponent, {
      width: '550px',
      height: 'auto',
      // panelClass: 'modal'
    });

    modalRef.componentInstance.lots = this.event.lots;
    modalRef.componentInstance.event = this.event;
    modalRef.componentInstance.user = this.user;

    modalRef.afterClosed().subscribe(result => {
      if (result === null || result === undefined){
        return;
      } 
      
      const lot = result.lot;
      const coupon = result.coupon;
      const subscription = result.subscription;

      if (result && result.error && (result.error === 'hasSubscription' || result.error === 'hasSubscriptionConfirmed') && result.subscription !== null) {
        if (result.error === 'hasSubscriptionConfirmed' || result.subscription.status === 2) {
          this.showErrorSubscribe(result.message ? result.message : null);
        }
        else if (result.subscription.status === 1) {
          const dialogRef = this.dialog.open(ModalConfirmPaymentComponent, {
            width: '440px'
          });

          const payment = new SubscriptionPayment().deserialize(result.subscription.payments);
          dialogRef.componentInstance.dialogConfirm = false;
          dialogRef.componentInstance.cancelButton = 'PAGAR COM CARTÃO DE CRÉDITO';
          dialogRef.componentInstance.titleDialog = this.translate.SUBSCRIPTION_ORDER.TITLE;
          dialogRef.componentInstance.dialogContent = this.translate.SUBSCRIPTION_ORDER.DESCRIPTION;
          dialogRef.componentInstance.subscriptionPayment = payment;
          dialogRef.componentInstance.type = 'success';

          dialogRef.afterClosed().subscribe((result) => {
            if (result) {
              this.eventPageService.setLot(lot);
              this.eventPageService.setCoupon(coupon);
              this.eventPageService.setSubscription(subscription);
              this.router.navigate(['/event-registration']);
            }
          });
        }
        else {
          this.showDialogExistSubscription(result.subscription, result.lot, result.coupon);
        }
      }
      else if (result && result.error && result.error === 'emptyBankAccount') {
        this.showErrorBankAccount();
      }
      else if (result && result.lot) {        
        this.eventPageService.setLot(result.lot);
        this.eventPageService.setCoupon(result.coupon);

        if (this.authGuard.getMe().user) {
          this.router.navigate(['/event-registration']);
        }
        else {
          this.router.navigate(['/event-identification']);
        }
      }
    });
  }

  showErrorSubscribe(description: string = null) {
    const dialogRef = this.dialog.open(CrossXDialogComponent, {
      width: '440px',
      disableClose: true
    });

    dialogRef.componentInstance.dialogConfirm = false;
    dialogRef.componentInstance.cancelButton = 'FECHAR';
    dialogRef.componentInstance.type = 'error';
    dialogRef.componentInstance.titleDialog = this.translate.SUBSCRIPTION.TITLE;
    dialogRef.componentInstance.dialogContent = description ? description : this.translate.SUBSCRIPTION.DESCRIPTION_COMPLETED;

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  
  showErrorBankAccount() {
    const dialogRef = this.dialog.open(CrossXDialogComponent, {
      width: '440px',
      disableClose: true
    });

    dialogRef.componentInstance.dialogConfirm = false;
    dialogRef.componentInstance.cancelButton = 'FECHAR';
    dialogRef.componentInstance.type = 'error';
    dialogRef.componentInstance.titleDialog = this.translate.BANK_ACCOUNT.TITLE;
    dialogRef.componentInstance.dialogContent = this.translate.BANK_ACCOUNT.DESCRIPTION;

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  showDialogExistSubscription(subscription: any, lot: Lot, coupon: any)
  {
    const dialogRef = this.dialog.open(CrossXDialogComponent, {
      width: '440px',
      disableClose: true
    });

    dialogRef.componentInstance.dialogConfirm = true;
    dialogRef.componentInstance.object = subscription;
    dialogRef.componentInstance.cancelButton = 'EXCLUIR';
    dialogRef.componentInstance.actionButton = 'CONTINUAR';
    dialogRef.componentInstance.type = 'warning';
    dialogRef.componentInstance.titleDialog = this.translate.SUBSCRIPTION.TITLE;
    dialogRef.componentInstance.dialogContent = this.translate.SUBSCRIPTION.DESCRIPTION;

    dialogRef.afterClosed().subscribe(result => {
      // continue
      if (result) {
        this.isLotLoading = false;
        const subscriptionData = new Subscription().deserialize(result);
        this.eventPageService.setLot(result.lot);
        this.eventPageService.setCoupon(result.coupon);
        this.eventPageService.setSubscription(subscriptionData);
        this.router.navigate(['/event-registration']);
      }
      // cancel
      else {
        this.cancelOldSubscription(subscription, lot, coupon);
      }
    });
  }


  onScroll(e) {
    this.zone.run(() => {
      this.hideNavBar = e.target.scrollTop < 400;
    });
  }

  gotoHome() {
    window.location.href = environment.dashUrl;
  }

  seeRegulation() {
    Utils.openInOtherWindowWithValidationURL(this.event.doc_rule);
  }

  seeTerms() {
    Utils.openInOtherWindowWithValidationURL(this.event.doc_term);
  }
  
  getTitleLotButton(): string
  {
    if (this.event.lot_invitation) {
      return this.translate.LOTS; 
    }
    if (this.event.lots && this.event.lots.length === 0 && !this.event.lot_sold_off) {
      return this.translate.LOTS_WAITING;
    }
    else if (this.event.lots && this.event.lots.length === 0 && this.event.lot_sold_off){
      return this.translate.LOTS_SOLDOUT;
    }
    else if (this.event.lots && this.event.lots.length > 0) {
      return this.translate.LOTS; 
    }
  }

  gotoLeaderboard($event)
  {
    if ($event.tab.textLabel.toLowerCase() === this.translate.TABS.HEATS.toLowerCase()) {
      this.heats.tabHeatChanged();
    }
    else if ($event.tab.textLabel.toLowerCase() === this.translate.TABS.WODS.toLowerCase()) {
      this.workouts.tabWorkoutChanged();
    }
    else if ($event.tab.textLabel === 'LEADERBOARD') {
      const url = `https://${this.event.slug}.${this.domain}/leaderboard`;
      if (isValidUrl(url)) {
        Utils.openInOtherWindowWithValidationURL(url);
      } else {
        console.error('Invalid URL:', url);
      }
      this.tabGroup.selectedIndex = this.selectedTabIndex; 
      return;
    }
    this.selectedTabIndex = $event.index;
  }
}

function isValidUrl(url: string): boolean {
  try {
    new URL(url);
    return true;
  } catch (_) {
    return false;
  }
}
