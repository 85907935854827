import {Component, OnInit} from '@angular/core';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {BreadcrumbsService} from '../../../../../../core/components/breadcrumbs/breadcrumbs.service';
import {FuseTranslationLoaderService} from '../../../../../../core/services/translation-loader.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthGuard} from '../../../../../../core/guards/auth.guard';
import {EventGuard} from '../../../../../../core/guards/event.guard';
import {MatDialog, MatDialogRef, MatIconRegistry} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {GooglePlacesService} from '../../../../../../core/services/google-places.service';
import {EventsService} from '../../../../../../core/services/event/events.service';
import {ChampionshipService} from '../../../../../../core/services/championship/championship.service';
import {ToastrService} from '../../../../../../core/components/toastr_/toastr/toastr.service';
import {BankAccountService} from '../../../../../../core/services/bank-account/bank-account.service';
import {BanksService} from '../../../../../../core/services/banks/banks.service';
import {EventConfigurationsService} from '../../../../../../core/services/event-configurations/event-configurations.service';
import {locale as english} from '../../i18n/en-US';
import {locale as portugues} from '../../i18n/pt-BR';
import {Subscription} from 'rxjs';
import {Event} from '../../../../../../core/model/event';
import {config} from '../../../../../../core/config/config';
import {UserService} from '../../../../../../core/services/user/user.service';
import {CrossXDialogComponent} from '../../../../../../core/components/cross-x-dialog/cross-x-dialog.component';
import {Errors} from '../../../../../../core/model/errors';
import {validateBRL, validateCNPJ, validateCPF} from '../../../../../../core/utils/custom-validators';
import {CepService} from '../../../../../../core/services/cep/cep.service';

@Component({
  selector: 'app-event-bank-account-form',
  templateUrl: './event-bank-account-form.component.html',
  styleUrls: ['./event-bank-account-form.component.scss']
})
export class EventBankAccountFormComponent implements OnInit {

  public translate: any;
  public event: Event = null;
  public isCompany = false;
  public KycButton = false;
  timeOutSearchBank;
  banks = [];
  public users: any[] = [];
  public ownerTypes = [
    {value: 'pf', label: 'Pessoa Física'},
    {value: 'pj', label: 'Pessoa Jurídica'}
  ];
  public bankAccountForm: FormGroup;
  public matchers: any;
  public userSelect: any = null;
  timeOut;
  public isLoading = false;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private titleService: Title,
    private breadcrumbsService: BreadcrumbsService,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authGuard: AuthGuard,
    private eventGuard: EventGuard,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private googlePlacesService: GooglePlacesService,
    public dialog: MatDialog,
    private eventService: EventsService,
    private championshipService: ChampionshipService,
    private toastr: ToastrService,
    private bankAccountService: BankAccountService,
    private banksService: BanksService,
    private eventConfigurationService: EventConfigurationsService,
    private userService: UserService,
    private toastrService: ToastrService,
    private cepService: CepService
  ) {
  }

  ngOnInit() {
    this.registerOnTranslate();
    this.getEvent();
    this.configForm();
    this.getRecipient();
    this.formInputOnValueChangesZipCode();
    this.formInputOnValueChangesZipCodeCompany();
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  doKyc(): void {
    this.bankAccountService.createKycLink(this.event.user.id).subscribe((result: any) => {
      if (result && result.kyc_link) {
        window.open(result.kyc_link, '_blank');
        return;
      }
      this.toastrService.error('ocorreu um erro ao gerar KYC');
      console.warn(result);
    });
  }

  getRecipient(): void {
    const subscription = this.bankAccountService.getRecipient(this.event.user.id).subscribe(
      (result: any) => {
        if (!result || (typeof result === 'object' && Object.keys(result).length === 0)) {
          this.KycButton = false;
          return;
        }

        const recipient = result;
        const addresses = recipient.recipients.addresses || [];
        
        const companyAddress = addresses.find(x => x.type === 'company') || {} as any;
        const ownerAddress = addresses.find(x => x.type === 'owner') || {} as any;

        recipient.recipients.owner_monthly_income = new Intl.NumberFormat('pt-BR', {
          style: 'decimal',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(recipient.recipients.owner_monthly_income || 0);

        recipient.recipients.annual_revenue = new Intl.NumberFormat('pt-BR', {
          style: 'decimal',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(recipient.recipients.annual_revenue || 0);

        const ownerType = recipient.recipients.type === 'company' ? 'pj' : 'pf';
        this.isCompany = recipient.recipients.type === 'company';

        const accountType = recipient.type === 'conta_corrente' ? 'checking' : 'savings';

        // Preenche o formulário garantindo valores padrão para evitar erros de "undefined"
        this.bankAccountForm.patchValue({
          account_name: recipient.legal_name || '',
          cpf: recipient.document_number || '',
          bank: recipient.recipients.bank || '',
          account_type: accountType,
          agency: recipient.agency || '',
          agency_digit: recipient.agency_digit || '',
          account_number: recipient.account_number || '',
          account_digit: recipient.account_digit || '',
          owner_type: ownerType,
          owner_phone: recipient.recipients.owner_phone_number || '',
          owner_professional_occupation: recipient.recipients.owner_professional_occupation || '',
          owner_birthdate: recipient.recipients.owner_birthdate || '',
          owner_cpf: recipient.recipients.owner_document || '',
          company_cpf: recipient.recipients.owner_document || '',
          owner_cnpj: recipient.recipients.company_cnpj || '',
          owner_email: recipient.recipients.owner_email || '',
          owner_name: recipient.recipients.owner_name || '',
          owner_monthly_income: recipient.recipients.owner_monthly_income || '',

          trade_name: recipient.recipients.company_trading_name || '',
          company_name: recipient.recipients.company_name || '',
          company_email: recipient.recipients.company_email || '',
          phone: recipient.recipients.company_phone || '',
          annual_revenue: recipient.recipients.company_annual_revenue || '',

          zip_code: ownerAddress.zip_code || '',
          address: ownerAddress.street || '',
          number: ownerAddress.street_number || '',
          complement: ownerAddress.complementary || '',
          neighborhood: ownerAddress.neighborhood || '',
          city: ownerAddress.city || '',
          state: ownerAddress.state || '',
          reference_point: ownerAddress.reference_point || '',

          company_zip_code: companyAddress.zip_code || '',
          company_address: companyAddress.street || '',
          company_number: companyAddress.street_number || '',
          company_complement: companyAddress.complementary || '',
          company_neighborhood: companyAddress.neighborhood || '',
          company_city: companyAddress.city || '',
          company_state: companyAddress.state || '',
          company_reference_point: companyAddress.reference_point || '',
        });

        // Desativa os campos do formulário após preenchê-los
        [
          'owner_type', 'owner_cpf', 'owner_email', 'owner_cnpj', 'company_cpf', 'owner_name',
          'trade_name', 'phone', 'annual_revenue', 'company_name', 'zip_code', 'address', 'number',
          'complement', 'neighborhood', 'city', 'state', 'reference_point', 'company_zip_code',
          'company_address', 'company_number', 'company_complement', 'company_neighborhood',
          'company_city', 'company_state', 'company_reference_point', 'owner_professional_occupation',
          'owner_monthly_income', 'agency_digit', 'agency', 'account_number', 'account_digit',
          'bank', 'account_type', 'account_name'
        ].forEach(field => this.bankAccountForm.get(field).disable());

        this.KycButton = recipient.recipients.kyc_status !== 'approved';
      },
      (err) => {
        console.error('Error fetching recipient data:', err);
      }
    );

    this.subscriptions.add(subscription);

  }


  changeOwnerType(event: any): void {
    this.isCompany = event === 'pj';
    this.bankAccountForm.get('owner_type').setValue(event);
    // this.configForm();
  }

  displayFnBank(bank: any): string {
    return bank ? `${bank.code} - ${bank.title}` : bank;
  }

  searchBank(bank: string) {
    clearTimeout(this.timeOutSearchBank);
    this.timeOutSearchBank = setTimeout(() => {
      if (bank.length > 0) {
        this.subscriptions.add(this.banksService.search(bank)
          .subscribe(
            result => {
              // this.banks = result;
              this.banks = Array.isArray(result) ? result : [];
            }, err => {
              this.banks = [];
            }
          ));
      } else {
        this.banks = [];
      }
    }, 300);

  }

  public saveAdmin(): void {
    // validate all form fields
    // if (!this.bankAccountForm.valid || this.bankAccountForm.value.user === null) {
    //   Utils.formValid(this.bankAccountForm);
    //   return;
    // }

    const birthdate = this.bankAccountForm.get('owner_birthdate').value;

    // Reaplica a máscara antes de enviar, caso necessário
    const formattedValue = this.formatDateWithMask(birthdate);

    const rawFormData = this.bankAccountForm.getRawValue();
    const formData = {
      ...rawFormData,
      owner_birthdate: formattedValue,
      event_id: this.event.id
    };
    console.log(formData);
    this.bankAccountService.createV5(formData).subscribe((response) => {

      if (!response || (typeof response === 'object' && Object.keys(response).length === 0)) {
        this.toastrService.error('Ocorreu um erro ao salvar');
        console.warn(response);
        this.KycButton = false;
        return;
      }

      this.toastrService.success('Conta bancária salva com sucesso');
      this.KycButton = true;


    });
  }

  // search user
  public searchUser(term: string) {
    clearTimeout(this.timeOut);

    if (term.length > 2) {
      this.timeOut = setTimeout(() => {
        this.searchUserWithTerm(term);
      }, 1000);

    } else if (term.length === 0) {
      this.users = [];
      // this.userId = null
    }
  }

  searchUserWithTerm(term: string) {
    clearTimeout(this.timeOut);
    const subscription = this.userService.searchUserByTerm(term)
      .subscribe(
        result => {
          this.users = result;
        }, err => {
          this.users = [];

        }
      );

    this.subscriptions.add(subscription);
  }

  public displayFnUser(user: any): string {
    this.userSelect = user;
    return user ? `${user.firstname} ${user.lastname}`.toUpperCase() : user;
  }

  private formatDateWithMask(value: string): string {
    return value.replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3');
  }

  private updateAdminInAPI(parameters: any, dialogRef: MatDialogRef<CrossXDialogComponent, any>): void {

    this.eventService.updateEvent(this.event.id, parameters)
      .subscribe(
        result => {
          this.eventGuard.setEvent(result);
          this.getEvent();

          this.toastr.show(
            this.translate.TOASTR.SUCCESS.DESCRIPTION,
            this.translate.TOASTR.SUCCESS.TITLE,
            null,
            'success'
          );

          dialogRef.componentInstance.confirmLoading = false;
          dialogRef.close();

        }, err => {
          const errors: Errors = new Errors().deserialize((err.error as any));

          this.toastr.show(
            errors.getFullMessages(),
            this.translate.TOASTR.ERROR.TITLE,
            null,
            'error'
          );

          dialogRef.componentInstance.confirmLoading = false;
          dialogRef.close();
        }
      );

  }

  private configForm(): void {
    this.bankAccountForm = this.formBuilder.group({
      user: [this.event.user, [Validators.required]],
      owner_type: ['', Validators.required],
      owner_phone: ['', [Validators.required]],
      owner_professional_occupation: ['', Validators.required],
      owner_birthdate: ['', Validators.required],
      owner_cpf: ['', [Validators.required, validateCPF]],
      owner_email: ['', Validators.required],
      owner_name: ['', Validators.required],
      owner_monthly_income: ['', [Validators.required, validateBRL]],

      account_name: ['', Validators.required],
      bank: ['', Validators.required],
      account_type: ['', Validators.required],
      agency_number: ['', Validators.required],
      agency: ['', Validators.required],
      agency_digit: ['', Validators.required],
      account_number: ['', Validators.required],
      account_digit: ['', Validators.required],

      trade_name: ['', []],
      company_name: ['', []],
      company_cpf: ['', []],
      company_email: ['', []],
      owner_cnpj: ['', []],
      phone: ['', []],
      annual_revenue: ['', []],

      // Campos de Endereço
      zip_code: ['', Validators.required],
      address: ['', Validators.required],
      number: ['', Validators.required],
      complement: ['', Validators.required],
      neighborhood: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      reference_point: ['', Validators.required],

      company_zip_code: ['', Validators.required],
      company_address: ['', Validators.required],
      company_number: ['', Validators.required],
      company_complement: ['', Validators.required],
      company_neighborhood: ['', Validators.required],
      company_city: ['', Validators.required],
      company_state: ['', Validators.required],
      company_reference_point: ['', Validators.required]
    });

    this.updateFormValidators();
  }

  private updateFormValidators(): void {
    // Preserve current form values
    const currentValues = this.bankAccountForm.getRawValue();

    if (this.isCompany) {
      this.bankAccountForm.get('trade_name').setValidators([Validators.required]);
      this.bankAccountForm.get('company_name').setValidators([Validators.required]);
      this.bankAccountForm.get('company_email').setValidators([Validators.required, Validators.email]);
      this.bankAccountForm.get('phone').setValidators([Validators.required]);
      this.bankAccountForm.get('annual_revenue').setValidators([Validators.required]);
    } else {
      this.bankAccountForm.get('trade_name').clearValidators();
      this.bankAccountForm.get('company_name').clearValidators();
      this.bankAccountForm.get('company_email').clearValidators();
      this.bankAccountForm.get('phone').clearValidators();
      this.bankAccountForm.get('annual_revenue').clearValidators();
    }

    // Update validators and validity of the form
    this.bankAccountForm.updateValueAndValidity();

    // Restore preserved values
    this.bankAccountForm.patchValue(currentValues);
  }


  private formInputOnValueChangesZipCode(): void {
    const subscription = this.bankAccountForm.get('zip_code').valueChanges.subscribe(value => {

      if (value.length > 7) {
        const subscriptionForm = this.cepService.search(value)
          .subscribe(
            result => {
              if (result) {
                this.bankAccountForm.get('address').setValue(result.street);
                this.bankAccountForm.get('neighborhood').setValue(result.neighborhood);
                this.bankAccountForm.get('city').setValue(result.city);
                this.bankAccountForm.get('state').setValue(result.state);
              }
            },
            err => {
            }
          );

        this.subscriptions.add(subscriptionForm);

      } else {
        this.bankAccountForm.get('address').setValue('');
        this.bankAccountForm.get('number').setValue('');
        this.bankAccountForm.get('neighborhood').setValue('');
        this.bankAccountForm.get('city').setValue('');
        this.bankAccountForm.get('state').setValue('');
      }
    });

    this.subscriptions.add(subscription);
  }

  private formInputOnValueChangesZipCodeCompany(): void {
    const subscription = this.bankAccountForm.get('company_zip_code').valueChanges.subscribe(value => {

      if (value.length > 7) {
        const subscriptionForm = this.cepService.search(value)
          .subscribe(
            result => {
              if (result) {
                this.bankAccountForm.get('company_address').setValue(result.street);
                this.bankAccountForm.get('company_neighborhood').setValue(result.neighborhood);
                this.bankAccountForm.get('company_city').setValue(result.city);
                this.bankAccountForm.get('company_state').setValue(result.state);
              }
            },
            err => {
            }
          );

        this.subscriptions.add(subscriptionForm);

      } else {
        this.bankAccountForm.get('company_address').setValue('');
        this.bankAccountForm.get('company_number').setValue('');
        this.bankAccountForm.get('company_neighborhood').setValue('');
        this.bankAccountForm.get('company_city').setValue('');
        this.bankAccountForm.get('company_state').setValue('');
      }
    });

    this.subscriptions.add(subscription);
  }

  private registerOnTranslate(): void {

    this.translationLoader.loadTranslations(english, portugues);
    const subscription = this.translateService
      .get('PERMISSIONS')
      .subscribe((response: Object) => {
        this.translate = response;

        this.titleService.setTitle(`${config.titleBase} - ${this.translate.TITLE}`);

        this.breadcrumbsService.replaceAll([{
          text: this.translate.TITLE_SUBSCRIPTIONS,
          route: `/event/subscriptions`
        }]);
      });

    this.subscriptions.add(subscription);

  }

  private getEvent(): void {
    this.event = new Event().deserialize(this.eventGuard.getActiveEvent().event);
  }

}
