import { TeamMember } from './../../model/team-member';
import { Team } from './../../model/team';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, Subscription } from 'rxjs';
import { Competitor } from '../../model/competitor';

@Injectable({
  providedIn: 'root'
})
export class TeamService implements OnDestroy {

  private subscriptions = new Subscription();
  private _teamObservable = new Subject<any>();

  constructor(
    private http: HttpClient
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  teamObservable(): Observable<any> {
    return this._teamObservable.asObservable();
  }

  alterMember(parameters: any, teamId: number, teamMemberId: number, isAdmin: boolean = true): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const url = `${isAdmin ? 'admin/' : ''}teams/${teamId}/member/${teamMemberId}`

    return this.http.put(url, parameters, { responseType })
      .map((response: Response) => {
        return new Team().deserialize(response as any)
      })
  }

  setTeamCaptain(team: Team, userId: number, isAdmin: boolean = true): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const url = `${isAdmin ? 'admin/' : ''}teams/${team.id}`
    const parms = {
      user_id: userId
    }

    return this.http.put(url, parms, { responseType })
      .map((response: Response) => {
        return response
        // return new Competition().deserialize(response as any)
      })
  }

  getCompetitors(championshipId: number, searchTerm: string = '', type: string = 'all', sort: any = null, page: number = 0): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    var url = `admin/championships/${championshipId}/competitors/${type}/?page=${page}&name=${searchTerm}`

    if (sort) url = url + `&order_by[${sort.column}]=${sort.order}`

    return this.http.get(url, { responseType })
      .map((response: Response) => {

        let competitors = (response as any).map((competitor: Competitor) => new Competitor().deserialize(competitor))
        return competitors
      })
  }

  // Members
  createMember(parameters: any): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const url = `admin/teammembers`

    return this.http.post(url, parameters, { responseType })
      .map((response: Response) => {
        return new Team().deserialize(response as any)
      })
  }

  deleteMember(teamMemberId: number): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const url = `admin/teammembers/${teamMemberId}`

    return this.http.delete(url, { responseType })
      .map((response: Response) => {
        return response
      })
  }

  // Get Team detail
  show(teamId: number): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const url = `teams/${teamId}`

    return this.http.get(url, { responseType })
      .map((response: Response) => {
        return new Team().deserialize(response as any)
      })
  }

  update(parameters: any, teamId: number, isAdmin: boolean = true): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const url = `${isAdmin ? 'admin/' : ''}teams/${teamId}`

    return this.http.put(url, parameters, { responseType })
      .map((response: Response) => {
        return new Team().deserialize(response as any)
      })
  }
  
  updateMember(parameters: any, teamMemberId: number, isAdmin: boolean = true): Observable<any> {
    // const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const url = `${isAdmin ? 'admin/' : ''}teammembers/${teamMemberId}`

    return this.http.put(url, parameters)
      .map((response: Response) => {
        return new TeamMember().deserialize(response as any)
      })
  }

  public fetchTeam(teamId: number): void
  {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const parsedUrl = `public/teams/${teamId}`;
    
    const subscription = this.http
      .get(parsedUrl, {responseType})
      .subscribe((team: Team) => {
          this._teamObservable.next(team);
        },
        () => {
          this._teamObservable.next([]);
        });

    this.subscriptions.add(subscription);
  }
}
