import {Component, OnInit, SecurityContext, ViewChild} from '@angular/core';
import {TabBarComponent} from '../../../../core/components/tab-bar/tab-bar.component';
import {TableConfig, TableXComponent} from '../../../../core/components/table-x/table-x.component';
import {FusePerfectScrollbarDirective} from '../../../../core/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {BreadcrumbsService} from '../../../../core/components/breadcrumbs/breadcrumbs.service';
import {FuseTranslationLoaderService} from '../../../../core/services/translation-loader.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {EventGuard} from '../../../../core/guards/event.guard';
import {MatDialog, MatDialogRef, MatIconRegistry} from '@angular/material';
import {CompetitorService} from '../../../../core/services/competitor/competitor.service';
import {ToastrService} from '../../../../core/components/toastr_/toastr/toastr.service';
import {locale as english} from './i18n/en-US';
import {locale as portugues} from './i18n/pt-BR';
import {Utils} from '../../../../core/utils';
import {config} from '../../../../core/config/config';
import { Event } from  '../../../../core/model/event';
import {SubscriptionService} from '../../../../core/services/subscription/subscription.service';
import {Subscription} from '../../../../core/model/subscription';
import * as moment from 'moment';
import {PaymentService} from '../../../../core/services/payment/payment.service';
import {Errors} from '../../../../core/model/errors';
import {CrossXDialogComponent} from '../../../../core/components/cross-x-dialog/cross-x-dialog.component';
import {Competitor} from '../../../../core/model/competitor';
import {UserProfileComponent} from '../profiles/user-profile/user-profile.component';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss']
})
export class SubscriptionsComponent implements OnInit {

  @ViewChild('tabbar', { read: TabBarComponent }) tabBar: TabBarComponent;
  @ViewChild('table', { read: TableXComponent }) tableList: TableXComponent;
  @ViewChild(FusePerfectScrollbarDirective) fusePerfectScrollbarDirective;

  //Translate 
  translate: any;

  event: Event;
  championshipId = 0;
  championships: any[];
  subscriptions: any[] = [];

  //Tabs
  tabsFilter: string[] = [];
  tabsOptions: any[] = [];

  //Table
  tableConfig: TableConfig = {
    checkbox: false,
    paddingSide: true,
    massActions: null,
    massActionCountTitle: null
  };

  // Booleans
  isLoading = false;
  isEmptyResult = false;
  isShowScrollToTop = false;
  isLoadingExport = false;

  // config request
  page = 1;
  totalPage = 20;
  loadedEnd = false;
  searchTerm = '';
  type = 'all';
  sort: any = null;

  timeOutSearch;
  isSearch = false;
  
  constructor(
    private titleService: Title,
    private breadcrumbsService: BreadcrumbsService,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private eventGuard: EventGuard,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private subscriptionService: SubscriptionService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private paymentService: PaymentService
  ) {
    this.translationLoader.loadTranslations(english, portugues);

    this.translateService.get('SUBSCRIPTIONS').subscribe((res: any) => {
      this.translate = res;
    });

    this.event = this.eventGuard.getActiveEvent().event;
    this.championships = this.eventGuard.getAllChampionships();

    if (this.translate) {
      this.titleService.setTitle(`${config.titleBase} - ${this.translate.TITLE}`);

      this.breadcrumbsService.replaceAll([{
        text: this.translate.TITLE,
        route: `/subscriptions`
      }]);
    }

    
    //Icon Info
    iconRegistry.addSvgIcon(
      'ic-info',
      sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/myprofile/ic-profile.svg')
    );
  }

  ngOnInit() {
    this.getSubscriptions();
  }
  
  //Get Subscriptions
  getSubscriptions()
  {
    this.isLoading = true;
    
    this.subscriptionService.getReportSubscriptions(this.event.id, this.championshipId, this.searchTerm, this.sort, this.page)
      .subscribe(
        result => {
          if (this.page === 1) {
            this.subscriptions = result;
          } else {
            result.forEach(subscription => {
              this.subscriptions.push(subscription);
            });
          }
          this.isLoading = false;
          this.isEmptyResult = this.subscriptions.length === 0;
          this.loadedEnd = result.length === 0;
          if (this.tableList) { this.tableList.reloadItems() }
        }, err => {
          this.isLoading = false;
          this.loadedEnd = true;
        }
      );
  }

  // refund(subscription: Subscription, index: number) {
  refund(subscription: any, index: number) {
    const paymentDate = moment(subscription.payment_date);
    if (moment().diff(paymentDate, 'days') > 28) {
      this.toastr.show(
        this.translate.TOASTR.REFUND.ERROR.DESCRIPTION,
        this.translate.TOASTR.REFUND.ERROR.TITLE,
        null,
        'error',
      );
      return;
    }

    const dialogRef = this.dialog.open(CrossXDialogComponent, {
      width: '440px',
      closeOnNavigation: true
    });

    dialogRef.componentInstance.type = 'warning';
    dialogRef.componentInstance.titleDialog = this.translate.DIALOG.REFUND_CONFIRM.TITLE;
    this.translateService.get('SUBSCRIPTIONS.DIALOG.REFUND_CONFIRM.DESCRIPTION', { user_name: subscription.user_name }).subscribe((res: string) => {
      dialogRef.componentInstance.dialogContent = res;
    });
    dialogRef.componentInstance.dialogConfirm = true;
    dialogRef.componentInstance.cancelButton = this.translate.DIALOG.REFUND_CONFIRM.CANCEL.toUpperCase();
    dialogRef.componentInstance.actionButton = this.translate.DIALOG.REFUND_CONFIRM.REFUND.toUpperCase();
    dialogRef.componentInstance.object = subscription;
    dialogRef.componentInstance.confirmActionRequest = true;
    dialogRef.componentInstance.dialogEvent.subscribe(result => {
      dialogRef.componentInstance.confirmLoading = true;
      this.refundAPI(subscription, index, dialogRef);
    });
  }
  
  refundAPI(subscription: any, index: number, dialogRef: MatDialogRef<CrossXDialogComponent>) {
    this.paymentService.refund(subscription.payment_id)
      .subscribe(
        result => {
          subscription.status = 4;
          subscription.status_description = 'Estornada';

          this.isEmptyResult = this.subscriptions.length === 0;
          this.loadedEnd = this.subscriptions.length === 0;
          if (this.tableList) { this.tableList.reloadItems() }

          dialogRef.componentInstance.confirmLoading = false;
          dialogRef.close();

          this.toastr.show(
            this.translate.TOASTR.REFUND.SUCCESS.DESCRIPTION,
            this.translate.TOASTR.REFUND.SUCCESS.TITLE,
            null,
            'success',
          );

        }, err => {

          dialogRef.componentInstance.confirmLoading = false;
          dialogRef.close();
          
          const errors: Errors = new Errors().deserialize((err.error as any));
          this.toastr.show(
            errors.getFullMessages(),
            this.translate.TOASTR.REFUND.ERROR.TITLE,
            null,
            'error',
          );
        }
      );
  }

  resendEmailConfirmed(subscription: any, index: number) {
    const dialogRef = this.dialog.open(CrossXDialogComponent, {
      width: '440px',
      closeOnNavigation: true
    });

    dialogRef.componentInstance.type = 'warning';
    dialogRef.componentInstance.titleDialog = this.translate.DIALOG.RESEND_EMAIL.TITLE;
    this.translateService.get('SUBSCRIPTIONS.DIALOG.RESEND_EMAIL.DESCRIPTION', { user_name: subscription.user_name }).subscribe((res: string) => {
      dialogRef.componentInstance.dialogContent = res;
    });
    dialogRef.componentInstance.dialogConfirm = true;
    dialogRef.componentInstance.cancelButton = this.translate.DIALOG.RESEND_EMAIL.CANCEL.toUpperCase();
    dialogRef.componentInstance.actionButton = this.translate.DIALOG.RESEND_EMAIL.SEND.toUpperCase();
    dialogRef.componentInstance.object = subscription;
    dialogRef.componentInstance.confirmActionRequest = true;
    dialogRef.componentInstance.dialogEvent.subscribe(result => {
      dialogRef.componentInstance.confirmLoading = true;
      this.resendEmailConfirmedAPI(subscription, index, dialogRef);
    });
  }

  resendEmailConfirmedAPI(subscription: any, index: number, dialogRef: MatDialogRef<CrossXDialogComponent>) {
    this.paymentService.resendEmailConfirmed(subscription.payment_id)
      .subscribe(
        result => {
          this.isEmptyResult = this.subscriptions.length === 0;
          this.loadedEnd = this.subscriptions.length === 0;
          if (this.tableList) { this.tableList.reloadItems() }

          dialogRef.componentInstance.confirmLoading = false;
          dialogRef.close();

          this.toastr.show(
            this.translate.TOASTR.RESEND_EMAIL.SUCCESS.DESCRIPTION,
            this.translate.TOASTR.RESEND_EMAIL.SUCCESS.TITLE,
            null,
            'success',
          );

        }, err => {

          dialogRef.componentInstance.confirmLoading = false;
          dialogRef.close();

          const errors: Errors = new Errors().deserialize((err.error as any));
          this.toastr.show(
            this.translate.TOASTR.RESEND_EMAIL.ERROR.DESCRIPTION,
            this.translate.TOASTR.RESEND_EMAIL.ERROR.TITLE,
            null,
            'error',
          );
        }
      );
  }
  
  exportReport() {
    this.isLoadingExport = true;

    this.subscriptionService.exportSubscriptions(this.event.id, this.championshipId, this.searchTerm)
      .subscribe(
        result => {
          Utils.openInOtherWindowWithValidationURL(result.file);

          this.isLoadingExport = false;
        }, err => {
          this.isLoadingExport = false;
        }
      );
  }

  getStatusType(status: number): string
  {
    switch (status) {
      case 0:
        return 'normal';
      case 1:
        return 'normal';
      case 2:
        return 'success';
      case 3:
        return 'danger';
      case 4:
        return 'purple';
      case 5:
        return 'danger';
    }
  }

  championshipSelect(event) {
    const id = event.value;
    this.championshipId = id;
    this.searchTerm = name;
    // Reset all options to list
    this.page = 1;
    this.totalPage = 20;
    this.isEmptyResult = false;
    this.isLoading = true;
    this.loadedEnd = false;
    this.subscriptions = [];
    this.getSubscriptions();

  }

  //Sort Results
  sortAction(sort) {
    if (sort.sort === null) {
      this.sort = null;
    } else {
      this.sort = {
        order: sort.order,
        column: sort.sort
      };
    }

    this.page = 1;
    this.totalPage = 20;
    this.loadedEnd = false;
    this.subscriptions = [];
    this.getSubscriptions();

  }

  searchName(name: string) {
    clearTimeout(this.timeOutSearch);
    this.timeOutSearch = setTimeout(() => {
      this.searchTerm = name;
      // Reset all options to list
      this.page = 1;
      this.totalPage = 20;
      this.loadedEnd = false;
      this.subscriptions = [];
      this.getSubscriptions();
    }, 200);
  }

  formatCurrency(value: string, currency: string): string {
    return Utils.formatCurrency(value, currency);
  }

  // Scroll Event to infinite scroll
  scrollEvent(event) {
    if (event.type === 'retriveWithScroll' && !this.isLoading && !this.loadedEnd) {
      this.page++;
      this.getSubscriptions();
    }
  }

  openUserProfile(item: any, index: number)
  {
    
  }

}
