import { Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'tab-bar',
  templateUrl: './tab-bar.component.html',
  styleUrls: ['./tab-bar.component.scss']
})
export class TabBarComponent implements OnInit {

  @HostBinding('style.width') width: Number = 0;
  @HostBinding('style.left') left: Number = 0;
  @ViewChild('tabbar', {read: ElementRef}) public tabbar: ElementRef;

  @Output() tabEvent = new EventEmitter();
  @Input() padding = false;
  @Input() items: string[] = [];

  @Input()
  tabActive = 0;

  constructor(
    private elRef: ElementRef
  ) {
  }

  ngOnInit() {

  }

  ngAfterContentInit() {

  }

  ngAfterViewInit() {
    // this.lineTabActive()
  }


  resetIndex() {
    this.tabActive = 0;
    this.lineTabActive();
    this.width = 0;
    this.left = 0;
  }

  tabClick(event, index: number) {
    this.tabActive = index;
    this.width = event.target.offsetWidth;
    this.left = event.target.offsetLeft;

    this.tabEvent.emit({
      type: 'tabActive',
      index: this.tabActive,
      title: this.items[this.tabActive]
    });
    
  }

  lineTabActive() {
    let active = this.elRef.nativeElement.querySelector('.active');
    if (active) {
      this.width = active.offsetWidth;
      this.left = active.offsetLeft;
    }
  }

  mouseOver(event) {
  }

  mouseLeave(event) {
    this.lineTabActive();
  }
  
}
