import { Component, OnInit } from '@angular/core';
import { FuseConfigService } from '../../../../../core/services/config.service';
import { EventPageService } from '../../../../../core/services/event-page/event-page.service';
import { Title } from '@angular/platform-browser';
import { FuseTranslationLoaderService } from '../../../../../core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material';
import { UserService } from '../../../../../core/services/user/user.service';
import { locale as english } from './i18n/en-US';
import { locale as portugues } from './i18n/pt-BR';
import { environment } from '../../../../../../environments/environment';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EventsService } from '../../../../../core/services/event/events.service';
import { HeatsTvHttpService } from './services/heats-tv-http.service';
import {HeatCompetitor} from '../../championship/heats/model/heat-competitor.model';
import {SEOService} from '../../../../../core/services/seo-service/seo.service';
import * as moment from 'moment';
import {Utils} from '../../../../../core/utils';

@Component({
  selector: 'fuse-heats-tv',
  templateUrl: 'heats-tv.component.html',
  styleUrls: ['heats-tv.component.scss']
})

export class HeatsTvComponent implements OnInit {

  translate: any;
  slug: string;
  isLoading = true;
  eventBrandUrl: string;
  eventName: string;
  form: FormGroup;
  private isOpened = true;
  private subscriptions = new Subscription();
  championships: Array<any> = [];
  championship: any;
  private currentChampionship: any;
  heats: Array<any> = [];
  private originalHeats: Array<any> = [];
  private type: { name: string, checked: boolean } = null;
  heat: any = null;
  private currentBackgroundColor = '#000000';
  private isResultInMilliseconds: boolean = false;
  isShowScore: boolean = false;

  types: Array<{ name: string, checked: boolean }> = [
    {
      name: 'single',
      checked: true
    },
    {
      name: 'team',
      checked: false
    }
  ];

  constructor(
    private fuseConfig: FuseConfigService,
    private eventPageService: EventPageService,
    private titleService: Title,
    private seoService: SEOService,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    public dialog: MatDialog,
    public userService: UserService,
    private formBuilder: FormBuilder,
    private eventService: EventsService,
    private heatsTvHttpService: HeatsTvHttpService,
  ) {
    this.type = this.getCurrentCheckedType();
    this.setupSlugOrGoHome();
    this.translationLoader.loadTranslations(english, portugues);
    this.registerOnTranslateService();
    this.setupForm();
    this.registerOnDetailsPerSlug();
    this.registerOnChampionSelected();
    this.registerOnFetchHeatsByChampionshipObservable();
    this.registerOnChangeType();
    this.registerOnFetchHeatWithHeatsCompetitorsByHeatIdObservable();
    this.registerOnChangeHeat();
  }


  // @ts-ignore
  get getClasses() {
    return {
      open: this.isOpened
    };
  }

  // @ts-ignore
  get showChampionship(): boolean {
    return this.championships.length > 1;
  }

  // @ts-ignore
  get updateHeatButtonDisabled(): boolean {
    return this.form && !this.form.dirty || (this.form && this.form.dirty && this.form.invalid);
  }

  // @ts-ignore
  get showHeatsOptions(): boolean {
    return this.heats && this.heats.length > 0;
  }

  // @ts-ignore
  get styles() {
    return {
      backgroundColor: this.currentBackgroundColor
    };
  }

  ngOnInit() {
    this.disableDefaultLayout();
  }

  gotoHome() {
    window.location.href = environment.dashUrl;
  }

  onCollapse() {
    this.isOpened = !this.isOpened;
  }

  onClick(event) {

    event.preventDefault();

    if (this.form.invalid && this.heat === null) {
      return;
    }

    // this.isLoading = true
    this.heatsTvHttpService.fetchHeatWithHeatsCompetitorsByHeatId(this.heat.id);
  }

  private registerOnFetchHeatsByChampionshipObservable(): void {
    const subscription = this.heatsTvHttpService
      .fetchHeatsByChampionshipObservable()
      .subscribe((data) => {
        this.originalHeats = data;
        this.resetForm(false, false, true);
        if (data.length > 0) {
          this.heats = data;
          this.form.controls['heat'].setValue(this.heats[0].id);
          return;
        }
        this.heats = [];


      });
    this.subscriptions.add(subscription);
  }

  private registerOnChangeType(): void {
    const subscription = this.form
      .get('type')
      .valueChanges
      .subscribe((data) => {

        this.resetForm(false, false, true);
        this.types
          .forEach((current, index) => {
            this.types[index].checked = false;

            if (current.name === data) {
              this.types[index].checked = true;
            }

          });

        this.type = this.getCurrentCheckedType();
        this.heats = this.originalHeats;
        if (this.heats.length > 0) {
          this.form.controls['heat'].setValue(this.heats[0].id)
        }
      });
    this.subscriptions.add(subscription);
  }
  
  private registerOnChangeHeat(): void {
    const subscription = this.form
      .get('heat')
      .valueChanges
      .subscribe((data) => {
        this.resetForm(false, false, false);
        this.heatsTvHttpService.fetchHeatWithHeatsCompetitorsByHeatId(data);
      });
    this.subscriptions.add(subscription);
  }
  
  private registerOnFetchHeatWithHeatsCompetitorsByHeatIdObservable(): void {
    const subscription = this.heatsTvHttpService
      .fetchHeatWithHeatsCompetitorsByHeatIdObservable()
      .subscribe((data) => {
        this.isLoading = false;
        
        let heatCompetitors: any[] = [];
        for (var i = 1; i <= data.total_lanes; i++) {
          // @ts-ignore
          var heatCompetitor = data.heat_competitors
            .find((heatCompetitor: any) => i === heatCompetitor.lane);

          if (heatCompetitor === null || heatCompetitor === undefined) {
            heatCompetitor = {
              lane: i,
              competitor: null,
              competitor_id: null
            };
          }
          heatCompetitors.push(heatCompetitor);
        }
        data.heat_competitors = heatCompetitors;
        this.heat = data;
      });

    this.subscriptions.add(subscription);
  }

  
  private registerOnDetailsPerSlug() {

    const slug = this.eventPageService.getSlug();

    const subscription = this.eventService
      .getEventDetailPerSlug(slug)
      .subscribe((data) => {
        if (data) {
          if (data.event && !data.event.published) {
            window.location.href = environment.dashUrl;
            return;
          }
          else if (data.event && !data.event.published_heats) {
            window.location.href = '/event';
            return;
          }

          this.titleService.setTitle(`${data.event.name} - HEATS`)

          this.seoService.addMetaTags({
            title: data.event.name,
            image: data.event.image,
            url: `${window.location}`,
          })
      
          this.eventName = data.event.name;
          this.eventBrandUrl = data.event.image;
          this.championships = data.championships.filter(c => c.id === 909);
          
          if (this.championships.length > 0) {
            this.championship = this.championships[0];
            this.form.controls['championship'].setValue(this.championship.id);
          }

          const currentBackgroundColor = data.configuration
            .find(current => current.name === 'leaderboard-background-color');

          if (currentBackgroundColor !== undefined) {
            this.currentBackgroundColor = currentBackgroundColor.value;
          }

          const isResultInMilliseconds = data.configuration
            .find(current => current.name === 'result-time-in-milliseconds');

          if (isResultInMilliseconds !== undefined) {
            this.isResultInMilliseconds = isResultInMilliseconds.value === "1" ? true : false;
          }
        }
      }, err => {
      });

    this.subscriptions.add(subscription);
  }

  private registerOnChampionSelected() {
    const subscription = this.form
      .get('championship')
      .valueChanges
      .subscribe((value) => {

        // @ts-ignore
        const championship = this.championships.find((current) => current.id === value);

        if (championship === undefined) {
          return;
        }

        this.resetForm(true, true, true);
        this.currentChampionship = championship;
        this.championship = championship.id;

        this.heatsTvHttpService
          .fetchHeatsByChampionship(this.championship);
      });

    this.subscriptions.add(subscription);
  }

  private setupForm() {
    this.form = this.formBuilder
      .group({
        championship: ['', Validators.required],
        type: ['', Validators.required],
        heat: ['', Validators.required],
        showScore: ['']
      });
  }

  private registerOnTranslateService(): void {
    const subscription = this.translateService
      .get('HEATS_TV')
      .subscribe((res: any) => {
        this.translate = res;
      });
    this.subscriptions.add(subscription);
  }

  private disableDefaultLayout(): void {
    this.fuseConfig
      .setSettings({
        layout: {
          navigation: 'none',
          toolbar: 'none',
          footer: 'none'
        }
      });
  }

  private setupSlugOrGoHome(): void {
    // Get Slug event
    this.slug = this.eventPageService.getSlug();

    if (this.slug === null) {
      this.gotoHome();
    }
  }

  private resetForm(resetChampionship: boolean, resetType: boolean, resetHeats: boolean): void {

    if (resetChampionship) {
      this.championship = null;
      // this.form.get('championship').setValue(null);
    }

    if (resetType) {
      this.types
        .forEach((type) => {
          type.checked = false;
        });

      this.types[0].checked = true;
      this.type = this.getCurrentCheckedType();
      // this.form.get('type').setValue(this.type.name);
    }

    if (resetHeats) {
      this.heats = [];
      // this.form.get('heats').setValue(null);
    }
  }

  private getCurrentCheckedType(): { name: string, checked: boolean } {
    // @ts-ignore
    return this.types.find((current) => current.checked);
  }

  formatResult(result?: any, wod?: any): string
  {
    if (result === null) {
      return '';
    }

    var result = result.result;
    // var column = ((!tiebreak) ? item.rank : ((item.points) ? `${item.points}pts` : ''))

    if (wod.wod_type_id === 2) {
      // format time in result and verify is CAP
      if (result.has_cap) {
        return (((wod.goal - result) > 0) ? `CAP+${(wod.goal - result)}` : 'CAP')
      } else {
        let d = moment.duration((result * -1));
        const seconds = `${d.seconds()}`.padStart(2, '0');
        const minutes = `${d.minutes()}`.padStart(2, '0');
        
        if (this.isResultInMilliseconds) {
          // @ts-ignore
          const milliseconds = `${d.milliseconds()}`.padStart(3, '0');
          return `${minutes}:${seconds}.${milliseconds}`
          // let time = moment.utc(moment.duration((result * -1), "s").asMilliseconds()).format("mm:ss")
          // return (type === "result") ? time : column
        } else {
          return`${minutes}:${seconds}`
        }
      }
    }
    else {
      return `${result}${Utils.getUnitName(wod.wod_type_id, true)}`
    }
  }

}
