import { UserService } from './../../../../core/services/user/user.service';
import { CreateEventComponent } from './../event/create-event/create-event.component';
import { EventGuard } from './../../../../core/guards/event.guard';
import { BreadcrumbsService } from './../../../../core/components/breadcrumbs/breadcrumbs.service';
import { EventsService } from './../../../../core/services/event/events.service';
import { config } from './../../../../core/config/config';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FuseTranslationLoaderService } from '../../../../core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { FuseConfigService } from '../../../../core/services/config.service';
import { MatIconRegistry, MatDialog } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthGuard } from '../../../../core/guards/auth.guard';
import { locale as english } from './i18n/en-US';
import { locale as portugues } from './i18n/pt-BR';
import * as moment from 'moment';
import { TableConfig, TableXComponent } from '../../../../core/components/table-x/table-x.component';
import { Router } from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {requiredWithConditional} from '../../../../core/utils/custom-validators';
import {FormErrorStateMatcher} from '../../../../core/utils/form-error-state-matcher';
import {Utils} from '../../../../core/utils';
import {GooglePlacesService} from '../../../../core/services/google-places.service';
import {BoxService} from '../../../../core/services/box.service';
import {Event} from '../../../../core/model/event';
import {Subscription} from 'rxjs';
import {ToastrService} from '../../../../core/components/toastr_/toastr/toastr.service';
import {Errors} from '../../../../core/model/errors';
import {CrossXDialogComponent} from '../../../../core/components/cross-x-dialog/cross-x-dialog.component';
import {CropDialogComponent} from '../../../../core/components/crop-dialog/crop-dialog.component';
import {fuseAnimations} from '../../../../core/animations';


@Component({
  selector: 'app-logged-profile',
  templateUrl: './logged-profile.component.html',
  styleUrls: ['./logged-profile.component.scss'],
  animations : fuseAnimations
})
export class LoggedProfileComponent implements OnInit {

  @ViewChild('tableMyEvents', { read: TableXComponent }) tableMyEvents: TableXComponent;
  @ViewChild('tableManagerEvents', { read: TableXComponent }) tableManagerEvents: TableXComponent;

  private subscriptions: Subscription = new Subscription();

  registrationForm: FormGroup;
  searchForm: FormGroup;
  matchers: any;
  seeMoreEvents = false;
  seeMoreEventsCompetitor = false;

  timeOutSearch;
  
  tshirtSize = Utils.getTShirts();
  genders: any[] = [];
  cities = [];
  placeId: string;

  // Box
  boxes = [];
  boxId: number;
  timeOutBoxSearch;
  
  myManagerEvents = [];
  myEvents = [];
  translate: any;
  userMe: any;
  public urlAvatar = 'assets/images/avatars/profile.jpg';
  isLoading = true;
  isLoadingEventCompetitor = true;
  iShowMyManagerEvents = false;
  iShowMyEvents = false;
  isLoadingUpdateBasicProfile = false;

  tableConfig: TableConfig = {
    checkbox: false,
    paddingSide: true,
    massActions: [
      {
        type: 'alter-location',
        title: 'Alterar Unidade'
      },
      {
        type: 'delete',
        title: 'Deletar essa porra'
      },
      {
        type: 'inactive',
        title: 'Inativar',
        class: 'textRed'
      }
    ],
    massActionCountTitle: {
      single: 'usuário selecionado',
      multiple: 'usuários selecionados'
    }
  };


  constructor(
    private titleService: Title,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private fuseConfig: FuseConfigService,
    private authGuard: AuthGuard,
    private router: Router,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private eventsService: EventsService,
    private breadcrumbsService: BreadcrumbsService,
    private eventGuard: EventGuard,
    public dialog: MatDialog,
    public userService: UserService,
    private formBuilder: FormBuilder,
    private googlePlacesService: GooglePlacesService,
    private boxesService: BoxService,
    private toastr: ToastrService,
  ) {

    //verify user logged
    this.authGuard.canActivateWithoutRedirect();
    
    this.fuseConfig.setSettings({
      layout: {
        navigation: 'none',
        toolbar   : 'below',      // 'above', 'below', none
        footer    : 'below'
      }
    });
    

    //Icon Info
    iconRegistry.addSvgIcon(
      'ic-info',
      sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/myprofile/ic-profile.svg')
    );

    this.userMe = this.authGuard.getMe().user;
    this.urlAvatar = this.userMe.avatar ? this.userMe.avatar : this.urlAvatar; 
    if (this.userMe.birthdate) {
      this.userMe['age'] = moment().diff(this.userMe.birthdate, 'year');
    }

    this.breadcrumbsService.removeAll();

    // Set page Tilte
    this.titleService.setTitle(`${config.titleBase}`);
   }

  ngOnInit() {
    this.registerOnTranslate();
    this.configForm();
    this.createFormSearch();
    
    //remove all events in local storange
    this.eventGuard.clearEvent();
    
    //Get Events
    this.getManagerEvent();
    this.getEventCompetitor();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  createFormSearch()
  {
    this.searchForm = this.formBuilder.group({
      name: ''
    });
  }

  public searchEvents(event: any, type: number): void {
    const search = event.target.value;  
    clearTimeout(this.timeOutSearch);
    this.timeOutSearch = setTimeout(() => {
      if (type === 1) {
        this.isLoading = true;
        this.getManagerEvent(search);
        return;
      }
      this.isLoadingEventCompetitor = true;
      this.getEventCompetitor(search);
    }, 600);
  }

  // Get Event Manager
  getManagerEvent(search = '')
  {
    const subscription = this.eventsService.getMyEvents(search)
      .subscribe(
        result => {
          this.myManagerEvents = result.events;
          if (this.tableManagerEvents) { this.tableManagerEvents.reloadItems(); }
          this.isLoading = false;
          if (search.length == 0) {
            this.iShowMyManagerEvents = this.myManagerEvents.length > 0;
          }
          if (result.paginate.total_pages > 1) {
            this.seeMoreEvents = true;
            return;
          }
          this.seeMoreEvents = false;
        }, err => {
          this.isLoading = false;
        }
      );
    
    this.subscriptions.add(subscription);
  }

  // Get Event Competitor
  getEventCompetitor(search = '')
  {
    const subscription = this.userService.getMyEventsCompetitor(search)
      .subscribe(
        result => {
          this.myEvents = result.myEvents;
          if (this.tableMyEvents) { this.tableMyEvents.reloadItems(); }
          this.isLoadingEventCompetitor = false;
          if (search.length == 0) {
            this.iShowMyEvents = this.myEvents.length > 0;
          }
          if (result.paginate.total_pages > 1) {
            this.seeMoreEventsCompetitor = true;
            return;
          }
          this.seeMoreEventsCompetitor = false;
        }, err => {
          this.isLoadingEventCompetitor = false;
        }
      );
    
    this.subscriptions.add(subscription);
  }

  //Open Event
  openEvent(event: any)
  {
    const dateEnd = moment(`${moment(event.datetime_end).format('YYYY-MM-DD')} 23:59:59`);
    const now = moment();
    
    if (!this.userMe.admin && now.diff(dateEnd, 'days') > 2) {
      this.toastr.show(
        this.translate.TOASTR.EVENT_IS_PAST.DESCRIPTION,
        this.translate.TOASTR.EVENT_IS_PAST.TITLE,
        null,
        'error',
      );
      return;
    }
    
    this.eventGuard.setEvent(event);
    this.router.navigate(['/event/informations']); 
  }

  // Open Event Competitor
  openEventCompetitor(event: any)
  {
    this.eventGuard.setEventUserDashboard(event, true);
    const championship = event.championships[0];
    let url = `/user-dashboard/information`;
    if (championship.is_invitation) {
      url = `/user-dashboard/championship/${championship.id}/payment`;
    }

    this.router.navigate([url]);
  }

  //Date format table
  dateFormat(dateString: string): string {
    const date = moment(dateString);
    return date.format(`${this.translate.DATE_FORMAT}`);
  }
  

  //Get Event Status
  getEventStatus(event: any, isType: boolean): string
  {
    const dateStart = moment(`${moment(event.datetime_start).format('YYYY-MM-DD')} 00:00:00`);
    const dateEnd = moment(`${moment(event.datetime_end).format('YYYY-MM-DD')} 23:59:59`);
    const now = moment();
    
    if (event.published && dateEnd.diff(now, 'days') >= 0) {
      return (isType) ? 'success' : this.translate.STATUS.ACTIVE.toUpperCase();
    }
    // else if (event.published && now.diff(dateEnd, 'days') >= 0) {
    else if (event.published && dateEnd.diff(now, 'days') < 0) {
      return (isType) ? 'normal' : this.translate.STATUS.PAST.toUpperCase();
    }
    else if (!event.published) {
      return (isType) ? 'warning' : this.translate.STATUS.NOT_PUBLISHED.toUpperCase();
    }

    return '';
  }
  
  public actionUpdateBasicProfile(): void {
    if (this.isLoadingUpdateBasicProfile) { return; } 
    if (!Utils.formsVerifyIsValid([this.registrationForm])) {
      Utils.formValid(this.registrationForm);
      return;
    }
    
    this.dialog.openDialogs.pop();
    const dialogRef = this.dialog.open(CrossXDialogComponent, {
      width: '440px',
      closeOnNavigation: true
    });

    dialogRef.componentInstance.type = 'warning';
    dialogRef.componentInstance.titleDialog = this.translate.DIALOG.ALTER_EVENTS.TITLE;
    dialogRef.componentInstance.dialogContent = this.translate.DIALOG.ALTER_EVENTS.DESCRIPTION;
    dialogRef.componentInstance.dialogConfirm = true;
    dialogRef.componentInstance.cancelButton = this.translate.DIALOG.ALTER_EVENTS.DONT_ALTER.toUpperCase();
    dialogRef.componentInstance.actionButton = this.translate.DIALOG.ALTER_EVENTS.CONFIRM.toUpperCase();
    dialogRef.componentInstance.confirmActionRequest = false;
    dialogRef.componentInstance.object = this.userMe;
    dialogRef.afterClosed().subscribe(result => {
      const updateAllEvent = !!result && result !== null; 
      this.updateBasicProfile(updateAllEvent);
    });
    
    // this.updateBasicProfile();
  }
  
  // Update basic profile
  updateBasicProfile(updateAllEvent: boolean = false) {
    const parameters = {
      firstname: this.registrationForm.value.firstname.toUpperCase(),
      lastname: this.registrationForm.value.lastname.toUpperCase(),
      leaderboard_name: `${this.registrationForm.value.firstname.toUpperCase()} ${this.registrationForm.value.lastname.toUpperCase()}`,
      gender: this.registrationForm.value.gender.value,
      cpf: this.registrationForm.value.cpf,
      birthdate: moment(this.registrationForm.value.birthdate, this.translate.DATE_FORMAT.toUpperCase()).format('YYYY-MM-DD'),
      box_id: (this.registrationForm.value.box.id) ? this.registrationForm.value.box.id : null,
      box: (!this.registrationForm.value.box.id && this.registrationForm.value.box) ? this.registrationForm.value.box.toUpperCase() : null,
      size: (this.registrationForm.value.tshirt) ? this.registrationForm.value.tshirt : null,
      update_all_event: updateAllEvent
    };
    
    if (this.registrationForm.value.email !== this.userMe.email) {
      parameters['email'] = this.registrationForm.value.email;
    } 
    
    this.isLoadingUpdateBasicProfile = true;
    
    const subscription = this.userService.update(parameters)
      .subscribe( response => {
        this.isLoadingUpdateBasicProfile = false;
        this.toastr.show(
          this.translate.TOASTR.PROFILE.SUCCESS.MESSAGE,
          this.translate.TOASTR.PROFILE.SUCCESS.TITLE,
          null,
          'success',
        );
      }, err => {
        this.isLoadingUpdateBasicProfile = false;
        const errors: Errors = new Errors().deserialize((err.error as any));
        
        this.toastr.show(
          errors.getFullMessages(),
          this.translate.TOASTR.PROFILE.ERROR.TITLE,
          null,
          'error',
        );
      });
    
    this.subscriptions.add(subscription);
  }
  
  private updateProfilePicture(file: any) {
    const subscription = this.userService.updatePicture(file)
      .subscribe( response => {
        this.userMe.avatar = response.avatar;
        this.toastr.show(
          this.translate.TOASTR.PROFILE.SUCCESS.MESSAGE,
          this.translate.TOASTR.PROFILE.SUCCESS.TITLE,
          null,
          'success',
        );
        
      }, err => {
        const errors: Errors = new Errors().deserialize((err.error as any));

        this.toastr.show(
          errors.getFullMessages(),
          this.translate.TOASTR.PROFILE.ERROR.TITLE,
          null,
          'error',
        );
      });

    this.subscriptions.add(subscription);
  }

  // Show Model to create event
  createEvent()
  {
    const modalRef = this.dialog.open(CreateEventComponent, {
      width: '816px',
      height: '100%',
      panelClass: 'modal'
    });

    // modalRef.componentInstance.competitor = item

    modalRef.afterClosed().subscribe(result => {
      if (result && result.created) {
        this.router.navigate(['/event/informations']); 
      }
    });
  }
  
  public getEventBadge(item: any): boolean {
    const event = new Event().deserialize(item);
    return event.hasInvitation();
  }

  // Select city
  searchCity(city: string) {
    if (city.length > 2) {
      this.googlePlacesService.search(city)
        .subscribe(
          result => {
            this.cities = result;
          }, err => {
            this.cities = [];

          }
        );
    }
  }

  citySelected(event) {
    this.placeId = event.place_id;
  }

  // Box
  searchBox(name: string) {
    clearTimeout(this.timeOutBoxSearch);
    this.timeOutBoxSearch = setTimeout(() => {
      if (name.length > 0) {
        this.boxesService.getListBoxes(name)
          .subscribe(
            result => {
              this.boxes = result;
            }, err => {
              this.boxes = [];
            }
          );
      } else {
        this.boxes = [];
      }
    }, 300);
  }

  displayFnBox(box: any): string {
    return box ? box.name.toUpperCase() : box;
  }

  private registerOnTranslate(): void {
    this.translationLoader.loadTranslations(english, portugues);
    const subscription = this.translateService.get('MYPROFILE').subscribe((res: any) => {
      this.translate = res;

      this.genders = [
        {
          value: 0,
          text: res.GENDER_MALE
        },
        {
          value: 1,
          text: res.GENDER_FEMALE
        }
      ];
    });
    this.subscriptions.add(subscription);
  }
  

  private configForm(): void {
    // @ts-ignore
    const gender = this.userMe.gender !== null ? this.genders.find(object => object.value === this.userMe.gender) : '';
    
    this.registrationForm = this.formBuilder.group({
      email: [this.userMe.email, [Validators.required, Validators.email]],
      // password: ['', [Validators.minLength(6), requiredWithConditional(this.user === null)]], //requiredWithConditional
      // confirmPassword: ['', [Validators.minLength(6), confirmPasswordEquals, requiredWithConditional(this.user === null)]],
      firstname: [this.userMe.firstname, [Validators.required]],
      lastname: [this.userMe.lastname, [Validators.required]],
      box: [this.userMe.box],
      cpf: [this.userMe.cpf ? this.userMe.cpf : ''],
      phone: [this.userMe.phone ? this.userMe.phone : ''],
      birthdate: [this.userMe.birthdate ? moment(this.userMe.birthdate, 'YYYY-MM-DD').format(this.translate.DATE_FORMAT.toUpperCase()) : ''],
      gender: [gender],
      tshirt: [this.userMe.size ? this.userMe.size : ''],
      city: [this.userMe.city && this.userMe.city.length > 0 ? this.userMe.city.toUpperCase() : '' ],
    });

    this.matchers = {
      email: new FormErrorStateMatcher(),
      firstname: new FormErrorStateMatcher(),
      lastname: new FormErrorStateMatcher(),
      box: new FormErrorStateMatcher(),
      cpf: new FormErrorStateMatcher(),
      birthdate: new FormErrorStateMatcher(),
      gender: new FormErrorStateMatcher(),
      tshirt: new FormErrorStateMatcher(),
      city: new FormErrorStateMatcher(),
    };
  }
  
  public readUrl(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();

      reader.onload = (event: any) => {
        // this.url = event.target.result;
        const modalRef = this.dialog.open(CropDialogComponent, {
          width: '816px',
          height: '100%',
          panelClass: 'modal'
        });

        modalRef.componentInstance.image = event.target.result;
        modalRef.componentInstance.configCrop = {
          checkCrossOrigin: false,
          viewMode: 0,
          dragMode: 'move',
          zoomOnWheel: true,
          zoomable: true,
          aspectRatio: 500 / 500,
          cropBoxResizable: true,
          cropBoxMovable: true,
          ready: function (e) {
          },
          cropstart: function (e) {
          },
          cropmove: function (e) {
          },
          cropend: function (e) {
          },
          crop: function (e) {
            const data = e.detail;
            
          },
          zoom: function (e) {
          }
        };

        modalRef.afterClosed().subscribe(result => {

          if (result !== null) {
            this.urlAvatar = result.image;
            this.updateProfilePicture(result.blob);
          }
        });
      };

      // this.fileToUpload = event.target.files[0];
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  public openMoreEvents(event: string): void {
    const url = `/list-event/${event}`;
    this.router.navigate([url]);
  }
}
