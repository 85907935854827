import { Team } from './../../../../../../core/model/team';
import { TeamService } from './../../../../../../core/services/team/team.service';
import { BoxService } from './../../../../../../core/services/box.service';
import { UserService } from './../../../../../../core/services/user/user.service';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FuseTranslationLoaderService } from '../../../../../../core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { locale as english } from './i18n/en-US';
import { locale as portugues } from './i18n/pt-BR';
import { FormErrorStateMatcher } from '../../../../../../core/utils/form-error-state-matcher';
import { Utils } from '../../../../../../core/utils';
import { Errors } from '../../../../../../core/model/errors';

@Component({
  selector: 'app-alter-member',
  templateUrl: './alter-member.component.html',
  styleUrls: ['./alter-member.component.scss']
})
export class AlterMemberComponent implements OnInit {

  // Translate
  translate: any

  // Member
  public member: any
  public team: Team

  // Form
  memberForm: FormGroup
  matchers: any
  memberError: boolean = false
  
  tshirtSize = Utils.getTShirts()
  
  // User
  user: any = null

  // Box
  boxes = []
  boxId: number
  timeOutBoxSearch

  isLoading: boolean = false
  requestErrors: string = null

  constructor(
    public dialogRef: MatDialogRef<AlterMemberComponent>,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private boxService: BoxService,
    private teamService: TeamService,
  ) { 
    this.translationLoader.loadTranslations(english, portugues);

    this.translateService.get("ALTER_MEMBER").subscribe((res: any) => {
      this.translate = res
    });

  }

  ngOnInit() {
    this.memberForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', Validators.email],
      box: [''],
      tShirt: ['']
    });

    this.matchers = {
      firstname: new FormErrorStateMatcher(),
      lastname: new FormErrorStateMatcher(),
      email: new FormErrorStateMatcher(),
    }
  }

  saveNewMember() {
    this.requestErrors = null
    // validate all form fields
    if (!this.memberForm.valid) {
      Utils.formValid(this.memberForm)
      return
    }

    var parameters = {
      leaderboard_name: (this.user && this.user.leaderboard_name.length > 0) ? this.user.leaderboard_name : `${this.memberForm.value.firstname.toUpperCase()} ${this.memberForm.value.lastname.toUpperCase()}`,
      size: (this.memberForm.value.tShirt) ? this.memberForm.value.tShirt : null,
      box_id: (this.memberForm.value.box && this.memberForm.value.box.id) ? this.memberForm.value.box.id : null,
      box: (!this.memberForm.value.box.id && this.memberForm.value.box) ? this.memberForm.value.box.toUpperCase() : null,
      firstname: this.memberForm.value.firstname.toUpperCase(),
      lastname: this.memberForm.value.lastname.toUpperCase(),
      email: (this.memberForm.value.email) ? this.memberForm.value.email.toLowerCase() : null,
    }

    if (this.user !== null) parameters['user_id'] = this.user.id
    
    this.isLoading = true
    this.teamService.alterMember(parameters, this.team.id, this.member.id, false)
      .subscribe(
        result => {
          this.isLoading = false
          this.team = result
          this.dialogRef.close({
            action: 'update-team-member',
            team: this.team
          })
        }, err => {
          const errors: Errors = new Errors().deserialize((err.error as any))
          this.requestErrors = errors.getFullMessages()
          this.isLoading = false
        }
      )
  }


  searchBox(name: string) {
    clearTimeout(this.timeOutBoxSearch)
    this.timeOutBoxSearch = setTimeout(() => {
      if (name.length > 0) {
        this.boxService.getListBoxes(name)
          .subscribe(
            result => {
              this.boxes = result
            }, err => {
              this.boxes = []
            }
          )
      } else {
        this.boxes = []
      }
    }, 100);
  }

  displayFnBox(box: any): string {
    return box ? box.name.toUpperCase() : box;
  }

  returnFn(box: any): number | undefined {
    return box ? box.id : undefined;
  }

  // Search User
  searchUser(event) {
    if (!this.memberForm.controls.email.hasError('email') && event.target.value && event.target.value.length > 0) {
      const email = event.target.value
      this.userService.searchUserByEmail(email)
        .subscribe(
          result => {
            if (result) {
              this.user = result
              this.memberForm.controls['firstname'].setValue((result.firstname) ? result.firstname : this.memberForm.value.firstname)
              this.memberForm.controls['lastname'].setValue((result.lastname) ? result.lastname : this.memberForm.value.lastname)
              this.memberForm.controls['tShirt'].setValue((result.size) ? result.size : this.memberForm.value.size)
              this.memberForm.controls['box'].setValue((result.box) ? result.box : this.memberForm.value.box)
            }
          }, err => {
            this.user = null

          }
        )
    }
    else if (event.target.value || event.target.value.length === 0) {
      this.user = null
    }
  }


}
