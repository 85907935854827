import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthGuard } from '../../guards/auth.guard';
import { Observable, Subscription, Subject } from 'rxjs';
import { Event } from './../../model/event';

@Injectable({
  providedIn: 'root'
})
export class EventsService implements OnDestroy {
  
  private subscriptions = new Subscription();
  private _eventInformationObservable = new Subject<any[]>();

  constructor(
    private http: HttpClient,
    private authGuard: AuthGuard
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  eventInformationObservable(): Observable<any[]> {
    return this._eventInformationObservable.asObservable();
  }

  fetchEventInformation(eventId: number): void {
    const subscription = this.getEventInformation(eventId)
    .subscribe((result) => {
          this._eventInformationObservable.next(result);
        }, 
        (err) => {
          this._eventInformationObservable.next([]);
        }
      );

    this.subscriptions.add(subscription);
  }

  // GETS
  getMyEvents(search: string = ''): Observable<any> {
    // const responseType: 'json' | 'arraybuffer' | 'blob' | 'observe' = 'response' ;
    const me = this.authGuard.getMe().user;
    return this.http.get(`admin/me/event?isAdmin=${false}&search=${search}`, { observe: 'response' })
    // return this.http.get(`admin/me/event`, { responseType })
      .map(({ body, headers }) => {
        return {
          paginate: JSON.parse(headers.get('pagination')),
          events: body
        };
      });
  }

  getEventInformation(eventId: number): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    return this.http.get(`admin/me/event/${eventId}`, { responseType })
      .map((response: Response) => {
        const res = response as any;
        return res;
      });
  }

  getEventDetail(event): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    return this.http.get(`public/championships/${event.id}`, { responseType })
      .map((response: Response) => {
        const res = response as any;
        const events = res.map((event: Event) => new Event().deserialize(event));
      
        return events;
      });
  }

  getEventDetailPerSlug(slug: string): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    return this.http.get(`public/championships/${slug}`, { responseType })
      .map((response: Response) => {
        return response;
      });
  }

  createEvent(event): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';

    // const me = this.authGuard.getMe().user
    
    return this.http.post(`admin/me/event`, event, { responseType })
      .map((response: Response) => {
        const events = response as any;
        return events;
      });
  }

  updateEvent(eventId: number, event: any, media: any = null): Observable<any> {
    const formData: FormData = new FormData();
    if (media && media.brand !== null) {
      formData.append('email_logo', media.brand, media.brand.name);
    }

    if (media && media.header !== null) {
      formData.append('header_image', media.header, media.header.name);
    }
    
    if (media && media.regulation !== null) {
      formData.append('doc_rule', media.regulation, media.regulation.name);
    } else {
      formData.append('doc_rule', '');
    }
    
    if (media && media.term !== null) {
      formData.append('doc_term', media.term, media.term.name);
    } else {
      formData.append('doc_term', '');
    }


    Object.keys(event).forEach(element => {
      formData.append(element, event[element]);
    });

    return this.http.post(`admin/me/event/${eventId}`, formData)
      .map((response: Response) => {
        const res = response as any;

        return res;
      });
  }

  publishEvent(eventId: number): Observable<any> {
    return this.http.post(`admin/me/event/${eventId}/publish`, null)
      .map((response: Response) => {
        const res = response as any;
        return res;
      });
  }

  unpublishEvent(eventId: number): Observable<any> {
    return this.http.post(`admin/me/event/${eventId}/unpublish`, null)
      .map((response: Response) => {
        const res = response as any;
        return res;
      });
  }

  updatePaymentMethods(parameters: any): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';

    return this.http.post(`admin/eventPaymentMethods`, parameters, { responseType })
      .map((response: Response) => {
        return response;
      });
  }
}
