import {ChangeDetectionStrategy, Component, Input, OnInit, ViewChild} from '@angular/core';
import { locale as english } from './../../i18n/en-US';
import { locale as portugues } from './../../i18n/pt-BR';
import {Subscription as SubscriptionEvent} from 'rxjs';
import {Event} from '../../../../../../core/model/event';
import {TableConfig, TableXComponent} from '../../../../../../core/components/table-x/table-x.component';
import {TranslateService} from '@ngx-translate/core';
import {FuseTranslationLoaderService} from '../../../../../../core/services/translation-loader.service';
import {BreadcrumbsService} from '../../../../../../core/components/breadcrumbs/breadcrumbs.service';
import {EventGuard} from '../../../../../../core/guards/event.guard';
import {MatDialog, MatDialogRef, MatSlideToggleChange} from '@angular/material';
import {ToastrService} from '../../../../../../core/components/toastr_/toastr/toastr.service';
import {CustomFieldsService} from '../../../../../../core/services/custom-fields/custom-fields.service';
import {CustomField, CustomFieldSubscriptionType, CustomFieldTypeForm} from '../../../../../../core/model/custom-field.model';
import {DefaultCustomFieldType} from '../../../../../../core/model/default-custom-field.model';
import {Errors} from '../../../../../../core/model/errors';
import {ModelCustomFieldComponent} from '../model-custom-field/model-custom-field.component';
import {CrossXDialogComponent} from '../../../../../../core/components/cross-x-dialog/cross-x-dialog.component';
import {ModelBoxesListComponent} from '../../../boxes/model-boxes-list/model-boxes-list.component';
import {SearchFieldTypes} from '../../../../../../core/utils/custom-fields-types';

@Component({
  selector: 'app-event-subscription-form',
  templateUrl: './event-subscription-form.component.html',
  styleUrls: ['./event-subscription-form.component.scss']
})
export class EventSubscriptionFormComponent implements OnInit {

  @ViewChild('tableBasic', { read: TableXComponent }) tableBasic: TableXComponent;
  @ViewChild('tableAdditional', { read: TableXComponent }) tableAdditional: TableXComponent;

  private subscriptions: SubscriptionEvent = new SubscriptionEvent();

  // translate
  public translate: any;

  //Event
  public event: Event = null
  
  @Input('typeActive') public typeActive: string = '';
  public customFieldsBasic: CustomField[] = [];
  public customFieldsAdditional: CustomField[] = [];
  public customFieldsCount: number = null;

  public isLoading: boolean = false;

  //Table
  public tableConfig: TableConfig = {
    checkbox: false,
    paddingSide: true,
    massActions: null,
    massActionCountTitle: null
  };

  constructor(
    private translateService: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
    private breadcrumbsService: BreadcrumbsService,
    private eventGuard: EventGuard,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private customFieldService: CustomFieldsService
  ) { }

  ngOnInit() {
    this.registerOnTranslate();
    this.registerOnFetchCustomFieldObservable();
    this.getEvent();
    this.getCustomFields();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  public onChangeSwitchRequired($event: MatSlideToggleChange, change: string, item: CustomField) {
    let parameters = {
      id: item.id
    }
    if (change === 'required') {
      parameters['is_required'] = $event.checked;
    } else if (change === 'hidden'){
      parameters['is_hidden'] = !$event.checked;
    }
    

    this.customFieldService.update(item.id, parameters)
      .subscribe(
        result => {
          let titleToastr = '';
          this.translateService.get('SUBSCRIPTIONS.TOASTR_CUSTOM_FIELD.UPDATE.TITLE', { custom_field_name: item.name }).subscribe((res: string) => {
            titleToastr = res
          });
          this.toastr.show(
            this.translate.TOASTR_CUSTOM_FIELD.UPDATE.DESCRIPTION,
            titleToastr,
            null,
            'success'
          )
        }, err => {
          const errors: Errors = new Errors().deserialize((err.error as any))
          let titleToastr = '';
          this.translateService.get('SUBSCRIPTIONS.TOASTR_CUSTOM_FIELD.UPDATE.ERROR', { custom_field_name: item.name }).subscribe((res: string) => {
            titleToastr = res
          });
          this.toastr.show(
            errors.getFullMessages(),
            titleToastr,
            null,
            'error'
          )
        }
      );
  }

  private registerOnFetchCustomFieldObservable(): void {
    const subscription = this.customFieldService
      .fetchCustomFieldsObservable()
      .subscribe(customFields => {
        if (customFields.type === this.typeActive) {
          this.isLoading = false;
          this.populateTables(customFields.data);
        } 
        
      }, response => {
        if (response.type === this.typeActive) {
          this.isLoading = false;
        }
      });
    this.subscriptions.add(subscription);
  }

  private populateTables(customFields: CustomField[]) {
    const isDefaultFields = customFields.filter(value => {
      return value.default_field === false || value.default_field === null;
    });
    this.customFieldsCount = isDefaultFields ? isDefaultFields.length : 0;
    
    this.customFieldsBasic = customFields.filter(value => {
      return value.type === 'basic'
    })

    this.customFieldsAdditional = customFields.filter(value => {
      return value.type === 'additional'
    })
  }
  
  private getCustomFields(): void {
    this.isLoading = true;
    this.customFieldService.fetchCustomFieldWithEventAndType(this.event.id, this.typeActive)
  }

  private getEvent(): void {
    this.event = new Event().deserialize(this.eventGuard.getActiveEvent().event)
  }

  private registerOnTranslate(): void {

    this.translationLoader.loadTranslations(english, portugues);
    const subscription = this.translateService
      .get('SUBSCRIPTIONS')
      .subscribe((response: Object) => {
        this.translate = response;
      });

    this.subscriptions.add(subscription);

  }

  public verifyIsDisabledSwitch(item: CustomField): boolean {
    if (item.default_field && item.default_custom_field) {
      switch (item.default_custom_field.id) {
        case DefaultCustomFieldType.FIRSTNAME:
        case DefaultCustomFieldType.LASTNAME:
        case DefaultCustomFieldType.EMAIL:
        case DefaultCustomFieldType.GENDER:
          return true;
          
      }
    } 
    
    return false;
  }

  public showItemOption(item: CustomField): boolean {
    return !item.default_field || (item.default_custom_field.id === DefaultCustomFieldType.BOX || item.default_custom_field.id === DefaultCustomFieldType.TSHIRT)
  }
  
  public createCustomField(): void {
    const modalRef = this.dialog.open(ModelCustomFieldComponent, {
      width: '600px',
      height: '100%',
      panelClass: 'modal'
    });

    modalRef.componentInstance.event = this.event
    modalRef.componentInstance.customFieldType = CustomFieldTypeForm.ADDITIONAL
    modalRef.componentInstance.subscriptionType = CustomFieldSubscriptionType.ATHLETES
    
    modalRef.afterClosed().subscribe(result => {
      if (result) {
        this.customFieldsCount++;
        let customField: CustomField = result.customField;
        if (customField.type === CustomFieldTypeForm.BASIC) {
          this.customFieldsBasic.push(customField);
          if(this.tableBasic) this.tableBasic.reloadItems();
        }
        else if (customField.type === CustomFieldTypeForm.ADDITIONAL) {
          this.customFieldsAdditional.push(customField);
          if(this.tableAdditional) this.tableAdditional.reloadItems();
        }
      }
      modalRef.close();
    });
  }
  
  public editCustomField(item: CustomField, index: number, table: string): void {
    const modalRef = this.dialog.open(ModelCustomFieldComponent, {
      width: '600px',
      height: '100%',
      panelClass: 'modal'
    });

    modalRef.componentInstance.event = this.event
    modalRef.componentInstance.customField = item
    modalRef.componentInstance.isEdit = true
    modalRef.componentInstance.customFieldType = CustomFieldTypeForm.ADDITIONAL
    modalRef.componentInstance.subscriptionType = CustomFieldSubscriptionType.ATHLETES

    modalRef.afterClosed().subscribe(result => {
      if (result) {
        let customField: CustomField = result.customField;
        if (table === 'basic') {
          this.customFieldsBasic[index] = customField
          if(this.tableBasic) this.tableBasic.reloadItems();
        }
        else if(table === 'additional') {
          this.customFieldsAdditional[index] = customField
          if(this.tableAdditional) this.tableAdditional.reloadItems();
        }
      }
      modalRef.close();
    });
  }

  
  //Delete custom field
  public deleteCustomField(item: CustomField, index: number, table: string): void {
    if (!item.can_be_delete) {
      this.toastr.show(
        this.translate.TOASTR_CUSTOM_FIELD.NOT_DELETE.DESCRIPTION,
        this.translate.TOASTR_CUSTOM_FIELD.NOT_DELETE.TITLE,
        null,
        'error',
      )
      return
    }

    this.dialog.openDialogs.pop();
    const dialogRef = this.dialog.open(CrossXDialogComponent, {
      width: '440px',
      closeOnNavigation: true
    });

    dialogRef.componentInstance.type = 'warning'
    dialogRef.componentInstance.titleDialog = this.translate.DIALOG.CONFIRM_DELETE.TITLE
    this.translateService.get('SUBSCRIPTIONS.DIALOG.CONFIRM_DELETE.DESCRIPTION', { custom_field_name: item.name }).subscribe((res: string) => {
      dialogRef.componentInstance.dialogContent = res
    });
    dialogRef.componentInstance.dialogConfirm = true
    dialogRef.componentInstance.cancelButton = this.translate.CANCEL.toUpperCase()
    dialogRef.componentInstance.actionButton = this.translate.DELETE.toUpperCase()
    dialogRef.componentInstance.object = item
    dialogRef.componentInstance.confirmActionRequest = true
    dialogRef.componentInstance.dialogEvent.subscribe(result => {
      dialogRef.componentInstance.confirmLoading = true
      this.deleteCustomFieldInAPI(item, index, table, dialogRef)
    })
  }

  private deleteCustomFieldInAPI(item: CustomField, index: number, table: string, dialogRef: MatDialogRef<CrossXDialogComponent>): void {

    const subscription = this.customFieldService.delete(item.id)
      .subscribe(
        result => {
          this.toastr.show(
            this.translate.TOASTR_CUSTOM_FIELD.DELETE.DESCRIPTION,
            this.translate.TOASTR_CUSTOM_FIELD.DELETE.TITLE,
            null,
            'success'
          )

          if (table === 'basic') {
            this.customFieldsBasic.splice(index, 1);
            if (this.tableBasic) this.tableBasic.reloadItems()
          }
          else if (table === 'additional') {
            this.customFieldsAdditional.splice(index, 1);
            if (this.tableAdditional) this.tableAdditional.reloadItems()
          }
          this.customFieldsCount--;
          
          dialogRef.componentInstance.confirmLoading = false;
          dialogRef.close();
        }, err => {
          const errors: Errors = new Errors().deserialize((err.error as any))
          
          this.toastr.show(
            errors.getFullMessages(),
            this.translate.TOASTR_CUSTOM_FIELD.DELETE.ERROR,
            null,
            'error'
          )
        }
      );

    this.subscriptions.add(subscription);
    
    
  }

  public showBoxesList(): void {
    const modalRef = this.dialog.open(ModelBoxesListComponent, {
      width: '600px',
      height: '100%',
      panelClass: 'modal'
    });
    // modalRef.componentInstance.event = this.event

    modalRef.afterClosed().subscribe(result => {
      modalRef.close();
    });
  }

  public getCustomFieldTypeName(item: CustomField): string {
    return SearchFieldTypes(item.custom_field_type.id, this.translateService.currentLang).name;
  }
}
