export const locale = {
  lang: 'en-US',
  data: {
    'SETTINGS': {
      'TITLE': 'Informações',
      'TITLE_SUBSCRIPTIONS': 'Inscrições',
      'TITLE_STEP': 'Etapas',
      'TITLE_LEADERBOARD': 'Geral',
      'TABS': {
        'EVENT': 'O EVENTO',
        'REGISTRATIONS': 'INSCRIÇÕES',
        'STEPS': 'ETAPAS',
        'LEADERBOARD': 'LEADERBOARD'
      },
      'EVENT_STATUS': {
        'PUBLISHED': 'Publicado',
        'NOT_PUBLISHED': 'Não publicado'
      },
      'EVENT_PAGE': 'Página do evento',
      'SAVE': 'SALVAR',
      'CANCEL': 'CANCELAR',
      'PUBLISH_EVENT': 'PUBLICAR EVENTO',
      'UNPUBLISH_EVENT': 'DESPUBLICAR EVENTO',
      'EVENT_PUBLISH_DIALOG': {
        'TITLE': 'Tem certeza que deseja {{action}}?',
        'DESCRIPTION': 'Você tem certeza que deseja {{action}} este evento.',
        'PUBLISH': 'Publicar',
        'UNPUBLISH': 'Despublicar',
      },
      'TOASTR': {
        'PUBLISH_TITLE': 'Evento publicado com sucesso!',
        'PUBLISH_DESCRIPTION': 'Seu evento foi publicado e está disponível para todos',
        'UNPUBLISH_TITLE': 'Evento despublicado com sucesso!',
        'UNPUBLISH_DESCRIPTION': 'Seu evento foi despublicado e o seu link não está mais público.',
        'UPDATE': {
          'SUCCESS': {
            'TITLE': 'Evento atualizado com sucesso!',
            'DESCRIPTION': 'Evento atualizado.'
          },
          'ERROR': {
            'TITLE': 'Erro ao atualizar o evento!',
          },
        }
      },
      'DATE_FORMAT': 'YYYY-MM-DD',
      'DATETIME_FORMAT': 'YYYY-MM-DD HH:mm:ss',
      'FIELDS': {
        'NAME': 'Nome do evento',
        'ADDRESS': 'Endereço',
        'LOCATION': 'Local',
        'TIMEZONE': 'Fuso horário do local',
        'DATE_START': 'Data inicial',
        'DATE_END': 'Data final',
        'TIME_START': 'Horário de início',
        'TIME_END': 'Horário de término',
        'EVENT_URL': 'URL do evento',
        'DESCRIPTION': 'Descrição',
        'REGULATION': 'Regulamento',
        'SEE_REGULATION': 'Ver o regulamento',
        'TERMS': 'Termos',
        'SEE_TERMS': 'Ver os termos'
      },
      'VALIDATIONS': {
        'NAME_REQUIRED': 'Nome do evento é obrigatório',
        'ADDRESS_REQUIRED': 'Endereço do evento é obrigatório',
        'LOCATION_REQUIRED': 'Local do evento é obrigatório',
        'TIMEZONE_REQUIRED': 'O fuso horário do evento é obrigatório',
        'DATE_INVALID': 'O formato da data é inválida',
        'DATE_START_REQUIRED': 'Data de início é obrigatório',
        'DATE_END_REQUIRED': 'Data de término é obrigatório',
        'URL_EVENT_REQUIRED': 'A url do evento é obrigatório',
        'URL_EVENT_MINLENGTH': 'Mínimo de {{count}} caracteres',
        'DESCRIPTION_REQUIRED': 'A descrição é obrigatório',
        'REGULATION_REQUIRED': 'O regulamento é obrigatório'
      },
      'SELECT': 'SELECIONAR',
      'REMOVE': 'REMOVER',
      'IMAGES': {
        'BRAND': 'Logo',
        'HEADER': 'Cabeçalho',
        'DESCRIPTION_BRAND': 'Tamanho recomentado: 500px X 500px.<br>Tamanho máximo de 5MB.<br>Tipos de imagem aceitos: JPG ou PNG.',
        'DESCRIPTION_HEADER': 'Tamanho recomentado: 1000px X 320px.<br>Tamanho máximo de 5MB.<br>Tipos de imagem aceitos: JPG ou PNG.',
        'SELECT_BRAND': 'SELECIONAR LOGO',
        'SELECT_IMAGE': 'SELECIONAR IMAGEM',
      },
      'REGISTRATION': {
        'USER_BANK_ACCOUNT': 'Conta bancária',
        'NEW_ACCOUNT': '+ Criar nova conta',
        'PAYMENT_METHOD': 'Formas de pagamento',
        'PAYMENT_METHOD_DESCRIPTION': 'Os dados do cartão de crédito serão criptografados, usando SSL, antes de serem enviados para o nosso gateway de pagamento.<br>Nenhum dado sensível será armazenado em nossos servidores.',
        'CREDIT_CARD': 'Cartão de crédito',
        'CREDIT_CARD_REQUIRED': '* A cobrança via cartão de crédito é obrigatória.',
        'BOLETO': 'Boleto',
        'SOFT_DESCRIPTION': 'Descrição na fatura',
        'SOFT_DESCRIPTION_REQUIRED': 'Descrição na fatura é obrigatória',
        'DAYS_DUE_DATE': 'Dias para o vencimento do boleto',
        'DAYS_DUE_DATE_REQUIRED': 'Dias para o vencimento do boleto obrigatório',
        'DAYS_DUE_DATE_MIN_VALUE': 'O valor mínimo é 2 dias',
        'BANK_ACCOUNT': 'Dados para recebimento',
        'TYPE_PERSON': 'Tipo de pessoa',
        'TYPES_PERSON': {
          'PHYSICAL': 'Pessoa física',
          'LEGAL': 'Pessoa jurídica'
        },
        'NAME': 'Nome',
        'SOCIAL_NAME': 'Razão social',
        'NAME_REQUIRED': 'O campo nome é obrigatório',
        'CPF': 'CPF',
        'CNPJ': 'CNPJ',
        'BANK': 'Banco',
        'BANK_REQUIRED': 'O banco é obrigatório',
        'BANK_MINLENGTH': 'O banco deve conter no mínimo 3 digitos',
        'TYPE_ACCOUNT': 'Tipo de conta',
        'TYPES_ACCOUNT': {
          'CC': 'Conta corrente',
          'CP': 'Conta poupança',
          'CCC': 'Conta corrente conjunta',
          'CPC': 'Conta poupança conjunta'
        },
        'AGENCY': 'Agência',
        'AGENCY_REQUIRED': 'A agência é obrigatória',
        'AGENCY_DV': 'Agência DV',
        'ACCOUNT': 'Conta corrente',
        'ACCOUNT_REQUIRED': 'A conta corrente é obrigatória',
        'ACCOUNT_DV': 'Conta DV',
        'ACCOUNT_DV_REQUIRED': 'Obrigatório',
        'CPF_REQUIRED': 'O CPF é obrigatório',
        'CNPJ_REQUIRED': 'O CNPJ é obrigatório',
        'MAX_LENGTH_ERROR': 'Limite de {{count}} caractéres',
        'EVENT_TAX': 'Taxa do evento',
        'TAX_PERCENTAGE': 'Taxa em porcentagem',
        'TAX_PERCENTAGE_REQUIRED': 'Taxa em porcentagem é obrigatória',
        'TAX_PERCENTAGE_MAX': 'Taxa máxima é de {{tax_max}}%',
        'TAX_PERCENTAGE_MIN': 'Taxa mínima é de {{tax_min}}%',
        'DIALOG': {
          'CONFIRM_TAX': {
            'TITLE': 'Você tem certeza que deseja alterar a taxa?',
            'DESCRIPTION': 'Você está alterando a taxa do CrossX no campeonato.'
          },
        },
        'TOASTR': {
          'PAYMENT_METHODS': {
            'TITLE': 'Formas de pagamento atualizado com sucesso!',
            'DESCRIPTION': 'Os métodos de pagamentos foram atualizados.',
            'ERROR': 'Erro ao atualizar as formas de pagamentos.'
          },
          'BANKACCOUNT': {
            'UPDATE': {
              'TITLE': 'Conta bancária alterada com sucesso!',
              'DESCRIPTION': 'Conta bancária alterada.',
              'ERROR': 'Erro ao atualizar a conta bancária'
            },
            'CREATE': {
              'TITLE': 'Conta bancária criada com sucesso!',
              'DESCRIPTION': 'Conta bancária criada.',
              'ERROR': 'Erro ao criar conta bancária'
            }
          },
          'PERCENTAGE_TAX': {
            'TITLE': 'Taxa alterada com sucesso!',
            'DESCRIPTION': 'A taxa do CrossX foi alterada',
            'ERROR': 'Erro ao alterar taxa do CrossX.'
          }
        }
      },
      'STEPS': {
        'NAME_STEP': 'Nome da etapa',
        'RANKING_TYPE': 'Tipo do ranking',
        'POINTS': 'Pontos',
        'RANK': 'Colocação',
        'NEW': 'Adicionar etapa',
        'VALIDATION': {
          'NAME_REQUIRED': 'O Campo nome é obrigatório'
        },
        'RESPONSE': {
          'TITLE': 'Etapas salvas com sucesso',
          'DESCRIPTION': 'As etapas foram salvas com sucesso'
        },
        'ENABLE_SUB_DIVISION': 'Sub-categorias',
        'TOASTR': {
          'SUB_DIVISION': {
            'ERROR': {
              'DESCRIPTION': 'Succeso ao habilitar/desabilitar sub-categorias.',
              'TITLE': 'Configurações salvas com sucesso!.',
            },
            'SUCCESS': {
              'DESCRIPTION': 'Falha ao habilitar/desabilitar sub-categorias.',
              'TITLE': 'Erro ao salvar as configurações!',
            }
          },
          'CHAMPIONSHIP': {
            'ERROR': {
              'TITLE': 'Falha ao salvar etapas!',
            }
          }
        }
      },
      'CHECKIN_ONLINE': {
        'SETTINGS': 'Configuração do check-in online',
        'FORM': {
          'FIELDS': {
            'START_DATE_DATE': 'Data inicial',
            'START_DATE_HOUR': 'Hora inicial',
            'START_DATE_MINUTE': 'Minuto inicial',
            'END_DATE_DATE': 'Data final',
            'END_DATE_HOUR': 'Hora final',
            'END_DATE_MINUTE': 'Minuto final',
            'ENABLE_CHECKIN_FILE': 'Solicitar termo de responsabilidade assinado no ckeckin online'
          },
          'ERRORS': {
            'START_DATE_DATE': {
              'REQUIRED': 'O campo data inicial é obrigatório.',
              'DATE_INVALID': 'O formato do campo data final é inválido.'
            },
            'START_DATE_HOUR': {
              'REQUIRED': 'O campo hora inicial é obrigatório.',
            },
            'START_DATE_MINUTE': {
              'REQUIRED': 'O campo minuto inicial é obrigatório.',
            },
            'END_DATE_DATE': {
              'REQUIRED': 'O campo data final é obrigatório.',
              'DATE_INVALID': 'O formato do campo data final é inválido.'
            },
            'END_DATE_HOUR': {
              'REQUIRED': 'O campo hora final é obrigatório.',
            },
            'END_DATE_MINUTE': {
              'REQUIRED': 'O campo minuto final é obrigatório.',
            },
          },
          'MASKS': {
            'START_DATE_DATE': '0000-00-00',
            'END_DATE_DATE': '0000-00-00',
          },
        },
        'TOASTR': {
          'ERROR_DATE': 'A data inicial não pode ser superior a data final',
          'SUCCESS': {
            'TITLE': 'Configurações salvas com sucesso!',
            'DESCRIPTION': 'As configurações do check-in foram salvas.'
          }
        }
      },
      'MAX_DATE_USER_EDIT': {
        'SETTINGS': 'Alteração dos dados pessoais',
        'DESCRIPTION': 'Os dados de alteração de camiseta, box e integrantes do time só serão permitidos até esta data.',
        'FORM': {
          'FIELDS': {
            'DATE': 'Data limite',
            'HOUR': 'Hora limite',
            'MINUTE': 'Minuto limite',
          },
          'ERRORS': {
            'DATE': {
              'REQUIRED': 'O campo data limite é obrigatório.',
              'DATE_INVALID': 'O formato do campo data limite é inválido.'
            },
            'HOUR': {
              'REQUIRED': 'O campo hora inicial é obrigatório.',
            },
            'MINUTE': {
              'REQUIRED': 'O campo minuto inicial é obrigatório.',
            },
          },
          'MASKS': {
            'DATE': '0000-00-00',
          },
        },
        'TOASTR': {
          'SUCCESS': {
            'TITLE': 'Configurações salvas com sucesso!',
            'DESCRIPTION': 'As configurações da data limite para edição pelo usuário foram salvas.'
          }
        }
      },
      'LEADERBOARD': {
        'SETTINGS': 'Configuração do leaderboard',
        'SETTINGS_DESCRIPTION': 'Configure as cores do leaderboard',
        'PREVIEW': 'VISUALIZAR',
        'SETTINGS_TV': 'Configuração do leaderboard tv',
        'SETTINGS_TV_DESCRIPTION': 'Configure as cores do leaderboard tv',
        'SETTINGS_TV_CATEGORY': 'Configure a ordem das categorias no leaderboard tv.',
        'STEPS': 'Etapas',
        'CATEGORIES': 'Categorias',
        'SPLIT_LEFT': 'Coluna da esquerda',
        'SPLIT_RIGHT': 'Coluna da direita',
        'BRAND': 'Logo',
        'DESCRIPTION_BRAND': 'Tamanho recomentado: 500px X 500px.<br>Tamanho máximo de 5MB.<br>Tipos de imagem aceitos: JPG ou PNG.',
        'SELECT_BRAND': 'SELECIONAR LOGO',
        'COLOR_BACKGROUND': 'Cor de fundo',
        'COLOR_LINE_DARK': 'Cor da linha escura',
        'COLOR_TEXT': 'Cor do texto',
        'COLOR_NAME_BOX': 'Cor do nome do box',
        'COLOR_HIGHLIGHT': 'Cor das melhores pontuações',
        'COLOR_CUT_LINE': 'Cor da linha de corte',
        'COLOR_EVENT_NAME': 'Cor do nome do evento',
        'COLOR_CATEGORY': 'Cor da categoria',
        'COLOR_BACKGROUND_CELL': 'Cor do fundo da célula',
        'COLOR_PROGRESS_BAR': 'Cor da barra de loading',
        'LEADERBOARD_BRAND': 'Exibir logo do evento no leaderboard',
        'VALIDATIONS': {
          'FIELD_REQUIRED': 'Campo obrigatório'
        },
        'STATUS': {
          'PUBLISH': 'Publicar leaderboard',
          'UNPUBLISH': 'Despublicar leaderboard'
        },
        'PUBLISH_DIALOG': {
          'TITLE': 'Tem certeza que deseja {{action}}?',
          'DESCRIPTION': 'Você tem certeza que deseja {{action}} este evento.',
          'PUBLISH': 'Publicar',
          'UNPUBLISH': 'Despublicar',
        },
        'TOASTR': {
          'PUBLISH_TITLE': 'Leaderboard do evento publicado com sucesso',
          'PUBLISH_DESCRIPTION': 'O leaderboard do evento está público, todos conseguem ver.',
          'UNPUBLISH_TITLE': 'Leaderboard do evento despublicado com sucesso',
          'UNPUBLISH_DESCRIPTION': 'O leaderboard foi despublicado e seu link não tem mais acesso.',
          'LEADERBOARD': {
            'ERROR': {
              'TITLE': 'Erro ao salvar as configurações',
              'DESCRIPTION': 'Todos os campos são obrigatórios'
            },
            'SUCCESS': {
              'TITLE': 'Configurações salvas com sucesso!',
              'DESCRIPTION': 'As configurações do leaderboard foram salvas.'
            }
          }
        },
      }
    },
    'SUBSCRIPTIONS': {
      'TABS': {
        'PAYMENT': 'Pagamento',
        'SUBSCRIPTION_FORM': 'Formulário de inscrição',
        'BANK_ACCOUNT': 'Conta bancária',
      },
      'TABLE': {
        'NAME': 'Nome',
        'TYPE': 'Tipo do campo',
        'REQUIRED': 'Obrigatório',
        'VISIBLE': 'Visível',
      },
      'CANCEL': 'Cancelar',
      'DELETE': 'Excluir',
      'BASIC_INFORMATION': 'Informações básicas',
      'ADDITIONAL_INFORMATION': 'Informações adicionais',
      'ADD_CUSTOM_FIELD': 'Adicionar campo',
      'ACTIONS': {
        'SEE_LIST': 'Ver lista',
        'EDIT': 'Editar',
        'DELETE': 'Deletar'
      },
      'TOASTR_CUSTOM_FIELD': {
        'UPDATE': {
          'TITLE': 'Campo {{ custom_field_name }} alterado com sucesso!',
          'DESCRIPTION': 'O campo foi atualizado.',
          'ERROR': 'Erro ao atualizar o campo {{ custom_field_name }}!'
        },
        'NOT_DELETE': {
          'TITLE': 'O campo não pode ser deletado!',
          'DESCRIPTION': 'O campo não pode ser deletado pois já tem alguma inscrição com ele.'
        },
        'DELETE': {
          'TITLE': 'Campo deletado com sucesso!',
          'DESCRIPTION': 'O campo foi deletado.',
          'ERROR': 'Erro ao deletar o campo!'
        }
      },
      'DIALOG': {
        'CONFIRM_DELETE': {
          'TITLE': 'Você tem certeza que deseja deletar esse campo?',
          'DESCRIPTION': 'Você está deletando o campo <strong>{{ custom_field_name }}</strong>, essa ação não poderá ser revertida.'
        }

      }
    },
    'MODAL_CUSTOM_FIELD': {
      'TITLE_NEW': 'Novo campo',
      'TITLE_EDIT': 'Editar "{{ custom_field_name }}"',
      'CANCEL': 'Cancelar',
      'SAVE': 'Salvar',
      'ACTION_CREATE': 'criado',
      'ACTION_UPDATE': 'atualizado',
      'FORM': {
        'NAME': 'Nome',
        'DESCRIPTION': 'Descrição',
        'TYPE': 'Tipo do campo',
        'MAX_COUNT_TEXT': 'Máximo de caracteres',
        'MAX_COUNT_OPTIONS': 'Máximo de opções para selecionar',
        'ADD_OPTION': 'Adicionar opção'
      },
      'VALIDATIONS': {
        'NAME_REQUIRED': 'O campo nome é obrigatório.',
        'TYPE_REQUIRED': 'O tipo do campo é obrigatório.',
        'OPTION_REQUIRED': 'É obrigatório inserir pelo menos uma opção.'
      },
      'TOASTR': {
        'TITLE': 'Campo {{ custom_field_name }} {{ action }} com sucesso!',
        'DESCRIPTION': 'O campo foi {{ action }} com sucesso.',
        'ERROR': 'Erro ao {{ action }} campo.'
      }
    },
    'BANK_ACCOUNT': {
      'SAVE': 'Salvar',
      'TYPE': 'Tipo de conta',
      'OWNER_DATA': 'Dados do titular',
      'NAME': 'Nome',
      'EMAIL': 'Email',
      'CPF': 'CPF',
      'BIRTH_DATE': 'Data de nascimento',
      'MONTHLY_INCOME': 'Renda mensal',
      'PROFESSIONAL_OCCUPATION': 'Ocupação profissional',
      'PHONE': 'Telefone',
      'ZIP_CODE': 'CEP',
      'ADDRESS': 'Endereço do titular',
      'NUMBER': 'Número',
      'COMPLEMENT': 'Complemento',
      'NEIGHBORHOOD': 'Bairro',
      'STATE': 'Estado',
      'CITY': 'Cidade',
      'REFERENCE_POINT': 'Ponto de referência',
      'ACCOUNT_DATA': 'Dados da conta',
      'ACCOUNT_NAME': 'Nome da conta',
      'BANK': 'Banco',
      'ACCOUNT_TYPE': 'Tipo de conta',
      'CHECKING': 'Conta corrente',
      'SAVINGS': 'Conta poupança',
      'AGENCY': 'Agência',
      'AGENCY_DIGIT': 'Dígito da agência',
      'ACCOUNT_NUMBER': 'Número da conta',
      'ACCOUNT_DIGIT': 'Dígito da conta',
      'COMPANY_DATA': 'Dados da empresa',
      'TRADE_NAME': 'Nome fantasia',
      'COMPANY_NAME': 'Razão social',
      'CNPJ': 'CNPJ',
      'ANNUAL_REVENUE': 'Faturamento anual'
    },
    //PERMISSIONS
    'PERMISSIONS': {
      'TITLE': 'Permissões',
      'ADMIN': 'Administrador do evento',
      'USER': 'Usuário',
      'SAVE': 'Salvar',
      'CANCEL': 'Cancelar',
      'VALIDATORS': {
        'USER_REQUIRED': 'É obrigatório selecionar o usuário'
      },
      'DIALOG': {
        'CONFIRM_ALTER_ADMIN': {
          'TITLE': 'Tem certeza que deseja trocar o administrador?',
          'DESCRIPTION': 'Você está colocando o usuário <strong>{{ user_name }} ({{ user_email }})</strong> como administrador do evento <strong>{{ event_name }}</strong>',
        }
      },
      'TOASTR': {
        'SUCCESS': {
          'TITLE': 'Administrador alterado com sucesso!',
          'DESCRIPTION': 'Você alterou o usuário que tem a permissão para administrar o evento.'
        },
        'ERROR': {
          'TITLE': 'Erro ao alterar o administrador do evento!'
        }
      }
    },
    //CROSSX APP
    'CROSSX_APP': {
      'TITLE': 'Aplicativo CrossX',
      'TABLE': {
        'TITLE': 'Título',
        'URL': 'URL'
      },
      'DIALOG': {
        'DELETE': {
          'TITLE': 'Tem certeza que deseja deletar?',
          'DESCRIPTION': 'Você está deletando a URL <strong>{{url_title}}</strong>, essa ação não poderá ser revertida.'
        }
      },
      'TOASTR': {
        'DELETE': {
          'SUCCESS': {
            'TITLE': 'URL deletada com sucesso!',
            'DESCRIPTION': 'A URL foi deletada',
          },
          'ERROR': 'Erro ao deletar a URL.'
        },
        'EVENT_NOT_PUBLISH': {
          'TITLE': 'Ação não permitida',
          'DESCRIPTION': 'Para publicar no app do CrossX primeiro publique o evento.'
        },
        'PUBLISH': {
          'SUCCESS': {
            'TITLE': 'Evento publicado no app do CrossX!',
            'DESCRIPTION': 'Evento foi publicado no app',
          },
          'ERROR': 'Erro ao publicar no app do CrossX!',
        },
        'UNPUBLISH': {
          'SUCCESS': {
            'TITLE': 'Evento despublicado no app do CrossX!',
            'DESCRIPTION': 'O evento foi despublicado.'
          },
          'ERRO': 'Erro ao despublicar no app do CrossX!'
        }
      },
      'PUBLISH': 'Publicar no app',
      'UNPUBLISH': 'Despublicar do app',
      'EMPTY': 'Não existe link cadastrado',
      'DELETE': 'Deletar',
      'CANCEL': 'Cancelar',
    },
    'CROSSX_APP_CREATE': {
      'TITLE_NEW': 'Adicionar URL',
      'FORM': {
        'TITLE': 'Título',
        'URL': 'URL',
        'FILE': 'Arquivo',
        'SELECT': 'Selecionar',
        'REMOVE': 'Remover',
      },
      'VALIDATIONS': {
        'TITLE_REQUIRED': 'O campo título é obrigatório.',
        'URL_INVALID': 'O formato da URL é inválido.'
      },
      'TOASTR': {
        'SUCCESS': {
          'TITLE': 'URL Criada com sucesso!',
          'DESCRIPTION': 'URL no CrossX Criada com sucesso',
        }
      },
      'CANCEL': 'Cancelar',
      'SAVE': 'Salvar'
    },
  }
};
