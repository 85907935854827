import {FacebookUserService} from '../../../../core/services/facebook.service';
import {AuthGuard} from '../../../../core/guards/auth.guard';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../../../core/services/authentication.service';
import {config} from '../../../../core/config/config';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FuseConfigService} from '../../../../core/services/config.service';
import {fuseAnimations} from '../../../../core/animations';
import {Title} from '@angular/platform-browser';
import {FuseTranslationLoaderService} from '../../../../core/services/translation-loader.service';

// LOCALE
import {locale as english} from './i18n/en-US';
import {locale as portuguese} from './i18n/pt-BR';

import {FacebookService, InitParams, LoginResponse} from 'ngx-facebook';
import * as moment from 'moment';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: fuseAnimations
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loginFormErrors: any;
  loadingButton = false;
  loadingButtonFacebook = false;
  loginError = false;

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    private fuseConfig: FuseConfigService,
    private formBuilder: FormBuilder,
    private titleService: Title,
    private authenticationService: AuthenticationService,
    private router: Router,
    private authGuard: AuthGuard,
    private fb: FacebookService,
    private fbUserService: FacebookUserService
  ) {

    this.translationLoader.loadTranslations(english, portuguese);

    this.fuseConfig.setSettings({
      layout: {
        navigation: 'none',
        toolbar: 'none',
        footer: 'none'
      }
    });

    const initParams: InitParams = {
      appId: '799424806771474',
      xfbml: true,
      version: 'v2.8'
    };

    fb.init(initParams);

    this.loginFormErrors = {
      email: {},
      password: {}
    };
    
    this.authGuard.canActivateWithoutRedirect();

    this.fbUserService.setUser(null);
  }

  // @ts-ignore
  get currentYear(): number {
    return new Date().getFullYear();
  }

  ngOnInit() {
    // Set page Tilte
    this.titleService.setTitle(`${config.titleBase} - Login`);

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });

    this.loginForm.valueChanges.subscribe(() => {
      this.onLoginFormValuesChanged();
    });

    this.onLoginFormValuesChanged();
  }

  onLoginFormValuesChanged() {
    for (const field in this.loginFormErrors) {
      if (!this.loginFormErrors.hasOwnProperty(field)) {
        continue;
      }

      // Clear previous errors
      this.loginFormErrors[field] = {};

      // Get the control
      const control = this.loginForm.get(field);

      if (control && control.dirty && !control.valid) {
        control.errors['invalid'] = (!control.valid) ? true : null;
        this.loginFormErrors[field] = control.errors;
      }
    }
  }

  logIn() {
    if (!this.loginForm.valid) {
      return;
    }

    this.loadingButton = true;
    this.authenticationService.login(this.loginForm.value.email, this.loginForm.value.password)
      .subscribe(
        result => {

          if (result.valid) {
            this.router.navigate(['/myprofile']);
          } else {
            this.loginError = true;
          }

          this.loadingButton = false;

        }, err => {
          this.loginError = true;
          this.loadingButton = false;
        }
      );
  }

  logInWithFacebook() {
    this.fb.login({scope: 'public_profile,user_friends,email,user_birthday'})
      .then((response: LoginResponse) => {
        this.loadingButtonFacebook = true;
        this.authenticationService.loginWithFacebook(response.authResponse.accessToken)
          .subscribe(
            result => {

              this.loadingButtonFacebook = false;

              if (result.valid) {
                this.router.navigate(['/myprofile']);
              }

            }, err => {
              this.loadingButtonFacebook = false;

              if (err.error.errorCode === 15011) {
                this.fb.api(`/${response.authResponse.userID}?fields=email,last_name,first_name,gender,birthday`)
                  .then((res: any) => {
                    const facebookUser = {
                      token: response.authResponse.accessToken,
                      userID: response.authResponse.userID,
                      userImage: `http://graph.facebook.com/${response.authResponse.userID}/picture?type=square&width=200&height=200`,
                      name: res.first_name,
                      lastName: res.last_name,
                      email: res.email,
                      gender: res.gender = 'male' ? 'Male' : 'Female',
                      birthday: moment(res.birthday, 'MM/DD/YYYY')
                    };
                    this.fbUserService.setUser(facebookUser);
                    this.router.navigate(['/register']);
                  }).catch();

              }
            }
          );
      })
      .catch((error: any) => console.error(error));
  }
}
