import {ChangeDetectorRef, Component, OnDestroy, OnInit, Output, SecurityContext, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {FuseTranslationLoaderService} from '../../../../../core/services/translation-loader.service';
import {HeatsService} from '../heats/services/heats.service';
import {HeatsUrlService} from '../heats/services/heats-url.service';
import {EventGuard} from '../../../../../core/guards/event.guard';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {locale as portugues} from '../heats-manager/i18n/pt-BR';
import {locale as english} from '../heats-manager/i18n/en-US';
import {config} from '../../../../../core/config/config';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {BreadcrumbsService} from '../../../../../core/components/breadcrumbs/breadcrumbs.service';
import {MatIconRegistry, MatMenuTrigger} from '@angular/material';
import {GeneratorHeatsComponent} from './generator-heats/generator-heats.component';
import {ScheduleHeatsComponent} from './schedule-heats/schedule-heats.component';
import {WodService} from '../../../../../core/services/wod/wod.service';
import * as moment from 'moment';
import {Heat} from '../../../../../core/model/heat.model';
import {ToastrService} from '../../../../../core/components/toastr_/toastr/toastr.service';
import {BtnMatFabConfig} from '../../../../../core/components/btn-mat-fab/btn-mat-fab.component';
import {Utils} from '../../../../../core/utils';

@Component({
  selector: 'app-heats-manager',
  templateUrl: './heats-manager.component.html',
  styleUrls: ['./heats-manager.component.scss']
})
export class HeatsManagerComponent implements OnInit, OnDestroy {

  @ViewChild('generatorHeats', {read: GeneratorHeatsComponent}) generatorHeats: GeneratorHeatsComponent;
  @ViewChild('scheduleHeats', {read: ScheduleHeatsComponent}) scheduleHeats: ScheduleHeatsComponent;
  @ViewChild(MatMenuTrigger) optionsButton: MatMenuTrigger;
  @Output() isPeddingSave = false;
  // translate
  translate: any;
  dates: any;
  // tabs
  tabsFilter: string[] = [];
  tabsOptions: any[] = [];
  tabIndexActive = 0;
  // Type
  typeActive: string = null;
  isLoadingExport = false;
  screenActive = 'heats';
  // Championship
  championship: any;
  // Sticky
  isSticky = false;
  // heats
  heats: Heat[] = [];
  heatsOld = '';
  private subscriptions: Subscription = new Subscription();
  isLoadingSaveHeats = false;
  btnMatFabConfig: BtnMatFabConfig[] = [];

  constructor(
    private titleService: Title,
    private breadcrumbsService: BreadcrumbsService,
    private translateService: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
    private heatsService: HeatsService,
    private heatsUrlService: HeatsUrlService,
    private eventGuard: EventGuard,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private wodService: WodService,
    private readonly toast: ToastrService,
    private readonly  changeDetectorRefs: ChangeDetectorRef
  ) {
  }

  // tslint:disable-next-line:member-ordering
  private static formatTime(minutes: number): string {
    if (!minutes) {
      return '';
    }
    return moment('00:00', 'HH:mm').add('minutes', minutes).format('HH:mm');
  }

  ngOnInit() {
    // Translate
    this.registerOnTranslateHeatCompetitors();
    this.registerBtnMatFab();
    // Get Reload Page
    this.eventRouter();
    // tabs config
    this.getTabsType();

    this.transformSVGtoIcon();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public enableHeats(): boolean {
    return this.tabsFilter.length > 0;
  }

  public setScreenActive(screen: string): void {
    this.screenActive = screen;
    if (this.screenActive === 'heats') {
      this.heats = this.scheduleHeats.heats;
    }
    if (this.screenActive === 'schedule') {
      this.heats = this.generatorHeats.heats;
    }
  }

  // tabbar action
  tabAction(event): void {
    this.typeActive = this.tabsOptions[event.index].type;
    this.screenActive = 'heats';
    if (this.typeActive !== this.generatorHeats.dueDate) {
      this.generatorHeats.dueDate = this.typeActive;
    }
    this.generatorHeats.competitions = this.dates[this.typeActive].competitions;
    this.generatorHeats.populateCompetitions();
  }

  onScroll(event): void {
    this.isSticky = event.target.scrollTop >= 280;
  }

  public populateHeat(heats: Heat[]): void {
    this.isLoadingSaveHeats = false;
    this.heats = heats;
    this.generatorHeats.detectChanges();
    this.scheduleHeats.detectChanges();
  }

  // heats
  public generateHeatsOld(heats: Heat[] | ''): void {
    if (heats === '') {
      this.heatsOld = '';
      return;
    }
    const data = [];
    heats.forEach(heat => {
      data.push({
        number: heat.number,
        transition: heat.transition,
        start_time: heat.start_time,
        end_time: heat.end_time,
        due_date: heat.due_date,
        heat_competitors: heat.heat_competitors,
        idRandom: heat.idRandom,
        is_edit_name: heat.is_edit_name,
        enable_custom_start_time: heat.enable_custom_start_time,
      });
    });
    this.heatsOld = JSON.stringify(data);
  }

  public eventCalcData(event: CalcDataScheduleInterface): void {
    if (event.screen === 'heat') {
      this.heats = this.generatorHeats.heats;
    }
    if (event.screen === 'schedule') {
      this.heats = this.scheduleHeats.heats;
    }
    if (event.event) {
      this.calcDataSchedule(event.event, event.hasItem, event.typeUpdate);
      return;
    }
    this.calcDataSchedule();
  }

  public addNewHeat(): void {
    if (this.heats.length === 0) {
      this.generatorHeats.formHeat.setValue({
        ...this.generatorHeats.formHeat.value,
        startTime: '00:00',
        transition: 0,
      });
    }
    this.generatorHeats.generateHeat();
  }

  public saveHeat(): void {
    this.btnMatFabConfig[1].isLoading = this.isLoadingSaveHeats = !(this.heats.filter(heat => heat.isUpdated === true).length === 0);  
    this.generatorHeats.detectChanges();
    this.scheduleHeats.detectChanges();
    this.generatorHeats.saveHeats();
  }
  
  private registerBtnMatFab(): void {
    this.btnMatFabConfig = [
      {
        icon: 'add',
        color: 'primary',
        isLoading: false,
        type: 'add_heat'
      },
      {
        icon: 'save',
        color: 'secondary',
        isLoading: false,
        type: 'save_heat'
      }
    ];
    // if (this.heats.length === 0) {
    //   this.btnMatFabConfig = [
    //     {
    //       icon: 'add',
    //       color: 'primary',
    //       isLoading: false,
    //       listIcons: [
    //         {
    //           icon: 'add',
    //           color: 'secondary',
    //           type: 'add_heat',
    //           text: this.translate.HEATS_GENERATOR.HEATS_ITEMS.ADD_HEAT,
    //           textColor: 'normal'
    //         }
    //       ]
    //     },
    //     {
    //       icon: 'save',
    //       color: 'secondary',
    //       isLoading: false,
    //       type: 'save_heat'
    //     }
    //   ];
    //   return;
    // }

    // this.btnMatFabConfig = [
    //   {
    //     icon: 'add',
    //     color: 'primary',
    //     isLoading: false,
    //     listIcons: [
    //       {
    //         icon: 'publish',
    //         color: 'secondary',
    //         type: 'publish_schedules',
    //         text: this.translate.HEATS_GENERATOR.HEATS_ITEMS.PUBLISH_SCHEDULE_ALL,
    //         textColor: 'normal'
    //       },
    //       {
    //         icon: 'publish',
    //         color: 'secondary',
    //         type: 'publish_competitors',
    //         text: this.translate.HEATS_GENERATOR.HEATS_ITEMS.PUBLISH_COMPETITOR_ALL,
    //         textColor: 'normal'
    //       },
    //       {
    //         icon: 'add',
    //         color: 'secondary',
    //         type: 'add_heat',
    //         text: this.translate.HEATS_GENERATOR.HEATS_ITEMS.ADD_HEAT,
    //         textColor: 'normal'
    //       },
    //       {
    //         icon: 'undo',
    //         color: 'secondary',
    //         type: 'unpublish_schedules',
    //         text: this.translate.HEATS_GENERATOR.HEATS_ITEMS.UNPUBLISH_SCHEDULE_ALL,
    //         textColor: 'danger'
    //       },
    //       {
    //         icon: 'undo',
    //         color: 'secondary',
    //         type: 'unpublish_competitors',
    //         text: this.translate.HEATS_GENERATOR.HEATS_ITEMS.UNPUBLISH_COMPETITOR_ALL,
    //         textColor: 'danger'
    //       }
    //     ]
    //   },
    //   {
    //     icon: 'save',
    //     color: 'secondary',
    //     isLoading: false,
    //     type: 'save_heat'
    //   }
    // ];
  }

  private getWodByChampionship(championship_id: number): void {
    this.subscriptions.add(
      this.wodService
        .getWodDateByChampionshipId({championship_id})
        .subscribe((results) => {
          this.dates = results;
          this.populateTabs();
        })
    );
  }

  private populateTabs(): void {
    if (this.dates) {
      Object.keys(this.dates).forEach((date) => {
        this.tabsOptions.push({
          name: moment(date).format(this.translate.DATE_FORMAT),
          type: date
        });
      });

      this.tabsFilter = [];
      this.tabsOptions.forEach((tab) => {
        this.tabsFilter.push(tab.name);
        if (this.typeActive === null) {
          this.typeActive = tab.type;
        }
      });
      if (this.enableHeats()) {
        if (!this.generatorHeats.competitions) {
          this.generatorHeats.competitions = this.dates[this.typeActive].competitions;
          this.generatorHeats.populateCompetitions();
        }
      }
    }
  }

  private registerOnTranslateHeatCompetitors(): void {

    this.translationLoader.loadTranslations(english, portugues);
    const subscription = this.translateService
      .get('HEAT_COMPETITORS')
      .subscribe((response: Object) => {
        this.translate = response;
      });

    this.subscriptions.add(subscription);
  }

  private getTabsType(): void {
    const subscription = this.activatedRoute.params.subscribe(
      params => {
        // Reset tabs
        this.typeActive = null;
        this.tabsOptions.splice(0);

        this.championship = this.eventGuard.getChampionshipPerId(params.championship_id);
        this.getWodByChampionship(params.championship_id);

        if (this.translate) {
          this.titleService.setTitle(`${config.titleBase} - ${this.translate.TITLE}`);

          this.breadcrumbsService.replaceAll([{
            text: this.translate.TITLE,
            route: `/championship/heats/${params.championship_id}`
          }]);
        }
      }
    ); // end router

    this.subscriptions.add(subscription);
  }

  private eventRouter() {
    this.subscriptions.add(this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.typeActive = null;
        }
      }));
  }

  private transformSVGtoIcon(): void {
    // Icon Info
    this.iconRegistry.addSvgIcon(
      'ic-lanes',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/heats/ic-lanes.svg')
    );
    this.iconRegistry.addSvgIcon(
      'ic-calendar',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/heats/ic-calendar.svg')
    );
  }

  private calcDataSchedule(event?: any, heatItem?: Heat, typeUpdate?: number): void {
    if (this.heats.length === 0) {
      return;
    }
    // @ts-ignore
    const defaultDate = this.heats.find((heatFilter: Heat) => heatFilter.number === 1).due_date;
    let nextDate = '';
    this.heats
      .sort((a: Heat, b: Heat) => a.number - b.number)
      .forEach((heat: Heat, i: number) => {
        this.heats[i].isUpdated = true;
        if (this.heatsOld === null) {
          return;
        }
        if (i === 0 || heat.enable_custom_start_time) {
          const date = moment(`${defaultDate} ${heat.start_time}`, 'YYYY-MM-DD HH:mm:ss');
          heat.due_date = date.format('YYYY-MM-DD');
          heat.end_time = date.add('minutes', heat.duration / 60).format('HH:mm:ss');
          const addMinutes = date.diff(moment(`${heat.due_date} ${heat.end_time}`, 'YYYY-MM-DD H:mm'), 'minutes') + heat.transition;
          nextDate = date.add('minutes', addMinutes).format('YYYY-MM-DD');
          return;
        }

        if (nextDate) {
          if (nextDate !== defaultDate) {
            this.toast.show(
              this.translate.HEAT_EDIT.TOASTR.ERROR_DATE.DESCRIPTION,
              this.translate.HEAT_EDIT.TOASTR.ERROR_DATE.TITLE,
              null,
              'error'
            );
            const data: Heat[] = JSON.parse(this.heatsOld);
            if (this.heats.length > data.length) {
              this.heats.splice(this.heats.length - 1, this.heats.length - data.length);
            }
            data.forEach((heatData) => {
              if (heatItem) {
                if (heatItem.idRandom === heatData.idRandom) {
                  if (typeUpdate === 1) {
                    event.target.value = moment(heatData.start_time, 'HH:mm:ss').format('HH:mm');
                  }
                  if (typeUpdate === 2) {
                    event.target.value = HeatsManagerComponent.formatTime(heatData.transition);
                  }
                  if (typeUpdate === 3) {
                    event.target.value = HeatsManagerComponent.formatTime(heatData.duration);
                  }
                }
              }
              // @ts-ignore
              const indexOld = this.heats.findIndex((heatFilter: Heat) => heatFilter.idRandom === heatData.idRandom);
              this.heats[indexOld].due_date = heatData.due_date;
              this.heats[indexOld].start_time = heatData.start_time;
              this.heats[indexOld].end_time = heatData.end_time;
              this.heats[indexOld].transition = heatData.transition;
              this.heats[indexOld].number = heatData.number;
              this.heats[indexOld].heat_competitors = heatData.heat_competitors;
              this.heats[indexOld].is_edit_name = heatData.is_edit_name;
              this.heats[indexOld].enable_custom_start_time = heatData.enable_custom_start_time;
            });
            this.heatsOld = null;
            const dataHeatOld = JSON.stringify(this.heats);
            this.heats = [];
            this.heats = JSON.parse(dataHeatOld);
            this.changeDetectorRefs.detectChanges();
            return;
          }
          const endTimeOld = this.heats[i - 1].end_time;
          heat.start_time = moment(endTimeOld, 'H:mm').add('minutes', this.heats[i - 1].transition).format('H:mm');
          const date = moment(`${defaultDate} ${heat.start_time}`, 'YYYY-MM-DD HH:mm:ss');
          heat.due_date = date.format('YYYY-MM-DD');
          heat.end_time = date.add('minutes', heat.duration / 60).format('HH:mm:ss');
          const addMinutes = date.diff(moment(`${heat.due_date} ${heat.end_time}`, 'YYYY-MM-DD H:mm'), 'minutes') + Number(this.heats[i].transition);
          nextDate = date.add('minutes', addMinutes).format('YYYY-MM-DD');
        }
      });
    this.heats = this.heats.sort((a: Heat, b: Heat) => a.number - b.number);
    this.scheduleHeats.detectChanges();
    this.generatorHeats.detectChanges();
    this.changeDetectorRefs.detectChanges();
  }

  exportHeat() {
    this.isLoadingExport = true;
    const subscription = this.heatsService.export(this.championship.id, this.generatorHeats.dueDate)
      .subscribe(
        result => {
          Utils.forceDownloadWithValidationURL(result.file);

          this.isLoadingExport = false;
        }, err => {
          this.isLoadingExport = false;
        }
      );

    this.subscriptions.add(subscription);
  }
}

export interface CalcDataScheduleInterface {
  event?: any;
  hasItem?: Heat;
  typeUpdate?: number;
  screen: 'heat' | 'schedule';
}
