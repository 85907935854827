import { MatDialog } from '@angular/material';
import { CrossXDialogComponent } from './../../../../core/components/cross-x-dialog/cross-x-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { FacebookUserService } from './../../../../core/services/facebook.service';
import { AuthGuard } from './../../../../core/guards/auth.guard';
import { Router } from '@angular/router';
import { AuthenticationService } from './../../../../core/services/authentication.service';
import { config } from './../../../../core/config/config';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FuseConfigService } from '../../../../core/services/config.service';
import { fuseAnimations } from '../../../../core/animations';
import { Title } from '@angular/platform-browser';
import { FuseTranslationLoaderService } from '../../../../core/services/translation-loader.service';
import { Location } from '@angular/common';

// LOCALE
import { locale as english } from './i18n/en-US';
import { locale as portuguese } from './i18n/pt-BR';

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  forgotForm: FormGroup;
  forgotFormErrors: any;
  loadingButton: boolean = false
  loadingButtonFacebook: boolean = false
  forgotError: boolean = false

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private fuseConfig: FuseConfigService,
    private formBuilder: FormBuilder,
    private titleService: Title,
    private authenticationService: AuthenticationService,
    private router: Router,
    private authGuard: AuthGuard,
    public dialog: MatDialog,
    private location: Location,
  ) { 
    this.translationLoader.loadTranslations(english, portuguese);

    this.fuseConfig.setSettings({
      layout: {
        navigation: 'none',
        toolbar: 'none',
        footer: 'none'
      }
    });

    this.forgotFormErrors = {
      email: {}
    };

    //verify user logged
    this.authGuard.canActivateWithoutRedirect()
  }

  ngOnInit() {
    // Set page Tilte
    this.translateService.get("FORGOT_PASSWORD").subscribe((res: any) => {
      this.titleService.setTitle(`${config.titleBase} - ${res.TITLE}`)
    });
    
    this.forgotForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });

    this.forgotForm.valueChanges.subscribe(() => {
      this.onforgotFormValuesChanged();
    });

    this.onforgotFormValuesChanged()
  }

  forgotPassword()
  {
    if (!this.forgotForm.valid) {
      return
    } 

    this.loadingButton = true
    this.authenticationService.forgotPassword(this.forgotForm.value.email)
      .subscribe(
        result => {
          this.loadingButton = false

          const dialogRef = this.dialog.open(CrossXDialogComponent, {
            width: '440px'
          });

          dialogRef.componentInstance.dialogConfirm = false
          dialogRef.componentInstance.cancelButton = 'FECHAR';

          dialogRef.afterClosed().subscribe(resultDialog => {
            if (result.valid) {
              this.router.navigate(['login'])
            }
          });

          if (result.valid) {
            dialogRef.componentInstance.type = 'success'
            dialogRef.componentInstance.titleDialog = 'Sucesso!'
            dialogRef.componentInstance.dialogContent = `As instruções para a redefinir a senha foram enviadas para o e-mail <strong>${this.forgotForm.value.email}</strong>`
          } else {
            dialogRef.componentInstance.type = 'error'
            dialogRef.componentInstance.titleDialog = 'Erro!'
            dialogRef.componentInstance.dialogContent = `Tente novamente`
          }

        }, err => {
          this.loadingButton = false

          const errors = err.error
          const dialogRef = this.dialog.open(CrossXDialogComponent, {
            width: '440px'
          });

          dialogRef.componentInstance.dialogConfirm = false
          dialogRef.componentInstance.cancelButton = 'FECHAR'
          //dialogRef.componentInstance.actionButton = 'EXCLUIR'
          dialogRef.componentInstance.type = 'error'
          dialogRef.componentInstance.titleDialog = errors.errorTitle
          dialogRef.componentInstance.dialogContent = errors.errorDescription

          dialogRef.afterClosed().subscribe(result => {
          });

        }
      );
  }

  gotoLogin()
  {
    this.location.back()
  }


  onforgotFormValuesChanged() {
    for (const field in this.forgotFormErrors) {
      if (!this.forgotFormErrors.hasOwnProperty(field)) {
        continue;
      }

      // Clear previous errors
      this.forgotFormErrors[field] = {};

      // Get the control
      const control = this.forgotForm.get(field);

      if (control && control.dirty && !control.valid) {
        control.errors["invalid"] = (!control.valid) ? true : null;
        this.forgotFormErrors[field] = control.errors;
      }
    }
  }


}
