import { Component, OnDestroy, OnInit } from '@angular/core';
import { HeatsHttpService } from '../../../../../../core/services/heats/heats-http.service';
import { Subscription, zip } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HeatsService } from '../services/heats.service';
import { locale as portugues } from '../i18n/pt-BR';
import { HeatQueryParams } from '../enums/heat-query.params';
import { HeatsUrlService } from '../services/heats-url.service';
import { Competition } from '../../../../../../core/model/competition';
import { Wod } from '../../../../../../core/model/wod';
import { Competitor } from '../../../../../../core/model/competitor';
import { TableConfig } from '../../../../../../core/components/table-x/table-x.component';
import { KeyValue } from '../model/key-value.model';
import { ToastrService } from '../../../../../../core/components/toastr_/public_api';
import { FuseTranslationLoaderService } from '../../../../../../core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import {EventGuard} from '../../../../../../core/guards/event.guard';
import {ActivatedRoute} from '@angular/router';
import {Utils} from '../../../../../../core/utils';

@Component({
  selector: 'app-filter-competitors',
  templateUrl: './filter-competitors.component.html',
  styleUrls: ['./filter-competitors.component.scss'],
})
export class FilterCompetitorsComponent implements OnInit, OnDestroy {

  dataFilterCompetitors: Competitor[];

  private readonly subscriptions = new Subscription();

  // form
  eventForm: FormGroup;
  competitions = new Array<Competition>();
  allCompetitions = new Array<Competition>();
  wods = new Array<Wod>();
  // translate
  translate: any;
  isLoading: boolean = false;

  tableCatConfig: TableConfig = {
    checkbox: false,
    paddingSide: true,
    massActions: [
      {
        type: 'reproved',
        title: 'Reprovar'
      }
    ],
    massActionCountTitle: null
  };
  isSticky = false;

  private defaultParams = Array<KeyValue>();

  constructor(
    private translateService: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
    private heatsHttpService: HeatsHttpService,
    private formBuilder: FormBuilder,
    private heatsService: HeatsService,
    private heatsUrlService: HeatsUrlService,
    private toastr: ToastrService,
    private eventGuard: EventGuard
  ) {
    this.registerOnTranslateHeatCompetitors();
    this.createForm();
    this.resgisterOnCompetition();
    this.registerOnCompetitorObservable();
    this.onChangeForm();
    this.registerOnScrollObservable();
  }

  ngOnInit() {
    this.heatsService.doRequest();
  }
  
  private getCompetitionsWithChampionship(): void {

  }

  private registerOnTranslateHeatCompetitors(): void {
    this.translationLoader.loadTranslations(portugues);
    const subscription = this.translateService
      .get('HEAT_HEATCOMPETITORS')
      .subscribe((response: Object) => {
        this.translate = response;
      });

    this.subscriptions.add(subscription);
  }

  private resgisterOnCompetition(): void {
    const subscription = zip(
      this.heatsService
        .competitionObservable(),
      this.heatsUrlService
        .queryParamsObservable()
    ).subscribe(([competitions, queryParams]) => {
      this.defaultParams = queryParams;
      this.wods = [];
      this.allCompetitions = competitions

      // @ts-ignore
      const teamType = this.defaultParams.find((param: KeyValue) => HeatQueryParams.TYPE === param.key);
      if (teamType) {
        // @ts-ignore
        const competitionFilter = Utils.getCompetitionsPerType(competitions, teamType.value)
        this.competitions = competitionFilter;
      } 
      
      if (competitions.length) {
        this.setDefaultParams();
        return;
      }  
      
      this.eventForm.controls['competitions'].setValue('');
      this.eventForm.controls['wods'].setValue('');
    });

    this.subscriptions.add(subscription);
    
  }

  private setDefaultCompetitionAndWod(): void {
    if (this.competitions.length) {
      this.eventForm.controls['competitions'].setValue(this.competitions[0].id);
      if (this.competitions[0].wods.length) {
        this.wods = this.competitions[0].wods;
        this.eventForm.controls['wods'].setValue(this.competitions[0].wods[0].id);
      }
    }
  }

  private registerOnCompetitorObservable(): void {
  }

  private registerOnScrollObservable(): void {
    this.heatsService
      .scrollEventObservable()
      .subscribe((event) => {
        this.isSticky = event.target.scrollTop >= 280;
      });
  }

  private setDefaultParams(): void {
    const competitionId = this.defaultParams
      .find((param: KeyValue) => HeatQueryParams.COMPETITION_ID === param.key);
    const wodId = this.defaultParams
      .find((param: KeyValue) => HeatQueryParams.WODS_ID === param.key);
    if (!this.competitions.length || !this.competitions[0].wods.length) {
      return;
    }
    if (!competitionId) {
      this.setDefaultCompetitionAndWod();
      return;
    }
    const verifyCompetitionId = this.competitions.find((competition: Competition) => competition.id === +competitionId.value);

    if (!verifyCompetitionId) {
      this.setDefaultCompetitionAndWod();
      return;
    }
    
    this.eventForm.controls['competitions'].setValue(verifyCompetitionId.id);
    this.wods = verifyCompetitionId.wods;

    if (!wodId) {
      this.eventForm.controls['wods'].setValue('');
      return;
    }
    this.eventForm.controls['wods'].setValue(Number(wodId.value));
  }

  private createForm(): void {
    this.eventForm = this.formBuilder.group({
      competitions: ['', Validators.required],
      wods: ['', Validators.required],
      search: ['', ''],
    });
  }

  onChangeForm(): void {
    this.eventForm
      .valueChanges
      .subscribe((values) => {
        
        let {wods, competitions} = values;
        const {search} = values;

        if (!competitions) {
          competitions = '';
          wods = '';
          this.heatsService.removeFilter(HeatQueryParams.COMPETITION_ID);
          this.heatsService.removeFilter(HeatQueryParams.WODS_ID);
          return;
        }

        const competititonFind = this.competitions
          .find((competition: Competition) => competition.id === competitions);
        
        if (!competititonFind) {
          competitions = '';
          wods = '';
          return;
        }

        if (!competititonFind.wods.length) {
          this.wods = [];
          wods = '';
          this.heatsService.removeFilter(HeatQueryParams.WODS_ID);
          this.toastr.show(
            this.translate.FILTER_COMPETITORS.TOASTR.WODS.DESCRIPTION,
            this.translate.FILTER_COMPETITORS.TOASTR.WODS.TITLE,
            null,
            'error',
          );
          return;
        }
        this.wods = competititonFind.wods;

        wods = competititonFind.wods[0].id;
        this.heatsService.addFilter(HeatQueryParams.COMPETITION_ID, competitions);
        this.heatsService.addFilter(HeatQueryParams.WODS_ID, wods);

        if (search) {
          this.heatsService.addFilter(HeatQueryParams.NAME, search);
        }
        
        this.isLoading = true;
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
