import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {locale as portugues} from '../../heats-manager/i18n/pt-BR';
import {locale as english} from '../../heats-manager/i18n/en-US';
import {DomSanitizer} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {FuseTranslationLoaderService} from '../../../../../../core/services/translation-loader.service';
import {EventGuard} from '../../../../../../core/guards/event.guard';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog, MatDialogRef, MatIconRegistry} from '@angular/material';
import {Subscription} from 'rxjs';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FormErrorStateMatcher} from '../../../../../../core/utils/form-error-state-matcher';
import {WodService} from '../../../../../../core/services/wod/wod.service';
import {Competition} from '../../../../../../core/model/competition';
import {Wod} from '../../../../../../core/model/wod';
import {CompetitorService} from '../../../../../../core/services/competitor/competitor.service';
import {Competitor, CompetitorCheckin} from '../../../../../../core/model/competitor';
import {Draggable} from '../../../../../../core/model/draggable.model';
import {Heat} from '../../../../../../core/model/heat.model';
import {ToastrService} from '../../../../../../core/components/toastr_/toastr/toastr.service';
import {CrossXDialogComponent} from '../../../../../../core/components/cross-x-dialog/cross-x-dialog.component';
import {HeatsService} from '../../../../../../core/services/heats/heats.service';
import {Errors} from '../../../../../../core/model/errors';
import {HeatLanesOrder} from '../Helpers/HeatLanesOrder';
import {SubDivision} from '../../../../../../core/model/sub-division';
import * as moment from 'moment';
import {BtnMatFabConfig} from '../../../../../../core/components/btn-mat-fab/btn-mat-fab.component';
import {CalcDataScheduleInterface} from '../heats-manager.component';

@Component({
  selector: 'app-generator-heats',
  templateUrl: './generator-heats.component.html',
  styleUrls: ['./generator-heats.component.scss'],
  // encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeneratorHeatsComponent implements OnInit, OnDestroy {

  @Output() heatsOldEvent = new EventEmitter<Heat[] | ''>();
  @Output() calcHeatsEvent = new EventEmitter<CalcDataScheduleInterface>();
  @Output() updateHeatEvent = new EventEmitter<Heat[]>();
  @Output() saveHeatEvent = new EventEmitter<void>();
  @Input() heats: Heat[] = [];
  @Input() heatsOld: string;
  @Input() btnMatFabConfig: BtnMatFabConfig[] = [];
  // translate
  translate: any;
  // type competitor
  enableCompetitorType = 1;
  competitorCheckin = CompetitorCheckin;
  // championship
  championship: any;
  competitionIdSelected: number = null;
  competitions: Competition[];
  competitionsAll: Competition[] = [];
  wods: Wod[] = [];
  wodsList: Wod[] = [];
  subDivisions: SubDivision[] | null;
  competitorsWithCompetitionActive: Competitor[] = [];
  competitors: Draggable[] = [];
  competitorsFilter: any = {
    teamName: ''
  };
  targets: string[] = [];
  targetsHeats: string[] = [];
  dueDate: string;
  idsDelete: number[] = [];
  stickyCountCompetitor = false;
  sticky: boolean;
  dialogActive: any = null;
  // form
  heatsGeneratorGroup: FormGroup;
  filterFormGroup: FormGroup;
  matchers: any;
  matchersFilterGroup: any;
  formHeat: FormGroup;
  startTime: any;
  transition: any;
  public isLoadingSaveHeats = false;
  public isLoadedHeats = false;
  public isFirstPopulatedCompetitors = false;
  private subscriptions: Subscription = new Subscription();
  private columnSortCompetitor = 'number';
  private orderSortCompetitor = 'ASC';

  constructor(
    private translateService: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
    private formBuilder: FormBuilder,
    private eventGuard: EventGuard,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private wodService: WodService,
    private competitorService: CompetitorService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private heatService: HeatsService,
    private changeDetectorRefs: ChangeDetectorRef
  ) {
  }

  // @ts-ignore
  get typeActive(): string {
    return this.dueDate;
  }

  // @ts-ignore
  @Input('typeActive') set typeActive(dueDate: string) {
    this.dueDate = dueDate;
    this.competitors = [];
    this.heats = [];

    if (this.heatsGeneratorGroup) {
      this.heatsGeneratorGroup.get('numberHeats').setValue('');
      this.heatsGeneratorGroup.get('numberLanes').setValue('');
    }

    if (this.championship && this.dueDate) {
      this.heatService.fetchHeatsWithChampionshipAndType(this.championship.id, this.dueDate);
    }
  }

  // @ts-ignore
  get isSticky(): boolean {
    return this.sticky;
  }

  // @ts-ignore
  @Input('isSticky') set isSticky(sticky: boolean) {
    this.sticky = sticky;
  }

  ngOnInit() {
    // this.changeDetectorRefs.detectChanges();
    // Translate
    this.registerWods();
    this.registerOnTranslateHeatCompetitors();
    this.getEventDetail();
    this.createForm();
    this.registerOnChangeCompetitionFilter();
    this.registerOnChangeWodsFilter();
    this.registerOnChangeNameFilter();
    this.registerOnGetCompetitorsWithCompetition();
    this.registerOnSaveHeats();
    this.registerOnGetHeats();
    this.registerOnSaveHeatsSchedule();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public saveHeats(): void {
    const parameters: { heats: any[], heat_delete_ids: number[] } = {
      heats: [],
      heat_delete_ids: []
    };

    const heatsSend = this.heats.filter(heat => heat.isUpdated === true);
    if (heatsSend.length === 0 && this.idsDelete.length === 0) {
      if (this.btnMatFabConfig[1]) {
        this.btnMatFabConfig[1].isLoading = false;
      }
      if (this.btnMatFabConfig[0]) {
        this.btnMatFabConfig[0].isLoading = false;
      }
      return;
    }
    this.btnMatFabConfig[1].isLoading = true;
    heatsSend.forEach((heat: Heat) => {
      let heatSend: any = {
        id: heat.id,
        championship_id: heat.championship_id,
        name: heat.name,
        number: heat.number,
        total_lanes: heat.total_lanes,
        due_date: heat.due_date,
        start_time: heat.start_time,
        end_time: heat.end_time,
        type: heat.type,
        transition: heat.transition,
        enable_custom_start_time: heat.enable_custom_start_time,
        is_edit_name: heat.is_edit_name,
        heat_competitors: [],
      };
      if (heat.published_schedule === true || heat.published_schedule === false) {
        heatSend = {
          ...heatSend,
          published_schedule: heat.published_schedule
        };
      }
      if (heat.published_competitor === true || heat.published_competitor === false) {
        heatSend = {
          ...heatSend,
          published_competitor: heat.published_competitor
        };
        if (heat.published_competitor === true) {
          heatSend = {
            ...heatSend,
            published_schedule: true
          };
        }
      }

      heat.heat_competitors.forEach((heatCompetitor: Draggable) => {
        if (heatCompetitor.type === 'item') {
          const heatCompetitorSend = {
            id: heatCompetitor.id,
            heat_id: heat.id,
            wod_id: heatCompetitor.wod_id,
            competitor_id: heatCompetitor.competitor_id,
            team_id: heatCompetitor.competitor.team.id,
            lane: heatCompetitor.lane
          };

          heatSend.heat_competitors.push(heatCompetitorSend);
        }
      });

      parameters.heats.push(heatSend);
    });
    parameters.heat_delete_ids = this.idsDelete;
    this.heatService.saveOrUpdateHeats(parameters);
  }

  public publishedUnpublishedAllSchedule(isUnpublished = false): void {
    if (this.btnMatFabConfig[0]) {
      this.btnMatFabConfig[0].isLoading = false;
    }
    this.dialog.openDialogs.pop();
    this.dialogActive = this.dialog.open(CrossXDialogComponent, {
      width: '440px',
      closeOnNavigation: true
    });

    const titleDialog = isUnpublished ?
      this.translate.HEATS_GENERATOR.DIALOG.UNPUBLISH_ALL_SCHEDULE.TITLE :
      this.translate.HEATS_GENERATOR.DIALOG.PUBLISH_ALL_SCHEDULE.TITLE;
    const dialogContent = isUnpublished ?
      this.translate.HEATS_GENERATOR.DIALOG.UNPUBLISH_ALL_SCHEDULE.MESSAGE :
      this.translate.HEATS_GENERATOR.DIALOG.PUBLISH_ALL_SCHEDULE.MESSAGE;
    this.dialogActive.componentInstance.titleDialog = titleDialog;
    this.dialogActive.componentInstance.dialogContent = dialogContent;
    this.dialogActive.componentInstance.type = 'warning';
    this.dialogActive.componentInstance.cancelButton = this.translate.HEATS_GENERATOR.DIALOG.CANCEL.toUpperCase();
    this.dialogActive.componentInstance.actionButton = this.translate.HEATS_GENERATOR.DIALOG.CONTINUE.toUpperCase();
    this.dialogActive.componentInstance.object = event;
    this.dialogActive.componentInstance.dialogConfirm = true;
    this.dialogActive.componentInstance.confirmActionRequest = true;
    this.subscriptions.add(this.dialogActive.componentInstance.dialogEvent.subscribe(result => {
      this.dialogActive.componentInstance.confirmLoading = true;
      this.heats.forEach((heat, i) => {
        this.heats[i].published_schedule = !isUnpublished;
        if (!this.heats[i].published_schedule) {
          this.heats[i].published_competitor = this.heats[i].published_schedule;
        }
        this.heats[i].isUpdated = true;
      });
      if (this.btnMatFabConfig[0]) {
        this.btnMatFabConfig[0].isLoading = true;
      }
      this.saveHeats();
    }));
  }

  public publishedUnpublishedAllCompetitor(isUnpublished = false): void {
    if (this.btnMatFabConfig[0]) {
      this.btnMatFabConfig[0].isLoading = false;
    }
    this.dialog.openDialogs.pop();
    this.dialogActive = this.dialog.open(CrossXDialogComponent, {
      width: '440px',
      closeOnNavigation: true
    });
    const titleDialog = isUnpublished ?
      this.translate.HEATS_GENERATOR.DIALOG.UNPUBLISH_ALL_COMPETITOR.TITLE :
      this.translate.HEATS_GENERATOR.DIALOG.PUBLISH_ALL_COMPETITOR.TITLE;
    const dialogContent = isUnpublished ?
      this.translate.HEATS_GENERATOR.DIALOG.UNPUBLISH_ALL_COMPETITOR.MESSAGE :
      this.translate.HEATS_GENERATOR.DIALOG.PUBLISH_ALL_COMPETITOR.MESSAGE;
    this.dialogActive.componentInstance.titleDialog = titleDialog;
    this.dialogActive.componentInstance.dialogContent = dialogContent;
    this.dialogActive.componentInstance.type = 'warning';
    this.dialogActive.componentInstance.cancelButton = this.translate.HEATS_GENERATOR.DIALOG.CANCEL.toUpperCase();
    this.dialogActive.componentInstance.actionButton = this.translate.HEATS_GENERATOR.DIALOG.CONTINUE.toUpperCase();
    this.dialogActive.componentInstance.object = event;
    this.dialogActive.componentInstance.dialogConfirm = true;
    this.dialogActive.componentInstance.confirmActionRequest = true;
    this.subscriptions.add(this.dialogActive.componentInstance.dialogEvent.subscribe(result => {
      this.dialogActive.componentInstance.confirmLoading = true;
      this.heats.forEach((heat, i) => {
        this.heats[i].published_competitor = !isUnpublished;
        if (this.heats[i].published_competitor) {
          this.heats[i].published_schedule = this.heats[i].published_competitor;
        }
        this.heats[i].isUpdated = true;
      });
      if (this.btnMatFabConfig[0]) {
        this.btnMatFabConfig[0].isLoading = true;
      }
      this.saveHeats();
    }));
  }

  public publishedAndUnpublishedScheduleAndCompetitor(type: number, heat: Heat): void {
    heat.isUpdated = true;
    // schedule
    if (type === 1) {
      this.dialog.openDialogs.pop();
      this.dialogActive = this.dialog.open(CrossXDialogComponent, {
        width: '440px',
        closeOnNavigation: true
      });

      const titleDialog = !heat.published_schedule ?
        this.translate.HEATS_GENERATOR.DIALOG.PUBLISH_SCHEDULE.TITLE :
        this.translate.HEATS_GENERATOR.DIALOG.UNPUBLISH_SCHEDULE.TITLE;
      const dialogContent = !heat.published_schedule ?
        this.translate.HEATS_GENERATOR.DIALOG.PUBLISH_SCHEDULE.MESSAGE :
        this.translate.HEATS_GENERATOR.DIALOG.UNPUBLISH_SCHEDULE.MESSAGE;
      this.dialogActive.componentInstance.type = 'warning';
      this.dialogActive.componentInstance.titleDialog = titleDialog;
      this.dialogActive.componentInstance.dialogContent = dialogContent;
      this.dialogActive.componentInstance.cancelButton = this.translate.HEATS_GENERATOR.DIALOG.CANCEL.toUpperCase();
      this.dialogActive.componentInstance.actionButton = this.translate.HEATS_GENERATOR.DIALOG.CONTINUE.toUpperCase();
      this.dialogActive.componentInstance.object = event;
      this.dialogActive.componentInstance.dialogConfirm = true;
      this.dialogActive.componentInstance.confirmActionRequest = true;
      this.subscriptions.add(this.dialogActive.componentInstance.dialogEvent.subscribe(result => {
        this.dialogActive.componentInstance.confirmLoading = true;
        heat.published_schedule = !heat.published_schedule;
        if (!heat.published_schedule) {
          heat.published_competitor = heat.published_schedule;
        }
        this.saveHeats();
      }));
      return;
    }
    // competitor
    if (type === 2) {
      this.dialog.openDialogs.pop();
      this.dialogActive = this.dialog.open(CrossXDialogComponent, {
        width: '440px',
        closeOnNavigation: true
      });

      const titleDialog = !heat.published_competitor ?
        this.translate.HEATS_GENERATOR.DIALOG.PUBLISH_COMPETITOR.TITLE :
        this.translate.HEATS_GENERATOR.DIALOG.UNPUBLISH_COMPETITOR.TITLE;
      const dialogContent = !heat.published_competitor ?
        this.translate.HEATS_GENERATOR.DIALOG.PUBLISH_COMPETITOR.MESSAGE :
        this.translate.HEATS_GENERATOR.DIALOG.UNPUBLISH_COMPETITOR.MESSAGE;
      this.dialogActive.componentInstance.type = 'warning';
      this.dialogActive.componentInstance.titleDialog = titleDialog;
      this.dialogActive.componentInstance.dialogContent = dialogContent;
      this.dialogActive.componentInstance.cancelButton = this.translate.HEATS_GENERATOR.DIALOG.CANCEL.toUpperCase();
      this.dialogActive.componentInstance.actionButton = this.translate.HEATS_GENERATOR.DIALOG.CONTINUE.toUpperCase();
      this.dialogActive.componentInstance.object = event;
      this.dialogActive.componentInstance.dialogConfirm = true;
      this.dialogActive.componentInstance.confirmActionRequest = true;
      this.subscriptions.add(this.dialogActive.componentInstance.dialogEvent.subscribe(result => {
        this.dialogActive.componentInstance.confirmLoading = true;
        heat.published_competitor = !heat.published_competitor;
        if (heat.published_competitor) {
          heat.published_schedule = heat.published_competitor;
        }
        this.saveHeats();
      }));
      return;
    }
  }

  public builderDrag(event: any, heat: Heat, tableName: string, heats: any) {
    heat.isUpdated = true;
    this.generateHeatsOld(heats);
  }

  public builderDrop(event, heat: Heat): void {
    const isCompetitor = event.value.tableName === 'competitors';
    if (isCompetitor) {
      this.heatsOldEvent.emit('');
      this.heatsOld = '';
    }

    let indexHeatOld = -1;
    if (event.value.tableName.split('-').length === 3) {
      // @ts-ignore
      indexHeatOld = this.heats.findIndex((heatFilter: Heat) => heatFilter.number === Number(event.value.tableName.split('-')[2]));
    }
    const tableName = this.getNameOfTable(heat);
    if (heat.duration === null) {
      heat.duration = event.value.wod.time_cap;
    }

    if (event.value.tableName !== tableName) {
      const items = heat.heat_competitors.filter((param) => param.type === 'item');

      // @ts-ignore
      const itemDrop = heat.heat_competitors.find((item) => item.type === 'item' && item === event.value);

      // VALIDATE LENGTH COMPETITOR SELECTED
      const selecteds = this.competitors.filter(item => {
        return item.selected === true;
      });

      if (!this.validateWodDate(event, heat, isCompetitor)) {
        this.removeCompetitorHeat(event, heat);
        return;
      }

      if (!this.validateTimeCap(event, heat)) {
        this.removeCompetitorHeat(event, heat);
        return;
      }

      // Validate limit lanes with all selecteds
      if (items.length + selecteds.length > heat.total_lanes) {
        this.removeCompetitorHeat(event, heat);

        this.toastr.show(
          this.translate.HEATS_GENERATOR.TOASTR.LIMIT_LANE.MESSAGE,
          this.translate.HEATS_GENERATOR.TOASTR.LIMIT_LANE.TITLE,
          null,
          'error',
        );
        return;
      }

      // Validate is same competitor and diff wods
      if (!this.hasSameCompetitionAndDiffWodInHeat(heat, event)) {
        this.removeCompetitorHeat(event, heat);

        this.toastr.show(
          this.translate.HEATS_GENERATOR.TOASTR.SAME_WOD_COMPETITION.MESSAGE,
          this.translate.HEATS_GENERATOR.TOASTR.SAME_WOD_COMPETITION.TITLE,
          null,
          'error',
        );

        return;
      }

      const itemPlaceholder = this.getItemPlaceholder(heat, event.dropIndex);
      if (itemPlaceholder !== undefined) {
        heat.heat_competitors.splice(heat.heat_competitors.indexOf(itemPlaceholder), 1);
      }

      if (selecteds && selecteds.length > 0) {
        // Verify quantanty empty lane at position multiple selecteds
        let validationNewDropIndex = event.dropIndex + 1;
        let isValid = true;
        selecteds.forEach(() => {
          if (heat.heat_competitors[validationNewDropIndex] === undefined ||
            heat.heat_competitors[validationNewDropIndex].type === 'item'
          ) {
            isValid = false;
          }
          validationNewDropIndex++;
        });

        if (!isValid) {
          this.competitors.push(itemDrop);
          const lane: Draggable = {
            id: null,
            type: 'placeholder',
            tableName: tableName,
            competitor_id: null,
            competitor: null,
            wod_id: null,
            children: null,
            selected: false,
            object: null,
            isOpen: false
          };
          const indexObject = heat.heat_competitors.indexOf(itemDrop);
          heat.heat_competitors[indexObject] = lane;
          // this.reloadLanes(heat);
          this.sortCompetitors();

          this.toastr.show(
            this.translate.HEATS_GENERATOR.TOASTR.COUNT_LANES_EMPTY.MESSAGE,
            this.translate.HEATS_GENERATOR.TOASTR.COUNT_LANES_EMPTY.TITLE,
            null,
            'error',
          );
          return;
        }

        let newDropIndex = event.dropIndex + 1;
        selecteds.forEach((item, index) => {
          const itemPlaceholderSelected = this.getItemPlaceholder(heat, newDropIndex);
          item.tableName = tableName;
          heat.heat_competitors[heat.heat_competitors.indexOf(itemPlaceholderSelected)] = item;
          this.competitors.splice(this.competitors.indexOf(item), 1);
          newDropIndex++;
        });
      }

      // Validate is all lane is full
      if ((items && items.length >= heat.total_lanes) || (items && items.length + selecteds.length >= heat.total_lanes)) {
        this.targets.splice(this.targets.indexOf(tableName), 1);
      }

      // @ts-ignore
      // const objectDrop = heat.heat_competitors.find((param) => param.competitor && param.competitor.id === event.value.competitor.id);
      // objectDrop.tableName = tableName
      itemDrop.tableName = tableName;
      // heat.heat_competitors.forEach(item => {
      //   item.tableName = tableName;
      // })
      // this.reloadLanes(heat.heat_competitors);
      this.sortCompetitors();
    }

    if (event.value.competitor && event.value.tableName !== tableName) {
      // @ts-ignore
      const objectDrop = heat.heat_competitors.find((param) => param.competitor && param.competitor.id === event.value.competitor.id);
      objectDrop.tableName = tableName;
    }

    if (indexHeatOld >= 0) {
      const removeDuration = this.heats[indexHeatOld].heat_competitors.filter(heatCompetitor => heatCompetitor.type !== 'placeholder');
      if (removeDuration.length === 0) {
        this.heats[indexHeatOld].duration = null;
      }
    }

    heat.isUpdated = true;
    if (!heat.is_edit_name) {
      if (heat.name.search('BATERIA') === 0 || heat.name === '') {
        heat.name = `${event.value.competition.name} - ${event.value.wod.name}`;
      } else {
        if (heat.name.search(`${event.value.competition.name} - ${event.value.wod.name}`) === -1) {
          heat.name = `${heat.name} / ${event.value.competition.name} - ${event.value.wod.name}`;
        }
      }
      heat.isEditName.form = new FormControl(heat.name, [
        Validators.required
      ]);
    }
    this.reloadLanes(heat);
  }

  public editTotalLanes(heat: Heat): void {
    if (heat.isEditLanes.form.invalid) {
      return;
    }
    const existCompetitor = heat.heat_competitors.filter(element => element.type !== 'placeholder').length > 0;
    heat.isEditLanes.edit = !heat.isEditLanes.edit;

    const oldTotalLanes = heat.total_lanes;
    heat.total_lanes = heat.isEditLanes.form.value;
    const diff = heat.total_lanes - oldTotalLanes;
    if (diff === 0) {
      return;
    }
    if (diff > 0) {
      for (let index = 0; index < diff; index++) {
        heat.heat_competitors.push({
          id: null,
          type: 'placeholder',
          tableName: this.getNameOfTable(heat),
          lane: oldTotalLanes + index + 1,
          competitor_id: null,
          competitor: null,
          wod_id: null,
          children: null,
          selected: false,
          object: null
        });
      }
    }
    if (diff < 0) {
      for (let index = (oldTotalLanes - (diff * -1)); index < oldTotalLanes; index++) {
        if (heat.heat_competitors[index].competition_id === this.filterFormGroup.get('competitions').value &&
          heat.heat_competitors[index].wod_id === this.filterFormGroup.get('wods').value
        ) {
          heat.heat_competitors[index].tableName = 'competitors';
          this.competitors.push(heat.heat_competitors[index]);
        }
      }
      heat.heat_competitors.splice(oldTotalLanes - (diff * -1), (diff * -1));
    }
    const data = heat.heat_competitors.filter(element => element.type !== 'placeholder');
    if (data.length === 0 && existCompetitor) {
      heat.duration = null;
    }
    heat.isUpdated = true;
    this.heatsOldEvent.emit('');
    this.heatsOld = '';
    this.sortCompetitors();
    // @ts-ignore
    if (!this.targets.find(target => target === this.getNameOfTable(heat))) {
      this.targets.push(this.getNameOfTable(heat));
    }
    if (data.length === heat.heat_competitors.length) {
      this.targets.splice(this.targets.indexOf(this.getNameOfTable(heat)), 1);
    }

    this.calcHeatsEvent.emit({
      screen: 'heat'
    });
  }

  public populateHeatsOfApi(heats: Heat[]): void {
    this.heats = [];
    this.targets = [];
    this.targetsHeats = [];
    heats.forEach((heat, index) => {
      heat.idRandom = `${new Date().getMilliseconds().toString()}${heat.number}${Math.random()}`;
      if (index === 0) {
        this.formHeat.setValue({
          ...this.formHeat.value,
          startTime: heat.start_time ? moment(heat.start_time, 'H:mm:ss').format('HH:mm') : null,
          transition: heat.transition
        });
      }
      heat.number = heat.number === null ? (index + 1) : heat.number;
      heat.isUpdated = false;
      if (heat.start_time && heat.end_time) {
        heat.duration = moment(heat.end_time, 'HH:mm').diff(moment(heat.start_time, 'HH:mm'), 'seconds', true);
        if (heat.duration < 0) {
          heat.duration = 24 * 60 * 60 + heat.duration;
        }
        if (heat.duration === 0) {
          heat.duration = null;
        }
      }
      heat.isEditLanes = {
        edit: false,
        form: new FormControl(heat.total_lanes, [
          Validators.required,
          Validators.min(1)
        ])
      };
      heat.isEditName = {
        edit: false,
        form: new FormControl(heat.name, [
          Validators.required
        ])
      };
      const tableName = this.getNameOfTable(heat);
      const heatCompetitors: Draggable[] = [];

      for (let i = 0; i < heat.total_lanes; i++) {
        // @ts-ignore
        const item = heat.heat_competitors.find(heatCompetitor => heatCompetitor.lane === i + 1);
        const draggable = new Draggable();
        if (item) {
          heat.duration = item.wod.time_cap;
          draggable.id = item.id;
          draggable.competitor_id = item.competitor.id;
          draggable.competitor = item.competitor;
          draggable.teamName = item.competitor.team.name;
          draggable.type = 'item';
          draggable.tableName = tableName;
          draggable.lane = item.lane;
          draggable.selected = false;

          // @ts-ignore
          draggable.wod = item.wod;
          draggable.wod_id = item.wod.id;

          if (item.competitor.competition instanceof Array) {
            draggable.competition = item.competitor.competition[0];
            draggable.competition_id = item.competitor.competition[0].id;
          } else {
            draggable.competition = item.competitor.competition;
            draggable.competition_id = item.competitor.competition.id;
          }

        } else {
          draggable.id = null;
          draggable.type = 'placeholder';
          draggable.tableName = tableName;
          draggable.lane = i + 1;
          draggable.competitor_id = null;
          draggable.competitor = null;
          draggable.wod_id = null;
          draggable.children = null;
          draggable.selected = false;
          draggable.object = null;
        }
        heatCompetitors.push(draggable);
      }

      heat.heat_competitors = heatCompetitors;
      if (this.getLanesNotEmpty(heat) < heat.total_lanes) {
        this.targets.push(tableName);
      }
      this.targetsHeats.push(tableName);
      this.heats.push(heat);
    });
    this.updateHeatEvent.emit(this.heats);
    this.changeDetectorRefs.detectChanges();
  }

  public clickType({index, type}: { index: number, type: number | string }): void {
    if (type === 'add_heat') {
      this.generateHeat();
      return;
    }
    if (type === 'save_heat') {
      this.saveHeatEvent.emit();
      return;
    }
    if (type === 'publish_schedules') {
      this.publishedUnpublishedAllSchedule();
      return;
    }
    if (type === 'unpublish_schedules') {
      this.publishedUnpublishedAllSchedule(true);
      return;
    }
    if (type === 'publish_competitors') {
      this.publishedUnpublishedAllCompetitor();
      return;
    }
    if (type === 'unpublish_competitors') {
      this.publishedUnpublishedAllCompetitor(true);
      return;
    }
  }

  public updateTransitionAndStartTime(): void {
    if (this.formHeat.invalid) {
      return;
    }
    this.formHeat.setValue({
      ...this.formHeat.value,
      isLoading: true
    });
    this.generateHeatsOld(this.heats);
    this.heats.forEach((heat, index) => {
      if (index === 0) {
        this.heats[index].start_time = this.formHeat.value.startTime;
      }
      this.heats[index].transition = this.formHeat.value.transition;
    });
    this.calcHeatsEvent.emit({
      screen: 'heat'
    });
    this.formHeat.setValue({
      ...this.formHeat.value,
      isLoading: false
    });
    this.changeDetectorRefs.detectChanges();
  }

  // Heats Generator
  public generateHeat(): void {
    if (this.btnMatFabConfig[0]) {
      this.btnMatFabConfig[0].isLoading = false;
    }
    if (this.formHeat.invalid) {
      this.toastr.show(
        this.translate.HEATS_GENERATOR.TOASTR.ADD_CLASS_ERROR.DESCRIPTION,
        this.translate.HEATS_GENERATOR.TOASTR.ADD_CLASS_ERROR.TITLE,
        null,
        'error'
      );
      return;
    }
    this.generateHeatsOld(this.heats);
    if (this.heats) {
      if (this.heats.length === 0) {
        this.resetAllHeats(10);
        return;
      }
    }
    this.addNewHeats();
    return;
  }

  public populateCompetitions(): void {
    this.filterFormGroup.get('competitions').setValue(this.competitions[0].id);
    this.competitionIdSelected = this.competitions[0].id;
    this.populateSubDivision(this.competitionIdSelected);
    this.populateWodWith(this.competitionIdSelected);
  }

  public actionCollapsableHeat(heat: Heat): void {
    heat.isOpen = !heat.isOpen;
  }

  public getNameOfTable(heat: Heat): string {
    return `heat-${this.championship.id}-${heat.number}`.toLowerCase();
  }

  public getLanesNotEmpty(heat: Heat): number {
    const lanes = heat.heat_competitors.filter(item => {
      return item.type !== 'placeholder';
    });
    return lanes ? lanes.length : 0;
  }

  deleteLane(item: Draggable, heat: Heat): void {
    heat.isUpdated = true;
    const heatCompetitorIndex = heat.heat_competitors.indexOf(item);
    if (item.competition_id === this.filterFormGroup.get('competitions').value &&
      item.wod_id === this.filterFormGroup.get('wods').value &&
      item.competitor.checkin_status !== CompetitorCheckin.WITHDRAW
    ) {
      item.tableName = 'competitors';
      this.competitors.push(item);
    }

    const lane = {
      id: null,
      type: 'placeholder',
      tableName: this.getNameOfTable(heat),
      lane: item.lane,
      competitor_id: null,
      competitor: null,
      wod_id: null,
      children: null,
      selected: false,
      object: null
    };
    heat.heat_competitors[heatCompetitorIndex] = lane;
    heat.isUpdated = true;
    const removeDuration = heat.heat_competitors.filter(heatCompetitor => heatCompetitor.type !== 'placeholder');
    if (removeDuration.length === 0) {
      heat.duration = null;
    }
    const resultFilter = heat.heat_competitors
      .find((heatCompetitorFilter: Draggable) =>
        heatCompetitorFilter.wod &&
        heatCompetitorFilter.competition &&
        heatCompetitorFilter.competition.name === item.competition.name &&
        heatCompetitorFilter.wod.name === item.wod.name);

    if (!heat.is_edit_name && !resultFilter) {
      if (heat.name.search(`${item.competition.name} - ${item.wod.name} / `) > -1) {
        heat.name = heat.name.replace(`${item.competition.name} - ${item.wod.name} / `, '');
      }
      if (heat.name.search(` / ${item.competition.name} - ${item.wod.name}`) > -1) {
        heat.name = heat.name.replace(` / ${item.competition.name} - ${item.wod.name}`, '');
      }
      if (heat.name.search(`${item.competition.name} - ${item.wod.name}`) > -1) {
        heat.name = heat.name.replace(`${item.competition.name} - ${item.wod.name}`, '');
      }
      if (heat.name === '') {
        heat.name = `BATERIA ${heat.number}`;
      }
      heat.isEditName.form = new FormControl(heat.name, [
        Validators.required
      ]);
    }

    this.targets.push(this.getNameOfTable(heat));
    this.calcHeatsEvent.emit({
      screen: 'heat'
    });
    this.sortCompetitors();
  }

  public setSortCompetitors(column: string): void {
    let columnSort: string = this.columnSortCompetitor;
    let orderSort: string = this.orderSortCompetitor;
    if (column === this.columnSortCompetitor) {
      if (this.orderSortCompetitor === 'ASC') {
        orderSort = 'DESC';
      } else {
        orderSort = 'ASC';
      }
    } else {
      columnSort = column;
      orderSort = 'ASC';
    }
    this.sortCompetitors(columnSort, orderSort);
  }

  clearLanes(heat: Heat): void {
    const tableName = this.getNameOfTable(heat);

    heat.heat_competitors.forEach(item => {
      if (item.type === 'item' &&
        item.competition_id === this.filterFormGroup.get('competitions').value &&
        item.wod_id === this.filterFormGroup.get('wods').value
      ) {
        item.teamName = 'competitors';
        this.competitors.push(item);
      }
    });
    heat.heat_competitors = [];
    heat.isUpdated = true;

    for (let l = 0; l < heat.total_lanes; l++) {
      const laneIndex = l + 1;
      const lane = {
        id: null,
        type: 'placeholder',
        tableName: tableName,
        lane: laneIndex,
        competitor_id: null,
        competitor: null,
        wod_id: null,
        children: null,
        selected: false,
        object: null
      };
      heat.heat_competitors.push(lane);
    }
    heat.duration = null;

    if (this.targets.indexOf(tableName) === -1) {
      this.targets.push(tableName);
    }

    if (this.targetsHeats.indexOf(tableName) === -1) {
      this.targetsHeats.push(tableName);
    }

    if (!heat.is_edit_name) {
      heat.name = `BATERIA ${heat.number}`;
      heat.isEditName.form = new FormControl(heat.name, [
        Validators.required
      ]);
    }

    this.calcHeatsEvent.emit({
      screen: 'heat'
    });
    this.sortCompetitors();
  }

  public deleteHeat(heat: Heat): void {
    this.dialog.openDialogs.pop();
    const dialogRef = this.dialog.open(CrossXDialogComponent, {
      width: '440px',
      closeOnNavigation: true
    });

    dialogRef.componentInstance.type = 'warning';
    dialogRef.componentInstance.titleDialog = this.translate.HEATS_GENERATOR.DIALOG.DELETE_HEAT.TITLE;
    dialogRef.componentInstance.dialogContent = this.translate.HEATS_GENERATOR.DIALOG.DELETE_HEAT.MESSAGE;
    dialogRef.componentInstance.cancelButton = this.translate.HEATS_GENERATOR.DIALOG.CANCEL.toUpperCase();
    dialogRef.componentInstance.actionButton = this.translate.HEATS_GENERATOR.DIALOG.CONTINUE.toUpperCase();
    dialogRef.componentInstance.object = event;
    dialogRef.componentInstance.dialogConfirm = true;
    dialogRef.componentInstance.confirmActionRequest = true;
    this.subscriptions.add(dialogRef.componentInstance.dialogEvent.subscribe(result => {
      dialogRef.componentInstance.confirmLoading = true;
      this.deleteHeatAPI(heat, dialogRef);
    }));
  }

  public getSelectedsCompetitors(): string {
    const selecteds = this.competitors.filter(item => {
      return item.selected === true;
    });
    const count = selecteds ? selecteds.length : 0;

    if (count === 0) {
      return '';
    }
    return `Selecionados <strong>${count}</strong> de `;
  }

  public orderLanes(heat, typeOrder: string): void {
    if (typeOrder === 'bast-rank-center') {
      HeatLanesOrder.byLanesToBestRankInCenter(heat, this.championship.enable_sub_division);
      this.changeDetectorRefs.detectChanges();
    } else if (typeOrder === 'competitor-in-middle') {
      HeatLanesOrder.byCompetitorInMiddle(heat);
      this.changeDetectorRefs.detectChanges();
    }
  }

  onScrollFilter(event): void {
    this.stickyCountCompetitor = event.target.scrollTop >= 247;
  }

  trackByRow(index, item) {
    return index;
  }

  public selectedCompetitor(event: any): void {
    const value = parseInt(event.target.value, 10);

    if (isNaN(value) || value < 0) {
        console.error('Invalid input value');
        return;
    }

    if (value === 0) {
        this.competitors.forEach((item) => {
            item.selected = false;
        });
        return;
    }

    const selectedCount = this.competitors.filter(item => item.selected).length;
    let quantity = value - selectedCount;

    if (quantity > 0) {
        this.competitors.forEach((item) => {
            if (quantity === 0 || item.selected) {
                return;
            }
            item.selected = true;
            quantity--;
        });
    } else if (quantity < 0) {
        for (let i = this.competitors.length - 1; i >= 0 && quantity < 0; i--) {
            if (this.competitors[i].selected) {
                this.competitors[i].selected = false;
                quantity++;
            }
        }
    }
}

  public detectChanges(): void {
    this.changeDetectorRefs.detectChanges();
  }

  public clearSelectedCompetitor(): void {
    this.competitors.forEach((item) => {
      item.selected = false;
    });
  
    const selectCompetitorElement = document.querySelector('#select-competitor') as HTMLInputElement;

    if (selectCompetitorElement) selectCompetitorElement.value = '';
  }

  private generateHeatsOld(heats: Heat[]): void {
    this.heatsOldEvent.emit(heats);
  }

  private validateTimeCap(event: any, heat: Heat): boolean {
    if (event.value.wod.time_cap !== heat.duration) {
      this.toastr.show(
        this.translate.HEATS_GENERATOR.TOASTR.TIME_CAP.MESSAGE,
        this.translate.HEATS_GENERATOR.TOASTR.TIME_CAP.TITLE,
        null,
        'error',
      );
    }
    return event.value.wod.time_cap === heat.duration;
  }

  private validateWodDate(event: any, heat: Heat, isCompetitor: boolean): boolean {
    if (!isCompetitor) {
      return true;
    }
    if (
      heat.heat_competitors.filter(heatCompetitorFilter => 
        heatCompetitorFilter.type !== 'placeholder' && 
        event.value.competitor_id !== heatCompetitorFilter.competitor_id).length > 0
    ) {
      return true;
    }
    let stop = false;
    const dateNow = this.dueDate;
    let dateNext = this.dueDate;
    const total = this.heats.length;
    // @ts-ignore
    const index = this.heats.findIndex((heatFilter: Heat) => heatFilter.number === heat.number);
    this.heats.forEach((heatData: Heat, indexData) => {
      if (stop) {
        return;
      }
      if (indexData >= index && total > indexData + 1) {
        stop = !!this.heats[indexData + 1].enable_custom_start_time;
        if (stop) {
          return;
        }
        dateNext = moment(`${heatData.due_date} ${heatData.end_time}`)
          .add(heatData.transition + event.value.wod.time_cap / 60, 'minutes')
          .format('YYYY-MM-DD');
      }
    });
    if (dateNow !== dateNext) {
      this.toastr.show(
        this.translate.HEAT_EDIT.TOASTR.ERROR_DATE.DESCRIPTION,
        this.translate.HEAT_EDIT.TOASTR.ERROR_DATE.TITLE,
        null,
        'error'
      );
      heat.duration = null;
      return false;
    }
    return true;
  }

  private removeCompetitorHeat(event: any, heat: Heat): void {
    if (this.heatsOld) {
      const data: Heat[] = JSON.parse(this.heatsOld);
      data.forEach((heatData, index) => {
        this.heats[index].heat_competitors = heatData.heat_competitors;
      });
      this.heatsOldEvent.emit('');
      this.heatsOld = '';
      this.sortCompetitors();
      return;
    }
    // @ts-ignore
    const itemDrop = heat.heat_competitors.find((item: any) => item.type === 'item' && item === event.value);
    this.competitors.push(itemDrop);
    heat.heat_competitors.splice(heat.heat_competitors.indexOf(itemDrop), 1);
    this.sortCompetitors();
    return;
  }

  private populateCompetitorWithWod(): void {
    if (this.filterFormGroup.get('wods').value) {
      this.isFirstPopulatedCompetitors = true;
      this.competitors = [];
      this.competitorsWithCompetitionActive.forEach((competitor: Competitor, index) => {
        if (!this.competitorInHeatWithWod(competitor, this.filterFormGroup.get('wods').value)) {
          const draggable = new Draggable();
          draggable.id = null;
          draggable.competitor_id = competitor.id;
          draggable.competitor = competitor;
          draggable.teamName = competitor.team.name;
          draggable.type = 'item';
          draggable.tableName = 'competitors';
          draggable.lane = null;
          draggable.selected = false;

          // @ts-ignore
          draggable.wod = this.wodsList.find((wod: Wod) => wod.id === this.filterFormGroup.get('wods').value);
          draggable.wod_id = this.filterFormGroup.get('wods').value;

          // @ts-ignore
          draggable.competition = competitor.competition;
          draggable.competition_id = competitor.competition.id;
          draggable.competitor.competition = competitor.competition;

          this.competitors.push(draggable);
        }
      });

      this.sortCompetitors('rank', 'ASC');
    }

  }

  private registerOnGetHeats(): void {
    const subscription = this.heatService
      .fetchHeatsObservable()
      .subscribe((heats: Heat[]) => {
        this.isLoadedHeats = true;
        this.populateHeatsOfApi(heats);
        this.populateCompetitorWithWod();
      });
    this.subscriptions.add(subscription);
  }

  private registerOnSaveHeats(): void {
    const subscription = this.heatService
      .heatsObservable()
      .subscribe((heats: Heat[]) => {
          this.isLoadingSaveHeats = false;
          if (this.btnMatFabConfig[1]) {
            this.btnMatFabConfig[1].isLoading = false;
          }
          if (this.btnMatFabConfig[0]) {
            this.btnMatFabConfig[0].isLoading = false;
          }
          if (this.dialogActive) {
            this.dialogActive.componentInstance.confirmLoading = false;
            this.dialogActive.close();
          }
          this.dialogActive = null;
          this.populateHeatsOfApi(heats);
          this.idsDelete = [];

          this.toastr.show(
            this.translate.HEATS_GENERATOR.TOASTR.SAVE.SUCCESS.MESSAGE,
            this.translate.HEATS_GENERATOR.TOASTR.SAVE.SUCCESS.TITLE,
            null,
            'success'
          );
        },
        err => {
          this.isLoadingSaveHeats = false;
          if (this.btnMatFabConfig[1]) {
            this.btnMatFabConfig[1].isLoading = false;
          }
          if (this.btnMatFabConfig[0]) {
            this.btnMatFabConfig[0].isLoading = false;
          }
          const errors: Errors = new Errors().deserialize((err.error as any));
          this.changeDetectorRefs.detectChanges();

          this.toastr.show(
            errors.getFullMessages(),
            this.translate.HEATS_GENERATOR.TOASTR.SAVE.ERROR.TITLE,
            null,
            'error'
          );
        }
      );
    this.subscriptions.add(subscription);
  }

  private registerOnSaveHeatsSchedule(): void {
    const subscription = this.heatService
      .heatsUpdateScheduleObservable()
      .subscribe((heats: Heat[]) => {
          if (heats.length > 0) {
            this.populateHeatsOfApi(heats);
          }
        },
        err => {
          const errors: Errors = new Errors().deserialize((err.error as any));

          this.toastr.show(
            errors.getFullMessages(),
            this.translate.HEATS_GENERATOR.TOASTR.DELETE_HEAT.ERROR.TITLE,
            null,
            'error'
          );
        }
      );
    this.subscriptions.add(subscription);
  }

  private registerOnGetCompetitorsWithCompetition() {
    const subscription = this.competitorService
      .competitorsWithCompetitionsObservable()
      .subscribe((competitors: Competitor[]) => {
        this.competitorsWithCompetitionActive = competitors;
        if (this.filterFormGroup.get('wods').value && this.isLoadedHeats) {
          this.populateCompetitorWithWod();
        }
      });
    this.subscriptions.add(subscription);
  }

  private competitorInHeatWithWod(competitor: Competitor, wodId: any) {
    let isExist = false;

    this.heats.forEach(heat => {
      heat.heat_competitors.forEach(item => {
        if (item.competitor_id === competitor.id && item.wod_id === wodId) {
          isExist = true;
        }
      });
    });

    return isExist;
  }

  private fetchCompetitors(): void {
    const competitionId = this.filterFormGroup.get('competitions').value;
    this.competitors = [];
    if (this.filterFormGroup.get('sub_divisions')) {
      const subDivisionId = this.filterFormGroup.get('sub_divisions').value;
      this.competitorService.getCompetitorsWith(this.championship.id, competitionId, subDivisionId);
      return;
    }
    this.competitorService.getCompetitorsWith(this.championship.id, competitionId);
  }

  private registerOnChangeCompetitionFilter(): void {
    const subscription = this.filterFormGroup
      .get('competitions')
      .valueChanges
      .subscribe((competitionId) => {
        this.populateWodWith(competitionId);
        this.populateSubDivision(competitionId);
        this.fetchCompetitors();
      });
    this.subscriptions.add(subscription);
  }

  private registerOnChangeSubDivisionFilter(): void {
    const subscription = this.filterFormGroup
      .get('sub_divisions')
      .valueChanges
      .subscribe((subDivisionId) => {
        this.fetchCompetitors();
      });
    this.subscriptions.add(subscription);
  }

  private populateSubDivision(competitionId: number): void {
    // @ts-ignore
    const competitionEnable = this.competitions.find((competition: Competition) => competition.id === competitionId);
    this.subDivisions = null;
    if (this.filterFormGroup.get('sub_divisions')) {
      this.filterFormGroup.removeControl('sub_divisions');
    }
    if (competitionEnable.sub_divisions) {
      this.subDivisions = competitionEnable.sub_divisions;
      this.filterFormGroup.addControl('sub_divisions', this.formBuilder.control(this.subDivisions[0].id, Validators.required));
      this.registerOnChangeSubDivisionFilter();
    }
  }

  private registerOnChangeNameFilter(): void {
    const subscription = this.filterFormGroup
      .get('search')
      .valueChanges
      .subscribe((search) => {
        this.competitorsFilter.teamName = search;
      });
    this.subscriptions.add(subscription);
  }

  private registerOnChangeWodsFilter(): void {
    const subscription = this.filterFormGroup
      .get('wods')
      .valueChanges
      .subscribe(wodId => {
        if (this.competitorsWithCompetitionActive.length > 0) {
          this.populateCompetitorWithWod();
        }
      });
    this.subscriptions.add(subscription);
  }

  private populateWodWith(competitionId: number): void {
    this.wods = [];
    // @ts-ignore
    const competitionSelected = this.competitions.find((competition: Competition) => competition.id === competitionId);
    this.wods = competitionSelected.wods;
    this.filterFormGroup.get('wods').setValue(this.wods[0].id);
  }

  private createForm(): void {
    this.formHeat = this.formBuilder.group({
      transition: [null, [Validators.required, Validators.min(0)]],
      startTime: [null, Validators.required],
      isLoading: [false]
    });

    this.heatsGeneratorGroup = this.formBuilder.group({
      numberHeats: ['', Validators.required],
      numberLanes: ['', Validators.required],
    });

    this.filterFormGroup = this.formBuilder.group({
      competitions: ['', Validators.required],
      wods: ['', Validators.required],
      search: '',
    });

    this.matchers = {
      numberHeats: new FormErrorStateMatcher(),
      numberLanes: new FormErrorStateMatcher(),
    };

    this.matchersFilterGroup = {
      competitions: new FormErrorStateMatcher(),
      wods: new FormErrorStateMatcher()
    };

    if (this.championship && this.dueDate) {
      this.heatService.fetchHeatsWithChampionshipAndType(this.championship.id, this.dueDate);
    }
  }

  private addNewHeats(): void {
    const numberLanes = this.heats[this.heats.length - 1].total_lanes;
    const lastNumberHeat: number = this.heats[this.heats.length - 1].number;
    const number = lastNumberHeat + 1;
    const heat: Heat = {
      id: null,
      name: `${this.translate.HEAT.toUpperCase()} ${number}`,
      number: number,
      championship_id: this.championship.id,
      due_date: this.dueDate,
      duration: null,
      end_time: null,
      start_time: moment(this.heats[this.heats.length - 1].end_time).add('minutes', this.heats[this.heats.length - 1].transition).format('HH:mm'),
      total_lanes: numberLanes,
      transition: this.formHeat.value.transition,
      type: this.dueDate,
      isOpen: false,
      isUpdated: true,
      heat_competitors: [],
      isEditLanes: {
        edit: false,
        form: new FormControl(numberLanes, [
          Validators.required,
          Validators.min(1)
        ])
      },
      idRandom: `${new Date().getMilliseconds().toString()}${number}${Math.random()}`,
      is_edit_name: false,
      enable_custom_start_time: false,
    };
    heat.isEditName = {
      edit: false,
      form: new FormControl(heat.name, [
        Validators.required
      ])
    };

    const tableName = this.getNameOfTable(heat);

    for (let l = 0; l < numberLanes; l++) {
      const laneIndex = l + 1;
      const lane = {
        type: 'placeholder',
        tableName: tableName,
        lane: laneIndex,
        competitor_id: null,
        competitor: null,
        wod_id: null,
        children: null,
        selected: false,
        object: null
      };
      heat.heat_competitors.push(lane);
    }

    this.heats.push(heat);
    this.targets.push(tableName);
    this.targetsHeats.push(tableName);
    this.calcHeatsEvent.emit({
      screen: 'heat'
    });
  }

  private resetAllHeats(numberLanes: number): void {
    this.heats = [];
    const number = 1;
    const heat: Heat = {
      id: null,
      name: `${this.translate.HEAT.toUpperCase()} ${number}`,
      number: number,
      championship_id: this.championship.id,
      due_date: this.dueDate,
      duration: null,
      end_time: null,
      start_time: this.formHeat.value.startTime,
      total_lanes: numberLanes,
      transition: this.formHeat.value.transition,
      type: this.dueDate,
      isOpen: false,
      isUpdated: true,
      heat_competitors: [],
      idRandom: `${new Date().getMilliseconds().toString()}${number}${Math.random()}`,
      is_edit_name: false,
      enable_custom_start_time: false,
      isEditLanes: {
        edit: false,
        form: new FormControl(numberLanes, [
          Validators.required,
          Validators.min(1)
        ])
      }
    };
    heat.end_time = moment(this.formHeat.value.startTime, 'HH:mm').add(heat.transition).format('HH:mm');
    heat.isEditName = {
      edit: false,
      form: new FormControl(heat.name, [
        Validators.required
      ])
    };

    const tableName = this.getNameOfTable(heat);

    for (let l = 0; l < numberLanes; l++) {
      const laneIndex = l + 1;
      const lane = {
        type: 'placeholder',
        tableName: tableName,
        lane: laneIndex,
        competitor_id: null,
        competitor: null,
        wod_id: null,
        children: null,
        selected: false,
        object: null
      };
      heat.heat_competitors.push(lane);
    }

    this.heats.push(heat);
    this.targets.push(tableName);
    this.targetsHeats.push(tableName);
    this.calcHeatsEvent.emit({
      screen: 'heat'
    });
  }

  private registerOnTranslateHeatCompetitors(): void {
    this.translationLoader.loadTranslations(english, portugues);
    const subscription = this.translateService
      .get('HEAT_COMPETITORS')
      .subscribe((response: Object) => {
        this.translate = response;
      });

    this.subscriptions.add(subscription);
  }

  // private registerBtnMatFab(): void {
  //   this.btnMatFabConfig = [
  //     {
  //       icon: 'add',
  //       color: 'primary',
  //       isLoading: false,
  //       type: 'add_heat'
  //     },
  //     {
  //       icon: 'save',
  //       color: 'secondary',
  //       isLoading: false,
  //       type: 'save_heat'
  //     }
  //   ];
  //   // if (this.heats.length === 0) {
  //   //   this.btnMatFabConfig = [
  //   //     {
  //   //       icon: 'add',
  //   //       color: 'primary',
  //   //       isLoading: false,
  //   //       listIcons: [
  //   //         {
  //   //           icon: 'add',
  //   //           color: 'secondary',
  //   //           type: 'add_heat',
  //   //           text: this.translate.HEATS_GENERATOR.HEATS_ITEMS.ADD_HEAT,
  //   //           textColor: 'normal'
  //   //         }
  //   //       ]
  //   //     },
  //   //     {
  //   //       icon: 'save',
  //   //       color: 'secondary',
  //   //       isLoading: false,
  //   //       type: 'save_heat'
  //   //     }
  //   //   ];
  //   //   return;
  //   // }
  //
  //   // this.btnMatFabConfig = [
  //   //   {
  //   //     icon: 'add',
  //   //     color: 'primary',
  //   //     isLoading: false,
  //   //     listIcons: [
  //   //       {
  //   //         icon: 'publish',
  //   //         color: 'secondary',
  //   //         type: 'publish_schedules',
  //   //         text: this.translate.HEATS_GENERATOR.HEATS_ITEMS.PUBLISH_SCHEDULE_ALL,
  //   //         textColor: 'normal'
  //   //       },
  //   //       {
  //   //         icon: 'publish',
  //   //         color: 'secondary',
  //   //         type: 'publish_competitors',
  //   //         text: this.translate.HEATS_GENERATOR.HEATS_ITEMS.PUBLISH_COMPETITOR_ALL,
  //   //         textColor: 'normal'
  //   //       },
  //   //       {
  //   //         icon: 'add',
  //   //         color: 'secondary',
  //   //         type: 'add_heat',
  //   //         text: this.translate.HEATS_GENERATOR.HEATS_ITEMS.ADD_HEAT,
  //   //         textColor: 'normal'
  //   //       },
  //   //       {
  //   //         icon: 'undo',
  //   //         color: 'secondary',
  //   //         type: 'unpublish_schedules',
  //   //         text: this.translate.HEATS_GENERATOR.HEATS_ITEMS.UNPUBLISH_SCHEDULE_ALL,
  //   //         textColor: 'danger'
  //   //       },
  //   //       {
  //   //         icon: 'undo',
  //   //         color: 'secondary',
  //   //         type: 'unpublish_competitors',
  //   //         text: this.translate.HEATS_GENERATOR.HEATS_ITEMS.UNPUBLISH_COMPETITOR_ALL,
  //   //         textColor: 'danger'
  //   //       }
  //   //     ]
  //   //   },
  //   //   {
  //   //     icon: 'save',
  //   //     color: 'secondary',
  //   //     isLoading: false,
  //   //     type: 'save_heat'
  //   //   }
  //   // ];
  // }

  private getEventDetail(): void {
    this.subscriptions.add(this.activatedRoute.params.subscribe(
      params => {
        this.championship = this.eventGuard.getChampionshipPerId(params.championship_id);
        this.wodService.getWodsWithChampionship(params.championship_id);
      }
    ));
  }

  private registerWods(): void {
    this.subscriptions.add(
      this.wodService
        .wodWithCompetitionsObservable()
        .subscribe((results) => {
          this.wodsList = [];
          results.forEach((result) => {
            result.wods.forEach((wod) => {
              this.wodsList.push(wod);
            });
          });
        })
    );
  }

  private reloadLanes(heat: Heat): void {
    const heatCompetitors: Draggable[] = [...heat.heat_competitors];
    heat.heat_competitors.splice(0);

    heatCompetitors.forEach((value, index) => {
      value.lane = index + 1;
      value.selected = false;
    });

    heatCompetitors.sort((a, b) => {
      if (a.lane > b.lane) {
        return 1;
      } else if (a.lane < b.lane) {
        return -1;
      } else {
        return 0;
      }
    });

    heatCompetitors.forEach(item => {
      heat.heat_competitors.push(item);
    });

    this.heats.forEach((heatData, indexHeatData) => {
      for (let index = 0; index < this.heats[indexHeatData].total_lanes; index++) {
        // @ts-ignore
        const data = this.heats[indexHeatData].heat_competitors.find((heatCompetitor: any) => heatCompetitor.lane === index + 1);
        if (!data) {
          this.heats[indexHeatData].heat_competitors.push({
            id: null,
            type: 'placeholder',
            tableName: this.getNameOfTable(this.heats[indexHeatData]),
            lane: index + 1,
            competitor_id: null,
            competitor: null,
            wod_id: null,
            children: null,
            selected: false,
            object: null
          });
        }
      }
      // @ts-ignore
      const existCompetitor = heatData.heat_competitors.find((heatCompetitor: any) => heatCompetitor.type !== 'placeholder');
      if (!existCompetitor && !this.heats[indexHeatData].duration) {
        this.heats[indexHeatData].duration = null;
      }
      this.heats[indexHeatData]
        .heat_competitors
        .sort((a, b) => {
          if (a.lane > b.lane) {
            return 1;
          } else if (a.lane < b.lane) {
            return -1;
          } else {
            return 0;
          }
        });
    });

    this.calcHeatsEvent.emit({
      screen: 'heat'
    });
  }

  private getItemPlaceholder(heat: Heat, dropIndex: any): Draggable {
    // @ts-ignore
    let itemPlaceholder = heat.heat_competitors.find((param) => param.type === 'placeholder' && param.lane === (dropIndex + 1));
    if (itemPlaceholder === undefined) {
      // @ts-ignore
      itemPlaceholder = heat.heat_competitors.find((param) => param.type === 'placeholder' && param.lane === dropIndex - 1);
    }
    if (itemPlaceholder === undefined) {
      // @ts-ignore
      itemPlaceholder = heat.heat_competitors.find((param) => param.type === 'placeholder' && param.lane === dropIndex);
    }
    if (itemPlaceholder === undefined) {
      // @ts-ignore
      itemPlaceholder = heat.heat_competitors.find((param) => param.type === 'placeholder');
    }

    return itemPlaceholder;
  }

  private sortCompetitors(column: string = null, order: string = null) {
    const columnSort = column ? column : this.columnSortCompetitor;
    this.columnSortCompetitor = columnSort;

    const orderSort = order ? order : this.orderSortCompetitor;
    this.orderSortCompetitor = orderSort;

    if (columnSort === 'number') {
      this.competitors = this.competitors.sort((a, b) => {
        if (columnSort === 'number') {
          if (a.competitor.number < b.competitor.number) {
            return orderSort === 'ASC' ? -1 : 1;
          }
          if (a.competitor.number > b.competitor.number) {
            return orderSort === 'ASC' ? 1 : -1;
          }
        }
      });
    } else if (columnSort === 'name') {
      this.competitors = this.competitors.sort((a, b) => {
        if (columnSort === 'name') {
          if (a.competitor.team.name < b.competitor.team.name) {
            return orderSort === 'ASC' ? -1 : 1;
          }
          if (a.competitor.team.name > b.competitor.team.name) {
            return orderSort === 'ASC' ? 1 : -1;
          }
        }
      });
    } else if (columnSort === 'rank') {
      const listNotNull = this.competitors.filter(item => {
        const rank = this.championship.enable_sub_division ? item.competitor.sub_division_rank : item.competitor.rank; 
        return rank !== null;
      }).sort((a, b) => {
        const rankA = this.championship.enable_sub_division ? a.competitor.sub_division_rank : a.competitor.rank;
        const rankB = this.championship.enable_sub_division ? b.competitor.sub_division_rank : b.competitor.rank;
        if (rankA > rankB) {
          return orderSort === 'ASC' ? 1 : -1;
        } else if (rankA < rankB) {
          return orderSort === 'ASC' ? -1 : 1;
        } else {
          return 0;
        }
      });

      const listNull = this.competitors.filter(item => {
        const rankC = this.championship.enable_sub_division ? item.competitor.sub_division_rank : item.competitor.rank;
        return rankC === null;
      });

      listNull.forEach(value => {
        listNotNull.push(value);
      });

      this.competitors = listNotNull;

    }

    this.competitors.forEach(item => {
      item.tableName = 'competitors';
      item.selected = false;
    });
    this.changeDetectorRefs.detectChanges();
  }

  private deleteHeatAPI(heat: Heat, dialogRef: MatDialogRef<CrossXDialogComponent>): void {
    if (heat.id === null) {
      dialogRef.componentInstance.confirmLoading = false;
      dialogRef.close();
      heat.heat_competitors.forEach(element => {
        if (element.competition_id === this.filterFormGroup.get('competitions').value &&
          element.wod_id === this.filterFormGroup.get('wods').value
        ) {
          element.tableName = 'competitors';
          this.competitors.push(element);
        }
      });
      this.heats.splice(this.heats.indexOf(heat), 1);
      this.toastr.show(
        this.translate.HEATS_GENERATOR.TOASTR.DELETE_HEAT.SUCCESS.MESSAGE,
        this.translate.HEATS_GENERATOR.TOASTR.DELETE_HEAT.SUCCESS.TITLE,
        null,
        'success'
      );
      this.heats.forEach((heat, index) => {
        this.heats[index].number = index + 1;
      });
      this.calcHeatsEvent.emit({
        screen: 'heat'
      });
      return;
    }
    dialogRef.componentInstance.confirmLoading = false;
    dialogRef.close();
    this.idsDelete.push(heat.id);
    heat.heat_competitors.forEach(element => {
      if (element.competition_id === this.filterFormGroup.get('competitions').value &&
        element.wod_id === this.filterFormGroup.get('wods').value
      ) {
        element.tableName = 'competitors';
        this.competitors.push(element);
      }
    });
    this.heats.splice(this.heats.indexOf(heat), 1);
    heat.isUpdated = true;

    this.toastr.show(
      this.translate.HEATS_GENERATOR.TOASTR.DELETE_HEAT.SUCCESS.MESSAGE,
      this.translate.HEATS_GENERATOR.TOASTR.DELETE_HEAT.SUCCESS.TITLE,
      null,
      'success'
    );

    this.heats.forEach((heat, index) => {
      this.heats[index].number = index + 1;
    });

    this.calcHeatsEvent.emit({
      screen: 'heat'
    });
  }

  private hasSameCompetitionAndDiffWodInHeat(heat: Heat, event): boolean {

    const itemSameCompetition = heat.heat_competitors.filter(item => {
      return item.type === 'item' && item.competition_id === event.value.competition_id && item !== event.value;
    });

    if (itemSameCompetition && itemSameCompetition.length > 0) {
      const itemSameCompetitionDiffWod = itemSameCompetition.filter(item => {
        return item.wod_id !== event.value.wod_id;
      });

      if (itemSameCompetitionDiffWod && itemSameCompetitionDiffWod.length > 0) {
        return false;
      }
    }

    return true;
  }
}
