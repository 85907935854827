import { Coupon } from './../../../../core/model/coupon';
import { CouponsService } from './../../../../core/services/coupons/coupons.service';
import { Event } from './../../../../core/model/event';
import { locale as english } from './i18n/en-US';
import { locale as portugues } from './i18n/pt-BR';
import { TableConfig } from './../../../../core/components/table-x/table-x.component';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { TabBarComponent } from '../../../../core/components/tab-bar/tab-bar.component';
import { TableXComponent } from '../../../../core/components/table-x/table-x.component';
import { Title, DomSanitizer } from '@angular/platform-browser';
import { BreadcrumbsService } from '../../../../core/components/breadcrumbs/breadcrumbs.service';
import { FuseTranslationLoaderService } from '../../../../core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EventGuard } from '../../../../core/guards/event.guard';
import {MatIconRegistry, MatDialog, MatDialogRef} from '@angular/material';
import { ToastrService } from '../../../../core/components/toastr_/public_api';
import { config } from '../../../../core/config/config';
import { Utils } from '../../../../core/utils';
import {ModalCouponComponent} from './modal-coupon/modal-coupon.component';
import {Errors} from '../../../../core/model/errors';
import {CrossXDialogComponent} from '../../../../core/components/cross-x-dialog/cross-x-dialog.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.scss']
})
export class CouponsComponent implements OnInit, OnDestroy {

  @ViewChild('tabbar', { read: TabBarComponent }) tabBar: TabBarComponent;
  @ViewChild('table', { read: TableXComponent }) tableList: TableXComponent;
  private subscriptions = new Subscription();

  // Translate 
  translate: any;

  event: Event;
  coupons: Coupon[] = [];

  // Tabs
  tabsFilter: string[] = [];
  tabsOptions: any[] = [];

  // Table
  tableConfig: TableConfig = {
    checkbox: false,
    paddingSide: true,
    massActions: null,
    massActionCountTitle: null
  };

  // Booleans
  isLoading = false;
  isEmptyResult = false;
  isShowScrollToTop = false;
  isLoadingExport = false;

  // config request
  page = 1;
  totalPage = 20;
  loadedEnd = false;
  searchTerm = '';
  type = 'active';
  sort: any = null;

  timeOutSearch;
  isSearch = false;

  constructor(
    private titleService: Title,
    private breadcrumbsService: BreadcrumbsService,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private eventGuard: EventGuard,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private couponService: CouponsService
  ) { 
    this.translationLoader.loadTranslations(english, portugues);

    this.subscriptions.add(this.translateService.get('COUPONS').subscribe((res: any) => {
      this.translate = res;
    }));

    this.event = this.eventGuard.getActiveEvent().event;

    if (this.translate) {
      this.titleService.setTitle(`${config.titleBase} - ${this.translate.TITLE}`);

      this.breadcrumbsService.replaceAll([{
        text: this.translate.TITLE,
        route: `/coupons`
      }]);

      // population tabs
      this.tabsOptions = [
        {
          name: this.translate.TABS.ACTIVE,
          type: 'active'
        },
        {
          name: this.translate.TABS.EXPIRED,
          type: 'expired'
        },
        {
          name: this.translate.TABS.INACTIVE,
          type: 'inactive'
        }
      ];

      this.tabsFilter = [];
      this.tabsOptions.forEach(tab => {
        this.tabsFilter.push(tab.name);
      });
    }
  }

  ngOnInit() {
    this.getCoupons();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getCoupons(): any {
    if (this.isLoading) { return; }

    this.isLoading = true;
    this.isEmptyResult = false;
    if (this.page === 1) { 
      this.coupons = []; 
    }

    this.subscriptions.add(this.couponService.getList(this.event.id, this.page, this.type, this.sort, this.searchTerm)
      .subscribe(
        result => {
          if (this.page === 0) {
            this.coupons = result;
          } else {
            result.forEach(coupon => {
              this.coupons.push(coupon);
            });
          }
          this.isLoading = false;
          this.isEmptyResult = (this.page === 1 && result.length === 0);
          this.loadedEnd = result.length === 0;
          if (this.tableList) { 
            this.tableList.reloadItems(); 
          }
          
        }, err => {
          this.isLoading = false;
          this.loadedEnd = true;
        }
      )); 
  }
  
  // Add New
  addNew() {
    const modalRef = this.dialog.open(ModalCouponComponent, {
      width: '816px',
      height: '100%',
      panelClass: 'modal'
    });

    modalRef.componentInstance.event = this.event;
    // modalRef.componentInstance.eventId = this.event.id
    // modalRef.componentInstance.competition = competition

    this.subscriptions.add(modalRef.afterClosed().subscribe(result => {
      if (result && result.coupon) {
        this.coupons.push(result.coupon);
        if (this.tableList) { this.tableList.reloadItems(); }

        this.toastr.show(
          this.translate.TOASTR.CREATE.DESCRIPTION,
          this.translate.TOASTR.CREATE.TITLE,
          null,
          'success',
        );
      }
    }));
  }
  
  editCoupon(coupon: Coupon, index: number) {
    const modalRef = this.dialog.open(ModalCouponComponent, {
      width: '816px',
      height: '100%',
      panelClass: 'modal'
    });

    modalRef.componentInstance.event = this.event;
    modalRef.componentInstance.coupon = coupon;
    modalRef.componentInstance.isEdit = true;
    
    this.subscriptions.add(modalRef.afterClosed().subscribe(result => {
      if (result && result.coupon) {
        this.coupons[index] = {
          ...this.coupons[index],
          ...result.coupon
        };
        if (this.tableList) { this.tableList.reloadItems(); }

        this.toastr.show(
          this.translate.TOASTR.EDIT.DESCRIPTION,
          this.translate.TOASTR.EDIT.TITLE,
          null,
          'success',
        );
      }
    }));
  }

  deleteCoupon(coupon: Coupon, index: number) {
    const dialogRef = this.dialog.open(CrossXDialogComponent, {
      width: '440px',
      closeOnNavigation: true
    });

    dialogRef.componentInstance.type = 'warning';
    dialogRef.componentInstance.titleDialog = this.translate.DIALOG.DELETE.TITLE;
    this.subscriptions.add(this.translateService.get('COUPONS.DIALOG.DELETE.DESCRIPTION', { coupon_name: coupon.name }).subscribe((res: string) => {
      dialogRef.componentInstance.dialogContent = res;
    }));
    dialogRef.componentInstance.dialogConfirm = true;
    dialogRef.componentInstance.cancelButton = this.translate.CANCEL.toUpperCase();
    dialogRef.componentInstance.actionButton = this.translate.DELETE.toUpperCase();
    dialogRef.componentInstance.object = coupon;
    dialogRef.componentInstance.confirmActionRequest = true;
    this.subscriptions.add(dialogRef.componentInstance.dialogEvent.subscribe(result => {
      dialogRef.componentInstance.confirmLoading = true;
      this.deleteCouponInAPI(coupon, index, dialogRef);
    }));
  }
  
  deleteCouponInAPI(coupon: Coupon, index: number, dialogRef: MatDialogRef<CrossXDialogComponent>)
  {
    this.subscriptions.add(this.couponService.delete(coupon.id)
      .subscribe(
        result => {
          dialogRef.componentInstance.confirmLoading = false;
          dialogRef.close();
          
          this.coupons.splice(index, 1);
          this.isEmptyResult = this.coupons.length === 0;
          if (this.tableList) { this.tableList.reloadItems(); }

          this.toastr.show(
            this.translate.TOASTR.DELETE.SUCCESS.DESCRIPTION,
            this.translate.TOASTR.DELETE.SUCCESS.TITLE,
            null,
            'success',
          );
        }, err => {
          dialogRef.componentInstance.confirmLoading = false;
          dialogRef.close();
          
          const errors: Errors = new Errors().deserialize((err.error as any));
          this.toastr.show(
            errors.getFullMessages(),
            this.translate.TOASTR.DELETE.ERROR.TITLE,
            null,
            'error',
          );
        }
      ));
  }

  activeInactiveCoupon(coupon: Coupon, index: number, action: string) {
    const dialogRef = this.dialog.open(CrossXDialogComponent, {
      width: '440px',
      closeOnNavigation: true
    });

    dialogRef.componentInstance.type = 'warning';
    dialogRef.componentInstance.titleDialog = (action === 'active') ? this.translate.DIALOG.ACTIVE.TITLE : this.translate.DIALOG.INACTIVE.TITLE;
    if ((action === 'active')) {
      this.subscriptions.add(this.translateService.get('COUPONS.DIALOG.ACTIVE.DESCRIPTION', { coupon_name: coupon.name }).subscribe((res: string) => {
        dialogRef.componentInstance.dialogContent = res;
      }));
    } else {
      this.subscriptions.add(this.translateService.get('COUPONS.DIALOG.INACTIVE.DESCRIPTION', { coupon_name: coupon.name }).subscribe((res: string) => {
        dialogRef.componentInstance.dialogContent = res;
      }));
    }
    
    dialogRef.componentInstance.dialogConfirm = true;
    dialogRef.componentInstance.cancelButton = this.translate.CANCEL.toUpperCase();
    dialogRef.componentInstance.actionButton = (action === 'active') ? this.translate.DIALOG.ACTIVE.ACTIVE.toUpperCase() : this.translate.DIALOG.INACTIVE.INACTIVE.toUpperCase();
    dialogRef.componentInstance.object = coupon;
    dialogRef.componentInstance.confirmActionRequest = true;
    this.subscriptions.add(dialogRef.componentInstance.dialogEvent.subscribe((result: any) => {
      dialogRef.componentInstance.confirmLoading = true;
      this.activeInactiveCouponAPI(coupon, index, action, dialogRef);
    }));
  }
  
  activeInactiveCouponAPI(coupon: Coupon, index: number, action: string, dialogRef: MatDialogRef<CrossXDialogComponent>) {
    const parameters = {
      event_id: this.event.id,
      status: action === 'active' ? 1 : 0,
    };

    this.subscriptions.add(this.couponService.update(parameters, coupon.id)
      .subscribe(
        result => {
          dialogRef.componentInstance.confirmLoading = false;
          dialogRef.close();
          
          this.coupons.splice(index, 1);
          this.isEmptyResult = this.coupons.length === 0;
          if (this.tableList) { this.tableList.reloadItems(); }

          this.toastr.show(
            (action === 'active') ? this.translate.TOASTR.ACTIVE.DESCRIPTION : this.translate.TOASTR.INACTIVE.DESCRIPTION,
            (action === 'active') ? this.translate.TOASTR.ACTIVE.TITLE : this.translate.TOASTR.INACTIVE.TITLE,
            null,
            'success',
          );
        }, err => {
          dialogRef.componentInstance.confirmLoading = false;
          dialogRef.close();
          
          const errors: Errors = new Errors().deserialize((err.error as any));
          this.toastr.show(
            errors.getFullMessages(),
            action === 'active' ? this.translate.TOASTR.ACTIVE.ERROR.TITLE : this.translate.TOASTR.INACTIVE.ERROR.TITLE,
            null,
            'error',
          );
        }
      ));
  }

  // Sort Results
  sortAction(sort) {
    if (sort.sort === null) {
      this.sort = null;
    } else {
      this.sort = {
        order: sort.order,
        column: sort.sort
      };
    }

    this.page = 1;
    this.totalPage = 20;
    this.loadedEnd = false;
    this.coupons = [];
    this.getCoupons();
  }

  searchName(name: string) {
    clearTimeout(this.timeOutSearch);
    this.timeOutSearch = setTimeout(() => {
      this.searchTerm = name;
      // Reset all options to list
      this.page = 1;
      this.totalPage = 20;
      this.loadedEnd = false;
      this.coupons = [];
      this.getCoupons();
    }, 100);
  }

  // Tabbar action
  tabAction(event) {
    
    if (this.tabsOptions[event.index].type === this.type) { return; }

    // Reset all options to list
    this.isLoading = false;
    this.page = 1;
    this.totalPage = 20;
    this.loadedEnd = false;
    this.searchTerm = '';
    this.type = this.tabsOptions[event.index].type;
    this.coupons = [];
    this.sort = null;
    if (this.tableList) { this.tableList.resetSort(); }
    this.getCoupons();
  }

  // Scroll Event to infinite scroll
  scrollEvent(event) {
    if (event.type === 'retriveWithScroll' && !this.isLoading && !this.loadedEnd) {
      this.page++;
      this.getCoupons();
    }
  }

  // Helper
  formatCurrency(value: string, currency: string): string {
    const unit = Utils.formatCurrency(value, currency);
    return unit ? unit : '';
  }

}
