export class Errors {
  errors?: Error[] = null;
  message?: string;

  deserialize(object: any) {
    if (object !== null) { (<any>Object).assign(this, object); }

    if (object !== null && object.errors) {
      this.errors = object.errors.map((error: Error) => new Error().deserialize(error));
    }

    return this;
  }

  getFullMessages(): string
  {
    let message = '';
    if (this.errors === null || this.errors.length === 0) { return ''; }

    this.errors.forEach(error => {
      if (message.length !== 0) {
        message += ', ';
      }
      message += error.message;
    });
    
    return message;
  }
}

export class Error {
  error?: string = null;
  field?: string = null;
  message?: string = null;

  deserialize(object: any) {
    
    if (typeof object === 'string') {
      this.message = object;
    }
    else {
      (<any>Object).assign(this, object);
    }

    return this;
  }
}
