import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'select-time',
  templateUrl: './select-time.component.html',
  styleUrls: ['./select-time.component.scss']
})
export class SelectTimeComponent implements OnInit {

  public time = '10:00';

  @Input() value: string;
  @Input() label: string;
  @Input() readonly: boolean = false;
  @Input() required = false;
  @Output() inputValue = new EventEmitter();

  hours: string[] = [];
  minutes: string[] = [];

  hourSelected: string;
  minuteSelected: string;
  isRequired = ''

  constructor() { }

  ngOnInit() {

    for (let index = 0; index < 24; index++){
      if (index < 10) {
        this.hours.push(`0${index}`);
      }
      else {
        this.hours.push(`${index}`);
      }
    }

    for (let index = 0; index < 60; index++) {
      if (index < 10) {
        this.minutes.push(`0${index}`);
      }
      else {
        this.minutes.push(`${index}`);
      }
    }

    if (this.value) {
      const valueArray = this.value.split(':');
      this.hourSelected = valueArray[0];
      this.minuteSelected = valueArray[1];
    }

    this.isRequired = (this.required) ? '*' : '';
  }

  minuteChange(event) {
    const value: any = event.value;
    this.minuteSelected = value;
    this.inputValue.emit(`${this.hourSelected}:${this.minuteSelected}`); 
    this.time = `${this.hourSelected}:${this.minuteSelected}`;
  }

  hourChange(event) {
    const value: any = event.value;
    this.hourSelected = value;
    this.inputValue.emit(`${this.hourSelected}:${this.minuteSelected}`);
    this.time = `${this.hourSelected}:${this.minuteSelected}`;
  }

  updateValue(newValue: string) {
    if (newValue) {
      const valueArray = newValue.split(':');
      this.hourSelected = valueArray[0];
      this.minuteSelected = valueArray[1];
    }
  }

}
