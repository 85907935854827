import { Component, OnInit } from '@angular/core';
import {Event} from '../../../../../../core/model/event';
import {Subscription} from 'rxjs';
import { locale as english } from './../../i18n/en-US';
import { locale as portugues } from './../../i18n/pt-BR';
import {MatDialogRef, MatIconRegistry} from '@angular/material';
import {FuseTranslationLoaderService} from '../../../../../../core/services/translation-loader.service';
import {TranslateService} from '@ngx-translate/core';
import {DomSanitizer} from '@angular/platform-browser';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CustomFieldsService} from '../../../../../../core/services/custom-fields/custom-fields.service';
import {ToastrService} from '../../../../../../core/components/toastr_/toastr/toastr.service';
import {DefaultCustomFieldType} from '../../../../../../core/model/default-custom-field.model';
import {FormErrorStateMatcher} from '../../../../../../core/utils/form-error-state-matcher';
import {Utils} from '../../../../../../core/utils';
import {CropDialogComponent} from '../../../../../../core/components/crop-dialog/crop-dialog.component';
import {Errors} from '../../../../../../core/model/errors';
import {CrossxAppService} from '../../../../../../core/services/crossx-app/crossx-app.service';
import {CrossXApp} from '../../../../../../core/model/crossx-app.model';

@Component({
  selector: 'app-create-crossx-app-url',
  templateUrl: './create-crossx-app-url.component.html',
  styleUrls: ['./create-crossx-app-url.component.scss']
})
export class CreateCrossxAppUrlComponent implements OnInit {

  public event: Event = null;
  public crossXApp: CrossXApp = null;
  public order: number = null;
  
  private subscriptions: Subscription = new Subscription();

  // Translate
  translate: any;
  
  public urlForm: FormGroup;
  public matchers: any;
  public errorRequest: string = null;
  public fileName: string = ""
  public fileToUpload: File = null

  public titleModel: string = 'Novo campo';
  public isLoading: boolean = false;
  
  constructor(
    public dialogRef: MatDialogRef<CreateCrossxAppUrlComponent>,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer,
    private iconRegistry: MatIconRegistry,
    private formBuilder: FormBuilder,
    private customFieldService: CustomFieldsService,
    private toastr: ToastrService,
    private crossXAppSerice: CrossxAppService
  ) { }

  ngOnInit() {
    this.registerOnTranslate();
    this.configForm();
  }
  
  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }
  
  public saveUrl(): void {
    if (this.fileToUpload === null && this.urlForm.value.url.length === 0) {
      this.errorRequest = 'É obrigatório colocar a URL ou selecionar um arquivo';
      return;
    }
    
    const parameters = {
      title: this.urlForm.value.title.toUpperCase(),
      order: this.order
    }

    if (this.fileToUpload === null){
      parameters['url'] = this.urlForm.value.url
    }
    

    this.isLoading = true;

    this.crossXAppSerice.createCrossXAppUrl(this.crossXApp.id, parameters, this.fileToUpload)
      .subscribe(
        result => {
          this.isLoading = false
          
          this.toastr.show(
            this.translate.TOASTR.SUCCESS.DESCRIPTION,
            this.translate.TOASTR.SUCCESS.TITLE,
            null,
            'success',
          )

          this.dialogRef.close({ url: result, isEdit: false })
        }, err => {
          this.isLoading = false

          const errors: Errors = new Errors().deserialize((err.error as any))
          this.errorRequest = errors.getFullMessages();
        }
      )
    
  }


  private registerOnTranslate(): void {
    this.translationLoader.loadTranslations(english, portugues);

    this.translateService.get("CROSSX_APP_CREATE").subscribe((res: any) => {
      this.translate = res
      this.titleModel = res.TITLE_NEW;
    });
  }

  private configForm(): void {
    this.urlForm = this.formBuilder.group({
      title: [''],
      url: ['', [Validators.pattern(Utils.getRegUrlFormat())]],
      file: ['']
    });
    

    this.matchers = {
      title: new FormErrorStateMatcher(),
      url: new FormErrorStateMatcher(),
      file: new FormErrorStateMatcher()
    }
  }
  
  public readUrl(event: any) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();

      reader.onload = (event: any) => {
        this.fileName = event.target.files[0].name
      }

      this.fileName = event.target.files[0].name
      this.fileToUpload = event.target.files[0]

      reader.readAsDataURL(event.target.files[0]);
    }
  }
  
  public removeFile() {
    this.fileToUpload = null;
    this.fileName = '';
  }
}
