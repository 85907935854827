import {Component, OnInit} from '@angular/core';
import {Event} from '../../../../../core/model/event';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {BreadcrumbsService} from '../../../../../core/components/breadcrumbs/breadcrumbs.service';
import {FuseTranslationLoaderService} from '../../../../../core/services/translation-loader.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthGuard} from '../../../../../core/guards/auth.guard';
import {EventGuard} from '../../../../../core/guards/event.guard';
import {MatDialog, MatDialogRef, MatIconRegistry} from '@angular/material';
import {GooglePlacesService} from '../../../../../core/services/google-places.service';
import {DragulaService} from 'ng2-dragula';
import {EventsService} from '../../../../../core/services/event/events.service';
import {ChampionshipService} from '../../../../../core/services/championship/championship.service';
import {ToastrService} from '../../../../../core/components/toastr_/toastr/toastr.service';
import {BankAccountService} from '../../../../../core/services/bank-account/bank-account.service';
import {BanksService} from '../../../../../core/services/banks/banks.service';
import {EventConfigurationsService} from '../../../../../core/services/event-configurations/event-configurations.service';
import { locale as english } from './../i18n/en-US';
import { locale as portugues } from './../i18n/pt-BR';
import {config} from '../../../../../core/config/config';
import {Utils} from '../../../../../core/utils';
import {FormErrorStateMatcher} from '../../../../../core/utils/form-error-state-matcher';
import {Errors} from '../../../../../core/model/errors';
import {CrossXDialogComponent} from '../../../../../core/components/cross-x-dialog/cross-x-dialog.component';

@Component({
  selector: 'app-event-subscriptions',
  templateUrl: './event-subscriptions.component.html',
  styleUrls: ['./event-subscriptions.component.scss']
})
export class EventSubscriptionsComponent implements OnInit {
  //Translate 
  translate: any

  //Event
  event: Event
  
  banks = []
  
  // Registration
  registrationForm: FormGroup
  registrationMatchers: any

  bankAccountForm: FormGroup
  bankAccountMatchers: any
  
  //Tax
  taxForm: FormGroup
  taxMatchers: any

  bankAccountList: any[] = []

  personTypes: any[] = []
  personTypeSelected: any
  accountTypes: any[] = []

  userMe: any = null;
  currentAccount: any = null;

  timeOutSearchBank

  isLoading: boolean = false
  isBankAccountLoading: boolean = false
  isRegistrationLoading: boolean = false
  isTaxLoading: boolean = false
  
  isShowInputBank: boolean = false

  constructor(
    private titleService: Title,
    private breadcrumbsService: BreadcrumbsService,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authGuard: AuthGuard,
    private eventGuard: EventGuard,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private googlePlacesService: GooglePlacesService,
    public dialog: MatDialog,
    private dragulaService: DragulaService,
    private eventService: EventsService,
    private championshipService: ChampionshipService,
    private toastr: ToastrService,
    private bankAccountService: BankAccountService,
    private banksService: BanksService,
    private eventConfigurationService: EventConfigurationsService
  ) {
    this.translationLoader.loadTranslations(english, portugues);

    this.translateService.get("SETTINGS").subscribe((res: any) => {
      this.translate = res
    })

    if (this.translate) {
      this.titleService.setTitle(`${config.titleBase} - ${this.translate.TITLE}`)

      this.breadcrumbsService.replaceAll([{
        text: this.translate.TITLE_SUBSCRIPTIONS,
        route: `/event/subscriptions`
      }])
    }

    const getEvent = this.eventGuard.getActiveEvent().event;
    if (getEvent) {
      this.event = new Event().deserialize(getEvent);
    } 
    
    this.userMe = this.authGuard.getMe().user
  }

  
  ngOnInit() {
    // Registration Form
    this.registrationForm = this.formBuilder.group({
      boleto: [false],
      softDescription: ['', [Validators.required]],
      daysDueDate: ['']
    });

    this.registrationMatchers = {
      softDescription: new FormErrorStateMatcher(),
      daysDueDate: new FormErrorStateMatcher(),
    }

    this.getEventDetail()

    // Registration Form
    this.personTypes = [
      {
        value: 1,
        title: this.translate.REGISTRATION.TYPES_PERSON.PHYSICAL
      },
      {
        value: 0,
        title: this.translate.REGISTRATION.TYPES_PERSON.LEGAL
      }
    ]

    this.accountTypes = [
      {
        value: 'conta_corrente',
        title: this.translate.REGISTRATION.TYPES_ACCOUNT.CC
      },
      {
        value: 'conta_poupanca',
        title: this.translate.REGISTRATION.TYPES_ACCOUNT.CP
      },
      {
        value: 'conta_corrente_conjunta',
        title: this.translate.REGISTRATION.TYPES_ACCOUNT.CCC
      },
      {
        value: 'conta_poupanca_conjunta',
        title: this.translate.REGISTRATION.TYPES_ACCOUNT.CPC
      }
    ]

    this.personTypeSelected = this.personTypes[0]

    this.bankAccountForm = this.formBuilder.group({
      bankAccount: [this.event.user_bank_account ? this.event.user_bank_account : '', [Validators.required]],
      personType: [this.personTypes[0], Validators.required],
      name: ['', Validators.required],
      documentNumber: ['', Validators.required],
      bank: ['', [Validators.required, Validators.minLength(3)]],
      accountType: [this.accountTypes[0], Validators.required],
      agency: ['', [Validators.required, Validators.maxLength(5)]],
      agencyDv: [''],
      account: ['', [Validators.required, Validators.maxLength(13)]],
      accountDv: ['', [Validators.required, Validators.maxLength(2)]],
    });

    this.bankAccountMatchers = {
      bankAccount: new FormErrorStateMatcher(),
      personType: new FormErrorStateMatcher(),
      name: new FormErrorStateMatcher(),
      documentNumber: new FormErrorStateMatcher(),
      bank: new FormErrorStateMatcher(),
      accountType: new FormErrorStateMatcher(),
      agency: new FormErrorStateMatcher(),
      account: new FormErrorStateMatcher(),
      accountDv: new FormErrorStateMatcher(),
    }

    this.getBankAccountList()

    let percentage = this.event.recipient_percentage ? this.event.recipient_percentage : 0.00;//`${this.event.recipient_percentage}` : '7,00';
    
    this.taxForm = this.formBuilder.group({
      tax: [percentage, [Validators.required]],
    });

    this.taxMatchers = {
      tax: new FormErrorStateMatcher(),
    }

    // Inputs value changes
    this.inputValueChanged()
  }

  inputValueChanged(): any {
    this.registrationForm.get('boleto').valueChanges.subscribe(value => {
      if (value) {
        this.registrationForm.controls['daysDueDate'].setValidators([Validators.required])
      }
      else {
        this.registrationForm.controls['daysDueDate'].clearValidators()
        this.registrationForm.controls['daysDueDate'].setErrors(null)
      }
    })

    this.bankAccountForm.get('bankAccount').valueChanges.subscribe(value => {
      this.isShowInputBank = (value !== undefined && value.type === 'create-new')

      if (this.isShowInputBank) {
        this.bankAccountForm.controls['personType'].setValidators([Validators.required])
        this.bankAccountForm.controls['name'].setValidators([Validators.required])
        this.bankAccountForm.controls['documentNumber'].setValidators([Validators.required])
        this.bankAccountForm.controls['bank'].setValidators([Validators.required, Validators.minLength(3)])
        this.bankAccountForm.controls['accountType'].setValidators([Validators.required])
        this.bankAccountForm.controls['agency'].setValidators([Validators.required, Validators.maxLength(5)])
        this.bankAccountForm.controls['account'].setValidators([Validators.required, Validators.maxLength(13)])
        this.bankAccountForm.controls['accountDv'].setValidators([Validators.required, Validators.maxLength(2)])
      }
      else {
        Object.keys(this.bankAccountForm.controls).forEach(field => {
          if (field !== 'bankAccount') {
            const control = this.bankAccountForm.get(field);
            control.clearValidators()
            control.setErrors(null)
          }
        });
      }
      
    })

    this.bankAccountForm.valueChanges.subscribe(() => {
    })

  }

  getEventDetail() {
    //Get Event details
    this.eventService.getEventInformation(this.event.id)
      .subscribe(
        result => {
          this.event = new Event().deserialize(result)
          
          this.event.paymentMethods.forEach(paymentMethod => {
            if (paymentMethod.name === 'boleto') {
              this.registrationForm.controls['boleto'].setValue(true)
              this.registrationForm.controls['daysDueDate'].setValue(paymentMethod.boleto_days_due)
            }
            else if (paymentMethod.name === 'credit_card') {
              this.registrationForm.controls['softDescription'].setValue(paymentMethod.soft_descriptor)
            }
          });
          
        }, err => { }
      )
  }

  getBankAccountList(): any {
    this.bankAccountService.getAccounts(this.event.user.id)
      .subscribe(
        result => {

          this.bankAccountList.push({
            id: 0,
            type: 'create-new',
            bank_id: '',
            agency: '',
            account_number: '',
            legal_name: this.translate.REGISTRATION.NEW_ACCOUNT,
          })

          result.forEach(account => {
            this.bankAccountList.push(account)
            if (this.event.user_bank_account && account.id === this.event.user_bank_account.id) {
              this.currentAccount = account
              this.bankAccountForm.controls['bankAccount'].setValue(account)
            }
          });
        }, err => {
        }
      )
  }

  // Payment Method
  savePaymentMethods()
  {
    // validate all form fields
    if (!this.registrationForm.valid) {
      Utils.formValid(this.registrationForm)
      return
    }

    var parameters = {
      event_id: this.event.id,
      soft_descriptor: this.registrationForm.value.softDescription.toUpperCase()
    }

    if (this.registrationForm.value.boleto) {
      if (parseInt(this.registrationForm.value.daysDueDate) < 2) {
        this.registrationForm.controls['daysDueDate'].setErrors({
          invalid: true
        })
        return
      } else {
        this.registrationForm.controls['daysDueDate'].setErrors(null)
      }

      parameters['boleto_days_due'] = this.registrationForm.value.daysDueDate
    }
    

    this.isRegistrationLoading = true
    this.eventService.updatePaymentMethods(parameters)
      .subscribe(
        result => {
          this.isRegistrationLoading = false

          this.toastr.show(
            this.translate.REGISTRATION.TOASTR.PAYMENT_METHODS.DESCRIPTION,
            this.translate.REGISTRATION.TOASTR.PAYMENT_METHODS.TITLE,
            null,
            'success',
          )

          this.getEventDetail()
        }, err => {
          this.isRegistrationLoading = false

          const errors: Errors = new Errors().deserialize((err.error as any))
          this.toastr.show(
            errors.getFullMessages(),
            this.translate.REGISTRATION.TOASTR.PAYMENT_METHODS.ERROR,
            null,
            'error',
          )
        }
      )

  }

  // Bank Account
  saveBankAccount()
  {
    // validate all form fields
    if (!this.bankAccountForm.valid) {
      Utils.formValid(this.bankAccountForm)
      return
    }

    var parameters: any = null
    if (this.bankAccountForm.value.bankAccount && this.bankAccountForm.value.bankAccount.id === 0) {
      const documentNumber = this.bankAccountForm.value.documentNumber.replace(/[^0-9]/, '');

      parameters = {
        user_id: this.event.user.id,
        event_id: this.event.id,
        type: this.bankAccountForm.value.accountType.value,
        bank_id: this.bankAccountForm.value.bank.id,
        agency: this.bankAccountForm.value.agency,
        agency_digit: this.bankAccountForm.value.agencyDv,
        account_number: this.bankAccountForm.value.account,
        account_digit: this.bankAccountForm.value.accountDv,
        document_number: documentNumber,
        legal_name: this.bankAccountForm.value.name,
      }
      

      this.isBankAccountLoading = true
      this.bankAccountService.create(parameters)
        .subscribe(
          result => {
            this.isBankAccountLoading = false
            this.bankAccountList.push(result)

            this.bankAccountForm.controls['bankAccount'].setValue(result)

            this.toastr.show(
              this.translate.REGISTRATION.TOASTR.BANKACCOUNT.CREATE.DESCRIPTION,
              this.translate.REGISTRATION.TOASTR.BANKACCOUNT.CREATE.TITLE,
              null,
              'success',
            )

            this.getEventDetail()
          }, err => {
            this.isBankAccountLoading = false

            const errors: Errors = new Errors().deserialize((err.error as any))
            this.toastr.show(
              errors.getFullMessages(),
              this.translate.REGISTRATION.TOASTR.BANKACCOUNT.CREATE.ERROR,
              null,
              'error',
            )
          }
        )
    }
    else {
      parameters = {
        user_bank_account_id: this.bankAccountForm.value.bankAccount.id
      }
      

      this.isBankAccountLoading = true
      this.eventService.updateEvent(this.event.id, parameters)
        .subscribe(
          result => {
            this.isBankAccountLoading = false
            this.eventGuard.updateEventInformation(result)

            this.toastr.show(
              this.translate.REGISTRATION.TOASTR.BANKACCOUNT.UPDATE.DESCRIPTION,
              this.translate.REGISTRATION.TOASTR.BANKACCOUNT.UPDATE.TITLE,
              null,
              'success',
            )
          }, err => {
            this.isBankAccountLoading = false

            const errors: Errors = new Errors().deserialize((err.error as any))
            this.toastr.show(
              errors.getFullMessages(),
              this.translate.REGISTRATION.TOASTR.BANKACCOUNT.UPDATE.ERROR,
              null,
              'error',
            )
          }
        )
    }

  }
  displayFnBank(bank: any): string {
    return bank ? `${bank.code} - ${bank.title}` : bank;
  }

  // Description Value change
  public onValueChange(value: string): void {
  }


  // Search Bank
  searchBank(bank: string) {
    clearTimeout(this.timeOutSearchBank)
    this.timeOutSearchBank = setTimeout(() => {
      if (bank.length > 0) {
        this.banksService.search(bank)
          .subscribe(
            result => {
              this.banks = result
            }, err => {
              this.banks = []
            }
          )
      } else {
        this.banks = []
      }
    }, 300);

  }
  
  // REGISTRATION
  personTypeSelect(event) {
    this.personTypeSelected = event.value
    this.bankAccountForm.get('documentNumber').reset()
    this.bankAccountForm.controls['documentNumber'].setErrors(null)
  }

  //Tax
  public saveRecepientTax(): void {
    // validate all form fields
    if (!this.taxForm.valid) {
      Utils.formValid(this.taxForm)
      return
    }

    const parameters = {
      recipient_percentage: this.taxForm.value.tax
    }

    this.dialog.openDialogs.pop();
    const dialogRef = this.dialog.open(CrossXDialogComponent, {
      width: '440px',
      closeOnNavigation: true
    });

    dialogRef.componentInstance.type = 'warning'
    dialogRef.componentInstance.titleDialog = this.translate.REGISTRATION.DIALOG.CONFIRM_TAX.TITLE
    dialogRef.componentInstance.dialogContent = this.translate.REGISTRATION.DIALOG.CONFIRM_TAX.DESCRIPTION
    dialogRef.componentInstance.dialogConfirm = true
    dialogRef.componentInstance.cancelButton = this.translate.CANCEL.toUpperCase()
    dialogRef.componentInstance.actionButton = this.translate.SAVE.toUpperCase()
    dialogRef.componentInstance.object = parameters
    dialogRef.componentInstance.confirmActionRequest = true
    dialogRef.componentInstance.dialogEvent.subscribe(result => {
      dialogRef.componentInstance.confirmLoading = true
      this.updateRecepientTaxInAPI(parameters, dialogRef);
    })
    
  }

  private updateRecepientTaxInAPI(parameters: any, dialogRef: MatDialogRef<CrossXDialogComponent, any>) {
    this.eventService.updateEvent(this.event.id, parameters)
      .subscribe(
        result => {
          this.eventGuard.setEvent(result)
          this.event = new Event().deserialize(result);

          this.toastr.show(
            this.translate.REGISTRATION.TOASTR.PERCENTAGE_TAX.DESCRIPTION,
            this.translate.REGISTRATION.TOASTR.PERCENTAGE_TAX.TITLE,
            null,
            'success'
          )

          dialogRef.componentInstance.confirmLoading = false;
          dialogRef.close();

        }, err => {
          const errors: Errors = new Errors().deserialize((err.error as any))

          this.toastr.show(
            errors.getFullMessages(),
            this.translate.REGISTRATION.TOASTR.PERCENTAGE_TAX.ERROR,
            null,
            'error'
          )

          dialogRef.componentInstance.confirmLoading = false;
          dialogRef.close();
        }
      )
  }
}
