import { SubscriptionService } from './../../../../../core/services/subscription/subscription.service';
import { Event } from './../../../../../core/model/event';
import { CouponsService } from './../../../../../core/services/coupons/coupons.service';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FuseTranslationLoaderService } from '../../../../../core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/en-US';
import { locale as portugues } from './i18n/pt-BR';
import { Lot } from '../../../../../core/model/lot';
import { Utils } from '../../../../../core/utils';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { FormErrorStateMatcher } from '../../../../../core/utils/form-error-state-matcher';
import { invalidInput } from '../../../../../core/utils/custom-validators';
import { Errors } from '../../../../../core/model/errors';
import {Gateways} from '../../../../../core/enum/gateways';

@Component({
  selector: 'app-modal-lots-list',
  templateUrl: './modal-lots-list.component.html',
  styleUrls: ['./modal-lots-list.component.scss']
})
export class ModalLotsListComponent implements OnInit {

  public event: Event;
  public lots: Lot[];
  public user?: any = null;

  // translate
  translate: any;

  promocodeForm: FormGroup;
  matcher = new FormErrorStateMatcher();
  coupon: {
    code: string
    type: number
    value: number
  } = null;
  
  showPromocodeInput = false;
  isLoading = false;
  isSelectLoading = false;
  invalidCode = false;
  hasCoupon = false;

  constructor(
    public dialogRef: MatDialogRef<ModalLotsListComponent>,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    private couponsService: CouponsService,
    private subscriptionService: SubscriptionService,
  ) { 
    this.translationLoader.loadTranslations(english, portugues);

    this.translateService.get('LOTS_LIST').subscribe((res: any) => {
      this.translate = res;
    });
  }

  ngOnInit() {
    this.promocodeForm = this.formBuilder.group({
      promocode: new FormControl('', [Validators.required]),
    });

    this.showPromocodeInput = this.showPromoCode();
  }

  // validate promocode
  validatePromocode() {
    if (this.promocodeForm.invalid) {
      Utils.formValid(this.promocodeForm);
      return;
    }

    this.isLoading = true;
    this.invalidCode = false;
    this.coupon = null;
    this.couponsService.validateCode(this.promocodeForm.value.promocode, this.event.id)
      .subscribe(
        result => {
          this.isLoading = false;
          this.invalidCode = true;
          this.hasCoupon = true;
          this.coupon = {
            code: this.promocodeForm.value.promocode,
            type: result.type,
            value: result.value
          };
          this.lots = (result.lots as any).map((result: Lot) => new Lot().deserialize(result));
        }, err => {
          console.log('err', err);
          this.isLoading = false;
          this.invalidCode = true;
          this.promocodeForm.controls['promocode'].setErrors({
            invalid: true
          });
          this.coupon = null;
        }
      ); 
  }

  public get disabledButton(): boolean {
      return this.lots.filter(v => v.active === true).length === 0;  
  }
  
  public showPromoCode(): boolean {
    if (this.event.championships[0].id === 239) {
      return true;
    } else {
      return this.lots.length === 0;
    }
  }

  finishSelectedLot() {
    const lot = this.lots.filter(v => v.active === true);

    if (this.event.championships[0].id === 239 && !this.hasCoupon) {
      return;
    }

    if (lot === null || lot.length === 0) {
      return;
    }

    if (lot[0].finalPrice > 0 && !this.event.has_bank_account && this.event.default_gateway_id === Gateways.PAGARME) {
      this.dialogRef.close({ error: 'emptyBankAccount'});
      return; 
    }

    if (this.user) {
      this.isSelectLoading = true;
      this.subscriptionService.getUserInEvent(lot[0].id, this.user.id)
        .subscribe(
          result => {
            const subscription = result;
            this.isSelectLoading = false;
            if (subscription !== null) {
              this.dialogRef.close({ error: 'hasSubscription', subscription: subscription, lot: lot[0], coupon: this.coupon  });
            }
            else {
              if (lot && lot.length > 0) {
                this.dialogRef.close({ lot: lot[0], coupon: this.coupon });
              } 
            }
          }, err => {
            console.log('err', err);
            const errors: Errors = new Errors().deserialize((err.error as any));
            this.dialogRef.close({ error: 'hasSubscriptionConfirmed', message: errors.getFullMessages() });
            // if (lot && lot.length > 0) {
            //   this.dialogRef.close({ lot: lot[0], coupon: this.coupon })
            // } 
          }
        );
    }
    else {
      if (lot && lot.length > 0) {
        this.dialogRef.close({ lot: lot[0], coupon: this.coupon });
      } 
    }
  }

  

  selectedLot(lot: Lot) {
    if (!lot.canSubscribe) {
      return;
    }
    
    this.lots.forEach(l => {
      l.active = false;
    });

    lot.active = true;
  }

  formatCurrency(value: string, currency: string): string {
    return Utils.formatCurrency(value, currency);
  }

}
