import { BanksService } from './../../../../core/services/banks/banks.service';
import { SelectTimeComponent } from './../../../../core/components/select-time/select-time.component';
import { AuthGuard } from './../../../../core/guards/auth.guard';
import { ChampionshipService } from './../../../../core/services/championship/championship.service';
import { Championship } from './../../../../core/model/championship';
import { EventsService } from './../../../../core/services/event/events.service';
import { Event } from './../../../../core/model/event';
import { Utils } from './../../../../core/utils';
import { CropDialogComponent } from './../../../../core/components/crop-dialog/crop-dialog.component';
import { EventGuard } from './../../../../core/guards/event.guard';
import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Title, DomSanitizer } from '@angular/platform-browser';
import { BreadcrumbsService } from '../../../../core/components/breadcrumbs/breadcrumbs.service';
import { FuseTranslationLoaderService } from '../../../../core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { MatIconRegistry, MatDialog } from '@angular/material';

import { locale as english } from './i18n/en-US';
import { locale as portugues } from './i18n/pt-BR';
import { TabBarComponent } from '../../../../core/components/tab-bar/tab-bar.component';
import { config } from '../../../../core/config/config';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import * as moment from 'moment';
import * as momentTimezone from 'moment-timezone';
import { GooglePlacesService } from '../../../../core/services/google-places.service';
import { AngularCropperjsComponent } from '../../../../../../node_modules/angular-cropperjs';
import {
  QuillComponent, QuillDirective,
  QuillConfigInterface, QuillModulesInterface
} from 'ngx-quill-wrapper';
import { DragulaService } from 'ng2-dragula';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs/Rx';
import { empty, Subscription } from '../../../../../../node_modules/rxjs';
import { ToastrService } from '../../../../core/components/toastr_/public_api';
import { FormErrorStateMatcher } from '../../../../core/utils/form-error-state-matcher';
import { dateValidator } from '../../../../core/utils/custom-validators';
import { CrossXDialogComponent } from '../../../../core/components/cross-x-dialog/cross-x-dialog.component';
import { Errors } from '../../../../core/model/errors';
import { BankAccountService } from '../../../../core/services/bank-account/bank-account.service';
import { } from '@types/googlemaps';
import {EventConfigurationsService} from '../../../../core/services/event-configurations/event-configurations.service';
// Drag and Drop
// https://github.com/valor-software/ng2-dragula

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {
  @ViewChild('tabbar', { read: TabBarComponent }) tabBar: TabBarComponent;
  @ViewChild('pickerTimeStart', { read: SelectTimeComponent }) pickerTimeStart: SelectTimeComponent;
  @ViewChild('pickerTimeEnd', { read: SelectTimeComponent }) pickerTimeEnd: SelectTimeComponent;
  @ViewChild('nameEventInput') nameEventInput: ElementRef;

  private subscriptions = new Subscription();

  // Translate 
  translate: any;

  // Event
  event: Event;
  eventName = '';

  // Championships
  championships: Championship[] = [];

  // Event Form
  eventForm: FormGroup;
  eventMatchers: any;

  banks = [];
  public cities = [];
  markers = [];
  placeId = '';
  timeStart = '10:00';
  timeEnd = '10:00';
  timezoneSelected: string;
  timezoneRequired = false;
  brandUrl = '';
  public brandFileToUpload: any = null;
  headerUrl = '';
  public headerFileToUpload: any = null;
  regulationFileName = '';
  public regulationFileToUpload: File = null;
  termsFileName = '';
  public termsFileToUpload: File = null;

  // Registration
  registrationForm: FormGroup;
  registrationMatchers: any;

  bankAccountForm: FormGroup;
  bankAccountMatchers: any;

  bankAccountList: any[] = [];

  personTypes: any[] = [];
  personTypeSelected: any;
  accountTypes: any[] = [];
  
  userMe: any = null;

  public configEditor: QuillConfigInterface = {
    readOnly: false,
  };
  public modules: QuillModulesInterface = {};
  private toolbar: any = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
    // [{ 'direction': 'rtl' }],                         // text direction

    // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    // [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    // [{ 'font': [] }],
    [{ 'align': [] }],
    ['link']
  ];

  // Leaderboard
  colors = {
    leaderboard: {
      bg: '#ff0000',
      rowDark: '#f00000',
      text: '#fff000',
      boxName: '#ffff00',
      highlight: '#000000'
    },
    leaderboardTv: {
      bg: '#264e0e',
      eventName: '#264e23',
      category: '#2e4e0e',
      cell: '#ff62da',
      progressBar: '#fff000'
    }
  };

  isShowBrandInLeaderboard = false;
  
  configCrop = {
    checkCrossOrigin: false
  };

  lat = -23.549063;
  lng = -46.633460;
  isShowMarker = false;

  timezoneData: any[] = [];
  filterdTimezoneData: Observable<any[]>;
  
  timeOutSearchBank;

  isLoading = false;
  isPublishLoading = false;
  isLeaderboardPublishLoading = false;
  isLoadingSteps = false;
  isBankAccountLoading = false;
  isRegistrationLoading = false;
  isLeaderboardLoading = false;

  isDisabledSteps = true;
  isShowInputBank = false;

  constructor(
    private titleService: Title,
    private breadcrumbsService: BreadcrumbsService,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authGuard: AuthGuard,
    private eventGuard: EventGuard,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private googlePlacesService: GooglePlacesService,
    public dialog: MatDialog,
    private dragulaService: DragulaService,
    private eventService: EventsService,
    private championshipService: ChampionshipService,
    private toastr: ToastrService,
    private bankAccountService: BankAccountService,
    private banksService: BanksService,
    private eventConfigurationService: EventConfigurationsService
  ) { 
    this.translationLoader.loadTranslations(english, portugues);

    this.subscriptions.add(this.translateService.get('SETTINGS').subscribe((res: any) => {
      this.translate = res;
    }));

    if (this.translate) {
      this.titleService.setTitle(`${config.titleBase} - ${this.translate.TITLE}`);

      this.breadcrumbsService.replaceAll([{
        text: this.translate.TITLE,
        route: `/settings`
      }]);
    }

    this.event = new Event().deserialize(this.eventGuard.getActiveEvent().event);
    this.eventName = this.event.name;
    this.timeStart = (this.event.datetime_start) ? moment(this.event.datetime_start).format('HH:mm') : '10:00';
    this.timeStart = (this.event.datetime_end) ? moment(this.event.datetime_end).format('HH:mm') : '10:00';
    // this.championships = this.event.championships

    this.timezoneSelected = (this.event.timezone && this.event.timezone.length > 0) ? this.event.timezone : null;
    this.isDisabledSteps = !this.authGuard.getMe().user.admin;
    
    if (this.event.championships && this.event.championships.length > 0) {
      this.event.championships.forEach(championship => {
        this.championships.push(new Championship().deserialize(championship));
      });  
    }

    this.isShowBrandInLeaderboard = this.event.isShowBrandInLeaderboard;
    
    
    // this.modules = { 'modules/focus': Focus };
    this.configEditor.modules = { toolbar: this.toolbar };
    this.configEditor.placeholder = this.translate.FIELDS.DESCRIPTION;
    
    this.userMe = this.authGuard.getMe().user;

    const bag: any = this.dragulaService.find('columns-leaderboard-tv');
    if (bag !== undefined) { this.dragulaService.destroy('columns-leaderboard-tv'); }

    dragulaService.createGroup('columns-leaderboard-tv', {
      revertOnSpill: true
    });
    
  }

  private _filterStates(value: any): any[] {
    const filterValue = (typeof value === 'string') ? Utils.removeAccents(value.toLowerCase()) : Utils.removeAccents(value.text.toLowerCase());
    return this.timezoneData.filter(timezone => timezone.text.toLowerCase().indexOf(filterValue) !== -1);
  }

  ngOnInit() {

    let tz: any;
    if (this.event.timezone) {
      const momentNames = momentTimezone.tz.names();
      const now = momentTimezone();
      
      momentNames.map((name) => {
        if (name === this.event.timezone) {
          tz = {
            text: `(GMT${now.tz(name).format('Z')}) ${name}`,
            value: name,
          };
        }
      });
      
    //  Leaderboard
      this.populateColorsWithLeaderboard();
      // colors = {
      //   leaderboard: {
      //     bg: "#ff0000",
      //     rowDark: "#f00000",
      //     text: "#fff000",
      //     boxName: '#ffff00',
      //     highlight: '#000000'
      //   },
      //   leaderboardTv: {
      //     bg: '#264e0e',
      //     eventName: '#264e23',
      //     category: '#2e4e0e',
      //     cell: '#ff62da',
      //     progressBar: '#fff000'
      //   }
      // }
    }

    // Slug
    let url = this.event.slug;
    if ((this.event.slug === null || this.event.slug.length === 0) && this.event.name) {
      const date = this.event.datetime_start ? moment(this.event.datetime_start, this.translate.DATE_FORMAT).format('YYYY') : moment().format('YYYY');
      url = `${Utils.removeAccents(this.event.name).toLowerCase().replace(/[^A-Z0-9]/ig, '')}${date}`;
    }

    // Event Form
    this.eventForm = this.formBuilder.group({
      name: new FormControl(this.event.name, [Validators.required]),
      address: [this.event.address, Validators.required],
      location: [this.event.location, Validators.required],
      timezone: [tz, Validators.required],
      dateStart: [(this.event.datetime_start) ? moment(this.event.datetime_start).format(this.translate.DATE_FORMAT) : '', [Validators.required, dateValidator(this.translate.DATE_FORMAT)]],
      dateEnd: [(this.event.datetime_end) ? moment(this.event.datetime_end).format(this.translate.DATE_FORMAT) : '', [Validators.required, dateValidator(this.translate.DATE_FORMAT)]],
      urlEvent: [url, [Validators.required, Validators.minLength(4)]],
      regulation: [''],
      terms: [''],
    });

    this.eventMatchers = {
      name: new FormErrorStateMatcher(),
      address: new FormErrorStateMatcher(),
      location: new FormErrorStateMatcher(),
      timezone: new FormErrorStateMatcher(),
      dateStart: new FormErrorStateMatcher(),
      dateEnd: new FormErrorStateMatcher(),
      urlEvent: new FormErrorStateMatcher(),
      regulation: new FormErrorStateMatcher(),
      terms: new FormErrorStateMatcher(),
    };

    // Registration Form
    this.registrationForm = this.formBuilder.group({
      boleto: [false],
      softDescription: ['', [Validators.required]],
      daysDueDate: ['']
    });

    this.registrationMatchers = {
      softDescription: new FormErrorStateMatcher(),
      daysDueDate: new FormErrorStateMatcher(),
    };
    
    this.getEventDetail();
    
    // this.router.events
    //   .subscribe((event) => {
    //     if (event instanceof NavigationEnd) {
    //       if (event.url === '/settings') {
            
    //       }
    //     }
    //   })
  
    this.filterdTimezoneData = this.eventForm.get('timezone').valueChanges
      .pipe(
        startWith(''),
        map(timezone => timezone ? this._filterStates(timezone) : this.timezoneData.slice())
      );
    

    // Registration Form
    this.personTypes = [
      {
        value: 1,
        title: this.translate.REGISTRATION.TYPES_PERSON.PHYSICAL
      },
      {
        value: 0,
        title: this.translate.REGISTRATION.TYPES_PERSON.LEGAL
      }
    ];

    this.accountTypes = [
      {
        value: 'conta_corrente',
        title: this.translate.REGISTRATION.TYPES_ACCOUNT.CC
      },
      {
        value: 'conta_poupanca',
        title: this.translate.REGISTRATION.TYPES_ACCOUNT.CP
      },
      {
        value: 'conta_corrente_conjunta',
        title: this.translate.REGISTRATION.TYPES_ACCOUNT.CCC
      },
      {
        value: 'conta_poupanca_conjunta',
        title: this.translate.REGISTRATION.TYPES_ACCOUNT.CPC
      }
    ];

    this.personTypeSelected = this.personTypes[0];

    this.bankAccountForm = this.formBuilder.group({
      bankAccount: [this.event.user_bank_account ? this.event.user_bank_account : '', [Validators.required]],
      personType: [this.personTypes[0], Validators.required],
      name: ['', Validators.required],
      documentNumber: ['', Validators.required],
      bank: ['', [Validators.required, Validators.minLength(3)]],
      accountType: [this.accountTypes[0], Validators.required],
      agency: ['', [Validators.required, Validators.maxLength(5)]],
      agencyDv: [''],
      account: ['', [Validators.required, Validators.maxLength(13)]],
      accountDv: ['', [Validators.required, Validators.maxLength(2)]],
    });

    this.bankAccountMatchers = {
      bankAccount: new FormErrorStateMatcher(),
      personType: new FormErrorStateMatcher(),
      name: new FormErrorStateMatcher(),
      documentNumber: new FormErrorStateMatcher(),
      bank: new FormErrorStateMatcher(),
      accountType: new FormErrorStateMatcher(),
      agency: new FormErrorStateMatcher(),
      account: new FormErrorStateMatcher(),
      accountDv: new FormErrorStateMatcher(),
    };

    this.getBankAccountList();

    //Timezones
    this.getTimezones();

    // Inputs value changes
    this.inputValueChanged();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  
  inputValueChanged(): any {
    this.subscriptions.add(this.eventForm.get('name').valueChanges.subscribe(value => {
      this.eventName = (value.length > 0) ? value.toUpperCase() : '';

      if (this.event.slug === null || this.event.slug.length === 0) {
        const date = this.eventForm.value.dateStart ? moment(this.eventForm.value.dateStart, this.translate.DATE_FORMAT).format('YYYY') : moment().format('YYYY');
        const url = `${Utils.removeAccents(value).toLowerCase().replace(/[^A-Z0-9]/ig, '')}${date}`;
        this.eventForm.controls['urlEvent'].setValue(url);
      }
    }));
    
    this.subscriptions.add(this.registrationForm.get('boleto').valueChanges.subscribe(value => {
      if (value) {
        this.registrationForm.controls['daysDueDate'].setValidators([Validators.required]);
      }
      else {
        this.registrationForm.controls['daysDueDate'].clearValidators();
        this.registrationForm.controls['daysDueDate'].setErrors(null);
      }
    }));

    this.subscriptions.add(this.bankAccountForm.get('bankAccount').valueChanges.subscribe(value => {
      this.isShowInputBank = (value !== undefined && value.type === 'create-new');

      if (this.isShowInputBank) {
        this.bankAccountForm.controls['personType'].setValidators([Validators.required]);
        this.bankAccountForm.controls['name'].setValidators([Validators.required]);
        this.bankAccountForm.controls['documentNumber'].setValidators([Validators.required]);
        this.bankAccountForm.controls['bank'].setValidators([Validators.required, Validators.minLength(3)]);
        this.bankAccountForm.controls['accountType'].setValidators([Validators.required]);
        this.bankAccountForm.controls['agency'].setValidators([Validators.required, Validators.maxLength(5)]);
        this.bankAccountForm.controls['account'].setValidators([Validators.required, Validators.maxLength(13)]);
        this.bankAccountForm.controls['accountDv'].setValidators([Validators.required, Validators.maxLength(2)]);
      }
      else {
        Object.keys(this.bankAccountForm.controls).forEach(field => {
          if (field !== 'bankAccount') {
            const control = this.bankAccountForm.get(field);
            control.clearValidators();
            control.setErrors(null);
          }
        });
      }
    }));

    this.subscriptions.add(this.bankAccountForm.valueChanges.subscribe(() => {
    }));

  }

  getEventDetail() {
    // Get Event details
    this.subscriptions.add(this.eventService.getEventInformation(this.event.id)
      .subscribe(
        result => {
          this.event = new Event().deserialize(result);
          this.eventName = this.event.name;
          if (this.event.datetime_start) {
            this.timeStart = moment(this.event.datetime_start).format('HH:mm');
            this.pickerTimeStart.updateValue(this.timeStart);
          }

          if (this.event.datetime_end) {
            this.timeEnd = moment(this.event.datetime_end).format('HH:mm');
            this.pickerTimeEnd.updateValue(this.timeEnd);
          }
          // this.timeStart = (this.event.datetime_start) ? moment(this.event.datetime_start.date).format('HH:mm') : '10:00'
          // this.timeEnd = (this.event.datetime_end) ? moment(this.event.datetime_end.date).format('HH:mm') : '10:00'
          this.championships = this.event.championships;

          if (this.event.email_logo !== null && this.event.email_logo.length > 0) {
            this.brandUrl = this.event.email_logo;
          }

          if (this.event.header_image !== null && this.event.header_image.length > 0) {
            this.headerUrl = this.event.header_image;
          }

          if (this.event.place_id && this.event.place_id.length > 0) {
            this.placeId = this.event.place_id;
            this.subscriptions.add(this.googlePlacesService.placeIdDetail(this.event.place_id)
              .subscribe(
                result => {
                  this.lat = result.geometry.location.lat();
                  this.lng = result.geometry.location.lng();
                  this.isShowMarker = true;

                }, err => {

                }
              ));
          }

          this.populateColorsWithLeaderboard();

          let tz: any;
          if (result.timezone) {
            const momentNames = momentTimezone.tz.names();
            const now = momentTimezone();

            momentNames.map((name) => {
              if (name === result.timezone) {
                tz = {
                  text: `(GMT${now.tz(name).format('Z')}) ${name}`,
                  value: name,
                };
              }
            });
          }

          this.eventForm.controls['name'].setValue((result.name) ? result.name : this.eventForm.value.name);
          this.eventForm.controls['address'].setValue((result.address) ? result.address : this.eventForm.value.address);
          this.eventForm.controls['location'].setValue((result.location) ? result.location : this.eventForm.value.location);
          this.eventForm.controls['timezone'].setValue((tz) ? tz : this.eventForm.value.timezone);
          this.eventForm.controls['urlEvent'].setValue((result.slug) ? result.slug : this.eventForm.value.urlEvent);
          this.nameEventInput.nativeElement.focus();
          

          this.event.paymentMethods.forEach(paymentMethod => {
            if (paymentMethod.name === 'boleto') {
              this.registrationForm.controls['boleto'].setValue(true);
              this.registrationForm.controls['daysDueDate'].setValue(paymentMethod.boleto_days_due);
            }
            else if (paymentMethod.name === 'credit_card') {
              this.registrationForm.controls['softDescription'].setValue(paymentMethod.soft_descriptor);
            }
          });
          


        }, err => { }
      ));
  }

  getBankAccountList(): any {
    this.subscriptions.add(this.bankAccountService.getAccounts(this.event.user.id)
      .subscribe(
        result => {

          this.bankAccountList.push({
            id: 0,
            type: 'create-new',
            bank_id: '',
            agency: '',
            account_number: '',
            legal_name: this.translate.REGISTRATION.NEW_ACCOUNT,
          });
          
          let ba: any = null;
          result.forEach(account => {
            this.bankAccountList.push(account);
            if (this.event.user_bank_account && account.id === this.event.user_bank_account.id) {
              ba = account;
            }
          });

          if (ba) { this.bankAccountForm.controls['bankAccount'].setValue(ba); }
        }, err => {
        }
      ));
  }

  // Update event detail
  updateEventDetail() {
    // validate all form fields
    if (!this.eventForm.valid) {
      Utils.formValid(this.eventForm);
      return;
    } 

    // if (this.eventForm.value.dateStart && !this.timezoneSelected) {
    //   this.timezoneRequired = true
    //   return
    // }

    // if (this.eventForm.value.dateEnd && !this.timezoneSelected) {
    //   this.timezoneRequired = true
    //   return
    // }
    
    const event = {
      // _method: 'PUT',
      name: this.eventForm.value.name.toUpperCase(),
      address: this.eventForm.value.address,
      place_id: this.placeId,
      location: this.eventForm.value.location,
      timezone: this.eventForm.value.timezone.value,
      slug: this.eventForm.value.urlEvent,
      description: Utils.removeParagraphInText(this.event.description),
    };

    if (this.eventForm.value.dateStart) {
      const dateTimeStart = moment(`${this.eventForm.value.dateStart} ${this.timeStart}:00`, this.translate.DATETIME_FORMAT);
      if (dateTimeStart.isValid()) {
        event['datetime_start'] = dateTimeStart.format('YYYY-MM-DD HH:mm:ss');
        this.eventForm.controls['dateStart'].setErrors(null);
      }
      else {
        this.eventForm.controls['dateStart'].setErrors({
          invalid: true
        });
      }
    }

    if (this.eventForm.value.dateEnd) {
      const dateTimeEnd = moment(`${this.eventForm.value.dateEnd} ${this.timeEnd}:00`, this.translate.DATETIME_FORMAT);
      if (dateTimeEnd.isValid()) {
        event['datetime_end'] = dateTimeEnd.format('YYYY-MM-DD HH:mm:ss');
        this.eventForm.controls['dateEnd'].setErrors(null);
      } else {
        this.eventForm.controls['dateEnd'].setErrors({
          invalid: true
        });
      }
    }
    

    const media = {
      brand: this.brandFileToUpload,
      header: this.headerFileToUpload,
      term: this.termsFileToUpload,
      regulation: this.regulationFileToUpload
    };

    this.isLoading = true;
    this.subscriptions.add(this.eventService.updateEvent(this.event.id, event, media)
      .subscribe(
        result => {
          this.isLoading = false;
          this.eventGuard.updateEventInformation(result);
          // this.eventForm.controls['dateStart'].setErrors(null)
          // this.eventForm.controls['dateEnd'].setErrors(null)

          this.toastr.show(
            this.translate.TOASTR.UPDATE.SUCCESS.DESCRIPTION,
            this.translate.TOASTR.UPDATE.SUCCESS.TITLE,
            null,
            'success',
          );
        }, err => {
          this.isLoading = false;

          const errors: Errors = new Errors().deserialize((err.error as any));
          this.toastr.show(
            errors.getFullMessages(),
            this.translate.TOASTR.UPDATE.ERROR.TITLE,
            null,
            'error',
          );
        }
      ));
  }

  publishOrUnpublishEvent() {
    if (!this.eventForm.valid) { return; }
    if (this.event.published) { this.unpublishEvent(); }
    else { this.publishEvent(); }
  }

  publishEvent() {
    if (!this.eventForm.valid) {
      Utils.formValid(this.eventForm);
      return;
    }

    this.dialog.openDialogs.pop();
    const dialogRef = this.dialog.open(CrossXDialogComponent, {
      width: '440px',
      closeOnNavigation: true
    });

    dialogRef.componentInstance.type = 'warning';
    this.subscriptions.add(this.translateService.get('SETTINGS.EVENT_PUBLISH_DIALOG.TITLE', { action: this.translate.EVENT_PUBLISH_DIALOG.PUBLISH }).subscribe((res: string) => {
      dialogRef.componentInstance.titleDialog = res;
    }));
    this.translateService.get('SETTINGS.EVENT_PUBLISH_DIALOG.DESCRIPTION', { action: this.translate.EVENT_PUBLISH_DIALOG.PUBLISH }).subscribe((res: string) => {
      dialogRef.componentInstance.dialogContent = res;
    });
    dialogRef.componentInstance.dialogConfirm = true;
    dialogRef.componentInstance.cancelButton = this.translate.CANCEL.toUpperCase();
    dialogRef.componentInstance.actionButton = this.translate.EVENT_PUBLISH_DIALOG.PUBLISH.toUpperCase();
    dialogRef.componentInstance.object = this.event;
    this.subscriptions.add(dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isPublishLoading = true;

        const event = {
          // _method: 'PUT',
          name: this.eventForm.value.name.toUpperCase(),
          address: this.eventForm.value.address,
          place_id: this.placeId,
          location: this.eventForm.value.location,
          timezone: this.eventForm.value.timezone.value,
          slug: this.eventForm.value.urlEvent,
          description: this.event.description,
          published: 1,
        };

        if (this.eventForm.value.dateStart) {
          const dateTimeStart = moment(`${this.eventForm.value.dateStart} ${this.timeStart}:00`, this.translate.DATETIME_FORMAT);
          if (dateTimeStart.isValid()) {
            event['datetime_start'] = dateTimeStart.format('YYYY-MM-DD HH:mm:ss');
            this.eventForm.controls['dateStart'].setErrors({
              invalid: false
            });
          }
          else {
            this.eventForm.controls['dateStart'].setErrors({
              invalid: true
            });
          }
        }

        if (this.eventForm.value.dateEnd) {
          const dateTimeEnd = moment(`${this.eventForm.value.dateEnd} ${this.timeEnd}:00`, this.translate.DATETIME_FORMAT);
          if (dateTimeEnd.isValid()) {
            event['datetime_end'] = dateTimeEnd.format('YYYY-MM-DD HH:mm:ss');
            this.eventForm.controls['dateEnd'].setErrors({
              invalid: false
            });
          } else {
            this.eventForm.controls['dateEnd'].setErrors({
              invalid: true
            });
          }
        }
        

        const media = {
          brand: this.brandFileToUpload,
          header: this.headerFileToUpload,
          term: this.termsFileToUpload,
          regulation: this.regulationFileToUpload
        };

        this.subscriptions.add(this.eventService.updateEvent(this.event.id, event, media)
          .subscribe(
            result => {
              this.isPublishLoading = false;
              this.event.published = true;
              this.eventGuard.updateEventInformation(this.event);

              this.toastr.show(
                this.translate.TOASTR.PUBLISH_DESCRIPTION,
                this.translate.TOASTR.PUBLISH_TITLE,
                null,
                'success',
              );
            }, err => {
              this.isPublishLoading = false;

              const errors: Errors = new Errors().deserialize((err.error as any));
              this.toastr.show(
                errors.getFullMessages(),
                this.translate.TOASTR.ERROR.TITLE,
                null,
                'error',
              );
            }
          ));
        
      }
    }));
  }
  
  unpublishEvent() {
    this.dialog.openDialogs.pop();
    const dialogRef = this.dialog.open(CrossXDialogComponent, {
      width: '440px',
      closeOnNavigation: true
    });

    dialogRef.componentInstance.type = 'warning';
    this.subscriptions.add(this.translateService.get('SETTINGS.EVENT_PUBLISH_DIALOG.TITLE', { action: this.translate.EVENT_PUBLISH_DIALOG.UNPUBLISH }).subscribe((res: string) => {
      dialogRef.componentInstance.titleDialog = res;
    }));
    this.subscriptions.add(this.translateService
        .get('SETTINGS.EVENT_PUBLISH_DIALOG.DESCRIPTION', { action: this.translate.EVENT_PUBLISH_DIALOG.UNPUBLISH }).subscribe((res: string) => {
          dialogRef.componentInstance.dialogContent = res;
        })
    );
    dialogRef.componentInstance.dialogConfirm = true;
    dialogRef.componentInstance.cancelButton = this.translate.CANCEL.toUpperCase();
    dialogRef.componentInstance.actionButton = this.translate.EVENT_PUBLISH_DIALOG.UNPUBLISH.toUpperCase();
    dialogRef.componentInstance.object = this.event;
    this.subscriptions.add(dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isPublishLoading = true;

        const parameters = {
          published: 0
        };

        this.subscriptions.add(this.eventService.updateEvent(this.event.id, parameters)
          .subscribe(
            result => {
              this.isPublishLoading = false;
              this.event.published = false;
              this.eventGuard.updateEventInformation(this.event);

              this.toastr.show(
                this.translate.TOASTR.UNPUBLISH_DESCRIPTION,
                this.translate.TOASTR.UNPUBLISH_TITLE,
                null,
                'success',
              );
            }, err => {
              this.isPublishLoading = false;

              const errors: Errors = new Errors().deserialize((err.error as any));
              this.toastr.show(
                errors.getFullMessages(),
                this.translate.TOASTR.ERROR.TITLE,
                null,
                'error',
              );
            }
          ));
        
      }
    }));
  }

  publishOrUnpublishLeaderboadEvent() {
    if (this.event.leaderboard_published) { this.unpublishLeaderboardEvent(); }
    else { this.publishLeaderboardEvent(); }
  }

  publishLeaderboardEvent() {
    if (!this.eventForm.valid) {
      Utils.formValid(this.eventForm);
      return;
    }

    this.dialog.openDialogs.pop();
    const dialogRef = this.dialog.open(CrossXDialogComponent, {
      width: '440px',
      closeOnNavigation: true
    });

    dialogRef.componentInstance.type = 'warning';
    this.subscriptions.add(
      this.translateService.get('SETTINGS.LEADERBOARD.PUBLISH_DIALOG.TITLE', { action: this.translate.LEADERBOARD.PUBLISH_DIALOG.PUBLISH }).subscribe((res: string) => {
        dialogRef.componentInstance.titleDialog = res;
      })
    );
    this.subscriptions.add(
      this.translateService.get('SETTINGS.LEADERBOARD.PUBLISH_DIALOG.DESCRIPTION', { action: this.translate.LEADERBOARD.PUBLISH_DIALOG.PUBLISH }).subscribe((res: string) => {
        dialogRef.componentInstance.dialogContent = res;
      })
    );
    dialogRef.componentInstance.dialogConfirm = true;
    dialogRef.componentInstance.cancelButton = this.translate.CANCEL.toUpperCase();
    dialogRef.componentInstance.actionButton = this.translate.LEADERBOARD.PUBLISH_DIALOG.PUBLISH.toUpperCase();
    dialogRef.componentInstance.object = this.event;
    this.subscriptions.add(dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isLeaderboardPublishLoading = true;

        const event = {
          leaderboard_published: 1,
        };
        

        const media = {
          brand: null,
          header: null,
          term: null,
          regulation: null
        };

        this.subscriptions.add(this.eventService.updateEvent(this.event.id, event, media)
          .subscribe(
            result => {
              this.isLeaderboardPublishLoading = false;
              this.event.leaderboard_published = true;
              this.eventGuard.updateEventInformation(this.event);

              this.toastr.show(
                this.translate.LEADERBOARD.TOASTR.PUBLISH_DESCRIPTION,
                this.translate.LEADERBOARD.TOASTR.PUBLISH_TITLE,
                null,
                'success',
              );
            }, err => {
              this.isLeaderboardPublishLoading = false;

              const errors: Errors = new Errors().deserialize((err.error as any));
              this.toastr.show(
                errors.getFullMessages(),
                this.translate.TOASTR.ERROR.TITLE,
                null,
                'error',
              );
            }
          ));
      }
    }));
  }
  
  unpublishLeaderboardEvent() {
    this.dialog.openDialogs.pop();
    const dialogRef = this.dialog.open(CrossXDialogComponent, {
      width: '440px',
      closeOnNavigation: true
    });

    dialogRef.componentInstance.type = 'warning';
    this.subscriptions.add(
      this.translateService.get('SETTINGS.LEADERBOARD.PUBLISH_DIALOG.TITLE', { action: this.translate.LEADERBOARD.PUBLISH_DIALOG.UNPUBLISH }).subscribe((res: string) => {
        dialogRef.componentInstance.titleDialog = res;
      })
    );
    this.subscriptions.add(
      this.translateService.get('SETTINGS.LEADERBOARD.PUBLISH_DIALOG.DESCRIPTION', { action: this.translate.LEADERBOARD.PUBLISH_DIALOG.UNPUBLISH }).subscribe((res: string) => {
        dialogRef.componentInstance.dialogContent = res;
      })
    );
    dialogRef.componentInstance.dialogConfirm = true;
    dialogRef.componentInstance.cancelButton = this.translate.CANCEL.toUpperCase();
    dialogRef.componentInstance.actionButton = this.translate.LEADERBOARD.PUBLISH_DIALOG.UNPUBLISH.toUpperCase();
    dialogRef.componentInstance.object = this.event;
    this.subscriptions.add(dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isLeaderboardPublishLoading = true;

        const parameters = {
          leaderboard_published: 0
        };

        this.subscriptions.add(this.eventService.updateEvent(this.event.id, parameters)
          .subscribe(
            result => {
              this.isLeaderboardPublishLoading = false;
              this.event.leaderboard_published = false;
              this.eventGuard.updateEventInformation(this.event);

              this.toastr.show(
                this.translate.LEADERBOARD.TOASTR.UNPUBLISH_DESCRIPTION,
                this.translate.LEADERBOARD.TOASTR.UNPUBLISH_TITLE,
                null,
                'success',
              );
            }, err => {
              this.isLeaderboardPublishLoading = false;

              const errors: Errors = new Errors().deserialize((err.error as any));
              this.toastr.show(
                errors.getFullMessages(),
                this.translate.TOASTR.ERROR.TITLE,
                null,
                'error',
              );
            }
          ));
      }
    }));
  }

  // Payment Method
  savePaymentMethods()
  {
    // validate all form fields
    if (!this.registrationForm.valid) {
      Utils.formValid(this.registrationForm);
      return;
    }

    const parameters = {
      event_id: this.event.id,
      soft_descriptor: this.registrationForm.value.softDescription.toUpperCase()
    };

    if (this.registrationForm.value.boleto) {
      if (parseInt(this.registrationForm.value.daysDueDate) < 2) {
        this.registrationForm.controls['daysDueDate'].setErrors({
          invalid: true
        });
        return;
      } else {
        this.registrationForm.controls['daysDueDate'].setErrors(null);
      }

      parameters['boleto_days_due'] = this.registrationForm.value.daysDueDate;
    }
    

    this.isRegistrationLoading = true;
    this.subscriptions.add(this.eventService.updatePaymentMethods(parameters)
      .subscribe(
        result => {
          this.isRegistrationLoading = false;

          this.toastr.show(
            this.translate.REGISTRATION.TOASTR.PAYMENT_METHODS.DESCRIPTION,
            this.translate.REGISTRATION.TOASTR.PAYMENT_METHODS.TITLE,
            null,
            'success',
          );

          this.getEventDetail();
        }, err => {
          this.isRegistrationLoading = false;

          const errors: Errors = new Errors().deserialize((err.error as any));
          this.toastr.show(
            errors.getFullMessages(),
            this.translate.REGISTRATION.TOASTR.PAYMENT_METHODS.ERROR,
            null,
            'error',
          );
        }
      ));
    
  }

  // Bank Account
  saveBankAccount()
  {
    // validate all form fields
    if (!this.bankAccountForm.valid) {
      Utils.formValid(this.bankAccountForm);
      return;
    }

    let parameters: any = null;
    if (this.bankAccountForm.value.bankAccount && this.bankAccountForm.value.bankAccount.id === 0) {
      const documentNumber = this.bankAccountForm.value.documentNumber.replace(/[^0-9]/, '');
      
      parameters = {
        user_id: this.event.user.id,
        event_id: this.event.id,
        type: this.bankAccountForm.value.accountType.value,
        bank_id: this.bankAccountForm.value.bank.id,
        agency: this.bankAccountForm.value.agency,
        agency_digit: this.bankAccountForm.value.agencyDv,
        account_number: this.bankAccountForm.value.account,
        account_digit: this.bankAccountForm.value.accountDv,
        document_number: documentNumber,
        legal_name: this.bankAccountForm.value.name,
      };
      
      this.isBankAccountLoading = true;
      this.subscriptions.add(this.bankAccountService.create(parameters)
        .subscribe(
          result => {
            this.isBankAccountLoading = false;
            this.bankAccountList.push(result);

            this.bankAccountForm.controls['bankAccount'].setValue(result);

            this.toastr.show(
              this.translate.REGISTRATION.TOASTR.BANKACCOUNT.CREATE.DESCRIPTION,
              this.translate.REGISTRATION.TOASTR.BANKACCOUNT.CREATE.TITLE,
              null,
              'success',
            );

            this.getEventDetail();
          }, err => {
            this.isBankAccountLoading = false;

            const errors: Errors = new Errors().deserialize((err.error as any));
            this.toastr.show(
              errors.getFullMessages(),
              this.translate.REGISTRATION.TOASTR.BANKACCOUNT.CREATE.ERROR,
              null,
              'error',
            );
          }
        ));
    }
    else {
      parameters = {
        user_bank_account_id: this.bankAccountForm.value.bankAccount.id 
      };
      

      this.isBankAccountLoading = true;
      this.subscriptions.add(this.eventService.updateEvent(this.event.id, parameters)
        .subscribe(
          result => {
            this.isBankAccountLoading = false;
            this.eventGuard.updateEventInformation(result);
            this.eventForm.controls['dateStart'].setErrors(null);
            this.eventForm.controls['dateEnd'].setErrors(null);

            this.toastr.show(
              this.translate.REGISTRATION.TOASTR.BANKACCOUNT.UPDATE.DESCRIPTION,
              this.translate.REGISTRATION.TOASTR.BANKACCOUNT.UPDATE.TITLE,
              null,
              'success',
            );
          }, err => {
            this.isBankAccountLoading = false;

            const errors: Errors = new Errors().deserialize((err.error as any));
            this.toastr.show(
              errors.getFullMessages(),
              this.translate.REGISTRATION.TOASTR.BANKACCOUNT.UPDATE.ERROR,
              null,
              'error',
            );
          }
        ));
    }
    
  }


  displayFnTimezone(timezone: any): string {
    return timezone ? timezone.text : timezone;
  }

  displayFnBank(bank: any): string {
    return bank ? `${bank.code} - ${bank.title}` : bank;
  }
  
  // Description Value change
  public onValueChange(value: string): void {
  }


  startTimeChange(time) {
    this.timeStart = time;
  }

  endTimeChange(time) {
    this.timeEnd = time;
  }

  readUrl(event: any, type: string) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();

      reader.onload = (event: any) => {
        if (type === 'brand') {

          const modalRef = this.dialog.open(CropDialogComponent, {
            width: '816px',
            height: '100%',
            panelClass: 'modal'
          });

          modalRef.componentInstance.image = event.target.result;
          modalRef.componentInstance.configCrop = {
            checkCrossOrigin: false,
            viewMode: 0,
            dragMode: 'move',
            zoomOnWheel: true,
            zoomable: true,
            aspectRatio: 500 / 500,
            cropBoxResizable: true,
            cropBoxMovable: true,
            ready: function (e) {
            },
            cropstart: function (e) {
            },
            cropmove: function (e) {
            },
            cropend: function (e) {
            },
            crop: function (e) {
              const data = e.detail;
              
            },
            zoom: function (e) {
            }
          };

          this.subscriptions.add(modalRef.afterClosed().subscribe(result => {

            if (result !== null) {
              this.brandUrl = result.image;
              this.brandFileToUpload = result.blob;
            }
          }));
        }
        else if (type === 'header') {
          // this.headerUrl = event.target.result

          const modalRef = this.dialog.open(CropDialogComponent, {
            width: '816px',
            height: '100%',
            panelClass: 'modal'
          });

          modalRef.componentInstance.image = event.target.result;
          modalRef.componentInstance.configCrop = {
            checkCrossOrigin: false,
            viewMode: 0,
            dragMode: 'move',
            zoomOnWheel: true,
            zoomable: true,
            aspectRatio: 1000 / 320,
            cropBoxResizable: true,
            cropBoxMovable: true
          };
          modalRef.componentInstance.width = 1000;
          modalRef.componentInstance.height = 320;
          modalRef.componentInstance.minWidth = 1000;
          modalRef.componentInstance.minHeight = 320;

          this.subscriptions.add(modalRef.afterClosed().subscribe(result => {

            if (result !== null) {
              this.headerUrl = result.image;
              this.headerFileToUpload = result.blob;
            }
          }));
        }
        else if (type === 'regulation') {
          this.regulationFileName = event.target.files[0].name;
        }
        else if (type === 'terms') {
          this.termsFileName = event.target.files[0].name;
        }
      };

      

      if (type === 'brand') {
        this.brandFileToUpload = event.target.files[0];  
      }
      else if (type === 'header') {
        this.headerFileToUpload = event.target.files[0];
      }
      else if (type === 'regulation') {
        this.regulationFileName = event.target.files[0].name;
        this.regulationFileToUpload = event.target.files[0];
      }
      else if (type === 'terms') {
        this.termsFileName = event.target.files[0].name;
        this.termsFileToUpload = event.target.files[0];
      }
    
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  // Search Bank
  searchBank(bank: string) {
    clearTimeout(this.timeOutSearchBank);
    this.timeOutSearchBank = setTimeout(() => {
      if (bank.length > 0) {
        this.subscriptions.add(this.banksService.search(bank)
          .subscribe(
            result => {
              this.banks = result;
            }, err => {
              this.banks = [];
            }
          ));
      } else {
        this.banks = [];
      }
    }, 300);
      
  }

  searchAddress(city: string) {
    if (city && city.length > 2) {

      this.subscriptions.add(this.googlePlacesService.search(city)
        .subscribe(
          result => {
            this.cities = result;
          }, err => {
            this.cities = [];

          }
        ));
    }
  }

  addressSelected(event) {
    this.placeId = event.place_id;
    this.subscriptions.add(this.googlePlacesService.placeIdDetail(event.place_id)
      .subscribe(
        result => {
          this.lat = result.geometry.location.lat();
          this.lng = result.geometry.location.lng();
          this.isShowMarker = true;

        }, err => {

        }
      ));
  }

  setTimezoneSelected(event) {
    this.timezoneSelected = event.value;
  }

  // REGISTRATION
  personTypeSelect(event) {
    this.personTypeSelected = event.value;
    this.bankAccountForm.get('documentNumber').reset();
    this.bankAccountForm.controls['documentNumber'].setErrors(null);
  }


  // Get Timezone
  getTimezones()
  {
    const momentNames = momentTimezone.tz.names();
    const now = momentTimezone();

    this.timezoneData = momentNames.map((name) => {
      return {
        text: `(GMT${now.tz(name).format('Z')}) ${name}`,
        value: name,
      };
    });
    
  }

  // Add Championship
  addChampionship() {
    const champ = new Championship();
    champ.name = '';
    champ.online = false;
    champ.type = 1;
    this.championships.push(champ);
  }

  removeChampionship(index: number) {
    this.championships.splice(index, 1);
  }

  
  saveChampionships() {
    this.isLoadingSteps = true;

    if (this.championships.length > 0) {
      let champError = false;
      this.championships.forEach(championship => {
        if (championship.name) { championship.name.toUpperCase(); }
        if (!championship.name || championship.name.length === 0) {
          championship.error = this.translate.STEPS.VALIDATION.NAME_REQUIRED;
          champError = true;
          this.isLoadingSteps = false;
        }
      });
      if (champError) { return; }
    }


    const championships = {
      championships: this.championships
    };

    this.subscriptions.add(this.championshipService.updateChampionships(championships, this.event.id)
      .subscribe(
        result => {
          this.isLoadingSteps = false;
          this.eventGuard.updateEventChampionships(result);

          if (result.length > 0) {
            const newChampionships: Championship[] = [];
            result.forEach(championship => {
              newChampionships.push(new Championship().deserialize(championship));
            });

            this.championships = newChampionships;

            this.toastr.show(
              this.translate.STEPS.RESPONSE.DESCRIPTION,
              this.translate.STEPS.RESPONSE.TITLE,
              null,
              'success',
            );
          }

        }, err => {
          this.isLoadingSteps = false;
        }
      ));
  }

  private populateColorsWithLeaderboard() {
    if (this.event.event_configurations && this.event.event_configurations.length > 0)  {
      this.event.event_configurations.forEach(config => {
        if (config.name === 'leaderboard-background-color') { this.colors.leaderboard.bg = config.value; }
        else if (config.name === 'leaderboard-dark-color') { this.colors.leaderboard.rowDark = config.value; }
        else if (config.name === 'leaderboard-light-color') { this.colors.leaderboard.text = config.value; }
        else if (config.name === 'leaderboard-box-name-color') { this.colors.leaderboard.boxName = config.value; }
        else if (config.name === 'leaderboard-highlight-color') { this.colors.leaderboard.highlight = config.value; }
      });
    }
  }
  
  saveLeaderboardColors() {

    for (const leaderboardKey in this.colors.leaderboard) {
      if (this.colors.leaderboard[leaderboardKey].length === 0) {
        this.toastr.show(
          this.translate.LEADERBOARD.TOASTR.LEADERBOARD.ERROR.DESCRIPTION,
          this.translate.LEADERBOARD.TOASTR.LEADERBOARD.ERROR.TITLE,
          null,
          'error',
        );
        
        return;
      } 
    }
    
    const parameters = {
      configurations: [
        {
          name: 'leaderboard-background-color',
          value: this.colors.leaderboard.bg
        },
        {
          name: 'leaderboard-dark-color',
          value: this.colors.leaderboard.rowDark
        },
        {
          name: 'leaderboard-light-color',
          value: this.colors.leaderboard.text
        },
        {
          name: 'leaderboard-box-name-color',
          value: this.colors.leaderboard.boxName
        },
        {
          name: 'leaderboard-highlight-color',
          value: this.colors.leaderboard.highlight
        },
        {
          name: 'leaderboard-show-brand',
          value: this.isShowBrandInLeaderboard
        }
      ]
    };
    
    this.isLeaderboardLoading = true;
    this.subscriptions.add(this.eventConfigurationService.updateConfigs(parameters, this.event.id)
      .subscribe(
        result => {
          this.isLeaderboardLoading = false;
          
          this.toastr.show(
            this.translate.LEADERBOARD.TOASTR.LEADERBOARD.SUCCESS.DESCRIPTION,
            this.translate.LEADERBOARD.TOASTR.LEADERBOARD.SUCCESS.TITLE,
            null,
            'success',
          );
        }, err => {
          this.isLeaderboardLoading = false;

          const errors: Errors = new Errors().deserialize((err.error as any));
          this.toastr.show(
            errors.getFullMessages(),
            this.translate.LEADERBOARD.TOASTR.LEADERBOARD.ERROR.TITLE,
            null,
            'error',
          );
        }
      ));
  }
}
