import { ModalSampleComponent } from './../../sample-content/modal-sample/modal-sample.component';
import { Tag } from './../../../../core/components/tag/tag';
import { Component } from '@angular/core';
import { FuseTranslationLoaderService } from '../../../../core/services/translation-loader.service';
import { Title } from '@angular/platform-browser';

import { locale as english } from './i18n/en-US';
import { locale as portuguese } from './i18n/pt-BR';
import { config } from './../../../../core/config/config';
import { Breadcrumb } from '../../../../core/components/breadcrumbs/breadcrumb';
import { BreadcrumbsService } from '../../../../core/components/breadcrumbs/breadcrumbs.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material';
import { CrossXDialogComponent } from '../../../../core/components/cross-x-dialog/cross-x-dialog.component';
import { ScrollStrategyOptions, Overlay } from '@angular/cdk/overlay';

@Component({
    selector   : 'fuse-sample',
    templateUrl: './sample.component.html',
    styleUrls  : ['./sample.component.scss']
})
export class FuseSampleComponent
{
    tags: Tag[] = [
        {
            id: "1",
            text: "São Paulo"
        },
        {
            id: "1",
            text: "Rio de Janeiro"
        },
        {
            id: "1",
            text: "New York"
        }
    ]

    breadcrumbs: Breadcrumb[] = this.getBreadCrumbs()

    //FORMS
    form: FormGroup;
    formErrors: any;

    selects = [
        {
            value: 'steak-0',
            viewValue: 'Steak'
        },
        {
            value: 'pizza-1',
            viewValue: 'Pizza'
        },
        {
            value: 'tacos-2',
            viewValue: 'Tacos'
        }
    ];

    modalRef = null;

    constructor(
        private translationLoader: FuseTranslationLoaderService,
        private titleService: Title,
        private breadcrumbsService: BreadcrumbsService,
        private formBuilder: FormBuilder,
        public dialog: MatDialog,
        private overlay: Overlay
    )
    {
      this.translationLoader.loadTranslations(english, portuguese);

      // Reactive form errors
      this.formErrors = {
          input: {},
          textArea2: {},
          email: {},
          maxCount: {},
      };
    }

    ngOnInit() {
        this.titleService.setTitle(`${config.titleBase} - Components`)

        this.breadcrumbsService.replaceAll([{
            text: "Components",
            route: "/components"
        }])

        // Reactive Form
        this.form = this.formBuilder.group({
            input: ['', Validators.required],
            textArea2: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            maxCount: ['', [Validators.required]],
        });

        this.form.valueChanges.subscribe(() => {
            this.onFormValuesChanged();
        });
    }

    //TAG
    tagDelete(tag: any)
    {
        this.tags.splice(tag.index, 1);
    }

    addTag()
    {
        let tag: Tag = {
            id: "0",
            text: `NOVA TAG - ${this.tags.length+1}`,
        }
        this.tags.push(tag)
    }

    //Breadcrumbs
    getBreadCrumbs(): Breadcrumb[]
    {
        return [
            {
                text: "Atletas",
                route: "/login"
            },
            {
                text: "Rodrigo Priolo",
                route: "/user/rodrigopriolo"
            }
        ]
    }

    addBreadcrumbs()
    {
        this.breadcrumbsService.addItem({
            text: `Novo item(${this.breadcrumbs.length+1})`,
            route: "/login"
        })
    }

    removeLastBreadcrumbs()
    {
        this.breadcrumbsService.removeLastItem()
    }

    removeAllBreadcrumbs()
    {
        this.breadcrumbsService.removeAll()
    }

    replaceAllBreadcrumbs()
    {
        this.breadcrumbsService.replaceAll([
            {
                text: "Novo 1",
                route: "/login"
            },
            {
                text: "Novo 2",
                route: "/login"
            },
            {
                text: "Novo 3",
                route: "/login"
            }
        ])
    }

    //FORMS
    onFormValuesChanged() {
        for (const field in this.formErrors) {
            
            if (!this.formErrors.hasOwnProperty(field)) {
                continue;
            }
            // Clear previous errors
            this.formErrors[field] = {};

            // Get the control
            const control = this.form.get(field);

            if (control && control.dirty && !control.valid) {
                control.errors["invalid"] = (!control.valid) ? true : null;
                this.formErrors[field] = control.errors;
                
            }
        }
    }

    //DIALOG
    dialogWarning()
    {

        const dialogRef = this.dialog.open(CrossXDialogComponent, {
            width: '440px'
        });
        //width: '430px'

        dialogRef.componentInstance.type = 'warning'
        dialogRef.componentInstance.titleDialog = 'Excluir Venda'
        dialogRef.componentInstance.dialogContent = 'Tem certeza que deseja excluir a <strong>venda 25</strong>?<br><br>Lorem ipsum dolor sit amet, vis suas mutat graece cu.In mea mazim periculis, sed porro pertinacia deterruisset ut, sea omnium facilisi necessitatibus at.Detracto vulputate no has, atqui fabulas eu duo.Te usu facer dicit.'
        dialogRef.componentInstance.dialogConfirm = true
        dialogRef.componentInstance.cancelButton = 'CANCELAR'
        dialogRef.componentInstance.actionButton = 'EXCLUIR'

        dialogRef.afterClosed().subscribe(result => {
        });
        
    }

    dialogSuccess() {

        const dialogRef = this.dialog.open(CrossXDialogComponent, {
            width: '440px'
        });
        //width: '430px'

        dialogRef.componentInstance.type = 'success'
        dialogRef.componentInstance.titleDialog = 'Sucesso!'
        dialogRef.componentInstance.dialogContent = 'Tem certeza que deseja excluir a <strong>venda 25</strong>?<br><br>Lorem ipsum dolor sit amet, vis suas mutat graece cu.In mea mazim periculis, sed porro pertinacia deterruisset ut, sea omnium facilisi necessitatibus at.Detracto vulputate no has, atqui fabulas eu duo.Te usu facer dicit.'
        dialogRef.componentInstance.dialogConfirm = false
        dialogRef.componentInstance.cancelButton = 'FECHAR'

        dialogRef.afterClosed().subscribe(result => {
        });

    }

    dialogError() {

        const dialogRef = this.dialog.open(CrossXDialogComponent, {
            width: '440px'
        });
        //width: '430px'

        dialogRef.componentInstance.type = 'error'
        dialogRef.componentInstance.titleDialog = 'Algo deu errado!'
        dialogRef.componentInstance.dialogContent = 'Tem certeza que deseja excluir a <strong>venda 25</strong>?<br><br>Lorem ipsum dolor sit amet, vis suas mutat graece cu.In mea mazim periculis, sed porro pertinacia deterruisset ut, sea omnium facilisi necessitatibus at.Detracto vulputate no has, atqui fabulas eu duo.Te usu facer dicit.'
        dialogRef.componentInstance.dialogConfirm = false
        dialogRef.componentInstance.cancelButton = 'FECHAR'

        dialogRef.afterClosed().subscribe(result => {
        });

    }

    dialogCustom() {
        const dialogRef = this.dialog.open(CrossXDialogComponent, {
            width: '440px'
        });
      // let overlay = this.overlay.create({
      //   positionStrategy: this.overlay.position().connectedTo(dialogRef, { originX: 'start', originY: 'bottom' }, { overlayX: 'start', overlayY: 'top' })
      // });
        //width: '430px'

        dialogRef.componentInstance.headerDialog = "Header customizado porra"
        dialogRef.componentInstance.iconPath = "./assets/icons/dialog/ic-dialog-warning.svg"
        dialogRef.componentInstance.textHeaderColor = "#FF0000"
        dialogRef.componentInstance.backgroundHeaderColor = "#36ff04"
        dialogRef.componentInstance.titleDialog = 'Dialog custom'
        dialogRef.componentInstance.dialogContent = 'Ficou bom esse Dialog<br><br><br><a href="http://appcrossx.com" target="_blank">site crossx</a>'
        dialogRef.componentInstance.dialogConfirm = false
        dialogRef.componentInstance.cancelButton = 'FECHAR ESSA BAGAÇA'

        dialogRef.afterClosed().subscribe(result => {
        });
    }

  modalCustom() {
    
    this.modalRef = this.dialog.open(ModalSampleComponent, {
      width: '100%',
      height: '100%',
      panelClass: 'modal'
    });

    
    this.modalRef.afterClosed().subscribe(result => {
    });
  }
}
