import { TranslateService } from '@ngx-translate/core';
import { Injectable, Type} from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { config } from './config/config'
import { AuthGuard } from './guards/auth.guard'
import 'rxjs/add/operator/do';
import { Router } from '@angular/router';

@Injectable()
export class APIInterceptor implements HttpInterceptor {

  token: string = null

  constructor(
    public authGuard: AuthGuard,
    public translateService: TranslateService,
    private router: Router
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //req.headers.set('Authorization', `Bearer ${this.authGuard.getToken()}`
    const lang = this.translateService.currentLang.toLowerCase()

    /// Base url request
    var baseUrl = `${config.serverUrl}/`
    var isInternalAPI: boolean = true
    if (req.url.toString().indexOf("http") > -1 || req.url.toString().indexOf("./") > -1) {
      baseUrl = ""
      isInternalAPI = false
    }

    const apiReq = req.clone({
      url: `${baseUrl}${req.url}`,
      headers: this.getHeaders(req.headers, isInternalAPI)
    });

    return next.handle(apiReq)
      .map(event => {
        if (event instanceof HttpResponse) {
          if (event.headers.get('Authorization') !== null) {
            this.token = event.headers.get('Authorization').replace('Bearer ', '')
            this.authGuard.setNewToken(event.headers.get('Authorization'))
          }
        }
        return event;
      })
      .do((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event.headers.get('Authorization') !== null) {
            this.token = event.headers.get('Authorization').replace('Bearer ', '')
            this.authGuard.setNewToken(event.headers.get('Authorization'))
          }
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.authGuard.logOut();
          }
          
          if (err.status === 500) {
            this.router.navigate(['/errors/error-500'])
          }
        }
      });

  }

  getHeaders(headers: HttpHeaders, reqInternal: boolean = true): HttpHeaders
  {
    if (this.authGuard.getToken()) {
      if (reqInternal) {
        return headers
        .set('Authorization', `Bearer ${this.authGuard.getToken()}`)
          // .set('Access-Control-Allow-Origin', '*')
          // .set('Access-Control-Allow-Credentials', 'true')
          // .set('Content-Type', 'application/json')
          // .set('Accept', 'application/json')
      }
      
      return headers
        .set('Access-Control-Allow-Origin', '*')
        .set('Content-Type', 'application/json; charset=UTF-8')
        // .set('Access-Control-Allow-Credentials', 'true')
        // .set('Accept', 'application/json')
      
    } else {
      if (reqInternal) {
        return headers
          // .set('Access-Control-Allow-Origin', '*')
          // .set('Authorization', `Bearer ${this.authGuard.getToken()}`)
      }

      return headers
        .set('Content-Type', 'application/json; charset=UTF-8')
        .set('Accept', 'application/json')
        // .set('Access-Control-Allow-Origin', '*')
        // .set('Access-Control-Allow-Credentials', 'true')
    }
  }
}


