import { TableXComponent } from './../../../../../core/components/table-x/table-x.component';
import {
  Competitor,
  CompetitorStatus,
  CompetitorCheckin
} from './../../../../../core/model/competitor';
import { CreateParticipantSingleComponent } from './create-participant-single/create-participant-single.component';
import { UserProfileComponent } from './../../profiles/user-profile/user-profile.component';
import { CompetitorService } from './../../../../../core/services/competitor/competitor.service';
import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  ViewEncapsulation,
  Inject,
  SecurityContext
} from '@angular/core';
import { Title, DomSanitizer, DOCUMENT } from '@angular/platform-browser';
import { BreadcrumbsService } from '../../../../../core/components/breadcrumbs/breadcrumbs.service';
import { FuseTranslationLoaderService } from '../../../../../core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';

import { locale as english } from './i18n/en-US';
import { locale as portugues } from './i18n/pt-BR';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { EventGuard } from '../../../../../core/guards/event.guard';
import { config } from '../../../../../core/config/config';
import { MatIconRegistry, MatDialog, MatMenuTrigger } from '@angular/material';
import { TabBarComponent } from '../../../../../core/components/tab-bar/tab-bar.component';
import { TableConfig } from '../../../../../core/components/table-x/table-x.component';
import { FusePerfectScrollbarDirective } from '../../../../../core/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { Utils } from '../../../../../core/utils';
import { TeamProfileComponent } from '../../profiles/team-profile/team-profile.component';
import { CreateParticipantTeamComponent } from './create-participant-team/create-participant-team.component';
import { ToastrService } from '../../../../../core/components/toastr_/public_api';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Competition } from '../../../../../core/model/competition';
import { SubDivisionHttpService } from '../../../../../core/services/sub-division/sub-division-http.service';
import { SubDivision } from '../../../../../core/model/sub-division';
import { Errors } from '../../../../../core/model/errors';
import { CrossXDialogComponent } from '../../../../../core/components/cross-x-dialog/cross-x-dialog.component';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { FilterSidebarService } from '../../../../../core/services/filter-sidebar/filter-sidebar.service';
import { Tag } from '../../../../../core/components/tag/tag';

@Component({
  selector: 'app-participants',
  templateUrl: './participants.component.html',
  styleUrls: ['./participants.component.scss']
})
export class ParticipantsComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();

  @ViewChild(MatMenuTrigger) resultOptionsMenu: MatMenuTrigger;
  @ViewChild('tabbar', { read: TabBarComponent }) tabBar: TabBarComponent;
  @ViewChild('table', { read: TableXComponent }) tableList: TableXComponent;
  @ViewChild(FusePerfectScrollbarDirective) fusePerfectScrollbarDirective;

  // Translate
  translate: any;

  eventId: number;
  championshipId: number;
  championship: any;
  competition: Competition;
  competitionId = 0;
  wod: any;
  wodId: number;

  // Tabs
  tabsFilter: string[] = [];
  tabsOptions: any[] = [];

  // Table
  tableConfig: TableConfig = {
    checkbox: false,
    paddingSide: true,
    massActions: null,
    massActionCountTitle: null
  };

  CompetitorCheckin = CompetitorCheckin;

  // disqualified
  private competitorDisqualified: Competitor;

  competitors: Competitor[] = [];
  competitorsFilter: any = {
    user: {
      fullname: ''
    }
  };

  // Booleans
  isLoading = false;
  isEmptyResult = false;
  isShowScrollToTop = false;
  isLoadingExport = false;

  // config request
  page = 1;
  totalPage = 20;
  loadedEnd = false;
  searchTerm = '';
  type = 'all';
  sort: any = null;
  totalCount: number = null;
  subDivisionId = 0;

  // sub-division
  subDivisions: SubDivision[];
  enableSubDivision = false;

  timeOutSearch: any;
  isSearch = false;

  tags: Tag[] = [];

  // filter
  toggleSidebar = false;

  selectedStatusOptions: FormControl;
  selectStatus = [
    {
      value: CompetitorCheckin.REGISTERED,
      name: 'Registrado',
      placeholder: 'Status'
    },
    {
      value: CompetitorCheckin.CHECKIN_ONLINE,
      name: 'Check-in on-line',
      placeholder: 'Status'
    },
    {
      value: CompetitorCheckin.CHECKIN,
      name: 'Check-in',
      placeholder: 'Status'
    },
    {
      value: CompetitorCheckin.NO_SHOW,
      name: 'No show',
      placeholder: 'Status'
    },
    {
      value: CompetitorCheckin.WITHDRAW,
      name: 'No show on-line',
      placeholder: 'Status'
    }
  ];
  statusCheckinId: number[];

  formFilter: FormGroup;

  // filters
  filters: {
    value: number | string;
    name: string;
    placeholder: string;
  }[] = [];
  defaultValueCompetition: any;
  defaultValueSubDivision: any;
  defaultValueStatus: any;

  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private titleService: Title,
    private breadcrumbsService: BreadcrumbsService,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private eventGuard: EventGuard,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private competitorService: CompetitorService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private subDivisionHttpService: SubDivisionHttpService,
    public filterSidebarService: FilterSidebarService,
    private readonly formBuilder: FormBuilder
  ) {
    this.resgisterSubDivision();
    this.translationLoader.loadTranslations(english, portugues);

    this.subscriptions.add(
      this.translateService.get('PARTICIPANTS').subscribe((res: any) => {
        this.translate = res;
      })
    );

    this.subscriptions.add(
      this.activatedRoute.params.subscribe(params => {
        this.championshipId = params.championship_id;

        this.fetchSubDivision();

        this.eventId = this.eventGuard.getActiveEvent().event.id;
        this.championship = this.eventGuard.getChampionshipPerId(
          this.championshipId
        );

        // Verify competitions has single and team
        const hasTypeCompetitions = Utils.validateChampionshipHasTeamAndSingle(
          this.championship.competitions
        );

        if (this.translate) {
          this.titleService.setTitle(
            `${config.titleBase} - ${this.translate.TITLE}`
          );

          this.breadcrumbsService.replaceAll([
            {
              text: this.translate.TITLE,
              route: `/championship/participants/${this.championshipId}`
            }
          ]);

          // population tabs
          this.tabsOptions = [
            {
              name: this.translate.TABS.ALL,
              type: 'all'
            }
          ];
          if (!hasTypeCompetitions.hasTeam) {
            this.tabsOptions = [];
            this.type = 'single';
          }
          if (hasTypeCompetitions.hasSingle) {
            this.tabsOptions.push({
              name: this.translate.TABS.SINGLE,
              type: 'single'
            });
          }

          if (hasTypeCompetitions.hasTeam) {
            this.tabsOptions.push({
              name: this.translate.TABS.TEAM,
              type: 'team'
            });
          }

          this.tabsOptions.push({
            name: this.translate.TABS.INACTIVE,
            type: 'inactive'
          });

          this.tabsFilter = [];
          this.tabsOptions.forEach(tab => {
            this.tabsFilter.push(tab.name);
          });
        }
      })
    ); // end router

    // Icon Info
    iconRegistry.addSvgIcon(
      'ic-info',
      sanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/myprofile/ic-profile.svg'
      )
    );

    this.registerFormFilter();
  }

  ngOnInit() {
    this.getParticipants();
    this.subscriptions.add(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.isLoading = false;
          this.tabBar.resetIndex();
          this.page = 1;
          this.totalPage = 20;
          this.loadedEnd = false;
          this.searchTerm = '';
          this.type = 'all';
          if (this.tableList) {
            this.tableList.resetSort();
          }
          this.competitors = [];
          this.isEmptyResult = false;
          this.getParticipants();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public toggleFilter(): void {
    this.filterSidebarService.toggle();
  }

  public tagDelete(tag: any): void {
    this.tags.splice(tag.index, 1);
    if (tag.tag.id.toLocaleLowerCase() === 'Status'.toLocaleLowerCase()) {
      this.statusCheckinId = [];
      this.selectStatus.forEach(status => {
        this.statusCheckinId.push(status.value);
      });
      this.formFilter.get('status').setValue(this.selectStatus);
    }
    const subDivisionId: string = this.championship.sub_division_group_name
      ? this.championship.sub_division_group_name
      : this.translate.SUB_DIVISION;
    if (
      tag.tag.id.toLocaleLowerCase() ===
      this.translate.CATEGORY.toLocaleLowerCase()
    ) {
      this.competitionId = 0;
      this.subDivisionId = 0;
      const subDivisionRemoveIndex: number = this.tags.findIndex(
        tagFilter =>
          tagFilter.id.toLocaleLowerCase() === subDivisionId.toLocaleLowerCase()
      );
      if (subDivisionRemoveIndex > -1) {
        this.tags.splice(subDivisionRemoveIndex, 1);
      }
      this.formFilter.get('competition').setValue(this.defaultValueCompetition);
      this.formFilter
        .get('sub_division')
        .setValue(this.defaultValueSubDivision);
    }
    if (tag.tag.id.toLocaleLowerCase() === subDivisionId.toLocaleLowerCase()) {
      this.subDivisionId = 0;
      this.formFilter
        .get('sub_division')
        .setValue(this.defaultValueSubDivision);
    }

    this.page = 1;
    this.totalPage = 20;
    this.loadedEnd = false;
    this.competitors = [];
    this.getParticipants();
  }

  private registerFormFilter(): void {
    this.defaultValueCompetition = {
      value: 0,
      name: this.translate.ALL.toLocaleUpperCase(),
      placeholder: this.translate.CATEGORY
    };
    this.defaultValueSubDivision = {
      value: 0,
      name: this.translate.ALL.toLocaleUpperCase(),
      placeholder: this.championship.sub_division_group_name
        ? this.championship.sub_division_group_name
        : this.translate.SUB_DIVISION
    };
    this.statusCheckinId = [];
    this.selectStatus.forEach(status => {
      this.statusCheckinId.push(status.value);
    });
    this.formFilter = this.formBuilder.group({
      status: [this.selectStatus],
      competition: [this.defaultValueCompetition],
      sub_division: [this.defaultValueSubDivision]
    });

    this.subscriptions.add(
      this.formFilter.controls['competition'].valueChanges.subscribe(
        ({ value }) => {
          this.championship.competitions.forEach(
            (competition: Competition, key: number) => {
              if (key === 0) {
                this.enableSubDivision = false;
              }
              if (competition.id === value) {
                this.enableSubDivision = competition.enable_sub_division;
              }
            }
          );
          if (!this.enableSubDivision) {
            this.formFilter.controls['sub_division'].setValue(0);
            this.subDivisionId = 0;
          }
        }
      )
    );
  }

  public submitFilter(): void {
    this.filters = [
      ...this.formFilter.value.status,
      {
        ...this.formFilter.value.competition
      }
    ];
    const statusValue = [];
    this.formFilter.value.status.forEach(status => {
      statusValue.push(status.value);
    });
    this.statusCheckinId = statusValue;
    this.competitionId = this.formFilter.value.competition.value;
    this.subDivisionId = this.formFilter.value.sub_division.value;

    if (this.enableSubDivision) {
      this.filters.push({
        ...this.formFilter.value.sub_division
      });
    }

    this.populateTag();

    this.page = 1;
    this.totalPage = 20;
    this.loadedEnd = false;
    this.competitors = [];
    this.getParticipants();
    this.filterSidebarService.toggle();
  }

  private populateTag(): void {
    this.tags = [];
    this.filters.forEach(filter => {
      const index = this.tags.findIndex(tag => tag.id === filter.placeholder);
      if (index !== -1) {
        this.tags[index].text = `${this.tags[index].text}, ${filter.name}`;
        return;
      }
      this.tags.push({
        id: filter.placeholder,
        text: `${filter.placeholder}: ${filter.name}`
      });
    });
  }

  private resgisterSubDivision(): void {
    const subscription = this.subDivisionHttpService
      .subDivisionObservable()
      .subscribe((results: SubDivision[]) => {
        this.subDivisions = results;
      });

    this.subscriptions.add(subscription);
  }

  private fetchSubDivision(): void {
    this.subDivisionHttpService.fetchSubDivision(this.championshipId);
  }

  getParticipants() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.isEmptyResult = false;
    this.totalCount = 0;
    if (this.page === 0) {
      this.competitors = [];
    }

    this.ngUnsubscribe = new Subject<void>();
    const subcription = this.competitorService
      .getCompetitors(
        this.championship.id,
        this.competitionId,
        this.searchTerm,
        this.type,
        this.sort,
        this.page,
        this.subDivisionId,
        this.statusCheckinId
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        response => {
          this.totalCount = Number(response.paginate.total);
          const result = response.body;
          if (this.page === 0) {
            this.competitors = result;
          } else {
            result.forEach(competitor => {
              this.competitors.push(competitor);
            });
          }
          this.competitors.forEach((competitor, index) => {
            this.competitors[index] = competitor.populateCheckinName();
          });
          this.isLoading = false;
          this.isEmptyResult = this.competitors.length === 0;
          this.loadedEnd = result.length === 0;
          if (this.tableList) {
            this.tableList.reloadItems();
          }
        },
        err => {
          this.isLoading = false;
          this.loadedEnd = true;
        }
      );
    this.subscriptions.add(subcription);
  }

  public checkin(type: number, id: number, index: number): void {
    const dialogRef = this.dialog.open(CrossXDialogComponent, {
      width: '440px',
      closeOnNavigation: true
    });

    dialogRef.componentInstance.type = 'warning';
    dialogRef.componentInstance.titleDialog = this.translate.DIALOG.TITLE;
    this.subscriptions.add(
      this.translateService
        .get('PARTICIPANTS.DIALOG.NO_SHOW', {
          name: this.competitors[index].team.name
        })
        .subscribe((message: string) => {
          dialogRef.componentInstance.dialogContent = message;
        })
    );
    if (type === CompetitorCheckin.REGISTERED) {
      this.subscriptions.add(
        this.translateService
          .get('PARTICIPANTS.DIALOG.REGISTERED', {
            name: this.competitors[index].team.name
          })
          .subscribe((message: string) => {
            dialogRef.componentInstance.dialogContent = message;
          })
      );
      const competitorIndex = this.competitors.findIndex(
        (competitor: Competitor) => competitor.id === id
      );
      if (competitorIndex > -1) {
        if (
          this.competitors[competitorIndex].checkin_status ===
          CompetitorCheckin.NO_SHOW_ONLINE
        ) {
          this.subscriptions.add(
            this.translateService
              .get('PARTICIPANTS.DIALOG.CHECKIN_ONLINE', {
                name: this.competitors[index].team.name
              })
              .subscribe((message: string) => {
                dialogRef.componentInstance.dialogContent = message;
              })
          );
        }
      }
    }
    if (type === CompetitorCheckin.CHECKIN_ONLINE) {
      this.subscriptions.add(
        this.translateService
          .get('PARTICIPANTS.DIALOG.CHECKIN_ONLINE', {
            name: this.competitors[index].team.name
          })
          .subscribe((message: string) => {
            dialogRef.componentInstance.dialogContent = message;
          })
      );
    }
    dialogRef.componentInstance.dialogConfirm = true;
    dialogRef.componentInstance.cancelButton = this.translate.DIALOG.CANCEL;
    dialogRef.componentInstance.actionButton = this.translate.DIALOG.CONFIRM;
    // dialogRef.componentInstance.object = subscription;
    dialogRef.componentInstance.confirmActionRequest = true;
    this.subscriptions.add(
      dialogRef.componentInstance.dialogEvent.subscribe(result => {
        dialogRef.componentInstance.confirmLoading = true;
        const parameters = {
          checkin_status: type
        };
        const subscription = this.competitorService
          .updateCompetitor(parameters, id)
          .subscribe(
            result => {
              const resultCompetitor = new Competitor().deserialize(result);
              this.competitors.forEach(
                (competitor: Competitor, key: number) => {
                  if (competitor.team.id === resultCompetitor.team.id) {
                    const data: any = {
                      ...competitor,
                      checkin_status: resultCompetitor.checkin_status,
                      checkin_name: resultCompetitor.checkin_name
                    };
                    this.competitors[key] = data;
                  }
                }
              );
              if (
                resultCompetitor.checkin_status === CompetitorCheckin.CHECKIN
              ) {
                this.toastr.show(
                  '',
                  this.translate.TOASTR.SUCCESS_CHECKIN.CHECKIN,
                  null,
                  'success'
                );
              } else if (
                resultCompetitor.checkin_status === CompetitorCheckin.REGISTERED
              ) {
                this.toastr.show(
                  '',
                  this.translate.TOASTR.SUCCESS_CHECKIN.REGISTERED,
                  null,
                  'success'
                );
              } else if (
                resultCompetitor.checkin_status ===
                CompetitorCheckin.CHECKIN_ONLINE
              ) {
                this.toastr.show(
                  '',
                  this.translate.TOASTR.SUCCESS_CHECKIN.CHECKIN_ONLINE,
                  null,
                  'success'
                );
              } else {
                this.toastr.show(
                  '',
                  this.translate.TOASTR.SUCCESS_CHECKIN.NO_SHOW,
                  null,
                  'success'
                );
              }
              this.tableList.reloadItems();
              dialogRef.close();
            },
            err => {
              const errors: Errors = new Errors().deserialize(err.error as any);
              this.toastr.show(
                errors.getFullMessages(),
                this.translate.TOASTR.ERROR_CHECKIN.TITLE,
                null,
                'error'
              );
              dialogRef.close();
            }
          );

        this.subscriptions.add(subscription);
      })
    );
  }

  public downloadFileCheckin(link: string): void {
    Utils.forceDownloadWithUrl(link);
  }

  public getStatusCheckinType(status: number): string {
    switch (status) {
      case CompetitorCheckin.REGISTERED:
        return 'normal';
      case CompetitorCheckin.CHECKIN_ONLINE:
        return 'success';
      case CompetitorCheckin.CHECKIN:
        return 'success';
      case CompetitorCheckin.NO_SHOW:
        return 'danger';
      case CompetitorCheckin.WITHDRAW:
        return 'danger';
      case CompetitorCheckin.NO_SHOW_ONLINE:
        return 'danger';
      default:
        return 'normal';
    }
  }

  // Sort Results
  sortAction(sort) {
    if (sort.sort === null) {
      this.sort = null;
    } else {
      this.sort = {
        order: sort.order,
        column: sort.sort
      };
    }

    // this.ngUnsubscribe.next();
    this.page = 1;
    this.totalPage = 20;
    this.loadedEnd = false;
    this.competitors = [];
    this.getParticipants();
  }

  sortResult(column: string, order: string) {
    Utils.sortArray(this.competitors, column, order);
  }

  // Export competitors
  exportCompetitors() {
    this.isLoadingExport = true;
    let queryParam = {};
    queryParam = {
      name: this.searchTerm,
      'checkin_status[]': this.statusCheckinId
    };
    if (this.competitionId !== 0) {
      queryParam = {
        ...queryParam,
        competition_id: this.competitionId
      };
    }
    if (this.enableSubDivision && this.subDivisionId !== 0) {
      queryParam = {
        ...queryParam,
        sub_division_id: this.subDivisionId
      };
    }
    const subscription = this.competitorService
      .exportCompetitors(this.championship.id, this.type, queryParam)
      .subscribe(
        result => {
          Utils.forceDownloadWithValidationURL(result.file);

          this.isLoadingExport = false;
        },
        err => {
          this.isLoadingExport = false;
        }
      );
    this.subscriptions.add(subscription);
  }

  searchName(name: string) {
    clearTimeout(this.timeOutSearch);
    this.timeOutSearch = setTimeout(() => {
      // this.ngUnsubscribe.next();
      this.searchTerm = name;
      // Reset all options to list
      this.page = 1;
      this.totalPage = 20;
      this.loadedEnd = false;
      this.competitors = [];
      this.getParticipants();
    }, 600);
  }

  openUserProfile(item: Competitor, index: number) {
    const modalRef = this.dialog.open(UserProfileComponent, {
      width: '816px',
      height: '100%',
      panelClass: 'modal'
    });

    const competitorStatus = item.status;
    const competitionsSingle: any[] = [];
    this.championship.competitions.forEach(competition => {
      if (competition.team_size === null || competition.team_size === 1) {
        competitionsSingle.push(competition);
      }
    });

    modalRef.componentInstance.competitions = competitionsSingle; // this.championship.competitions
    modalRef.componentInstance.championship = this.championship; // this.championship.competitions
    modalRef.componentInstance.competitor = item;
    modalRef.componentInstance.subDivisions = this.subDivisions;

    const subscription = modalRef.afterClosed().subscribe(result => {
      if (result && result.action) {
        switch (result.action) {
          case 'remove-to-list':
            this.competitors.splice(index, 1);
            break;

          case 'update':
            item = result.object;
            this.competitors[index] = result.object;
            break;

          default:
            break;
        }

        if (result.toastr) {
          this.showToastr(result.toastr);
        }
      }

      if (this.tableList) {
        this.tableList.reloadItems();
      }
    });
    this.subscriptions.add(subscription);
  }

  openTeamProfile(item: Competitor, index: number) {
    const modalRef = this.dialog.open(TeamProfileComponent, {
      width: '816px',
      height: '100%',
      panelClass: 'modal'
    });

    const competitionsTeam: any[] = [];
    this.championship.competitions.forEach(competition => {
      if (competition.team_size !== null) {
        competitionsTeam.push(competition);
      }
    });

    modalRef.componentInstance.competitions = competitionsTeam; // this.championship.competitions
    modalRef.componentInstance.competitor = item;
    modalRef.componentInstance.subDivisions = this.subDivisions;
    modalRef.componentInstance.championship = this.championship;

    const subscription = modalRef.afterClosed().subscribe(result => {

      if (result && result.action) {
        switch (result.action) {
          case 'remove-to-list':
            this.competitors.splice(index, 1);
            break;

          case 'update':
            this.competitors.forEach((competitor: Competitor, key: number) => {
              if (competitor.team.id === result.object.team.id) {
                const data = {
                  ...result.object,
                  user: competitor.user
                };
                this.competitors[key] = data;
              }
            });
            break;

          default:
            break;
        }

        if (result.toastr) {
          this.showToastr(result.toastr);
        }
      }

      if (this.tableList) {
        this.tableList.reloadItems();
      }
      
    });
    this.subscriptions.add(subscription);
  }

  showToastr(toastr: any) {
    this.toastr.show(toastr.description, toastr.title, null, toastr.type);
  }

  // Tabbar action
  tabAction(event) {

    // Verify tab select is not actual active
    if (this.tabsOptions[event.index].type === this.type) {
      return;
    }
    // Reset all options to list
    // this.ngUnsubscribe.next();
    this.isLoading = false;
    this.page = 1;
    this.totalPage = 20;
    this.loadedEnd = false;
    this.searchTerm = '';
    this.type = this.tabsOptions[event.index].type;
    this.competitors = [];
    this.sort = null;
    if (this.tableList) {
      this.tableList.resetSort();
    }
    this.getParticipants();
  }

  // Scroll Event to infinite scroll
  scrollEvent(event) {
    if (
      event.type === 'retriveWithScroll' &&
      !this.isLoading &&
      !this.loadedEnd
    ) {
      this.page++;
      this.getParticipants();
    }
  }

  // Add New
  addNew() {

    if (this.type === 'single') {
      const modalRef = this.dialog.open(CreateParticipantSingleComponent, {
        width: '816px',
        height: '100%',
        panelClass: 'modal'
      });

      const competitionsSingle: any[] = [];
      this.championship.competitions.forEach(competition => {
        if (competition.team_size === null) {
          competitionsSingle.push(competition);
        }
      });

      modalRef.componentInstance.competitions = competitionsSingle; // this.championship.competitions
      modalRef.componentInstance.championshipId = this.championship.id;
      modalRef.componentInstance.eventId = this.eventId;
      modalRef.componentInstance.subDivisions = this.subDivisions;
      modalRef.componentInstance.championship = this.championship;

      this.subscriptions.add(
        modalRef.afterClosed().subscribe(result => {

          if (result) {
            if (result.createCompetitor) {
              this.toastr.show(
                this.translate.TOASTR.SUCCESS_NEW_SINGLE.DESCRIPTION,
                this.translate.TOASTR.SUCCESS_NEW_SINGLE.TITLE,
                null,
                'success'
              );

              this.page = 1;
              this.totalPage = 20;
              this.loadedEnd = false;
              this.competitors = [];
              this.getParticipants();
            }
          }
        })
      );
    } else if (this.type === 'team') {
      const modalRef = this.dialog.open(CreateParticipantTeamComponent, {
        width: '816px',
        height: '100%',
        panelClass: 'modal'
      });

      const competitionsTeam: any[] = [];
      this.championship.competitions.forEach(competition => {
        if (competition.team_size !== null) {
          competitionsTeam.push(competition);
        }
      });

      modalRef.componentInstance.competitions = competitionsTeam; // this.championship.competitions
      modalRef.componentInstance.eventId = this.eventId;
      modalRef.componentInstance.championshipId = this.championship.id;
      modalRef.componentInstance.subDivisions = this.subDivisions;
      modalRef.componentInstance.championship = this.championship;

      this.subscriptions.add(
        modalRef.afterClosed().subscribe(result => {
          if (result) {
            if (result.createCompetitor) {
              this.toastr.show(
                this.translate.TOASTR.SUCCESS_NEW_TEAM.DESCRIPTION,
                this.translate.TOASTR.SUCCESS_NEW_TEAM.TITLE,
                null,
                'success'
              );

              this.page = 1;
              this.totalPage = 20;
              this.loadedEnd = false;
              this.competitors = [];
              this.getParticipants();
            }
          }
        })
      );
    }
  }
}
