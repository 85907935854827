import { EventGuard } from './../../guards/event.guard';
import { Injectable, OnDestroy } from '@angular/core';
import { AuthGuard } from '../../guards/auth.guard';
import { HttpClient } from '../../../../../node_modules/@angular/common/http';
import { Observable, Subscription, Subject } from '../../../../../node_modules/rxjs';
import { Championship } from '../../model/championship';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ChampionshipService implements OnDestroy {

  private subscriptions = new Subscription();
  private _championshipObservable = new Subject<Championship>();
  
  constructor(
    private http: HttpClient,
    private authGuard: AuthGuard,
    private eventGuard: EventGuard,
    private router: Router
  ) { }

  championshipObservable(): Observable<Championship> {
    return this._championshipObservable
      .asObservable();
  }

  fetchChampionship(id: number): void {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const baseUrl = [
      'admin',
      'championships',
      id
    ];
    const queryParams = {};
    const url = this.router
      .createUrlTree(baseUrl, {
        queryParams
      });
    const parsedUrl = url.toString().substring(1, url.toString().length);
    const subscription = this.http
      .get(parsedUrl, { responseType })
      .subscribe(({ data }: any) => {
        this._championshipObservable.next(data);
      }, err => {
        this._championshipObservable.next(err);
      });

    this.subscriptions.add(subscription);
  }

  updateChampionshipById(id: number, championshipData: any): Observable<Championship> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const baseUrl = [
      'admin',
      'championships',
      id
    ];
    const queryParams = {};

    const url = this.router.createUrlTree(baseUrl, {
        queryParams
      });

    const parsedUrl = url.toString().substring(1, url.toString().length);

    return this.http
      .put(parsedUrl, championshipData, { responseType })
      .map(({ data }: any) => {
          return data;
        });
  }

  // GETS
  updateChampionships(championships: any, eventId: number): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';

    return this.http.post(`admin/championships/${eventId}/action`, championships, { responseType })
      .map((response: Response) => {
        const championships = response as any;
        return championships;
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
