import { Wod } from './../../model/wod';
import { WodUserDashboard } from './../../model/user-dashboard/wod';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable, Subject, Subscription} from 'rxjs';
import { Competition } from '../../model/competition';
import {Competitor} from '../../model/competitor';
import {HeatQueryParams} from '../../../main/content/pages/championship/heats/enums/heat-query.params';
import {Router} from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class WodService implements OnDestroy {

  private subscriptions = new Subscription();
  private _wodWithCompetitionsObservable = new Subject<Competition[]>();
  
  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  wodWithCompetitionsObservable(): Observable<Competition[]> {
    return this._wodWithCompetitionsObservable.asObservable();
  }

  getWods(championshipId: number, competitionId: number): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';

    return this.http.get(`admin/wod?championship_id=${championshipId}&competition_id=${competitionId}`, { responseType })
      .map((response: Response) => {
        const wods = response as any;
        return wods.map((result: Wod) => new Wod().deserialize(result));
      });
  }

  // User dashboard
  getWodsWithCompetitionId(competitionId: number): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';

    return this.http.get(`wods?competition_id=${competitionId}`, { responseType })
      .map((response: Response) => {
        const results = response as any;
        return results.map((result: WodUserDashboard) => new WodUserDashboard().deserialize(result));
      });
  }
  
  // Get Wod per Championship with competition
  getWodsWithChampionship(championshipId: number): void {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const baseUrl = [
      'admin',
      'wods-in-competitions'
    ];
    const queryParams = {
      championship_id: championshipId
    };
    const url = this.router.createUrlTree(baseUrl, {
        queryParams: queryParams
      }
    );
    const parsedUrl = url.toString().substring(1, url.toString().length);
    
    const subscription = this.http
      .get(parsedUrl, {responseType})
      .subscribe((competitors: Competition[]) => {
          this._wodWithCompetitionsObservable.next(competitors);
        },
        () => {
          this._wodWithCompetitionsObservable.next([]);
        });

    this.subscriptions.add(subscription);
  }

  getWodDateByChampionshipId(queryParams: { championship_id: number }): Observable<any> {

    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const baseUrl = [
      'admin',
      'wod-date'
    ];

    const url = this.router.createUrlTree(baseUrl, {
        queryParams
      }
    );
    const parsedUrl = url.toString().substring(1, url.toString().length);
    
    return this.http
      .get(parsedUrl, { responseType })
      .map((response) => {
          return response;
        });
  }
  
  getPublicWodWithChampionship(championshioId: number): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';

    return this.http.get(`public/championship/${championshioId}/wods`, { responseType })
      .map((response: Response) => {
        const results = response as any;
        return results.map((result: Competition) => new Competition().deserialize(result));
      });
  }

  // Create Wod
  createWod(parameters: any): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';

    return this.http.post(`admin/wod`, parameters, { responseType })
      .map((response: Response) => {
        return (response as any).map((wod: Wod) => new Wod().deserialize(wod));
      });
  }

  // Update Wod
  updateWod(wodId: number, parameters: any): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';

    return this.http.put(`admin/wod/${wodId}`, parameters, { responseType })
      .map((response: Response) => {
        return new Wod().deserialize(response as any);
      });
  }

  // Mass Update Wod
  massUpdate(parameters: any): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';

    return this.http.post(`admin/wod/mass-update`, parameters, { responseType })
      .map((response: Response) => {
        return (response as any).map((result: Wod) => new Wod().deserialize(result as any));
      });
  }

  delete(id: number) {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const url = `admin/wod/${id}`;

    return this.http.delete(url, { responseType })
      .map((response: Response) => {
        const results = response as any;
        return results;
      });
  }

  duplicate(parameters: any): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';

    return this.http.post(`admin/wod/duplicate`, parameters, { responseType })
      .map((response: Response) => {
        return (response as any).map((result: Competition) => new Competition().deserialize(result));
        // return new Lot().deserialize(response as any)
      });
  }
}
