import { Competition } from './../../model/competition';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthGuard } from '../../guards/auth.guard';
import { EventGuard } from '../../guards/event.guard';
import { Observable } from 'rxjs';
import { HeatQueryParams } from '../../../main/content/pages/championship/heats/enums/heat-query.params';
import { Heat } from '../../../main/content/pages/championship/heats/model/heat.model';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CompetitionService {

  constructor(
    private http: HttpClient,
    private authGuard: AuthGuard,
    private eventGuard: EventGuard,
    private router: Router,
  ) {
  }

  getCompetitions(championshipId: number): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const url = `admin/competitions/?championshipId=${championshipId}`;

    return this.http.get(url, {responseType})
      .map((response: Response) => {
        const results = response as any;
        return results.map((result: Competition) => new Competition().deserialize(result));
      });
  }

  getCompetititonsByType(championshipId, type): Observable<Competition[]> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const baseUrl = [
      'admin',
      'competitions'
    ];
    const queryParams = {};
    // queryParams[HeatQueryParams.TYPE] = type;
    queryParams[HeatQueryParams.CHAMPIONSHIPID] = championshipId;

    const url = this.router.createUrlTree(baseUrl, {
        queryParams: queryParams
      }
    );
    const parsedUrl = url.toString().substring(1, url.toString().length);
    return this.http
      .get(parsedUrl, {responseType})
      .map((competitions: Competition[]) => {
        return competitions;
      });
  }

  createCompetitions(competitions: any): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';

    return this.http.post(`admin/competitions`, competitions, {responseType})
      .map((response: Response) => {

        const results = response as any;
        return results.map((result: Competition) => new Competition().deserialize(result));
      });
  }

  updateCompetitions(competitionId: number, competition: any): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';

    return this.http.put(`admin/competitions/${competitionId}`, competition, {responseType})
      .map((response: Response) => {

        return new Competition().deserialize(response as any);
      });
  }

  massUpdate(competitions: any): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';

    return this.http.post(`admin/competitions/mass-update`, competitions, {responseType})
      .map((response: Response) => {

        return (response as any).map((result: Competition) => new Competition().deserialize(result));
      });
  }

  deleteCompetition(competitionId: number) {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const url = `admin/competitions/${competitionId}`;

    return this.http.delete(url, {responseType})
      .map((response: Response) => {
        const results = response as any;
        return results;
      });
  }
}
