import { Competitor, CompetitorCheckin } from './../../model/competitor';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, Subscription } from 'rxjs';
import { Competition } from '../../model/competition';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CompetitorService implements OnDestroy {
  private subscriptions = new Subscription();
  private _competitorsWithCompetitionsObservable = new Subject<Competitor[]>();
  private _competitorObservable = new Subject<any>();

  constructor(private http: HttpClient, private router: Router) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  competitorsWithCompetitionsObservable(): Observable<Competitor[]> {
    return this._competitorsWithCompetitionsObservable.asObservable();
  }

  competitorObservable(): Observable<any> {
    return this._competitorObservable.asObservable();
  }

  // Get Wod per Championship with competition
  getCompetitorsWith(championshipId: number, competitionId: number, subDivisionId?: number): void {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const baseUrl = [
      'admin',
      'competitors',
      championshipId,
      'competition',
      competitionId
    ];
    let queryParams: any = {};
    if (subDivisionId) {
      queryParams = {
        ...queryParams,
        sub_division_id: subDivisionId
      };
    }

    const url = this.router.createUrlTree(baseUrl, { queryParams });
    const parsedUrl = url.toString().substring(1, url.toString().length);

    const subscription = this.http.get(parsedUrl, { responseType }).subscribe(
      (competitors: Competitor[]) => {
        this._competitorsWithCompetitionsObservable.next(competitors);
      },
      () => {
        this._competitorsWithCompetitionsObservable.next([]);
      }
    );

    this.subscriptions.add(subscription);
  }

  getCompetitors(
    championshipId: number,
    competitionId: number = 0,
    searchTerm: string = '',
    type: string = 'all',
    sort: any = null,
    page: number = 0,
    subDivisionId = 0,
    checkinStatus = [
      CompetitorCheckin.CHECKIN,
      CompetitorCheckin.CHECKIN_ONLINE,
      CompetitorCheckin.NO_SHOW,
      CompetitorCheckin.REGISTERED
    ]
  ): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    let url = `admin/championships/${championshipId}/competitors/${type}/?page=${page}&name=${searchTerm}`;

    if (sort) {
      url = url + `&order_by[${sort.column}]=${sort.order}`;
    }
    if (competitionId > 0) {
      url = url + `&competition_id=${competitionId}`;
    }
    if (subDivisionId > 0) {
      url = url + `&sub_division_id=${subDivisionId}`;
    }
    checkinStatus.forEach(status => {
      url = `${url}&checkin_status[]=${status}`;
    });

    return this.http.get(url, { observe: 'response' }).map(response => {
      const competitors = (response.body as any).map((competitor: Competitor) =>
        new Competitor().deserialize(competitor)
      );
      return {
        paginate: JSON.parse(response.headers.get('pagination')),
        body: competitors
      };
    });
  }

  updateCompetitor(parameters: any, competitorId: number): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const url = `admin/competitors/${competitorId}`;

    return this.http
      .put(url, parameters, { responseType })
      .map((response: Response) => {
        return response;
      });
  }

  public massUpdateCompetitor(parameters: any): Observable<Competitor[]> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const url = `admin/competitors/mass-update`;

    return this.http
      .put(url, parameters, { responseType })
      .map((competitors: Competitor[]) => {
        return competitors;
      });
  }

  getupdateCompetitorByUser(competitorId: number): void {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const baseUrl = ['competitors', competitorId];

    const url = this.router.createUrlTree(baseUrl);
    const parsedUrl = url.toString().substring(1, url.toString().length);
    const subscription = this.http.get(parsedUrl, { responseType }).subscribe(
      (competitor: Competitor) => {
        this._competitorObservable.next(competitor);
      },
      err => {
        this._competitorObservable.next(err);
      }
    );

    this.subscriptions.add(subscription);
  }

  updateCompetitorByUser(
    competitorId: number,
    params: any,
    queryParams = {}
  ): void {
    const baseUrl = ['competitors', competitorId];

    const url = this.router.createUrlTree(baseUrl, {
      queryParams
    });
    const parsedUrl = url.toString().substring(1, url.toString().length);
    const subscription = this.http.post(parsedUrl, params).subscribe(
      (competitor: Competitor) => {
        this._competitorObservable.next(competitor);
      },
      err => {
        this._competitorObservable.next(err);
      }
    );

    this.subscriptions.add(subscription);
  }

  disqualifiedCompetitor(parameters: any, competitorId: number) {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const url = `admin/competitors-disqualified/${competitorId}`;

    return this.http
      .put(url, parameters, { responseType })
      .map((response: Competitor) => {
        return response;
      });
  }

  createSingleCompetitor(parameters: any): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const url = `admin/competitors`;

    return this.http
      .post(url, parameters, { responseType })
      .map((response: Response) => {
        return response;
        // let competitors = (response as any).map((competitor: Competitor) => new Competitor().deserialize(competitor))
        // return competitors
      });
  }

  createTeamCompetitor(parameters: any): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const url = `admin/competitors/team`;

    return this.http
      .post(url, parameters, { responseType })
      .map((response: Response) => {
        return response;
      });
  }

  // export
  exportCompetitors(
    championshipId: number,
    type: string,
    queryParams: any
  ): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const baseUrl = ['admin', 'competitors', championshipId, 'export', type];

    const url = this.router.createUrlTree(baseUrl, {
      queryParams
    });

    const parsedUrl = url.toString().substring(1, url.toString().length);

    return this.http
      .get(parsedUrl, { responseType })
      .map((response: Response) => {
        return response;
      });
  }

  public getCompetitor(competitorId: number): void {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const parsedUrl = `public/competitors/${competitorId}`;

    const subscription = this.http.get(parsedUrl, { responseType }).subscribe(
      (competitor: Competitor) => {
        this._competitorObservable.next(competitor);
      },
      () => {
        this._competitorObservable.next([]);
      }
    );

    this.subscriptions.add(subscription);
  }
}
