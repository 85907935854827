import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef, MatIconRegistry} from '@angular/material';
import {FuseTranslationLoaderService} from '../../../../../../core/services/translation-loader.service';
import {TranslateService} from '@ngx-translate/core';
import {DomSanitizer} from '@angular/platform-browser';
import { locale as english } from './../../i18n/en-US';
import { locale as portugues } from './../../i18n/pt-BR';
import {CustomField} from '../../../../../../core/model/custom-field.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as moment from '../../../championship/category-lot-wod/modal-create-lot/modal-create-lot.component';
import {dateValidator} from '../../../../../../core/utils/custom-validators';
import {FormErrorStateMatcher} from '../../../../../../core/utils/form-error-state-matcher';
import {CustomFieldType, CustomFieldTypes} from '../../../../../../core/model/custom-field-type.model';
import {GetFieldTypes} from '../../../../../../core/utils/custom-fields-types';
import {Subscription} from 'rxjs';
import {DefaultCustomFieldType} from '../../../../../../core/model/default-custom-field.model';
import {Utils} from '../../../../../../core/utils';
import {Event} from '../../../../../../core/model/event';
import {CustomFieldsService} from '../../../../../../core/services/custom-fields/custom-fields.service';
import {Errors} from '../../../../../../core/model/errors';
import {ToastrService} from '../../../../../../core/components/toastr_/toastr/toastr.service';
import {CustomFieldOption} from '../../../../../../core/model/custom-field-option.model';
import { NgxDnDModule } from '@swimlane/ngx-dnd';
import {TableConfig, TableXComponent} from '../../../../../../core/components/table-x/table-x.component';

@Component({
  selector: 'app-model-custom-field',
  templateUrl: './model-custom-field.component.html',
  styleUrls: ['./model-custom-field.component.scss'],
})

export class ModelCustomFieldComponent implements OnInit {
  @ViewChild('tableOptions', { read: TableXComponent }) tableOptions: TableXComponent;
  
  public event: Event = null;
  public customFieldType: string = 'basic';
  public subscriptionType: string = 'athletes';
  public isEdit: boolean = false;
  public customField: CustomField = null;
  public titleModel: string = 'Novo campo';
  
  private subscriptions: Subscription = new Subscription();

  // Translate
  translate: any;

  public fieldTypes: CustomFieldType[] = []
  public customFieldForm: FormGroup;
  public matchers: any;
  public typeSelected: CustomFieldType = null;
  public options: CustomFieldOption[] = []
  private optionsDeleted: number[] = []
  
  public errorRequest: string = null;
  public isLoading: boolean = false;
  private indexItemNew = 300000;

  //Table
  public tableConfig: TableConfig = {
    checkbox: false,
    paddingSide: false,
    massActions: null,
    massActionCountTitle: null
  };
  
  constructor(
    public dialogRef: MatDialogRef<ModelCustomFieldComponent>,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer,
    private iconRegistry: MatIconRegistry,
    private formBuilder: FormBuilder,
    private customFieldService: CustomFieldsService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.registerOnTranslate();
    this.configForm();
    this.registerOnChangeInput()
  }
  
  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }
  
  public saveCustomField(): void {
    this.errorRequest = null;
    
    // validate all form fields
    if (!this.customFieldForm.valid) {
      Utils.formValid(this.customFieldForm)
      return
    }
    
    let parameters = {
      custom_field_type_id: this.typeSelected.id,
      default_custom_field_id: this.customField && this.customField.default_custom_field ? this.customField.default_custom_field.id : null,
      type: this.customFieldType,
      subscription_type: this.subscriptionType,
      name: this.customFieldForm.value.name,
      description: this.customFieldForm.value.description,
      max_count: this.showMaxCount() ? this.customFieldForm.value.max_count : null,
      is_required: this.customField ? this.customField.is_required : true,
      is_hidden: this.customField ? this.customField.is_hidden : false,
    }
    
    if (this.typeSelected.hasOptions) {
      let options = [];
      this.options.forEach(option => {
        let op = {
          name: option.name,
          order: option.order,
        }
        if (!option.isNew) {
          op['id'] = option.id;
        }
        options.push(op)
      })
      parameters['options'] = options; 
      
      if (this.optionsDeleted.length > 0) {
        parameters['options_delete'] = this.optionsDeleted;
      }
    }
    
    
    this.isLoading = true;
    if (this.isEdit) {
      const subscription = this.customFieldService.update(this.customField.id, parameters)
        .subscribe(
          result => {
            let titleToastr = '';
            let descriptionToastr = '';
            const customFieldName = this.customFieldForm.value.name ? this.customFieldForm.value.name : this.customField.name;
            this.translateService.get('MODAL_CUSTOM_FIELD.TOASTR.TITLE', { custom_field_name: customFieldName, action: this.translate.ACTION_UPDATE }).subscribe((res: string) => {
              titleToastr = res
            });
            this.translateService.get('MODAL_CUSTOM_FIELD.TOASTR.DESCRIPTION', { action: this.translate.ACTION_UPDATE }).subscribe((res: string) => {
              descriptionToastr = res
            });
            this.toastr.show(
              descriptionToastr,
              titleToastr,
              null,
              'success'
            )
            this.isLoading = false;
            this.dialogRef.close({ customField: result, isEdit: this.isEdit })
          }, err => {
            const errors: Errors = new Errors().deserialize((err.error as any))
            this.errorRequest = errors.getFullMessages();
            this.isLoading = false;
          }
        );

      this.subscriptions.add(subscription);
    } else {
      const subscription = this.customFieldService.create(this.event.id, parameters)
        .subscribe(
          result => {
            let titleToastr = '';
            let descriptionToastr = '';
            this.translateService.get('MODAL_CUSTOM_FIELD.TOASTR.TITLE', { custom_field_name: this.customFieldForm.value.name, action: this.translate.ACTION_CREATE }).subscribe((res: string) => {
              titleToastr = res
            });
            this.translateService.get('MODAL_CUSTOM_FIELD.TOASTR.DESCRIPTION', { action: this.translate.ACTION_CREATE }).subscribe((res: string) => {
              descriptionToastr = res
            });
            this.toastr.show(
              descriptionToastr,
              titleToastr,
              null,
              'success'
            )
            this.isLoading = false;
            this.dialogRef.close({ customField: result, isEdit: this.isEdit })
          }, err => {
            const errors: Errors = new Errors().deserialize((err.error as any))
            this.errorRequest = errors.getFullMessages();
            this.isLoading = false;
          }
        );
      
      this.subscriptions.add(subscription);
    }
  }
  
  
  private registerOnChangeInput(): void {
    const subscription = this.customFieldForm
      .get('type')
      .valueChanges
      .subscribe((value: CustomFieldType) => {
        this.typeSelected = value;
        
        this.options = [
          {
            id: this.indexItemNew + this.options.length,
            name: '',
            order: 0,
            isNew: true
          }
        ];
      });
    this.subscriptions.add(subscription);
  }

  private configForm(): void {
    this.fieldTypes = GetFieldTypes(this.translateService.currentLang);
    
    this.customFieldForm = this.formBuilder.group({
      name: [{
        value: (this.customField) ? this.customField.name : '',
        disabled: this.customField && this.customField.default_custom_field && this.customField.default_custom_field.id === DefaultCustomFieldType.TSHIRT
      }, [Validators.required]],
      description: [{
        value: (this.customField) ? this.customField.description : '',
        disabled: this.customField && this.customField.default_custom_field && this.customField.default_custom_field.id === DefaultCustomFieldType.TSHIRT
      }],
      type: [{
        value: (this.customField && this.customField.custom_field_type) ? this.customField.custom_field_type : '',
        disabled: this.customField && this.customField.default_custom_field && this.customField.default_custom_field.id === DefaultCustomFieldType.TSHIRT
      }, [Validators.required]],
      max_count: [(this.customField && this.customField.max_count) ? this.customField.max_count : ''],
    });

    this.matchers = {
      name: new FormErrorStateMatcher(),
      description: new FormErrorStateMatcher(),
      type: new FormErrorStateMatcher(),
      max_count: new FormErrorStateMatcher(),
    };
    
    
    if (this.customField) {
      // @ts-ignore
      const type: CustomFieldType = this.fieldTypes.find(option => {
        return option.id === this.customField.custom_field_type.id;
      });
      this.typeSelected = type;
      this.customFieldForm.controls['type'].setValue(type);
      this.options = [];
      if (this.customField.event_custom_field_options && this.customField.event_custom_field_options.length > 0) {
        this.customField.event_custom_field_options.map(option => {
          const op: CustomFieldOption = {
            id: option.id,
            name: option.name,
            order: option.order,
            isNew: false
          }
          this.options.push(op)
        })
      } 
      
    }
    
  }

  private registerOnTranslate(): void {
    this.translationLoader.loadTranslations(english, portugues);

    this.translateService.get("MODAL_CUSTOM_FIELD").subscribe((res: any) => {
      this.translate = res
      this.titleModel = res.TITLE_NEW;
    });

    if (this.isEdit && this.customField) {
      this.translateService.get('MODAL_CUSTOM_FIELD.TITLE_EDIT', { custom_field_name: this.customField.name }).subscribe((res: string) => {
        this.titleModel = res
      });
    } 
    
    
  }

  public showMaxCount(): boolean {
    if (this.typeSelected) {
      switch (this.typeSelected.type) {
        case CustomFieldTypes.MULTIPLE_LINE_TEXT:
        case CustomFieldTypes.SINGLE_LINE_TEXT:
        case CustomFieldTypes.NUMBER:
        case CustomFieldTypes.MULTIPLE_CHECKBOX:
          return true;
          
        default:
          return false;
      }
    } 
    
    return false;
  }
  
  public showMaxCountLabel(): string {
    if (this.typeSelected) {
      switch (this.typeSelected.type) {
        case CustomFieldTypes.MULTIPLE_CHECKBOX:
          return this.translate.FORM.MAX_COUNT_OPTIONS;

        default:
          return this.translate.FORM.MAX_COUNT_TEXT
      }
    }
    
    return this.translate.FORM.MAX_COUNT_TEXT
  }

  public addOption(): void {
    this.options.push({
      id: this.indexItemNew + this.options.length,
      name: '',
      order: this.options.length,
      isNew: true
    })
    
    if (this.tableOptions) this.tableOptions.reloadItems(); 
  }

  inputOnBlur(event: any, item: CustomFieldOption) {
    item.name = event.target.value;
  }

  reorderAction(orders: any, parms: any) {
    
    if (orders.type === 'reorder') {
      this.options.map(item => {
        const dragItem = orders.data.find(dItem => {
          return parseInt(dItem.id) === item.id
        });
        item.order = dragItem.order;
      });
    }
  }

  public removeOption(option: CustomFieldOption): void {
      // @ts-ignore
      const object = this.options.find((item) => {
        return item.id === option.id
      });
      this.options.splice(this.options.indexOf(object), 1);
      this.options.map((value, indexItem) => {
        value.order = indexItem-1;
      });
      
      if (!option.isNew) {
        this.optionsDeleted.push(option.id);
      } 
  }
}
