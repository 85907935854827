import { environment } from './../../../environments/environment';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map'
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FacebookService, InitParams } from 'ngx-facebook';
import { trim } from 'lodash';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
   public token: string
   
   constructor(
    private http: HttpClient,
    private router: Router,
    private fb: FacebookService,
   ) { 
      var currentUser = JSON.parse(localStorage.getItem('current_user_crossplus'));
      this.token = currentUser && currentUser.token;
   }

   login(email: string, password: string) : Observable<any>
   {
      const parameters = { "email": email, "password": password }

      return this.http.post('public/login', parameters)
         .map((response: Response) => {

            let user = response as any

            if (user.firstname && user.lastname) {
              user.fullname = `${trim(user.firstname)} ${trim(user.lastname)}`
            }

            let token: string = user && user.token
            // let accessess = response["accesses"]
            // let accessActive = (accessess.length > 0) ? accessess[0] : null
            // let confirmedEmail = user.confirmedEmail

            if (token) {
               // set token property
               this.token = token;

               localStorage.setItem('current_user_crossplus', JSON.stringify({
                  token: token,
                  user: user
               }));

              // Add token in cookie
              document.cookie = `crossplus_token=${token}; domain=${environment.domain}; expires=${moment().add(1, 'years').format('ddd[,] DD MMM YYYY HH:mm:ss')} UTC, path=/`;
              document.cookie = `crossplus_user=${JSON.stringify(user)}; domain=${environment.domain}; expires=${moment().add(1, 'years').format('ddd[,] DD MMM YYYY HH:mm:ss')} UTC, path=/`;

               // return true to indicate successful login
              return { valid: true, user: user, token: this.token };
            } else {
               // return false to indicate failed login
               return { valid: false, user: null };
            }
         })
   }

  loginWithFacebook(facebookId: string) : Observable<any>
   {
    const parameters = { "facebook_id": facebookId }

      return this.http.post('public/login', parameters)
           .map((response: Response) => {
              let user = response["user"]
              let token = user && response["token"]
              let accessess = response["accesses"]
              let accessActive = (accessess.length > 0) ? accessess[0] : null
              let confirmedEmail = user.confirmedEmail

               if (token) {
                   // set token property
                   this.token = token;
                   localStorage.setItem('current_user_crossplus', JSON.stringify({
                       token: token,
                       user: user,
                       accesses: accessess,
                       accessActive: accessess[0]
                   }));

                 // Add token in cookie
                 document.cookie = `crossplus_token=${token}; domain=${environment.domain}; expires=${moment().add(1, 'years').format('ddd[,] DD MMM YYYY HH:mm:ss')} UTC, path=/`;
                 document.cookie = `crossplus_user=${JSON.stringify(user)}; domain=${environment.domain}; expires=${moment().add(1, 'years').format('ddd[,] DD MMM YYYY HH:mm:ss')} UTC, path=/`;

                   // return true to indicate successful login
                 return { valid: true, user: user, token: this.token, accesses: accessess, accessActive: accessActive, confirmedEmail: confirmedEmail };
               } else {
                   // return false to indicate failed login
                   return { valid: false, user: null };
               }
           })
   }

  createUser(user: any, picture: File) : Observable<any>
  {
    const formData: FormData = new FormData();
    if (picture != null) {
      formData.append('avatar', picture, picture.name);
    }

    Object.keys(user).forEach(element => {
      formData.append(element, user[element]);
    });

    return this.http.post('public/signup', formData)
      .map((response: Response) => {

        let user = response as any

        if (user.firstname && user.lastname) {
          user.fullname = `${trim(user.firstname)} ${trim(user.lastname)}`
        }

        let token = user && user.token

        if (token) {
          // set token property
          this.token = token;
          localStorage.setItem('current_user_crossplus', JSON.stringify({
            token: token,
            user: user
          }));

          // Add token in cookie
          document.cookie = `crossplus_token=${token}; domain=${environment.domain}; expires=${moment().add(1, 'years').format('ddd[,] DD MMM YYYY HH:mm:ss')} UTC, path=/`;
          document.cookie = `crossplus_user=${JSON.stringify(user)}; domain=${environment.domain}; expires=${moment().add(1, 'years').format('ddd[,] DD MMM YYYY HH:mm:ss')} UTC, path=/`;

          // return true to indicate successful login
          return { valid: true, user: user, token: this.token };
        } else {
          // return false to indicate failed login
          return { valid: false, user: null };
        }
      })
  }

  //TOKEN
  validateWithToken(code: string): Observable<any>
  {
    const parameters = { "code": code }
    
    return this.http.post('user/validateEmail/code', parameters)
      .map((response: Response) => {

        let user = response["user"]
        if (user) {
          var localItem = localStorage.getItem('current_user_crossplus')
          var localUser = JSON.parse(localStorage.getItem('current_user_crossplus')).user
          var localToken = JSON.parse(localStorage.getItem('current_user_crossplus')).token
          localUser.confirmedEmail = true

          localStorage.setItem('current_user_crossplus', JSON.stringify({
            token: localToken,
            user: localUser
          }));

          // Add token in cookie
          document.cookie = `crossplus_token=${localToken}; domain=${environment.domain}; expires=${moment().add(1, 'years').format('ddd[,] DD MMM YYYY HH:mm:ss')} UTC, path=/`;
          document.cookie = `crossplus_user=${JSON.stringify(localUser)}; domain=${environment.domain}; expires=${moment().add(1, 'years').format('ddd[,] DD MMM YYYY HH:mm:ss')} UTC, path=/`;

          return { valid: true }
        }
      })
  }

  //FORGOT PASSWORD
  forgotPassword(email: string) : Observable<any>
  {
    const parameters = {
      email: email
    }

    return this.http.post('public/forgot', parameters)
      .map((response: Response) => {

        return { valid: true }
        
      })
  }

  //Change password
  changePasswordWithToken(password:string, confirmPassword: string, token: string)
  {
    const parameters = {
      password: password,
      remember_token: token
    }
    

    return this.http.post(`public/reset`, parameters)
      .map((response: Response) => {
        return response
      })
  }

   //User Logout
  logOut(urlRedirect: string = 'login')
  {
    // clear token remove user from local storage to log user out
    this.token = null;
    localStorage.removeItem('current_user_crossplus');
    localStorage.removeItem('current_event_crossplus');
    document.cookie = `crossplus_token=; domain=${environment.domain}; expires=Thu, 01 Jan 1970 00:00:01 UTC; path=/`;
    document.cookie = `crossplus_user=; domain=${environment.domain}; expires=Thu, 01 Jan 1970 00:00:01 UTC; path=/`;
    this.router.navigate([urlRedirect]);
  }


}
