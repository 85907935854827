import { PaymentService } from './../../../../../core/services/payment/payment.service';
import { TableXComponent } from './../../../../../core/components/table-x/table-x.component';
import { CompetitorService } from './../../../../../core/services/competitor/competitor.service';
import { BoxService } from './../../../../../core/services/box.service';
import { TeamService } from './../../../../../core/services/team/team.service';
import { TeamMember } from './../../../../../core/model/team-member';
import { Competitor, CompetitorStatus } from './../../../../../core/model/competitor';
import { Team } from './../../../../../core/model/team';
import { User } from './../../../../../core/model/user';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FuseTranslationLoaderService } from '../../../../../core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';

import { locale as english } from './i18n/en-US';
import { locale as portugues } from './i18n/pt-BR';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TableConfig } from '../../../../../core/components/table-x/table-x.component';
import { UserService } from '../../../../../core/services/user/user.service';
import { FormErrorStateMatcher } from '../../../../../core/utils/form-error-state-matcher';
import { Utils } from '../../../../../core/utils';
import { Payment, PaymentMethods, PaymentStatus } from '../../../../../core/model/payment';
import { Errors } from '../../../../../core/model/errors';
import { Competition } from '../../../../../core/model/competition';
import {Event} from '../../../../../core/model/event';
import {EventGuard} from '../../../../../core/guards/event.guard';
import {Disqualified} from '../../../../../core/enum/disqualified';
import {Subscription} from 'rxjs';
import { Championship } from '../../../../../core/model/championship';
import { SubDivision } from '../../../../../core/model/sub-division';

@Component({
  selector: 'app-team-profile',
  templateUrl: './team-profile.component.html',
  styleUrls: ['./team-profile.component.scss']
})
export class TeamProfileComponent implements OnInit {
  @ViewChild('tableList', { read: TableXComponent }) tableList: TableXComponent;

  private subscriptions: Subscription = new Subscription();

  public competitor: Competitor;
  public competitions: any[] = [];
  public event: Event = null;
  public subDivisions: SubDivision[];
  public championship: Championship;
  translate: any;

  // Table
  tableConfig: TableConfig = {
    checkbox: false,
    paddingSide: true,
    massActions: null,
    massActionCountTitle: null
  };

  tableConfigTeam: TableConfig = {
    checkbox: false,
    paddingSide: true,
    massActions: null,
    massActionCountTitle: null
  };

  payments: Payment[] = [];

  tshirtSize = Utils.getTShirts();

  // Form alter member
  alterMemberForm: FormGroup;
  matchers: any;

  userForm: FormGroup;
  userFormErrors: any;

  // User
  user: any = null;

  requestErrors: string  = null;
  inactiveRequestError: string = null;

  // Box
  boxes = [];
  boxId: number;
  timeOutBoxSearch;

  managerType = 0;
  isAddMemberEnabled = true;

  // cut and wd
  confirmStatusDisqualifiedCompetitor = false;
  paramsDisqualified: any;
  successDisqualifiedCompetitor = false;
  successDiqualifiedCompetitorRequest: string;
  disqualifiedMessage: string;
  enumDisqualified = Disqualified;

  isLoading = false;
  isUpdateLoading = false;
  isLoadingAlterMember = false;
  isEmptyResult = false;
  isAlterMember = false;
  confirmStatusOpened = false;
  isLoadingConfirmStatus = false;
  isLeaderboardNameEdited = false;
  enableSubDivision = false;

  editNumber = false;
  numberForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<TeamProfileComponent>,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private teamService: TeamService,
    private boxesService: BoxService,
    private competitorService: CompetitorService,
    private paymentService: PaymentService,
    private eventGuard: EventGuard
  ) {
    this.translationLoader.loadTranslations(english, portugues);
    this.event = new Event().deserialize(this.eventGuard.getActiveEvent().event);
  }

  ngOnInit() {
    this.translateService.get('USER').subscribe((res: any) => {
      this.translate = res;
    });
    if (this.competitor.competition.enable_sub_division) {
      this.enableSubDivision = true;
    }
    this.formUser();
  }

  public competitionSelect(event: any): void {
    const id = event.value;
    
    this.competitions
      .forEach((competition: Competition, key: number) => {
        if (key === 0) {
          this.enableSubDivision = false;
        }
        if (competition.id === id) {
          this.competitor.competition = competition;
          this.enableSubDivision = competition.enable_sub_division;
        }
      });
    this.formUser();
  }

  private formUser(): void {
    this.managerType = (!this.competitor.team.members || this.competitor.team.members.length < this.competitor.competition.team_size) ? 0 : 1;
    this.isAddMemberEnabled = (this.competitor.team.members) ? (this.competitor.team.members.length < this.competitor.competition.team_size) : true;

    this.userForm = this.formBuilder.group({
      teamName: [this.competitor.team.name.toUpperCase(), Validators.required],
      competition: [this.competitor.competition.id],
      box: [(this.competitor.team.box) ? this.competitor.team.box : ''],
    });

    this.numberForm = this.formBuilder.group({
      number: [this.competitor.number ? this.competitor.number : this.competitor.competition.start_number, [
        Validators.required, 
        Validators.min(this.competitor.competition.start_number)
      ]]
    });

    this.alterMemberForm = this.formBuilder.group({
      member: (this.managerType === 0) ? [''] : ['', Validators.required],
      email: ['', Validators.email],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      // leaderboardName: ['', [Validators.required, Validators.minLength(4)]],
      leaderboardName: [{
        value: '',
        disabled: true
      }, [Validators.required, Validators.minLength(4)]],
      tShirt: [''],
      box: [''],
      radioManagerType: [(this.isAddMemberEnabled) ? this.managerType : 1]
    });

    this.matchers = {
      member: new FormErrorStateMatcher(),
      email: new FormErrorStateMatcher(),
      firstname: new FormErrorStateMatcher(),
      lastName: new FormErrorStateMatcher(),
      leaderboardName: new FormErrorStateMatcher(),
      name: new FormErrorStateMatcher()
    };


    if (this.enableSubDivision) {
      this.userForm = this.formBuilder.group({
        teamName: [this.competitor.team.name.toUpperCase(), Validators.required],
        competition: [this.competitor.competition.id],
        subDivision: [this.competitor.sub_division ? this.competitor.sub_division.id : null, Validators.required],
        box: [(this.competitor.team.box) ? this.competitor.team.box : ''],
      });
  
      this.alterMemberForm = this.formBuilder.group({
        member: (this.managerType === 0) ? [''] : ['', Validators.required],
        email: ['', Validators.email],
        firstname: ['', Validators.required],
        lastname: ['', Validators.required],
        // leaderboardName: ['', [Validators.required, Validators.minLength(4)]],
        leaderboardName: [{
          value: '',
          disabled: true
        }, [Validators.required, Validators.minLength(4)]],
        tShirt: [''],
        box: [''],
        radioManagerType: [(this.isAddMemberEnabled) ? this.managerType : 1]
      });
  
      this.matchers = {
        member: new FormErrorStateMatcher(),
        email: new FormErrorStateMatcher(),
        firstname: new FormErrorStateMatcher(),
        lastName: new FormErrorStateMatcher(),
        leaderboardName: new FormErrorStateMatcher(),
        name: new FormErrorStateMatcher(),
        subDivision: new FormErrorStateMatcher(),
      };
    }

    this.formsValueChange();
    this.getPayments();
  }

  getPayments() {
    this.paymentService.getTeamPayments(this.competitor.team.id)
      .subscribe(
        result => {
          this.payments = result;
        }, err => {
        }
      );
  }

  getPaymentMethodName(payment: Payment): string
  {
    switch (payment.payment_method) {
      case PaymentMethods.CREDITCARD:
        return this.translate.PAYMENT_METHOD.CREDIT_CARD;

      case PaymentMethods.BOLETO:
        return this.translate.PAYMENT_METHOD.BOLETO;
    
      default:
        return '';
    }
  }

  getPaymentStatus(payment: Payment, type: boolean = false): string 
  {
    switch (payment.payment_status) {
      case PaymentStatus.APPROVED:
        return (!type) ? this.translate.PAYMENT_STATUS.APPROVED : 'success';
    
      case PaymentStatus.WAITING:
        return (!type) ? this.translate.PAYMENT_STATUS.WAITING : 'normal';
    
      case PaymentStatus.REFUSED:
        return (!type) ? this.translate.PAYMENT_STATUS.REFUSED : 'danger';
    
      case PaymentStatus.REFUNDED:
        return (!type) ? this.translate.PAYMENT_STATUS.REFUNDED : 'purple';
    
      case PaymentStatus.CHARGEBACK:
        return (!type) ? this.translate.PAYMENT_STATUS.CHARGEBACK : 'purple';
    
      default:
        return '';
    }
  }

  formatCurrency(value: string, currency: string): string
  {
    return Utils.formatCurrency(value, currency);
  }

  public editNumberCompetitor(): void {
    const parameters = {
      number: this.numberForm.value.number,
      competition_id: this.userForm.value.competition,
    };

    const subscription = this.competitorService.updateCompetitor(parameters, this.competitor.id)
      .subscribe(
        result => {
          const competitorUpdated = new Competitor().deserialize(result);
          this.competitor.leaderboard_name = this.userForm.value.teamName.toUpperCase();
          this.competitor.team.name = this.userForm.value.teamName.toUpperCase();
          this.competitor.competition.id = this.userForm.value.competition;
          this.competitor.competition_id = this.userForm.value.competition;
          this.isUpdateLoading = false;

          const competition = this.competitions.filter(obj => {
            return obj.id === this.userForm.value.competition;
          });

          this.competitor.competition = new Competition().deserialize(competition[0]);
          this.dialogRef.close({
            action: 'update',
            object: competitorUpdated,
            toastr: {
              title: this.translate.TOASTR.UPDATE.TITLE,
              description: this.translate.TOASTR.UPDATE.DESCRIPTION,
              type: 'success'
            }
          });
        }, err => {
          this.isUpdateLoading = false;
          const errors: Errors = new Errors().deserialize((err.error as any));
          this.requestErrors = errors.getFullMessages();
        }
      );
    
    this.subscriptions.add(subscription);
  }

  // Update Competitor
  updateCompetitor() {
    // validate all form fields
    if (!this.userForm.valid) {
      Utils.formValid(this.userForm);
      return;
    }

    const parameters = {
      leaderboard_name: this.userForm.value.teamName.toUpperCase(),
      team_name: this.userForm.value.teamName.toUpperCase(),
      competition_id: this.userForm.value.competition,
      sub_division_id: this.enableSubDivision ? this.userForm.value.subDivision : null,
      box_id: (this.userForm.value.box.id) ? this.userForm.value.box.id : null,
      box: (!this.userForm.value.box.id && this.userForm.value.box) ? this.userForm.value.box.toUpperCase() : null,
    };
    

    this.isUpdateLoading = true;
    this.competitorService.updateCompetitor(parameters, this.competitor.id)
      .subscribe(
        result => {
          const competitorUpdated = new Competitor().deserialize(result);
          this.competitor.leaderboard_name = this.userForm.value.teamName.toUpperCase();
          this.competitor.team.name = this.userForm.value.teamName.toUpperCase();
          this.competitor.competition.id = this.userForm.value.competition;
          this.competitor.competition_id = this.userForm.value.competition;
          this.isUpdateLoading = false;

          const competition = this.competitions.filter(obj => {
            return obj.id === this.userForm.value.competition;
          });

          this.competitor.competition = new Competition().deserialize(competition[0]);
          this.dialogRef.close({
            action: 'update',
            object: competitorUpdated,
            toastr: {
              title: this.translate.TOASTR.UPDATE.TITLE,
              description: this.translate.TOASTR.UPDATE.DESCRIPTION,
              type: 'success'
            }
          });
        }, err => {
          this.isUpdateLoading = false;
        }
      );
  }

  cancelUpdateCompetitor() {
    this.userForm.controls['teamName'].setValue(this.competitor.team.name);
    this.userForm.controls['competition'].setValue(this.competitor.competition.id);
  }

  // Action alter member
  alterMember() {
    if (this.alterMemberForm.invalid) {
      Utils.formValid(this.alterMemberForm);
      return;
    }
    
    const parameters = {
      leaderboard_name: `${this.alterMemberForm.value.firstname} ${this.alterMemberForm.value.lastname}`.toUpperCase(),
      size: (this.alterMemberForm.value.tShirt) ? this.alterMemberForm.value.tShirt : null,
      box_id: (this.alterMemberForm.value.box && this.alterMemberForm.value.box.id) ? this.alterMemberForm.value.box.id : null,
      box: (this.alterMemberForm.value.box && !this.alterMemberForm.value.box.id) ? this.alterMemberForm.value.box.toUpperCase() : null,
      firstname: this.alterMemberForm.value.firstname.toUpperCase(),
      lastname: this.alterMemberForm.value.lastname.toUpperCase(),
      email: (this.alterMemberForm.value.email) ? this.alterMemberForm.value.email.toLowerCase() : null, 
    };
    
    if (this.user !== null) { parameters['user_id'] = this.user.id; }
    
    this.isLoadingAlterMember = true;
    this.requestErrors = null;

    if (this.managerType === 1) {
      this.teamService.alterMember(parameters, this.competitor.team.id, this.alterMemberForm.value.member)
        .subscribe(
          result => {
            this.competitor.team = result;
            if (this.tableList) { this.tableList.reloadItems(); }
            this.cancelAlterMember();
            this.getConfigManagerMember();
          }, err => {
            this.isLoadingAlterMember = false;
            const errors: Errors = new Errors().deserialize((err.error as any));
            this.requestErrors = errors.getFullMessages();
          }
        );
    } else {
      parameters['team_id'] = this.competitor.team.id;
      
      this.teamService.createMember(parameters)
        .subscribe(
          result => {
            if (this.competitor.team.members === null || this.competitor.team.members.length === 0) {
              this.competitor.user = result.members[0].user;
            }
            this.competitor.team = result;
            if (this.tableList) { this.tableList.reloadItems(); }
            this.cancelAlterMember();
            this.getConfigManagerMember();
          }, err => {
            this.isLoadingAlterMember = false;
            const errors: Errors = new Errors().deserialize((err.error as any));
            this.requestErrors = errors.getFullMessages();
          }
        );
    } 
  }

  getConfigManagerMember()
  {
    this.managerType = (!this.competitor.team.members || this.competitor.team.members.length < this.competitor.competition.team_size) ? 0 : 1;
    this.isAddMemberEnabled = (!this.competitor.team.members || this.competitor.team.members.length < this.competitor.competition.team_size);
    this.alterMemberForm.controls['radioManagerType'].setValue(this.managerType);
  }

  // Cancel Alter Member
  cancelAlterMember() {
    this.isLoadingAlterMember = false;
    this.isAlterMember = !this.isAlterMember;
    this.alterMemberForm.reset();
    this.requestErrors = null;
    // this.alterMemberForm.controls['member'].setValue('')
    // this.alterMemberForm.controls['email'].setValue('')
    // this.alterMemberForm.controls['firstname'].setValue('')
    // this.alterMemberForm.controls['lastname'].setValue('')
    // this.alterMemberForm.controls['leaderboardName'].setValue('')
    // this.alterMemberForm.controls['tShirt'].setValue('')
    // this.alterMemberForm.controls['box'].setValue('')

    this.user = null;
    this.isLeaderboardNameEdited = false;
  }

  // Set Captain
  setTeamCaptain(teamMember: TeamMember) {
    teamMember.isLoading = true;
    this.teamService.setTeamCaptain(this.competitor.team, teamMember.user.id)
      .subscribe(
        result => {
          teamMember.isLoading = false;
          this.competitor.team.members.forEach(member => {
            member.capitan = (member.user.id === teamMember.user.id);
          });
          this.competitor.user = teamMember.user;
          this.competitor.team.user = teamMember.user;
          this.competitor.team.user_id = teamMember.user.id;
          this.competitor.team.user_name = teamMember.user.fullname;
        }, err => {
          teamMember.isLoading = false;
        }
      );
  }

  // Active/Inactive
  setActiveOrInactive()
  {
    const parameters = {
      status: (this.competitor.status === CompetitorStatus.ACTIVE) ? CompetitorStatus.INACTIVE : CompetitorStatus.ACTIVE
    };
    
    this.isLoadingConfirmStatus = true;

    this.competitorService.updateCompetitor(parameters, this.competitor.id)  
      .subscribe(
        result => {
          this.isLoadingConfirmStatus = false;
          this.competitor.status = (this.competitor.status === CompetitorStatus.ACTIVE) ? CompetitorStatus.INACTIVE : CompetitorStatus.ACTIVE;
          this.confirmStatusOpened = !this.confirmStatusOpened;
          this.dialogRef.close({
            action: 'remove-to-list',
            toastr: {
              title: (this.competitor.status === CompetitorStatus.ACTIVE) ? this.translate.TOASTR.ACTIVE.TITLE : this.translate.TOASTR.INACTIVE.TITLE,
              description: (this.competitor.status === CompetitorStatus.ACTIVE) ? this.translate.TOASTR.ACTIVE.DESCRIPTION : this.translate.TOASTR.INACTIVE.DESCRIPTION,
              type: 'success'
            }
          });
        }, err => {
          this.isLoadingConfirmStatus = false;
          this.confirmStatusOpened = !this.confirmStatusOpened;

          const errors: Errors = new Errors().deserialize((err.error as any));
          this.inactiveRequestError = errors.getFullMessages();
        }
      );  
  }

  // Remove member
  removeMember(teamMember: TeamMember, index: number) {
    this.teamService.deleteMember(teamMember.id)
      .subscribe(
        result => {
          this.competitor.team.members.splice(index, 1);
          if (this.competitor.team.members && this.competitor.team.members.length > 0) {
            this.competitor.team.members.forEach(member => {
              member.capitan = false;
            });

            this.competitor.team.members[0].capitan = true;
          }
          this.getConfigManagerMember();
          if (this.tableList) { this.tableList.reloadItems(); }
        }, err => {
        }
      );
  }

  // search box
  searchBox(name: string) {
    clearTimeout(this.timeOutBoxSearch);
    this.timeOutBoxSearch = setTimeout(() => {
      if (name !== null && name.length > 0) {
        this.boxesService.getListBoxes(name)
          .subscribe(
            result => {
              this.boxes = result;
            }, err => {
              this.boxes = [];
            }
          );
      } else {
        this.boxes = [];
      }
    }, 100);
  }

  displayFnBox(box: any): string {
    return box ? box.name.toUpperCase() : box;
  }

  boxSelected(event) {
    this.alterMemberForm.controls['box'].setValue(event);
    this.boxId = event.id;
  }

  // Search User
  searchUser(event) {
    const email = event.target.value;
    if (!this.alterMemberForm.controls.email.hasError('email') && email.length > 0) {
      this.userService.searchUserByEmail(email)
        .subscribe(
          result => {
            this.user = result;
            this.alterMemberForm.controls['firstname'].setValue(result.firstname);
            this.alterMemberForm.controls['lastname'].setValue(result.firstname);
            this.alterMemberForm.controls['leaderboardName'].setValue(result.leaderboard_name);
            this.alterMemberForm.controls['box'].setValue((result.box) ? result.box : this.alterMemberForm.value.box);
            this.alterMemberForm.controls['tShirt'].setValue(result.size);
            if (result.box) { this.boxId = result.box.id; }
          }, err => {
            this.user = null;

          }
        );
    } 
    else if (event.target.value || event.target.value.length === 0) {
      this.user = null;
    }
  }

  getCompetitorStatus(): string {
    switch (this.competitor.status) {
      case CompetitorStatus.PEDDING:
        return this.translate.STATUS_COMPETITOR.PEDDING;

      case CompetitorStatus.ACTIVE:
        return this.translate.STATUS_COMPETITOR.ACTIVE;

      case CompetitorStatus.INACTIVE:
        return this.translate.STATUS_COMPETITOR.INACTIVE;

      case CompetitorStatus.CANCELLED:
        return this.translate.STATUS_COMPETITOR.CANCELLED;
    
      default:
        return '';
    }
  }

  // Forms values change
  formsValueChange() {
    this.alterMemberForm.get('radioManagerType').valueChanges.subscribe(value => {
      this.managerType = value;

      if (value === 1) {
        this.alterMemberForm.get('member').setValidators([Validators.required]);
      } else {
        this.alterMemberForm.get('member').clearValidators();
      }
      this.alterMemberForm.get('member').updateValueAndValidity();
    });

    this.alterMemberForm.get('firstname').valueChanges.subscribe(val => {
      if (!this.isLeaderboardNameEdited) {
        const lastname = (this.alterMemberForm.value.lastname) ? this.alterMemberForm.value.lastname : '';
        this.alterMemberForm.controls['leaderboardName'].setValue(`${val} ${lastname}`.toUpperCase());
      }
    });

    this.alterMemberForm.get('lastname').valueChanges.subscribe(val => {
      if (!this.isLeaderboardNameEdited) {
        const firstname = (this.alterMemberForm.value.firstname) ? this.alterMemberForm.value.firstname : '';
        this.alterMemberForm.controls['leaderboardName'].setValue(`${firstname} ${val}`.toUpperCase());
      }
    });
  }

  // Form
  leaderboardNameFocus(event: any) {
    this.isLeaderboardNameEdited = true;
  }

  leaderboardNameBlur(event: any) {
    this.isLeaderboardNameEdited = (this.alterMemberForm.value.leaderboardName !== `${this.alterMemberForm.value.firstname} ${this.alterMemberForm.value.lastname}`);
  }


  public disqualifiedCompetitor(type: number): void {
    this.confirmStatusDisqualifiedCompetitor = true;
    let customFieldName: string;
    let customFieldType = '';
    if (type === Disqualified.CUT) {
      this.paramsDisqualified = {
        disqualified: 0,
        is_cut: 1,
      };
      customFieldName = this.translate.DISQUALIFIED.CUT;
      customFieldType = this.translate.DISQUALIFIED.TYPE_CUT;
    }
    if (type === Disqualified.WD) {
      this.paramsDisqualified = {
        disqualified: 1,
        is_cut: 0,
      };
      customFieldName = this.translate.DISQUALIFIED.WD;
      customFieldType = this.translate.DISQUALIFIED.TYPE_WD;
    }
    if (type === Disqualified.CANCEL) {
      this.paramsDisqualified = {
        disqualified: 0,
        is_cut: 0,
      };
      customFieldName = this.translate.DISQUALIFIED.REMOVE;
    }
    
    const subscription = this.translateService
      .get('USER.DISQUALIFIED.MESSAGE', { custom_field_name: customFieldName.toLowerCase(), custom_field_type: customFieldType  })
      .subscribe((disqualifiedMessage: string) => {
        this.disqualifiedMessage = disqualifiedMessage;
      });
    this.subscriptions.add(subscription);
  }

  public confirmDisqualifiedCompetitor(): void {
    this.isLoadingConfirmStatus = true;
    const subscription = this.competitorService
      .disqualifiedCompetitor(this.paramsDisqualified, this.competitor.id)
      .subscribe((result: Competitor) => {
        this.competitor = result;
        this.isLoadingConfirmStatus = false;
        this.confirmStatusDisqualifiedCompetitor = false;
        this.successDisqualifiedCompetitor = true;
        this.successDiqualifiedCompetitorRequest = this.translate.DISQUALIFIED.SUCCESS;
      });

    this.subscriptions.add(subscription);
  }
}
