import { Utils } from './../../../../../../core/utils';
import { SubscriptionPayment } from './../../../../../../core/model/subscription-payment';
import { Component, OnInit, EventEmitter } from '@angular/core';
import { locale as english } from './i18n/en-US';
import { locale as portugues } from './i18n/pt-BR';
import { MatDialogRef, MatIconRegistry } from '@angular/material';
import { FuseTranslationLoaderService } from '../../../../../../core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-modal-confirm-payment',
  templateUrl: './modal-confirm-payment.component.html',
  styleUrls: ['./modal-confirm-payment.component.scss']
})
export class ModalConfirmPaymentComponent implements OnInit {

  public subscriptionPayment: SubscriptionPayment = null

  public confirmMessage: string;
  public type: string;

  //header
  public headerDialog: string = null
  public iconPath: string = null
  public textHeaderColor: string
  public backgroundHeaderColor: string

  //content
  public titleDialog: string = null
  public dialogContent: string = null
  public dialogConfirm: boolean = false
  public cancelButton: string = "CANCELAR"
  public actionButton: string = "EXCLUIR"
  public object: any

  // Loading
  public confirmLoading: boolean = false
  public confirmActionRequest: boolean = false
  public action: any

  // Event
  public dialogEvent = new EventEmitter()

  // Translate
  translate: any

  constructor(
    public dialogRef: MatDialogRef<ModalConfirmPaymentComponent>,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer,
    private iconRegistry: MatIconRegistry,
  ) {
    this.translationLoader.loadTranslations(english, portugues);

    this.translateService.get("PAYMENT").subscribe((res: any) => {
      this.translate = res
    });
  }

  
  ngOnInit() {
    if (this.titleDialog === null) {
      this.titleDialog = this.subscriptionPayment === null ? this.translate.SUBSCRIPTION_SUCCESS_TITLE : this.subscriptionPayment.isBoleto ? this.translate.BOLETO_SUCCESS_TITLE : this.translate.CARD_SUCCESS_TITLE
    }
    if (this.dialogContent === null) {
      this.dialogContent = this.subscriptionPayment === null ? this.translate.SUBSCRIPTION_SUCCESS_DESCRITPION : this.subscriptionPayment.isBoleto ? this.translate.BOLETO_SUCCESS_DESCRIPTION : this.translate.CARD_SUCCESS_DESCRIPTION
    }
    
    this.iconPath = this.subscriptionPayment && this.subscriptionPayment.isBoleto ? "./assets/icons/registration/ic-boleto-dialog.svg" : "./assets/icons/dialog/ic-dialog-success.svg"
  }

  downloadBoleto() {
    Utils.openInOtherWindow(this.subscriptionPayment.boleto_url)
  }

  confirmAction() {
    if (this.confirmActionRequest) {
      this.dialogEvent.emit({
        type: 'confirm',
        object: this.subscriptionPayment
      });
    }
    else {
      this.dialogRef.close(this.subscriptionPayment);
    }
  }

}
