import * as moment from 'moment';
import {AbstractControl, FormControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {ValidCreditCard} from './credit-card';

export function dateValidator(dateFormat: string = 'YYYY-MM-DD'): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control && control.value && !moment(control.value, dateFormat, true).isValid()) {
      return {'invalid': true};
    }
    return null;
  };
}

export function maxDateValidator(maxDate: string, dateFormat: string = 'YYYY-MM-DD'): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const dateInput = moment(control.value, dateFormat, true);
    if (control && control.value && dateInput.isValid()) {
      const maxDateMoment = moment(maxDate, dateFormat, true);
      if (dateInput.isAfter(maxDateMoment)) {
        return {'max_date_invalid': true};
      }
    }
    return null;
  };
}

export function requiredWithConditional(inputConditional: boolean): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if ((control.value == null || control.value === undefined || control.value === '') && inputConditional) {
      return {'required': true};
    }
    return null;
  };
}

export function invalidInput(isInvalid: boolean): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if ((control.value !== null || control.value !== undefined || control.value !== '') && isInvalid) {
      return {'invalid': true};
    }
    return null;
  };
}

export function CreditCardValidate(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {

    if (control.value !== null && control.value !== undefined && control.value !== '' && control.value > 12 && !ValidCreditCard(control.value)) {
      return {'invalid': true};
    }

    return null;
  };
}

export function confirmPasswordEquals(control: AbstractControl) {
  if (!control.parent || !control) {
    return;
  }

  const password = control.parent.get('password');
  const passwordConfirm = control.parent.get('confirmPassword');

  if (!password || !passwordConfirm) {
    return;
  }

  if (passwordConfirm.value === '') {
    return;
  }

  if (password.value !== passwordConfirm.value) {
    return {
      passwordsNotMatch: true
    };
  }
}

export function requiredFileType(type: string | string[]) {
  return function (control: FormControl) {
    const file = control.value;
    if (file) {
      if (file.split('.').length === 2) {
        const extension = file.split('.')[1];
        if (extension) {
          if (Array.isArray(type)) {
            let isValid = false;
            type.forEach((element: string) => {
              if (element.toLowerCase() === extension.toLowerCase()) {
                isValid = true;
              }
            });

            if (isValid) {
              return;
            }

            return {
              requiredFileType: true
            };
          }
          if (type.toLowerCase() === extension.toLowerCase()) {
            return;
          }
        }
      }
    }
    return {
      requiredFileType: true
    };
  };
}

/** Validador de CPF */
export function validateCPF(control: AbstractControl): ValidationErrors | null {
  const cpf = control.value ? control.value.replace(/\D/g, '') : ''; // Remove todos os caracteres não numéricos

  if (!cpf || cpf.length !== 11) {
    return {invalidCPF: true};
  }

  let sum = 0;
  let remainder;

  for (let i = 1; i <= 9; i++) {
    sum += parseInt(cpf.charAt(i - 1), 10) * (11 - i);
  }
  remainder = (sum * 10) % 11;
  if (remainder === 10 || remainder === 11) {
    remainder = 0;
  }
  if (remainder !== parseInt(cpf.charAt(9), 10)) {
    return {invalidCPF: true};
  }

  sum = 0;
  for (let i = 1; i <= 10; i++) {
    sum += parseInt(cpf.charAt(i - 1), 10) * (12 - i);
  }
  remainder = (sum * 10) % 11;
  if (remainder === 10 || remainder === 11) {
    remainder = 0;
  }
  if (remainder !== parseInt(cpf.charAt(10), 10)) {
    return {invalidCPF: true};
  }

  return null;
}

/** Validador de CNPJ */
export function validateCNPJ(control: AbstractControl): ValidationErrors | null {
  const cnpj = control.value ? control.value.replace(/\D/g, '') : '';

  if (!cnpj || cnpj.length !== 14) {
    return {invalidCNPJ: true};
  }

  let size = cnpj.length - 2;
  let numbers = cnpj.substring(0, size);
  let digits = cnpj.substring(size);
  let sum = 0;
  let pos = size - 7;

  for (let i = size; i >= 1; i--) {
    sum += parseInt(numbers.charAt(size - i), 10) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }

  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result !== parseInt(digits.charAt(0), 10)) {
    return {invalidCNPJ: true};
  }

  size++;
  numbers = cnpj.substring(0, size);
  sum = 0;
  pos = size - 7;

  for (let i = size; i >= 1; i--) {
    sum += parseInt(numbers.charAt(size - i), 10) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }

  result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result !== parseInt(digits.charAt(1), 10)) {
    return {invalidCNPJ: true};
  }

  return null;
}

export function validateBRL(control: AbstractControl): ValidationErrors | null {
  const value = control.value;

  // Remove espaços, vírgulas ou pontos (caracteres não numéricos)
  const sanitizedValue = value ? value.replace(/\D/g, '') : '';

  // Verifica se o valor tem pelo menos 1 dígito
  if (!sanitizedValue || sanitizedValue.length === 0) {
    return { invalidMonthlyIncome: true };
  }

  // Verifica se o valor é um número válido e é maior que zero
  const numberValue = parseFloat(sanitizedValue) / 100; // Para lidar com centavos
  if (isNaN(numberValue) || numberValue <= 0) {
    return { invalidMonthlyIncome: true };
  }

  return null; // Valor válido
}

export function phoneValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const phone = control.value;
    // Verifique se o número tem entre 10 e 11 dígitos numéricos
    const validPhone = phone ? phone.replace(/\D/g, '').length >= 10 : false;
    return validPhone ? null : { invalidPhone: true };
  };
}

export function dualPhoneValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const phone = control.value ? control.value.replace(/\D/g, '') : '';
    const isValid = phone.length === 10 || phone.length === 11; // Aceita 10 ou 11 dígitos
    return isValid ? null : { invalidPhone: true };
  };
}





