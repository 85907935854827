import {Injectable, OnDestroy} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Params, Router, RouterEvent} from '@angular/router';
import {BehaviorSubject, combineLatest, Observable, of, Subject, Subscription} from 'rxjs';
import {KeyValue} from '../model/key-value.model';
import {FuseNavigationService} from '../../../../../../core/components/navigation/navigation.service';
import {HeatQueryParams} from '../enums/heat-query.params';


@Injectable()
export class HeatsUrlService extends Subject<{ path: string }> implements OnDestroy {

  private queryParams = new Map<string, KeyValue>();
  private _paramsObservable: Subject<Params> = new BehaviorSubject<Params>(null);
  private subscriptions = new Subscription();
  private url = '';
  private pathParams = new Map<string, KeyValue>();
  private _queryParamsObservable: Subject<Array<KeyValue>> = new BehaviorSubject<Array<KeyValue>>(null);
  private _championshipIdChangedObservable = new Subject<boolean>();
  private currentParams: Params = new Object();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    super();
    this.registerOnParams();
    this.registerOnUrl();
  }

  public queryParamsObservable(): Observable<Array<KeyValue>> {
    return this._queryParamsObservable.asObservable();
  }

  public championshipIdChangedObservable(): Observable<boolean> {
    return this._championshipIdChangedObservable.asObservable();
  }

  public paramsObservable(): Observable<Params> {
    return this._paramsObservable.asObservable();
  }

  setQueryParam(key: string, value: string): HeatsUrlService {
    this.queryParams.set(key, {
      key: key,
      value: value
    });
    return this;
  }

  removeQueryParam(key): HeatsUrlService {
    if (this.queryParams.has(key)) {
      this.queryParams.delete(key);
    }
    return this;
  }

  public navigate(): Promise<boolean | void> {
    const url = this.url.split('?').shift();
    return this.router
      .navigate([url], {
        queryParams: this.getQueryParams(),
      }).catch((errors) => {
      });
  }

  getQueryParamsAsArray(): Array<KeyValue> {
    return Array.from(this.queryParams.values());
  }

  getQueryParams(): Object {
    const result = {};
    this.getQueryParamsAsArray()
      .forEach((param: KeyValue) => {
        result[param.key] = param.value;
      });
    return result;
  }

  getPathParamsAsArray(): Array<KeyValue> {
    return Array.from(this.pathParams.values());
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private registerOnParams(): void {
    const subscription = combineLatest(
      this.activatedRoute.params,
      this.activatedRoute.queryParams,
    )
      .subscribe(([params, queryParams]) => {
        // TODO Verificar com o John como solucionar isso
        const pathParams = this.activatedRoute.snapshot.children[0].params;
        if (!this.currentParams['championship_id']) {
          this.currentParams = pathParams;
        }
        else if (this.currentParams['championship_id'] !== pathParams['championship_id']) {
          this.currentParams = pathParams;
          this.queryParams.clear();
          this._championshipIdChangedObservable.next(true);
        }

        Object.keys(queryParams)
          .forEach((keyName: string) => {
            this.setQueryParam(keyName, queryParams[keyName]);
          });

        this._queryParamsObservable.next(this.getQueryParamsAsArray());

        Object
          .keys(pathParams)
          .forEach((keyName) => {
            this.setPathParam(keyName, pathParams[keyName]);
          });
        
        this._paramsObservable.next(pathParams as Params);

      });

    this.subscriptions.add(subscription);

  }

  private registerOnUrl(): void {
    const subscription = this.router
      .events
      .subscribe((event: RouterEvent) => {
        if (event instanceof NavigationEnd) {
          this.url = event.url;
          super.next({
            path: event.url.split('?').shift()
          });
        }
      });
    this.subscriptions.add(subscription);
  }

  private setPathParam(name: string, value: string): HeatsUrlService {
    this.pathParams.set(name, {
      key: name,
      value: value
    });
    return this;
  }

}
