import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthGuard } from '../../guards/auth.guard';
import { EventGuard } from '../../guards/event.guard';
import { Observable } from 'rxjs';
import { Competition } from '../../model/competition';
import { Payment } from '../../model/payment';
import { SubscriptionPayment } from '../../model/subscription-payment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(
    private http: HttpClient,
    private authGuard: AuthGuard,
    private eventGuard: EventGuard
  ) {
  }

  getTeamPayments(teamId: number): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const url = `admin/teams/${teamId}/payments`

    return this.http.get(url, {responseType})
      .map((response: Response) => {
        let results = response as any
        return results.map((payment: Payment) => new Payment().deserialize(payment))
      })
  }

  makePayment(parameters: any): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    // const headers = new HttpHeaders({ 'Access-Control-Allow-Origin': 'https://api.pagar.me' });

    return this.http.post(`public/registration/payments`, parameters)
      .map((response: Response) => {
        return new SubscriptionPayment().deserialize(response as any)
      })
  }

  refund(paymentId: number): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    // const headers = new HttpHeaders({ 'Access-Control-Allow-Origin': 'https://api.pagar.me' });

    return this.http.post(`admin/payments/${paymentId}/refund`, {responseType})
      .map((response: Response) => {
        return response
      })
  }

  resendEmailConfirmed(paymentId: number): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    // const headers = new HttpHeaders({ 'Access-Control-Allow-Origin': 'https://api.pagar.me' });
    return this.http.post(`admin/report/subscriptions/${paymentId}/send-email-confirmed`, {responseType})
      .map((response: Response) => {
        return response
      })
  }
}
