import { CompetitorService } from './../../../../../../core/services/competitor/competitor.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MatIconRegistry } from '@angular/material';
import { FuseTranslationLoaderService } from '../../../../../../core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BoxService } from '../../../../../../core/services/box.service';
import { UserService } from '../../../../../../core/services/user/user.service';
import { FormErrorStateMatcher } from '../../../../../../core/utils/form-error-state-matcher';

import { locale as english } from './i18n/en-US';
import { locale as portugues } from './i18n/pt-BR';
import { TeamMember } from '../../../../../../core/model/team-member';
import { User } from '../../../../../../core/model/user';
import { Utils } from '../../../../../../core/utils';
import { Errors } from '../../../../../../core/model/errors';
import { SubDivision } from '../../../../../../core/model/sub-division';
import { Championship } from '../../../../../../core/model/championship';
import { Competition } from '../../../../../../core/model/competition';


@Component({
  selector: 'app-create-participant-team',
  templateUrl: './create-participant-team.component.html',
  styleUrls: ['./create-participant-team.component.scss']
})
export class CreateParticipantTeamComponent implements OnInit {

  public competitions: any[] = [];
  public eventId: number;
  public championshipId: number;
  public subDivisions: SubDivision[];
  public championship: Championship;
  // translate
  translate: any;

  // 
  isEdit = false;
  isLoading = false;
  enableSubDivision = false;

  competitionForm: FormGroup;
  formsGroup: FormGroup[];
  competitionFormErrors: any;
  matchers: any;
  requestErrors: string = null;

  tshirtSize = Utils.getTShirts();

  // Box
  boxes = [];
  boxId: number;
  timeOutBoxSearch;

  // Members
  membersTotal = 4;
  memberText = '';

  members: TeamMember[];

  constructor(
    public dialogRef: MatDialogRef<CreateParticipantTeamComponent>,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private boxesService: BoxService,
    private userService: UserService,
    private competitorService: CompetitorService
  ) { 
    this.translationLoader.loadTranslations(english, portugues);

    //Icon Info
    iconRegistry.addSvgIcon(
      'ic-cat-info',
      sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/category/ic-cat-info.svg')
    );
  }

  ngOnInit() {
    this.translateService.get('NEW_PARTICIPANTS').subscribe((res: any) => {
      this.translate = res;
    });

    this.competitionForm = this.formBuilder.group({
      competition: new FormControl('', [Validators.required]),
      teamName: new FormControl('', [Validators.required]),
      box: new FormControl(''),
    });

    this.matchers = {
      competition: new FormErrorStateMatcher(),
      teamName: new FormErrorStateMatcher()
    };

    // var newMember = new TeamMember()
    // newMember.isOpen = true
    // newMember.capitan = true
    this.members = [
      // newMember
    ];

    this.formsGroup = [
      this.competitionForm,
      // newMember.competitorForm
    ];

    this.memberText = this.translate.MEMBERS_NOCOUNT;
  }

  // Create Competitor
  createCompetitor() {
    // validate all form fields
    if (!Utils.formsVerifyIsValid(this.formsGroup)) {
      Utils.formSetHasError(this.formsGroup);
      return;
    } 

    // Verify members count
    /*
    if (this.members.length < this.membersTotal) {
      this.requestErrors = this.translate.MEMBER_COUNT_ERROR
      return
    }
    */

    const parameters = {
      event_id: this.eventId,
      championship_id: this.championshipId,
      sub_division_id: this.enableSubDivision ? this.competitionForm.value.subDivision : null,
      competition_id: this.competitionForm.value.competition.id,
      name: this.competitionForm.value.teamName.toUpperCase(),
      box_id: (this.competitionForm.value.box.id) ? this.competitionForm.value.box.id : null,
      box: (!this.competitionForm.value.box.id && this.competitionForm.value.box) ? this.competitionForm.value.box.toUpperCase() : null,
    };

    if (this.members.length > 0) {
      const profiles: any[] = [];
      this.members.forEach(member => {
        profiles.push(member.sendToApi());
      });
      parameters['profile'] = profiles;
    }
    

    this.requestErrors = null;
    this.isLoading = true;
    this.competitorService.createTeamCompetitor(parameters)
      .subscribe(
        result => {
          this.isLoading = false;

          this.dialogRef.close({ createCompetitor: true });
        }, err => {
          const errors: Errors = new Errors().deserialize((err.error as any));
          this.requestErrors = errors.getFullMessages();
          this.isLoading = false;
        }
      ); 
  }

  // Coll
  actionCollapsible() {
    this.members.forEach(member => {
      member.isOpen = false;
    });
  }

  // Add new competition empty
  addMember() {
    if (!Utils.formsVerifyIsValid(this.formsGroup)) {
      Utils.formSetHasError(this.formsGroup);
      return;
    } 
    
    const newMember = new TeamMember();
    newMember.isOpen = true;
    if (this.members.length === 0) { newMember.capitan = true; }

    this.members.forEach(member => {
      member.isOpen = false;
    });

    this.members.push(newMember);
    this.formsGroup.push(newMember.competitorForm);

    this.translateService.get('NEW_PARTICIPANTS.MEMBERS', { count: this.members.length, total: this.membersTotal }).subscribe((res: string) => {
      this.memberText = res;
    });
  }

  // Remove competition
  removeMember(index: number) {
    const member = this.members[index];
    this.members.splice(index, 1);
    if (member.capitan && this.members.length > 0) { this.members[0].capitan = true; } 
  }

  // Set member capitain
  setCapitain(member: TeamMember) {
    this.members.forEach(m => {
      m.capitan = (m === member);
    });
  }

  // Search Box
  searchBox(name: string) {
    clearTimeout(this.timeOutBoxSearch);
    this.timeOutBoxSearch = setTimeout(() => {
      if (name.length > 0) {
        this.boxesService.getListBoxes(name)
          .subscribe(
            result => {
              this.boxes = result;
            }, err => {
              this.boxes = [];
            }
          );
      } else {
        this.boxes = [];
      }
    }, 1000);
  }

  displayFnBox(box: any): string {
    return box ? box.name.toUpperCase() : box;
  }

  returnFn(box: any): number | undefined {
    return box ? box.id : undefined;
  }

  boxSelected(event, member: TeamMember) {
    member.user.box = event;
    member.user.box_id = event.id;
  }

  // Search User
  searchUser(event, member: TeamMember) {
    if (!member.competitorForm.controls.email.hasError('email') && event.target.value && event.target.value.length > 0) {
      const email = event.target.value;
      this.userService.searchUserByEmail(email)
        .subscribe(
          result => {
            if (result) {
              member.setUser(new User().deserialize(result));
            } else {
              member.user = new User();
            }
          }, err => {
            member.user = new User();
          }
        );
    } 
    else if (event.target.value || event.target.value.length === 0) {
      member.user = new User();
      // member.competitorForm.reset()
    }
  }

  competitionChange(event) {
    const id = event.value.id;
    
    this.competitions
      .forEach((competition: Competition, key: number) => {
        if (key === 0) {
          this.enableSubDivision = false;
        }
        if (competition.id === id) {
          this.matchers = {
            ...this.matchers,
            subDivision: new FormErrorStateMatcher()
          };
          this.competitionForm.addControl('subDivision', new FormControl('', [Validators.required]));
          this.enableSubDivision = competition.enable_sub_division;
        }
      });
    if (!this.enableSubDivision) {
      delete this.matchers.subDivision;
      this.competitionForm.removeControl('subDivision'); 
    }

    this.membersTotal = event.value.team_size;

    this.translateService.get('NEW_PARTICIPANTS.MEMBERS', { count: this.members.length, total: this.membersTotal }).subscribe((res: string) => {
      this.memberText = res;
    });
  }
}
