import {ChangeDetectorRef, Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {TabBarComponent} from '../../../../../core/components/tab-bar/tab-bar.component';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {BreadcrumbsService} from '../../../../../core/components/breadcrumbs/breadcrumbs.service';
import {FuseTranslationLoaderService} from '../../../../../core/services/translation-loader.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthGuard} from '../../../../../core/guards/auth.guard';
import {EventGuard} from '../../../../../core/guards/event.guard';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog, MatIconRegistry} from '@angular/material';
import {ToastrService} from '../../../../../core/components/toastr_/toastr/toastr.service';
import {FilterPipe} from 'ngx-filter-pipe';
import {CompetitionService} from '../../../../../core/services/competition/competition.service';
import {LotService} from '../../../../../core/services/lot/lot.service';
import {WodService} from '../../../../../core/services/wod/wod.service';
import { locale as english } from './i18n/en-US';
import { locale as portugues } from './i18n/pt-BR';
import { config } from '../../../../../core/config/config';
import {Subscription} from 'rxjs';
import {Event} from '../../../../../core/model/event';

@Component({
  selector: 'app-lots',
  templateUrl: './lots.component.html',
  styleUrls: ['./lots.component.scss']
})
export class LotsComponent implements OnInit {

  @ViewChild('tabbar', { read: TabBarComponent }) tabBar: TabBarComponent;

  private subscriptions: Subscription = new Subscription();

  // Translate
  public translate: any = null;

  public championship: any = null;
  public championshipId: number = null;
  public event: Event = null;
  
  //Tabs
  tabsFilter: string[] = []
  tabIndexActive: number = 0

  constructor(
    private titleService: Title,
    private breadcrumbsService: BreadcrumbsService,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private authGuard: AuthGuard,
    private eventGuard: EventGuard,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private filterPipe: FilterPipe,
    private zone: NgZone,
    private changeDetectorRefs: ChangeDetectorRef,
    private competitionService: CompetitionService,
    private lotService: LotService,
    private wodService: WodService
  ) { }

  ngOnInit() {
    this.registerOnTranslate();
    this.registerOnActiveRouter();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  public tabAction(tabEvent: any): void {
    this.tabIndexActive = tabEvent.index
  }

  private registerOnTranslate(): void {

    this.translationLoader.loadTranslations(english, portugues);
    const subscription = this.translateService
      .get('LOTS')
      .subscribe((response: Object) => {
        this.translate = response;
      });

    this.subscriptions.add(subscription);

  }

  private registerOnActiveRouter(): void {
    this.activatedRoute.params.subscribe(
      params => {
        this.championshipId = params.championship_id

        this.event = new Event().deserialize(this.eventGuard.getActiveEvent().event)
        this.championship = this.eventGuard.getChampionshipPerId(this.championshipId)
        // this.competitions = this.championship.competitions

        if (this.translate) {
          this.titleService.setTitle(`${config.titleBase} - ${this.translate.TITLE.toUpperCase()}`)

          this.breadcrumbsService.replaceAll([{
            text: this.translate.TITLE.toUpperCase(),
            route: `/championship/lots/${this.championshipId}`
          }])
        }
      }
    ); //end router

    this.tabsFilter = []
    if (this.event.enabled_leaderboard) {
      this.tabsFilter.push(this.translate.TABS.ATHLETES.toUpperCase())
    } 
    this.tabsFilter.push(this.translate.TABS.TICKETS.toUpperCase())
  }
  
}
