import { Component, OnInit, ContentChild, Input } from '@angular/core';

@Component({
  selector: 'column',
  templateUrl: './table-x-column.component.html',
  styleUrls: ['./table-x-column.component.scss']
})
export class TableXColumnComponent implements OnInit {

  @ContentChild('dataTableCell') cellTemplate;
  @Input() property: string
  @Input() header: string
  @Input() align: string
  @Input() sortable = false
  @Input() maxWidth: string
  @Input() width: string
  @Input() classes: string
  @Input() classesBody: string
  @Input() borderLeftColor: any = null

  constructor() { }

  ngOnInit() {
    
  }
  

}
