import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import pagarme from 'pagarme/browser'

@Injectable({
  providedIn: 'root'
})
export class PagarmeService {

  constructor(
    private http: HttpClient
  ) { }

  getPublicKey(): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const url = `https://api.pagar.me/1/transactions/card_hash_key?encryption_key=${environment.pagarme_encryption_key}`

    return this.http.get(url, { responseType })
      .map((response: Response) => {
      })
  }

  // Generate Card Hash
  generateCardHash(card: any) {
    return pagarme.client.connect({ encryption_key: environment.pagarme_encryption_key })
      .then(client => client.security.encrypt(card))
      .then(card_hash => card_hash)
      .catch(error => error)
  }
}
