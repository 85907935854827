import { BoxService } from './../../../../core/services/box.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthGuard } from './../../../../core/guards/auth.guard';
import { Router } from '@angular/router';
import { AuthenticationService } from './../../../../core/services/authentication.service';
import { config } from './../../../../core/config/config';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { FuseConfigService } from '../../../../core/services/config.service';
import { fuseAnimations } from '../../../../core/animations';
import { Title } from '@angular/platform-browser';
import { FuseTranslationLoaderService } from '../../../../core/services/translation-loader.service'
import { FacebookService } from 'ngx-facebook';
import { Location } from '@angular/common';
import * as moment from 'moment';

//SERVICE
import { FacebookUserService } from '../../../../core/services/facebook.service';

// LOCALE
import { locale as english } from './i18n/en-US';
import { locale as portuguese } from './i18n/pt-BR';
import { HttpClient } from '@angular/common/http';
import { GooglePlacesService } from '../../../../core/services/google-places.service';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { FormErrorStateMatcher } from '../../../../core/utils/form-error-state-matcher';
import { dateValidator } from '../../../../core/utils/custom-validators';
import { MatDialog } from '@angular/material';
import { CropDialogComponent } from '../../../../core/components/crop-dialog/crop-dialog.component';
import { Utils } from '../../../../core/utils';


@Component({
  selector: 'register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  animations: fuseAnimations
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  registerFormErrors: any;
  matchers: any

  registerError: boolean = false
  hidePassword: boolean = true
  hideConfirmPassword: boolean = true
  isBithdayInvalid: boolean = false
  loadingButton: boolean = false
  emailError: string 
  genders = []
  cities = []
  boxes = []
  placeId: string = ''
  boxId: number
  public birthdayModel = ""
  public birthdayModelAPI = ""
  url: string = "assets/icons/content/placeholder-picture.svg"
  public fileToUpload: any = null
  userFacebook = {
    userID: '',
    name: '',
    lastName: '',
    email: '',
    gender: '',
    birthday: ''
  }
  translateObj: any

  timeOutBoxSearch

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private titleService: Title,
    private fuseConfig: FuseConfigService,
    private router: Router,
    private authGuard: AuthGuard,
    private authenticationService: AuthenticationService,
    private fb: FacebookService,
    private fbUserService: FacebookUserService,
    private http: HttpClient,
    private location: Location,
    private googlePlacesService: GooglePlacesService,
    private boxesService: BoxService,
    public dialog: MatDialog,
  ) { 
    this.translationLoader.loadTranslations(english, portuguese);

    this.fuseConfig.setSettings({
      layout: {
        navigation: 'none',
        toolbar: 'none',
        footer: 'none'
      }
    });

    //verify user logged
    this.authGuard.canActivateWithoutRedirect()

    this.registerFormErrors = {
      firstname: {},
      lastname: {},
      email: {},
      password: {},
      confirmPassword: {},
      gender: {},
      box: {},
      birthdate: {
        invalid: false
      },
      city: {},
      terms: {}
    };

    //verify user logged
    this.authGuard.canActivateWithoutRedirect()

  }


  ngOnInit() {
    // Set page Tilte
    this.translate.get("REGISTER").subscribe((res: any) => {
      this.translateObj = res
      this.titleService.setTitle(`${config.titleBase} - ${res.TITLE}`)
      this.genders = [
        {
          value: 1,
          text: res.GENDER_MALE
        },
        {
          value: 2,
          text: res.GENDER_FEMALE
        }
      ]
    });

    // Facebook
    var userFacebook = this.fbUserService.getUser()
    if (userFacebook) {
      this.userFacebook = userFacebook
      this.birthdayModel = userFacebook.birthday.format(`${this.translateObj.DATE_FORMAT}`)
      this.url = userFacebook.userImage

      // if (userFacebook.userImage && userFacebook.userImage != "") {
      //   this.getDataUri(userFacebook.userImage, function(dataUri){
      //     this.fileToUpload = dataUri
      //   })
      // }
    }
    

    this.registerForm = this.formBuilder.group({
      firstname: [(userFacebook && userFacebook.name) ? userFacebook.name : '', Validators.required],
      lastname: [(userFacebook && userFacebook.lastName) ? userFacebook.lastName : '', Validators.required],
      email: [(userFacebook && userFacebook.email) ? userFacebook.email : '', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6), confirmPasswordEquals]],
      gender: ['', Validators.required],
      birthdate: ['', [Validators.required, dateValidator(this.translateObj.DATE_FORMAT)]],
      terms: ['', Validators.required],
      box: [''],
      city: ['', Validators.required]
    });

    this.matchers = {
      firstname: new FormErrorStateMatcher(),
      lastname: new FormErrorStateMatcher(),
      email: new FormErrorStateMatcher(),
      password: new FormErrorStateMatcher(),
      confirmPassword: new FormErrorStateMatcher(),
      gender: new FormErrorStateMatcher(),
      birthdate: new FormErrorStateMatcher(),
      terms: new FormErrorStateMatcher(),
      city: new FormErrorStateMatcher(),
    }
    

    this.registerForm.get('birthdate').valueChanges.subscribe(val => {
      let newDate = moment(val, `${this.translateObj.DATE_FORMAT}`)
      this.birthdayModelAPI = newDate.format('DD/MM/YYYY')
    })    
  }

  // getDataUri(url, callback) {
  //   var image = new Image();

  //   image.onload = function () {
  //     var canvas = document.createElement('canvas');
  //     canvas.width = 200; // or 'width' if you want a special/scaled size
  //     canvas.height = 200; // or 'height' if you want a special/scaled size

  //     canvas.getContext('2d').drawImage(null, 0, 0);

  //     // Get raw image data
  //     callback(canvas.toDataURL('image/png').replace(/^data:image\/(png|jpg);base64,/, ''));

  //     // ... or get as Data URI
  //     callback(canvas.toDataURL('image/png'));
  //   };

  //   image.src = url;
  // }

  register()
  {
    // validate all form fields
    if (!this.registerForm.valid) {
      Utils.formValid(this.registerForm)
      return
    } 

    var user = {
      firstname: this.registerForm.value.firstname.toUpperCase(),
      lastname: this.registerForm.value.lastname.toUpperCase(),
      email: this.registerForm.value.email,
      password: this.registerForm.value.password,
      gender: this.registerForm.value.gender,
      birthdate: this.birthdayModelAPI,
      type: 1,
      city: this.registerForm.value.city.toUpperCase(),
      place_id: this.placeId
    }

    if (this.registerForm.value.box && this.registerForm.value.box.id) {
      user['box_id'] = this.registerForm.value.box.id
    }

    if (!this.registerForm.value.box.id && this.registerForm.value.box) {
      user['box'] = this.registerForm.value.box.toUpperCase()
    }

    this.loadingButton = true
    this.authenticationService.createUser(user, this.fileToUpload)
      .subscribe(
        result => {
          this.loadingButton = false

          if (result.valid) {
            this.router.navigate(['myprofile']);
          }

        }, err => {
          this.loadingButton = false
          const errorInputs = err.error.errors
          errorInputs.forEach(error => {
            if (this.registerForm.get(error.field)) {
              var message = error.message
              this.registerFormErrors[error.field] = {};

              const control = this.registerForm.get(error.field);
              control.setErrors({
                valid: false,
                message: message
              })

              this.registerFormErrors[error.field] = control.errors;
            }
          });
        }
      );
  }

  onregisterFormValuesChanged() {
    for (const field in this.registerFormErrors) {
      if (!this.registerFormErrors.hasOwnProperty(field)) {
        continue;
      }
      
      // Clear previous errors
      this.registerFormErrors[field] = {};

      // Get the control
      const control = this.registerForm.get(field);
      if (control && control.dirty && !control.valid) {
        
        control.errors["invalid"] = (!control.valid) ? true : null;

        // if (field === 'confirmPassword' && this.registerForm.get(field).value !== "" && this.registerForm.get('password').value !== "") {
        //   if(this.registerForm.get('confirmPassword').value !== this.registerForm.get('password').value) {
        //     control.errors["equals"] = false;
        //   } else {
        //     control.errors["equals"] = null;
        //   } 
        // }

        if (field === 'birthdate') {
          let newDate = moment(this.registerForm.value.birthdate, `${this.translateObj.DATE_FORMAT}`);
          this.birthdayModelAPI = newDate.format('YYYY-MM-DD');
          control.errors["testando"] = true;
        }

        this.registerFormErrors[field] = control.errors;
      }
      
      if (field === 'birthdate' && control && this.registerForm.value.birthdate.length >= 10) {
        this.registerFormErrors[field] = {};
        let newDate = moment(this.registerForm.value.birthdate, `${this.translateObj.DATE_FORMAT}`)
        this.birthdayModelAPI = newDate.format('YYYY-MM-DD')
        if (!newDate.isValid()) {
          control.setErrors({ 
            invalid: true,
            required: false
          })
        }
        
        this.registerFormErrors[field] = control.errors;
      }

      
    }
  }

  verifyDate(date:string) {
    if (date.length >= 10) {
      this.onregisterFormValuesChanged();
    }
  }

  searchCity(city: string) {
    if (city.length > 2) {
      this.googlePlacesService.search(city)
        .subscribe(
          result => {
            this.cities = result
          }, err => {
            this.cities = []

          }
        )  
    }
  }

  citySelected(event) {
    this.placeId = event.place_id
  }

  searchBox(name: string) {
    clearTimeout(this.timeOutBoxSearch)
    this.timeOutBoxSearch = setTimeout(() => {
      if (name.length > 0) {
        this.boxesService.getListBoxes(name)
          .subscribe(
            result => {
              this.boxes = result
            }, err => {
              this.boxes = []
            }
          )
      } else {
        this.boxes = []
      }
    }, 20);
  }

  displayFnBox(box: any): string {
    return box ? box.name.toUpperCase() : box;
  }

  boxSelected(event) {
    this.boxId = event.id
  }


  

  takePhoto()
  {
    
  }

  readUrl(event: any) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.onload = (event: any) => {
        // this.url = event.target.result;
        const modalRef = this.dialog.open(CropDialogComponent, {
          width: '816px',
          height: '100%',
          panelClass: 'modal'
        });

        modalRef.componentInstance.image = event.target.result
        modalRef.componentInstance.configCrop = {
          checkCrossOrigin: false,
          viewMode: 0,
          dragMode: 'move',
          zoomOnWheel: true,
          zoomable: true,
          aspectRatio: 500 / 500,
          cropBoxResizable: true,
          cropBoxMovable: true,
          ready: function (e) {
          },
          cropstart: function (e) {
          },
          cropmove: function (e) {
          },
          cropend: function (e) {
          },
          crop: function (e) {
            var data = e.detail;
            
          },
          zoom: function (e) {
          }
        }

        modalRef.afterClosed().subscribe(result => {

          if (result !== null) {
            this.url = result.image
            this.fileToUpload = result.blob
          }
        });
      }
      
      // this.fileToUpload = event.target.files[0];
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  // Method back to login
  backToLogin()
  {
    this.location.back()
  }

}

function confirmPasswordEquals(control: AbstractControl) {
  if (!control.parent || !control) {
    return;
  }

  const password = control.parent.get('password');
  const passwordConfirm = control.parent.get('confirmPassword');

  if (!password || !passwordConfirm) {
    return;
  }

  if (passwordConfirm.value === '') {
    return;
  }

  if (password.value !== passwordConfirm.value) {
    return {
      passwordsNotMatch: true
    };
  }
}