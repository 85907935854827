import { Competition } from './../../../../../../core/model/competition';
import { Component, OnInit, QueryList, ViewChildren, OnDestroy } from '@angular/core';
import { MatDialogRef, MatIconRegistry } from '@angular/material';
import { FuseTranslationLoaderService } from '../../../../../../core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormArray, FormControl } from '@angular/forms';
import { ResultService } from '../../../../../../core/services/result/result.service';
import { locale as english } from './i18n/en-US';
import { locale as portugues } from './i18n/pt-BR';
import { CompetitionService } from '../../../../../../core/services/competition/competition.service';
import { Errors } from '../../../../../../core/model/errors';
import { Championship } from '../../../../../../core/model/championship';
import { TableXComponent, TableConfig } from '../../../../../../core/components/table-x/table-x.component';
import { SubDivision } from '../../../../../../core/model/sub-division';
import { SubDivisionLimit } from '../../../../../../core/model/sub-division-limit';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modal-category',
  templateUrl: './modal-category.component.html',
  styleUrls: ['./modal-category.component.scss']
})
export class ModalCategoryComponent implements OnInit, OnDestroy {
  @ViewChildren('tableSubDivision') tableSubDivision: QueryList<TableXComponent>;

  public tableCatConfig: TableConfig = {
    checkbox: false,
    paddingSide: true,
    massActions: [
      {
        type: 'reproved',
        title: 'Reprovar'
      }
    ],
    massActionCountTitle: {
      single: 'resultado selecionado',
      multiple: 'resultados selecionados'
    }
  };
  
  public competitionEdit: Competition;
  public isEdit = false;
  public championshipId: number = null;
  public eventId: number = null;
  public championship: Championship;
  public subDivisions: SubDivision[];

  subscriptions: Subscription = new Subscription();

  competitions: Competition[] = [];

  // sub-division
  enableSubDivision = false;
  subDivisionInput = [];

  // Translate
  translate: any;

  isLoading = false;
  requestErrors: string;

  constructor(
    public dialogRef: MatDialogRef<ModalCategoryComponent>,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer,
    private iconRegistry: MatIconRegistry,
    private formBuilder: FormBuilder,
    private competitionService: CompetitionService
  ) { 
    this.translationLoader.loadTranslations(english, portugues);

    const subscription = this.translateService.get('MODAL_COMPETITION').subscribe((res: any) => {
      this.translate = res;
    });

    this.subscriptions.add(subscription);

    // Icon Info
    iconRegistry.addSvgIcon(
      'ic-cat-info',
      sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/category/ic-cat-info.svg')
    );
   }

  ngOnInit() {
    this.enableSubDivision = !!this.championship.enable_sub_division;
    if (this.isEdit) {
      // TODO COPY to EDIT COMPETITION
      const newCompetition: Competition = new Competition().deserialize(this.competitionEdit);
      newCompetition.championshipId = this.championshipId;
      newCompetition.eventId = this.eventId;
      this.competitions = [newCompetition];
      // sub-division
      if (this.subDivisions) {
        if (this.subDivisions.length > 0) {
          const subDivisionLimitOld: SubDivisionLimit[] = this.competitions[0].sub_division_limits;
          this.competitions[0].sub_division_limits = [];
          this.subDivisions.forEach((subDivision) => {
            const subDivisionLimit = new SubDivisionLimit();
            newCompetition.sub_division_limits.push({
              ...subDivisionLimit,
              cut_line: null,
              limit: null,
              sub_division_id: subDivision.id,
              sub_division: subDivision
            });
          });
          this.competitions[0].sub_division_limits.forEach((subDivisionLimit, index) => {
            const indeOld = subDivisionLimitOld.findIndex((filter: SubDivisionLimit) => filter.sub_division_id === subDivisionLimit.sub_division_id);
            if (indeOld >= 0) {
              this.competitions[0].sub_division_limits[index] = {
                ...this.competitions[0].sub_division_limits[index],
                ...subDivisionLimitOld[indeOld]
              };
            }
          });
        }
      }
    } else {
      this.addCompetition();
    }
  }

  // Add new competition empty
  public addCompetition(): void {
    const newCompetition = new Competition();
    newCompetition.isOpen = true;
    newCompetition.championshipId = this.championshipId;
    newCompetition.eventId = this.eventId;
    newCompetition.sub_division_limits = [];

    this.subDivisions.forEach((subDivision) => {
      const subDivisionLimit = new SubDivisionLimit();
      newCompetition.sub_division_limits.push({
        ...subDivisionLimit,
        cut_line: null,
        limit: null,
        sub_division_id: subDivision.id,
        sub_division: subDivision
      });
    });

    this.competitions.forEach(competition => {
      competition.isOpen = false;
    });

    this.competitions.push(newCompetition);
  }

  // Coll
  actionCollapsible() {
    this.competitions.forEach(competition => {
      competition.isOpen = false;
    });
  }

  // Remove competition
  removeCompetition(index: number) {
    this.competitions.splice(index, 1);
  }

  // Create Competitions
  saveCompetitions() {
    if (this.competitions.length === 0) { 
      return; 
    }

    let isError = false;
    this.requestErrors = null;
    const competitions: any[] = [];
    this.competitions.forEach(competition => {
      const object = competition.sendToAPI(this.isEdit);
      isError = object.error; 
      if (!isError) { 
        competitions.push(object.competition); 
      }
    });

    if (isError) { 
      return; 
    }

    this.isLoading = true;
    if (this.isEdit) {
      const subscription = this.competitionService.updateCompetitions(this.competitionEdit.id, competitions[0])
        .subscribe(
          result => {
            result.isOpen = true;
            this.competitionEdit.name = this.competitions[0].name.toUpperCase();
            this.competitionEdit.limit = this.competitions[0].limit;
            this.competitionEdit.team = this.competitions[0].team;
            this.competitionEdit.team_size = (result.team) ? this.competitions[0].team_size : null;
            this.competitionEdit.sumSingle = this.competitions[0].sumSingle;
            this.competitionEdit.gender = this.competitions[0].gender;
            this.isLoading = false;
            this.dialogRef.close({ competition: result, isEdit: this.isEdit });
          }, err => {
            const errors: Errors = new Errors().deserialize((err.error as any));
            this.requestErrors = errors.getFullMessages();

            this.isLoading = false;
          }
        );
      this.subscriptions.add(subscription);
    }
    else {
      const subscription = this.competitionService.createCompetitions({ competitions: competitions })
        .subscribe(
          result => {
            this.isLoading = false;
            this.dialogRef.close({ competitions: result, isEdit: this.isEdit });
          }, err => {
            const errors: Errors = new Errors().deserialize((err.error as any));
            this.requestErrors = errors.getFullMessages();
            
            this.isLoading = false;
          }
        ); 
      this.subscriptions.add(subscription);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
