import { EventsService } from './../services/event/events.service';
import { Injectable, SecurityContext } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs';
import { FuseNavigationService } from '../components/navigation/navigation.service';
import * as moment from 'moment';
import { Competition } from '../model/competition';
import { AuthGuard } from './auth.guard';
import { environment } from '../../../environments/environment';
import { Utils } from '../utils';
import {FuseConfigService} from '../services/config.service';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class EventGuard implements CanActivate {

  
  private storageSub = new Subject<any>();
  private isUserDashboard = false;

  constructor(
    private authGuard: AuthGuard,
    private router: Router,
    private sanitizer: DomSanitizer,
    private eventService: EventsService,
    private navigationService: FuseNavigationService,
    private fuseConfig: FuseConfigService
  ) { 
    
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    
    
    if (localStorage.getItem('current_event_crossplus') && JSON.parse(localStorage.getItem('current_event_crossplus')).event) {
      // logged in so return true
      
      if (!this.verifyRouteIsUserAuthorize(next.routeConfig.path)) {
        this.router.navigate(['/myprofile']);
        return false;
      }

      this.isUserDashboard = this.getActiveEvent().isUserDashboard;

      const lastUpdate = moment(JSON.parse(localStorage.getItem('current_event_crossplus')).last_update_championships);
      if (lastUpdate && JSON.parse(localStorage.getItem('current_event_crossplus')).championships) {
        if (moment().diff(lastUpdate, 'hour') > 2) {
          const event = JSON.parse(localStorage.getItem('current_event_crossplus')).event;
          this.updateEventDetail(event);  
        }
        
        //Update menu
        if (this.isUserDashboard) this.updateMenuUserDashboard();
        else this.updateMenu();

      } else {
        const event = JSON.parse(localStorage.getItem('current_event_crossplus')).event;
        this.updateEventDetail(event);
      }

      // Conpass
      // const user = this.authGuard.getMe().user
      // var event = JSON.parse(localStorage.getItem('current_event_crossplus')).event
      // if (!user.admin) {
      //   (<any>window).Conpass.init({
      //     name: `${user.fullname}`,
      //     email: user.email,
      //     //  Informações opcionais (usado para segmentação)
      //     custom_fields: {
      //       system: 'crossx_campeonatos',
      //       event_id: event ? event.id : null,
      //       user_status: user.status,
      //       user_gender: user.gender_description ? user.gender_description : null,
      //     }
      //   });
      // }
      
      return true;
    }

    // not logged in so redirect to login page
    this.router.navigate(['/myprofile']);
    return false;
  }

  setEvent(event: any, isUserDashboard: boolean = false)
  {
    event['last_update_championships'] = moment();

    localStorage.setItem('current_event_crossplus', JSON.stringify({
      event: event,
      championships: event.championships,
      isUserDashboard: isUserDashboard
    }));

    this.fuseConfig.setSettings({
      layout: {
        navigation: 'left',
        toolbar   : 'below',      // 'above', 'below', none
        footer    : 'none'
      }
    });

    if (event.championships && event.championships.length > 0) {
      this.storageSub.next({
        type: 'event',
        data: event,
        isUserDashboard: isUserDashboard
      });

      // Update menu
      this.updateMenu();
    }

    this.eventService.getEventInformation(event.id)
      .subscribe(
        result => {          
          localStorage.setItem('current_event_crossplus', JSON.stringify({
            event: result,
            championships: result.championships,
            isUserDashboard: isUserDashboard
          }));

          if (event.championships && event.championships.length > 0) {
            // Update menu
            this.updateMenu();
          }
        }, err => { }
      );

    // const champ = this.navigationService.getNavigationItem('championships');
    // champ.children.splice(0, champ.children.length)

    // this.updateEventDetail(event)
  }

  setEventUserDashboard(event: any, isUserDashboard: boolean = true) {
    event['last_update_championships'] = moment();

    localStorage.setItem('current_event_crossplus', JSON.stringify({
      event: event,
      championships: event.championships,
      isUserDashboard: isUserDashboard
    }));

    this.fuseConfig.setSettings({
      layout: {
        navigation: 'left',
        toolbar   : 'below',      // 'above', 'below', none
        footer    : 'none'
      }
    });

    if (event.championships && event.championships.length > 0) {
      this.storageSub.next({
        type: 'event',
        data: event,
        isUserDashboard: isUserDashboard
      });

      // Update menu
      this.updateMenuUserDashboard();
    }

    this.isUserDashboard = isUserDashboard;
    // this.updateMenuUserDashboard()

    // const champ = this.navigationService.getNavigationItem('championships');
    // champ.children.splice(0, champ.children.length)

    // this.updateEventDetail(event)
  }

  updateEventInformation(event: any) {
    const chmpionships = JSON.parse(localStorage.getItem('current_event_crossplus')).championships;
    const isUserDashboard = JSON.parse(localStorage.getItem('current_event_crossplus')).isUserDashboard;

    const newEvent = event;
    newEvent['championships'] = chmpionships;
    newEvent['last_update_championships'] = moment();
    

    localStorage.setItem('current_event_crossplus', JSON.stringify({
      event: newEvent,
      championships: event.championships,
      isUserDashboard: isUserDashboard
    }));
  }

  updateEventDetail(event: any)
  {
    this.eventService.getEventDetail(event)
      .subscribe(
        result => {
          const championships: any[] = result['championships'];
          const currentEvent = JSON.parse(localStorage.getItem('current_event_crossplus'));
          currentEvent['championships'] = championships;
          currentEvent['last_update_championships'] = moment();
          localStorage.setItem('current_event_crossplus', JSON.stringify(currentEvent));

          this.storageSub.next({
            type: 'event',
            data: currentEvent,
            isUserDashboard: currentEvent.isUserDashboard
          });
          

          
        }, err => {
          
        }
      ); 
  }

  updateEventChampionships(championships: any, isAdmin: boolean = true) {
    const event = JSON.parse(localStorage.getItem('current_event_crossplus')).event;
    const isUserDashboard = JSON.parse(localStorage.getItem('current_event_crossplus')).isUserDashboard;

    const newEvent = event;
    newEvent['championships'] = championships;
    newEvent['last_update_championships'] = moment();

    localStorage.setItem('current_event_crossplus', JSON.stringify({
      event: newEvent,
      championships: event.championships,
      isUserDashboard: isUserDashboard
    }));

    const currentEvent = JSON.parse(localStorage.getItem('current_event_crossplus'));

    this.storageSub.next({
      type: 'event',
      data: currentEvent,
      isUserDashboard: isUserDashboard
    });

    //Update menu
    if (isAdmin) {
      this.updateMenu();
    } else {
      this.updateMenuUserDashboard();
    }
  }

  // Update Menu User Dashboard
  updateMenuUserDashboard() {
    const event = JSON.parse(localStorage.getItem('current_event_crossplus')).event;
    const championships = JSON.parse(localStorage.getItem('current_event_crossplus')).championships;

    const menuAdmin = [
      this.navigationService.getNavigationItem('dashboard'),
      this.navigationService.getNavigationItem('event-config'),
      this.navigationService.getNavigationItem('cupons'),
      this.navigationService.getNavigationItem('points'),
      this.navigationService.getNavigationItem('subscriptions'),
    ];

    menuAdmin.forEach(menu => {
      menu.hidden = true;
    });
    
    const menuInformation = this.navigationService.getNavigationItem('user-dashboard-information');
    menuInformation.hidden = false;

    const menuEventConfig = this.navigationService.getNavigationItem('event-config');
    menuEventConfig.hidden = true;

    const champ = this.navigationService.getNavigationItem('championships');
    champ.children.splice(0, champ.children.length);
    
    if (championships !== null && championships.length > 0) {

      // if (championships.length === 1) champ.title = 'NAV.CHAMPIONSHIPS.TITLE'
      // else champ.title = 'NAV.CHAMPIONSHIPS.TITLE_PLURAL'

      championships.forEach(championship => {
        const newNavItem = {
          id: `championship-${championship.id}`,
          title: championship.name,
          type: 'collapse',
          iconSvg: 'ic-menu-classes',
          children: []
        }; //End Model
        
        if (championship.is_invitation || championship.competitor_id === null) {
          newNavItem.children = [
            {
              'id': `user-dashboard-championship-${championship.id}-payment`,
              'title': 'NAV.USER_DASHBOARD.PAYMENTS',
              'type': 'item',
              'url': `/user-dashboard/championship/${championship.id}/payment`
            },
          ];
        } else {
          newNavItem.children = [
            /*{
              'id': `user-dashboard-championship-${championship.id}-information`,
              'title': 'NAV.USER_DASHBOARD.INFORMATION',
              'type': 'item',
              'url': `/user-dashboard/championship/${championship.id}/information`
            },*/
            {
              'id': `user-dashboard-championship-${championship.id}-results`,
              'title': 'NAV.USER_DASHBOARD.RESULTS',
              'type': 'item',
              'url': `/user-dashboard/championship/${championship.id}/results`
            },
            {
              'id': `user-dashboard-championship-${championship.id}-payment`,
              'title': 'NAV.USER_DASHBOARD.PAYMENTS',
              'type': 'item',
              'url': `/user-dashboard/championship/${championship.id}/payment`,
              hidden: !event.enabled_subscription
            },
            {
              'id': `user-dashboard-championship-${championship.id}-checkin`,
              'title': 'Check-in',
              'type': 'item',
              'url': `/user-dashboard/championship/${championship.id}/checkin`,
              hidden: !event.enable_checkin_online || championship.online === 1
            },
            {
              'id': `user-dashboard-championship-${championship.id}-leaderboard`,
              'title': 'NAV.USER_DASHBOARD.LEADERBOARD',
              'type': 'item',
              function: () => {
                try {
                  const url = `https://${event.slug}.${environment.domain}/leaderboard`;

                  Utils.openInOtherWindowWithValidationURL(url);
                } catch (e) {
                  console.warn('URL not sanitized');
                }
              }
              // 'url': `https://${ event.slug }.${ environment.domain }/leaderboard`
            },
          ];
        }
        
        this.navigationService.addNavigationItem('championships', newNavItem);
      }); // End Foreach

      champ.hidden = false;
    } else {
      champ.hidden = true;
    }
  }

  // Update Menu Admin
  updateMenu()
  {
    const event = JSON.parse(localStorage.getItem('current_event_crossplus')).event;
    const championships = JSON.parse(localStorage.getItem('current_event_crossplus')).championships;

    const menuInformation = this.navigationService.getNavigationItem('user-dashboard-information');
    menuInformation.hidden = true;
    
    const menuAdmin = [
      this.navigationService.getNavigationItem('dashboard'),
      this.navigationService.getNavigationItem('event-config'),
      this.navigationService.getNavigationItem('cupons'),
      this.navigationService.getNavigationItem('points'),
      this.navigationService.getNavigationItem('subscriptions'),
      this.navigationService.getNavigationItem('user-dashboard-information'),
    ];

    menuAdmin.forEach(menu => {
      menu.hidden = false;
    });
    
    //Coupon enabled if event subscription
    menuAdmin[2].hidden = !event.enabled_subscription;
    menuAdmin[4].hidden = !event.enabled_subscription;
    
    menuAdmin[0].hidden = true;
    menuAdmin[3].hidden = true;
    menuAdmin[5].hidden = true;
    
    //Populate event setup
    const eventSetup = this.navigationService.getNavigationItem('event-config');
    eventSetup.children = [
      {
        'id': `event-informations`,
        'title': 'NAV.EVENTCONFIG.INFORMATIONS',
        'type': 'item',
        'url': `/event/informations`
      },
      {
        'id': `event-permissions`,
        'title': 'NAV.EVENTCONFIG.PERMISSIONS',
        'type': 'item',
        'url': `/event/permissions`,
        hidden: !this.authGuard.getMe().user.admin
      },
      {
        'id': `event-subscriptions`,
        'title': 'NAV.EVENTCONFIG.SUBSCRIPTIONS',
        'type': 'item',
        'url': `/event/subscriptions`,
        hidden: !event.enabled_subscription
      },
      {
        'id': `event-championships`,
        'title': 'NAV.EVENTCONFIG.CHAMPIONSHIPS',
        'type': 'item',
        'url': `/event/championships`
      },
      {
        'id': `event-leaderboard`,
        'title': 'NAV.EVENTCONFIG.LEADERBOARD',
        'type': 'item',
        'url': `/event/leaderboard`,
        hidden: !event.enabled_leaderboard
      },
      {
        'id': `event-crossx-app`,
        'title': 'NAV.EVENTCONFIG.APP_CROSSX',
        'type': 'item',
        'url': `/event/crossx-app`,
        hidden: !this.authGuard.getMe().user.admin
      }
    ];
    
    // Championships config
    const champ = this.navigationService.getNavigationItem('championships');
    champ.children.splice(0, champ.children.length);

    if (championships !== null && championships.length > 0) {
      championships.forEach(championship => {
        const newNavItem = {
          id: `championship-${championship.id}`,
          title: championship.name,
          type: 'collapse',
          iconSvg: 'ic-menu-classes',
          children: [
            {
              'id': `championship-category-${championship.id}`,
              'title': 'NAV.CHAMPIONSHIPS.CATEGORY',
              'type': 'item',
              'url': `/championship/category/${championship.id}`,
              hidden: !event.enabled_leaderboard
            },
            {
              'id': `championship-sub-division-${championship.id}`,
              'title': 'NAV.CHAMPIONSHIPS.SUB_DIVISION',
              'type': 'item',
              'url': `/championship/sub-division/${championship.id}`,
              hidden: !championship.enable_sub_division
            },
            {
              'id': `championship-category-${championship.id}`,
              'title': 'NAV.CHAMPIONSHIPS.WODS',
              'type': 'item',
              'url': `/championship/wods/${championship.id}`,
              hidden: !event.enabled_leaderboard
            },
            {
              'id': `championship-lots-${championship.id}`,
              'title': 'NAV.CHAMPIONSHIPS.LOTS',
              'type': 'item',
              'url': `/championship/lots/${championship.id}`,
              hidden: !event.enabled_subscription
            },
            {
              'id': `championship-participants-${championship.id}`,
              'title': 'NAV.CHAMPIONSHIPS.PARTICIPANTS',
              'type': 'item',
              'url': `/championship/participants/${championship.id}`,
              hidden: !event.enabled_leaderboard
            },
            {
              'id': `championship-heats-${championship.id}`,
              'title': 'NAV.CHAMPIONSHIPS.HEATS',
              'type': 'item',
              'url': `/championship/heats/${championship.id}`,
              hidden: !event.enabled_heats
            },
            {
              'id': `championship-results-${championship.id}`,
              'title': 'NAV.CHAMPIONSHIPS.RESULTS',
              'type': 'item',
              'url': `/championship/results/${championship.id}`,
              hidden: !event.enabled_leaderboard
            },
            {
              'id': `championship-leaderboard-${championship.id}`,
              'title': 'NAV.CHAMPIONSHIPS.LEADERBOARD',
              'type': 'item',
              'url': `/championship/leaderboard/${championship.id}`,
              hidden: !event.enabled_leaderboard
            }
          ]
        }; //End Model
        this.navigationService.addNavigationItem('championships', newNavItem);
      }); // End Foreach

      champ.hidden = false;
    } else {
      champ.hidden = true;
    }
    
  }

  getActiveEvent()
  {
    if (localStorage.getItem('current_event_crossplus')) {
      const event = JSON.parse(localStorage.getItem('current_event_crossplus')).event;
      const isUserDashboard = JSON.parse(localStorage.getItem('current_event_crossplus')).isUserDashboard;
      // let accesses = JSON.parse(localStorage.getItem('current_user_crossplus')).accesses
      // let accessActive = JSON.parse(localStorage.getItem('current_user_crossplus')).accessActive
      return { event: event, isUserDashboard: isUserDashboard };
    }

    return { event: null, isUserDashboard: false };
  }

  clearEvent()
  {
    localStorage.removeItem('current_event_crossplus');

    this.storageSub.next({
      type: 'event',
      action: 'clear'
    });
  }

  // Championships
  getAllChampionships(): any[]
  {
    const championships = JSON.parse(localStorage.getItem('current_event_crossplus')).championships;
    return championships;
  }

  getChampionshipPerId(id: number): any
  {
    
    const championships = JSON.parse(localStorage.getItem('current_event_crossplus')).championships;
    let champ: any;

    championships.forEach(championship => {
      if (championship.id == id) {
        champ = championship;
      }
    });

    if (champ) {
      return champ;
    }
    
    this.router.navigate(['/myprofile']);
    return null;
  }

  // Competition
  getEventUpdates() 
  {
    const event = JSON.parse(localStorage.getItem('current_event_crossplus')).event;
    const isUserDashboard = JSON.parse(localStorage.getItem('current_event_crossplus')).isUserDashboard;
    this.eventService.getEventInformation(event.id)
      .subscribe(
        result => {

          localStorage.setItem('current_event_crossplus', JSON.stringify({
            event: result,
            championships: result.championships,
            isUserDashboard: isUserDashboard
          }));
        }, err => { }
      );
  }

  // Update competitions
  addCompetitionsInChampionship(championshipId: number, newCompetitions: any[]) {
    if (localStorage.getItem('current_event_crossplus')) {
      const event = JSON.parse(localStorage.getItem('current_event_crossplus')).event;
      const isUserDashboard = JSON.parse(localStorage.getItem('current_event_crossplus')).isUserDashboard;
      const championships = event.championships;

      championships.forEach(championship => {
        if (championship.id === championshipId) {
          const competitions = championship.competitions;
          newCompetitions.forEach(newCompetition => {
            competitions.push(newCompetition);
          });
        }
      });

      event.championships = championships;
      

      localStorage.setItem('current_event_crossplus', JSON.stringify({
        event: event,
        championships: event.championships,
        isUserDashboard: isUserDashboard
      }));

      if (event.championships && event.championships.length > 0) {
        this.storageSub.next({
          type: 'event',
          data: event
        });
      }
      
      // return { event: event, isUserDashboard: isUserDashboard }
    }
  }

  updateCompetitionsInChampionship(championshipId: number, competitions: any[]) {
    if (localStorage.getItem('current_event_crossplus')) {
      const event = JSON.parse(localStorage.getItem('current_event_crossplus')).event;
      const isUserDashboard = JSON.parse(localStorage.getItem('current_event_crossplus')).isUserDashboard;
      const championships = event.championships;

      championships.forEach(championship => {
        if (championship.id === championshipId) {
          championship.competitions = [];
          // championship.competitions = competitions
          // var competitions = championship.competitions
          competitions.forEach(newCompetition => {
            if (newCompetition instanceof Competition) {
              championship.competitions.push(newCompetition.convertToJSON());
            } else {
              championship.competitions.push(newCompetition);
            }
            
          });
        }
      });

      event.championships = championships;
      

      localStorage.setItem('current_event_crossplus', JSON.stringify({
        event: event,
        championships: event.championships,
        isUserDashboard: isUserDashboard
      }));

      if (event.championships && event.championships.length > 0) {
        this.storageSub.next({
          type: 'event',
          data: event
        });
      }
    }
  }

  watchStorage(): Observable<any> {
    return this.storageSub.asObservable();
  }


  private verifyRouteIsUserAuthorize(route: string): boolean {
    const userIsAdmin = this.authGuard.getMe().user.admin;
    const routesIsAdmin = [
      'event/crossx-app',
      'event/permissions'
    ];
    
    if (userIsAdmin) {
      return true;
    }
    else {
      const thisRouteIsAdmin = routesIsAdmin.filter(value => value === route);
      return thisRouteIsAdmin.length === 0;
    }
  }
}
