import { SEOService } from './core/services/seo-service/seo.service';
import { EventPageService } from './core/services/event-page/event-page.service';
import { Component } from '@angular/core';
import { FuseSplashScreenService } from './core/services/splash-screen.service';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from './core/services/translation-loader.service';

import { FuseNavigationService } from './core/components/navigation/navigation.service';
import { FuseNavigationModel } from './navigation/navigation.model';
import { locale as navigationEnglish } from './navigation/i18n/en-US';
import { locale as navigationPortuguese } from './navigation/i18n/pt-BR';
import { locale as globalEnglish } from './i18n/en-US';
import { locale as globalPortuguese } from './i18n/pt-BR';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { config } from './core/config/config';

@Component({
  selector: 'fuse-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  private readonly VALID_ROUTES: Array<string> = [
    '/event',
    '/event-identification',
    '/event-registration',
    '/leaderboard',
    '/workouts',
    '/heats-tv'
  ];

  constructor(
    private fuseNavigationService: FuseNavigationService,
    private fuseSplashScreen: FuseSplashScreenService,
    private translate: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
    private titleService: Title,
    private router: Router,
    private eventPageService: EventPageService,
    private seoService: SEOService
  ) {
    // Add languages
    this.translate.addLangs(['pt-BR', 'en-US']);

    // Set the default language
    this.translate.setDefaultLang('pt-BR');
    
    this.translate.use(navigator.language);

    // Set the navigation model
    this.fuseNavigationService.setNavigationModel(new FuseNavigationModel());

    // Set the navigation translations
    this.translationLoader.loadTranslations(navigationEnglish, navigationPortuguese, globalEnglish, globalPortuguese);

    this.titleService.setTitle('CrossX');

    this.seoService.addMetaTags({
      title: 'CrossX',
      image: 'https://is2-ssl.mzstatic.com/image/thumb/Purple128/v4/db/b0/c3/dbb0c302-f5a3-1fd9-2c8f-c527e0915d7d/AppIcon-1x_U007emarketing-sRGB-85-220-4.png/246x0w.jpg',
    });

    const {host} = window.location;
    const slug = host.substr(0, host.indexOf('.'));
    if (slug.length > 0 && slug !== config.hostDash) {
      this.eventPageService.setEvent(slug);
    }
  }

  ngOnInit(): void {
    const {host} = window.location;
    const slug = host.substr(0, host.indexOf('.'));

    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          // if ((<any>window).Conpass) {
          //   (<any>window).Conpass.routeChange();
          // }

          if (slug.length > 0 && slug !== config.hostDash) {
            this.eventPageService.setEvent(slug);

            const foundedRoute = this.VALID_ROUTES.find((current) => current === this.router.url);

            if (foundedRoute === undefined) {
              this.router.navigate(['/event']);
            }
          }
        }
      });
  }
}
