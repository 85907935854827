import { WodUserDashboard } from './../../model/user-dashboard/wod';
import { ResultUserDashboard } from './../../model/user-dashboard/result';
import { Result } from './../../model/result';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ResultService implements OnDestroy {

  private subscriptions = new Subscription();
  private _verifyResultsObservable = new Subject<boolean>();

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  verifyResultsObservable(): Observable<boolean> {
    return this._verifyResultsObservable.asObservable();
  }

  getResults(championshipId: number, competitionId: number, wodId: number, subDivisionId = 0, page = 1, per_page = 70): Observable<any> {

    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const baseUrl = [
      'admin',
      'results',
    ];
    let queryParams = {};
    queryParams = {
      ...queryParams,
      championship_id: championshipId,
      competition_id: competitionId,
      wod_id: wodId,
      page,
      per_page,
    };

    if (subDivisionId) {
      queryParams = {
        ...queryParams,
        sub_division_id: subDivisionId
      };
    }
    const url = this.router.createUrlTree(baseUrl, {
      queryParams: queryParams
    });
    const parsedUrl = url.toString().substring(1, url.toString().length);

    return this.http.get(parsedUrl, { responseType })
      .map((response: Response) => {
        return response as any;
      });
  }
  
  public verifyResults(championshipId: number, competitionId: number): void {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const baseUrl = [
      'admin',
      'results-verify-publish',
    ];
    const queryParams = {
      championship_id: championshipId,
      competition_id: competitionId
    };
    const url = this.router.createUrlTree(baseUrl, {
      queryParams: queryParams
    });
    const parsedUrl = url.toString().substring(1, url.toString().length);

    const subscription = this.http
      .get(parsedUrl, {responseType})
      .subscribe((result: any) => {
          this._verifyResultsObservable.next(result.enabled_published);
        },
        () => {
          this._verifyResultsObservable.next(false);
        });

    this.subscriptions.add(subscription);

  }


  updateResult(result: any, resultId: number): Observable<any>
  {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const url = `admin/results/${resultId}`;

    return this.http.put(url, result, { responseType })
      .map((response: Response) => {
        const results = response as any;
        return results;
      });
  }

  saveOrUpdateResult(result: any, item: any, wodId: number): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    
    if (item.result) {
      
      const url = `admin/results/${item.result.id}`;

      return this.http.put(url, result, { responseType })
        .map((response: Response) => {
          const results = response as any;
          return results;
        });  
    } else {
      const url = `admin/results/`;
      const newResult = result;
      newResult['competition_id'] = item.competition_id;
      newResult['championship_id'] = item.championship_id;
      newResult['sub_division_id'] = item.sub_division_id;
      newResult['competitor_id'] = item.id;
      newResult['wod_id'] = wodId;
      

      return this.http.post(url, result, { responseType })
        .map((response: Response) => {
          const results = response as any;

          if (!results.status) {
            results['status'] = null;
          }

          if (!results.video) {
            results['video'] = null;
          }

          return results;
        });  
    }
  }

  deleteResult(resultId: number): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const url = `admin/results/${resultId}`;

    return this.http.delete(url, { responseType })
      .map((response: Response) => {
        const results = response as any;
        return results;
      });
  }

  publishResults(publish: any): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const url = `admin/results/publish`;

    return this.http.post(url, publish, { responseType })
      .map((response: Response) => {
        
        const published = response as any;
        return published;
      });
  }


  // User Dashboard
  getResultsByCompetitor(competitorId: number): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const url = `results?competitor_id=${competitorId}`;

    return this.http.get(url, { responseType })
      .map((response: Response) => {
        const results = response as any;
        return results.map((result: ResultUserDashboard) => new ResultUserDashboard().deserialize(result));
      });
  }

  saveOrUpdateResultByCompetitor(wod: WodUserDashboard, result: any, competitionId?: number, championshipId?: number, competitorId?: number): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';

    if (wod.resultEdit !== null && wod.resultEdit.id !== null) {
      
      const url = `results/${wod.resultEdit.id}`;

      return this.http.put(url, result, { responseType })
        .map((response: Response) => {
          const results = response as any;
          return results;
        });
    } else {
      const newResult = result;
      newResult['competition_id'] = competitionId;
      newResult['championship_id'] = championshipId;
      newResult['competitor_id'] = competitorId;
      

      return this.http.post('results', newResult, { responseType })
        .map((response: Response) => {
          const results = response as any;

          if (!results.status) {
            results['status'] = null;
          }

          if (!results.video) {
            results['video'] = null;
          }

          return results;
        });
    }
  }


  // Public
  getPublicResults(championshipId: number, competitionId: number, limit = 70, page = 1, subDivisionId: number = 0): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    const url = `public/results?championship=${championshipId}&competition=${competitionId}&sub_division=${subDivisionId}&limit=${limit}&page=${page}`;
    

    return this.http.get(url, { responseType })
      .map((response: Response) => {
        return response;
      });
  }
}
