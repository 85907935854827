import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { TableConfig, TableXComponent } from '../../../../../../core/components/table-x/table-x.component';
import { ReoderEvent } from '../../../../../../core/components/table-x/model/reoder-event.model';
import { Competitor } from '../../../../../../core/model/competitor';
import { DragulaService } from 'ng2-dragula';
import { BehaviorSubject, combineLatest, forkJoin, pipe, Subject, Subscription, zip } from 'rxjs';
import { HeatsHttpService } from '../../../../../../core/services/heats/heats-http.service';
import { HeatsService } from '../services/heats.service';
import { ToastrService } from '../../../../../../core/components/toastr_/toastr/toastr.service';
import { Heat } from '../model/heat.model';
import { HeatCompetitor } from '../model/heat-competitor.model';

@Component({
  selector: 'app-competitors-datagrid',
  templateUrl: './competitors-datagrid.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./competitors-datagrid.component.scss']
})

export class CompetitorsDatagridComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild(TableXComponent)
  tableXComponent: TableXComponent;

  @Input()
  index: number;

  @Input()
  generateDropzones: boolean;

  externalId: string;

  @Input()
  lanesQuantity: number;

  tableConfig: TableConfig = {
    checkbox: false,
    paddingSide: true,
    massActions: [
      {
        type: 'reproved',
        title: 'Reprovar'
      }
    ],
    massActionCountTitle: null
  };

  heats: Array<Heat>;

  heatCompetitors = new Array<HeatCompetitor>();

  @Output('onHeatCompetitorsChanged')
  onHeatCompetitorsChanged = new EventEmitter<{ data: Array<HeatCompetitor>, matrixIndex: number }>();

  @Input('dragulaModel')
  dragulaModel: Object;

  @Input()
  heat: Heat;


  private internalCompetitors = Array<Competitor>();
  private subscriptions = new Subscription();

  private _indexInputedObservable = new BehaviorSubject<number>(null);

  constructor(
    private dragulaService: DragulaService,
    private heatsHttpService: HeatsHttpService,
    private renderer2: Renderer2,
    private heatsService: HeatsService,
    private toastrService: ToastrService
  ) {
    this.registerOnDragularServiceDrop();
    this.registerOnHeatsHttpService();
    this.registerOnHeatsGeneratorDropCollectionObservable();
    this.registerOnSavedHeatsItems();
    this.registerOnInitialHeatsObservable();
  }

  get showStartTimeAndEndTime() {
    return this.heat &&
      this.heat.start_time &&
      this.heat.end_time;
  }

  get heatName() {
    return `Bateria ${this.index + 1}`;
  }

  ngOnInit() {
  }

  get tableName() {
    return `heats-${this.index}`;
  }

  get totalItemsOnGrid() {
    let total = 0;

    if (!this.heatCompetitors) {
      return total;
    }

    this.heatCompetitors.forEach((item) => {

      if (item) {
        ++total;
      }

    });

    return total;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public reoder(event: ReoderEvent) {
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (
      changes.index &&
      changes.index.currentValue
    ) {
      this.externalId = `externalId${this.index}`;
      this._indexInputedObservable.next(changes.index.currentValue);
    }

    if (
      changes.lanesQuantity &&
      changes.lanesQuantity.currentValue
    ) {
      if (this.heatCompetitors && !this.heatCompetitors.length) {
        this.heatCompetitors = new Array<HeatCompetitor>();
      }
    }

    if (
      changes.heat &&
      changes.heat.currentValue
    ) {
      if (
        this.heat.heat_competitors &&
        this.heat.heat_competitors.length > 0
      ) {
        this.heatCompetitors = this.heat.heat_competitors;
        this.tableXComponent.resetPlaceholders();
        this.setCompetitors(this.heatCompetitors);
      }
    }

  }

  private registerOnHeatsHttpService(): void {
    // const subscription = this.heatsHttpService
    //   .competitorsObservable()
    //   .subscribe((data) => {
    //     this.internalCompetitors = data;
    //   });
    // this.subscriptions.add(subscription);
  }

  private registerOnDragularServiceDrop() {
    const subscription = this.dragulaService
      .drop('DRAGULA_FACTS')
      .subscribe(({el, target}) => {
        const externalIndex = +target.getAttribute('external-index');

        if (this.index !== externalIndex) {
          return;
        }

        const id = +el.getAttribute('item-id');

        const current = this.internalCompetitors.find(interalCompetitor => interalCompetitor.id === id);

        if (!current) {
          return;
        }

        const itemIndex = +target.getAttribute('item-index');

        const heatCompetitorIndex = this.heatCompetitors.findIndex((heatCompetitor) => {
          return heatCompetitor && heatCompetitor.competitor.id === current.id;
        });

        if (heatCompetitorIndex !== -1) {
          this.heatCompetitors.splice(heatCompetitorIndex, 1);
        }

        const currentHeatCompetitor = new HeatCompetitor();
        currentHeatCompetitor.competitor = current;
        currentHeatCompetitor.lane = itemIndex;
        // currentHeatCompetitor.wod_id = current.wod_id;
        currentHeatCompetitor.competitor_id = current.id;

        this.heatCompetitors.push(currentHeatCompetitor);

        this.tableXComponent.setDropzoneItem(itemIndex, current);

        this.dispatchHeatCompetitorsChangedEvent(externalIndex);

      });

    this.subscriptions.add(subscription);
  }

  private dispatchHeatCompetitorsChangedEvent(matrixIndex: number) {
    this.onHeatCompetitorsChanged
      .emit({
        data: this.heatCompetitors,
        matrixIndex: matrixIndex,
      });
  }

  onClick(event): void {
    event.preventDefault();

    if (!event.shiftKey) {
      return;
    }
    

  }

  private registerOnHeatsGeneratorDropCollectionObservable(): void {

    const subscription = this.heatsService
      .heatsGeneratorDropCollectionObservable()
      .subscribe((dropCollection) => {

        if (dropCollection.matrixIndex === this.index) {
          return;
        }

        if (!this.heatCompetitors.length) {
          return;
        }

        dropCollection.data
          .forEach((currentElement) => {

            const index = this.heatCompetitors.findIndex((currentHeatCompetitor) => {
              return currentHeatCompetitor && currentElement && currentHeatCompetitor.competitor.id === currentElement.competitor.id;
            });

            if (index === -1) {
              return;
            }

            this.heatCompetitors[index] = null;

          });

        const compareData = dropCollection.data.map((item) => item && item.competitor);

        this.tableXComponent.checkDropzones(dropCollection.matrixIndex, compareData);
      });

    this.subscriptions.add(subscription);
  }

  private registerOnSavedHeatsItems(): void {

    const subscription = this.heatsHttpService
      .heatsSavedObservable()
      .subscribe((result: boolean) => {
        if (result) {
          this.toastrService.show(
            'Heats gravados com sucesso',
            'Heats gravados',
            null,
            'success'
          );
        }
      });

    this.subscriptions.add(subscription);
  }

  private registerOnInitialHeatsObservable(): void {

    const subscription = this.heatsService
      .initialHeatsObservable()
      .subscribe((data: Heat[]) => {
        this.heats = data;
      });

    this.subscriptions.add(subscription);
  }

  private setCompetitors(heatCompetitors: HeatCompetitor[]) {

    if (heatCompetitors.length <= 0) {
      return;
    }

    heatCompetitors.forEach((heatCompetitor: HeatCompetitor) => {
      const competitor = heatCompetitor.competitor;
      const key = heatCompetitor.lane;
      this.tableXComponent.setDropzoneItem(key, competitor);
    });
  }

}
