import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Event} from '../../model/event';

@Injectable({
  providedIn: 'root'
})
export class EventConfigurationsService {

  constructor(
    private http: HttpClient
  ) { }

  updateConfigs(parameters: any, eventId: number): Observable<any> {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';

    return this.http.put(`admin/event-configurations/${eventId}`, parameters, { responseType })
      .map((response: Response) => {
        return response;
      });
  }
}
