import {CompetitorService} from './../../../../../../core/services/competitor/competitor.service';
import {Utils} from './../../../../../../core/utils';
import {Component, OnInit} from '@angular/core';
import {MatDialogRef, MatIconRegistry} from '@angular/material';
import {FuseTranslationLoaderService} from '../../../../../../core/services/translation-loader.service';
import {TranslateService} from '@ngx-translate/core';
import {DomSanitizer} from '@angular/platform-browser';
import {FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import {BoxService} from '../../../../../../core/services/box.service';

import {locale as english} from './i18n/en-US';
import {locale as portugues} from './i18n/pt-BR';
import {FormErrorStateMatcher} from '../../../../../../core/utils/form-error-state-matcher';
import {UserService} from '../../../../../../core/services/user/user.service';
import {Errors} from '../../../../../../core/model/errors';
import {trim} from 'lodash';
import { Competition } from '../../../../../../core/model/competition';
import { SubDivision } from '../../../../../../core/model/sub-division';
import { Championship } from '../../../../../../core/model/championship';

@Component({
  selector: 'app-create-participant-single',
  templateUrl: './create-participant-single.component.html',
  styleUrls: ['./create-participant-single.component.scss']
})
export class CreateParticipantSingleComponent implements OnInit {

  public competitions: any[] = [];
  public championshipId: number;
  public eventId: number;
  public subDivisions: SubDivision[];
  public championship: Championship;

  // translate
  translate: any;

  // 
  isEdit = false;
  isLoading = false;
  requestErrors: string = null;

  competitionForm: FormGroup;
  matchers: any;

  tshirtSize = Utils.getTShirts();

  // Box
  boxes = [];
  boxId: number;
  timeOutBoxSearch;

  enableSubDivision = false;

  // User
  user: any = null;

  isLeaderboardNameEdited = false;

  constructor(
    public dialogRef: MatDialogRef<CreateParticipantSingleComponent>,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private boxesService: BoxService,
    private userService: UserService,
    private competitorService: CompetitorService,
  ) {
    this.translationLoader.loadTranslations(english, portugues);

    //Icon Info
    iconRegistry.addSvgIcon(
      'ic-cat-info',
      sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/category/ic-cat-info.svg')
    );
  }

  ngOnInit() {
    this.translateService.get('NEW_PARTICIPANTS').subscribe((res: any) => {
      this.translate = res;
    });

    this.competitionForm = this.formBuilder.group({
      competition: new FormControl('', [Validators.required]),
      firstname: new FormControl('', [Validators.required]),
      lastname: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.email]),
      leaderboardName: new FormControl({
        value: '',
        disabled: true
      }, [Validators.required]),
      box: new FormControl(''),
      tShirt: new FormControl('')
    });

    this.matchers = {
      competition: new FormErrorStateMatcher(),
      email: new FormErrorStateMatcher(),
      firstname: new FormErrorStateMatcher(),
      lastname: new FormErrorStateMatcher(),
      leaderboardName: new FormErrorStateMatcher(),
    };

    this.competitionForm.get('firstname').valueChanges.subscribe(val => {
      if (!this.isLeaderboardNameEdited) {
        this.competitionForm.controls['leaderboardName'].setValue(`${val} ${this.competitionForm.value.lastname}`.toUpperCase());
      }
    });

    this.competitionForm.get('lastname').valueChanges.subscribe(val => {
      if (!this.isLeaderboardNameEdited) {
        this.competitionForm.controls['leaderboardName'].setValue(`${this.competitionForm.value.firstname} ${val}`.toUpperCase());
      }
    });
  }


  public competitionSelect(event: any): void {
    const id = event.value;
    
    this.competitions
      .forEach((competition: Competition, key: number) => {
        if (key === 0) {
          this.enableSubDivision = false;
        }
        if (competition.id === id) {
          this.matchers = {
            ...this.matchers,
            subDivision: new FormErrorStateMatcher()
          };
          this.competitionForm.addControl('subDivision', new FormControl('', [Validators.required]));
          this.enableSubDivision = competition.enable_sub_division;
        }
      });
    if (!this.enableSubDivision) {
      delete this.matchers.subDivision;
      this.competitionForm.removeControl('subDivision'); 
    }
  }

  // Create Competitor
  createCompetitor() {
    // validate all form fields
    if (!this.competitionForm.valid) {
      Utils.formValid(this.competitionForm);
      return;
    }

    const parameters = {
      event_id: this.eventId,
      competition_id: this.competitionForm.value.competition,
      sub_division_id: this.enableSubDivision ? this.competitionForm.value.subDivision : null,
      championship_id: this.championshipId,
      email: this.competitionForm.value.email.toLowerCase(),
      firstname: this.competitionForm.value.firstname.toUpperCase(),
      lastname: this.competitionForm.value.lastname.toUpperCase(),
      leaderboard_name: `${trim(this.competitionForm.value.firstname)} ${trim(this.competitionForm.value.lastname)}`.toUpperCase(),
      box_id: (this.competitionForm.value.box.id) ? this.competitionForm.value.box.id : null,
      box: (!this.competitionForm.value.box.id && this.competitionForm.value.box) ? this.competitionForm.value.box.toUpperCase() : null,
      size: (this.competitionForm.value.tShirt) ? this.competitionForm.value.tShirt : null,
    };

    if (this.user) {
      parameters['user_id'] = this.user.id;
    }

    this.requestErrors = null;
    this.isLoading = true;
    this.competitorService.createSingleCompetitor(parameters)
      .subscribe(
        result => {
          this.isLoading = false;

          this.dialogRef.close({createCompetitor: true});
        }, err => {
          if (err.error && err.error.length > 0) {
            this.requestErrors = err.error[0];
          }
          this.isLoading = false;
        }
      );
  }

  searchBox(name: string) {
    clearTimeout(this.timeOutBoxSearch);
    this.timeOutBoxSearch = setTimeout(() => {
      if (name.length > 0) {
        this.boxesService.getListBoxes(name)
          .subscribe(
            result => {
              this.boxes = result;
            }, err => {
              this.boxes = [];
            }
          );
      } else {
        this.boxes = [];
      }
    }, 300);
  }

  displayFnBox(box: any): string {
    return box ? box.name.toUpperCase() : box;
  }

  returnFn(box: any): number | undefined {
    return box ? box.id : undefined;
  }

  // Search User
  searchUser(event) {
    if (!this.competitionForm.controls.email.hasError('email') && event.target.value && event.target.value.length > 0) {
      const email = event.target.value;
      this.userService.searchUserByEmail(email)
        .subscribe(
          result => {
            if (result) {
              this.user = result;
              this.competitionForm.controls['firstname'].setValue((result.firstname) ? result.firstname : this.competitionForm.value.firstname);
              this.competitionForm.controls['lastname'].setValue((result.lastname) ? result.lastname : this.competitionForm.value.lastname);
              this.competitionForm.controls['leaderboardName'].setValue((result.leaderboard_name) ? result.leaderboard_name : this.competitionForm.value.leaderboardName);
              this.competitionForm.controls['tShirt'].setValue((result.size) ? result.size : this.competitionForm.value.size);
              this.competitionForm.controls['box'].setValue((result.box) ? result.box : this.competitionForm.value.box);
            }
          }, err => {
            this.user = null;

          }
        );
    } else if (event.target.value || event.target.value.length === 0) {
      this.user = null;
    }
  }

  // Form
  leaderboardNameFocus(event: any) {
    this.isLeaderboardNameEdited = true;
  }

  leaderboardNameBlur(event: any) {
    this.isLeaderboardNameEdited = (this.competitionForm.value.leaderboardName !== `${this.competitionForm.value.firstname} ${this.competitionForm.value.lastname}`);
  }

}
