import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import * as moment from 'moment';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SEOService {

  constructor(private meta: Meta) {
    
  }

  addMetaTags(metas: SEOMeta) {
    this.removeMetaTags()

    this.meta.addTags([
      { name: 'description', content: (metas.description) ? metas.description : 'Sistema de Gestão para Campeonatos. Inscrição, Qualifier e Leaderboard' },
      { name: 'keywords', content: 'CrossX, Campeonatos, CrossFit, CrossTrainning, LPO, Weighlifth, Leaderboard, points, results, resultados, games' },
      { name: 'robots', content: (metas.robots) ? metas.robots : (environment.production) ? 'index, nofollow' : 'noindex, nofollow' },
      { name: 'date', content: `${moment().format('YYYY-MM-DD')}`, scheme: 'YYYY-MM-DD' },
      { charset: 'UTF-8' },
      { httpEquiv: 'Content-Type', content: 'text/html' },
      { property: 'og:url', content: (metas.url) ? metas.url : 'http://crossx.com.br' },
      { property: 'og:title', content: (metas.title) ? metas.title : 'CrossX Campeonatos' },
      { property: 'og:description', content: (metas.description) ? metas.description : 'Sistema de Gestão para Campeonatos. Inscrição, Qualifier e Leaderboard' },
      { property: 'og:image', content: (metas.image) ? metas.image : 'https://is2-ssl.mzstatic.com/image/thumb/Purple128/v4/db/b0/c3/dbb0c302-f5a3-1fd9-2c8f-c527e0915d7d/AppIcon-1x_U007emarketing-sRGB-85-220-4.png/246x0w.jpg' },
      { property: 'og:type', content: "website" },
      { property: 'og:site_name', content: "CrossX Campeonatos" },
      { name: 'twitter:card', content: 'summary' },
      { name: 'twitter:title', content: (metas.title) ? metas.title : 'CrossX Campeonatos' },
      { name: 'twitter:description', content: (metas.description) ? metas.description : 'Sistema de Gestão para Campeonatos. Inscrição, Qualifier e Leaderboard' },
      { name: 'twitter:image', content: (metas.image) ? metas.image : 'https://is2-ssl.mzstatic.com/image/thumb/Purple128/v4/db/b0/c3/dbb0c302-f5a3-1fd9-2c8f-c527e0915d7d/AppIcon-1x_U007emarketing-sRGB-85-220-4.png/246x0w.jpg' },
    ])
  }
  getMetaTags() {
    let metaEl: HTMLMetaElement = this.meta.getTag('name= "keywords"');

    let els: HTMLMetaElement[] = this.meta.getTags('name');
    els.forEach(el => {
    });
  }
  updateMetaTags() {
    this.meta.updateTag({ name: 'description', content: 'Updated: Title and Meta tags examples' });
    this.meta.updateTag({ httpEquiv: 'Content-Type', content: 'application/json' }, 'httpEquiv= "Content-Type"');
    this.meta.updateTag({ name: 'robots', content: 'NOINDEX, NOFOLLOW' });
    this.meta.updateTag({ name: 'keywords', content: 'JavaScript, Angular' });
    this.meta.updateTag({ name: 'date', content: '2018-06-03', scheme: 'YYYY-MM-DD' });
    this.meta.updateTag({ name: 'author', content: 'VXYZ' });
    this.meta.updateTag({ charset: 'UTF-16' }, 'charset= "UTF-8"');
    this.meta.updateTag({ property: 'og:title', content: "My Text2" });
    this.meta.updateTag({ property: 'og:type', content: "website" });
  }

  removeMetaTags() {
    //Using removeTag
    this.meta.removeTag('name = "description"');
    this.meta.removeTag('name = "keywords"');
    this.meta.removeTag('name = "robots"');

    //Using removeTagElement
    let date: HTMLMetaElement = this.meta.getTag('name = "date"');
    this.meta.removeTagElement(date);
    let ogTitle: HTMLMetaElement = this.meta.getTag('property = "og:title"');
    this.meta.removeTagElement(ogTitle);
    let ogType: HTMLMetaElement = this.meta.getTag('property = "og:type"');
    this.meta.removeTagElement(ogType);
    let ogDescription: HTMLMetaElement = this.meta.getTag('property = "og:description"');
    this.meta.removeTagElement(ogDescription);
    let ogImage: HTMLMetaElement = this.meta.getTag('property = "og:image"');
    this.meta.removeTagElement(ogImage);
    let ogSiteName: HTMLMetaElement = this.meta.getTag('property = "og:site_name"');
    this.meta.removeTagElement(ogSiteName);
    let ogUrl: HTMLMetaElement = this.meta.getTag('property = "og:url"');
    this.meta.removeTagElement(ogUrl);
    let tCard: HTMLMetaElement = this.meta.getTag('name = "twitter:card"');
    this.meta.removeTagElement(tCard);
    let tTitle: HTMLMetaElement = this.meta.getTag('name = "twitter:title"');
    this.meta.removeTagElement(tTitle);
    let tDescription: HTMLMetaElement = this.meta.getTag('name = "twitter:description"');
    this.meta.removeTagElement(tDescription);
    let tImage: HTMLMetaElement = this.meta.getTag('name = "twitter:image"');
    this.meta.removeTagElement(tImage);
  }

}

interface SEOMeta {
  title: string
  description?: string
  image?: string
  url?: string
  keywords?: string
  robots?: string
};