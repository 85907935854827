import {Component, Input, OnInit, QueryList, ViewChildren} from '@angular/core';
import { locale as english } from './../i18n/en-US';
import { locale as portugues } from './../i18n/pt-BR';
import {Subscription} from 'rxjs';
import {FuseTranslationLoaderService} from '../../../../../../core/services/translation-loader.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Competition} from '../../../../../../core/model/competition';
import {MatDialog, MatDialogRef, MatIconRegistry} from '@angular/material';
import {DomSanitizer} from '@angular/platform-browser';
import {ToastrService} from '../../../../../../core/components/toastr_/toastr/toastr.service';
import {Event} from '../../../../../../core/model/event';
import {CompetitionService} from '../../../../../../core/services/competition/competition.service';
import {LotService} from '../../../../../../core/services/lot/lot.service';
import {ModalCreateLotComponent} from '../../category-lot-wod/modal-create-lot/modal-create-lot.component';
import {TableConfig, TableXComponent} from '../../../../../../core/components/table-x/table-x.component';
import {CrossXDialogComponent} from '../../../../../../core/components/cross-x-dialog/cross-x-dialog.component';
import {Lot} from '../../../../../../core/model/lot';
import {Errors} from '../../../../../../core/model/errors';
import {ModalCopyLotComponent} from '../../category-lot-wod/modal-copy-lot/modal-copy-lot.component';
import {Utils} from '../../../../../../core/utils';

@Component({
  selector: 'app-lots-with-competition',
  templateUrl: './lots-with-competition.component.html',
  styleUrls: ['./lots-with-competition.component.scss']
})
export class LotsWithCompetitionComponent implements OnInit {

  @ViewChildren('tableLot') tablesLot: QueryList<TableXComponent>;
  
  //External
  @Input() championshipId: number = null;
  @Input() championship: any = null;
  @Input() event: Event = null;
  
  //Translate
  public translate: any = null;

  public competitions: Competition[] = [];
  private subscriptions: Subscription = new Subscription();

  tableCatConfig: TableConfig = {
    checkbox: false,
    paddingSide: true,
    massActions: [
      {
        type: 'reproved',
        title: 'Reprovar'
      }
    ],
    massActionCountTitle: {
      single: 'resultado selecionado',
      multiple: 'resultados selecionados'
    }
  };
  
  public isCategoryEmpty = false;
  public isLoading = false;

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private competitionService: CompetitionService,
    private lotService: LotService,
  ) { }

  ngOnInit() {
    this.registerOnTranslate();
    this.registerOnRouteChange();
    this.getCompetitions();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public getCompetitions(): void {
    // Get All competitions in championship
    this.isLoading = true;
    const subscription = this.competitionService.getCompetitions(this.championshipId)
      .subscribe(
        result => {
          this.competitions = result;
          this.isLoading = false;
          this.isCategoryEmpty = this.competitions.length === 0;
        }, err => {
          this.isLoading = false;
          this.isCategoryEmpty = true;
        }
      );
    
    this.subscriptions.add(subscription);
  }

  reorderAction(orders: any, parms: any) {

    if (orders.type === 'reorder') {
      if (orders.tableName.indexOf('lots-') > -1) {
        const parameters = {
          lots: orders.data
        };

        this.lotService.massUpdate(parameters)
          .subscribe(
            result => {
            }, err => {
            }
          );
      }
    }
  }

  // Lots
  addLotOfCompetition(competition: Competition) {
    const modalRef = this.dialog.open(ModalCreateLotComponent, {
      width: '816px',
      height: '100%',
      panelClass: 'modal'
    });

    modalRef.componentInstance.championshipId = this.championship.id;
    modalRef.componentInstance.eventId = this.event.id;
    modalRef.componentInstance.competition = competition;

    modalRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.tablesLot) {
          this.tablesLot.forEach(tableLot => {
            tableLot.reloadItems();
          });
        }

        this.toastr.show(
          this.translate.TOASTR.CREATE.DESCRIPTION,
          this.translate.TOASTR.CREATE.TITLE,
          null,
          'success',
        );
      }
    });
  }

  editLotOfCompetition(lot: Lot, competition: Competition, index: number) {
    const modalRef = this.dialog.open(ModalCreateLotComponent, {
      width: '816px',
      height: '100%',
      panelClass: 'modal'
    });

    modalRef.componentInstance.championshipId = this.championship.id;
    modalRef.componentInstance.eventId = this.event.id;
    modalRef.componentInstance.competition = competition;
    modalRef.componentInstance.isEdit = true;
    modalRef.componentInstance.lot = lot;

    modalRef.afterClosed().subscribe(result => {
      if (result && result.isEdit) {
        lot = result.lot;
        competition.lots[index] = lot;
        if (this.tablesLot) {
          this.tablesLot.forEach(tableLot => {
            tableLot.reloadItems();
          });
        }

        this.toastr.show(
          this.translate.TOASTR.EDIT.DESCRIPTION,
          this.translate.TOASTR.EDIT.TITLE,
          null,
          'success',
        );
      }
    });
  }

  deleteLot(lot: Lot, competition: Competition, index: number) {

    if (lot.sold > 0) {
      this.toastr.show(
        this.translate.TOASTR.DELETE.ERROR_DELETE_DESCRIPTION,
        this.translate.TOASTR.DELETE.ERROR_DELETE_TITLE,
        null,
        'error',
      );
      return;
    }

    this.dialog.openDialogs.pop();
    const dialogRef = this.dialog.open(CrossXDialogComponent, {
      width: '440px',
      closeOnNavigation: true
    });

    dialogRef.componentInstance.type = 'warning';
    dialogRef.componentInstance.titleDialog = this.translate.DIALOG.TITLE;
    this.translateService.get('LOTS.DIALOG.DESCRIPTION', { lot_name: lot.name }).subscribe((res: string) => {
      dialogRef.componentInstance.dialogContent = res;
    });
    dialogRef.componentInstance.dialogConfirm = true;
    dialogRef.componentInstance.cancelButton = this.translate.DIALOG.CANCEL.toUpperCase();
    dialogRef.componentInstance.actionButton = this.translate.DIALOG.EXCLUIR.toUpperCase();
    dialogRef.componentInstance.object = lot;
    dialogRef.componentInstance.confirmActionRequest = true;
    dialogRef.componentInstance.dialogEvent.subscribe(result => {
      dialogRef.componentInstance.confirmLoading = true;
      this.deleteLotInAPI(lot, competition, index, dialogRef);
    });
  }

  deleteLotInAPI(lot: Lot, competition: Competition, index: number, dialogRef: MatDialogRef<CrossXDialogComponent>) {
    this.lotService.deleteLot(lot.id)
      .subscribe(
        response => {
          dialogRef.componentInstance.confirmLoading = false;
          dialogRef.close();

          // let index = competition.lots.indexOf(lot)
          competition.lots.splice(index, 1);
          if (this.tablesLot) {
            this.tablesLot.forEach(tableLot => {
              tableLot.reloadItems();
            });
          }

          // dialog.close()
          this.toastr.show(
            this.translate.TOASTR.DELETE.DESCRIPTION,
            this.translate.TOASTR.DELETE.TITLE,
            null,
            'success',
          );
        }, err => {
          const errors: Errors = new Errors().deserialize((err.error as any));

          dialogRef.componentInstance.confirmLoading = false;
          dialogRef.close();

          this.toastr.show(
            errors.getFullMessages(),
            this.translate.TOASTR.DELETE.ERROR_DELETE_TITLE,
            null,
            'error',
          );
        }
      );
  }

  copyLots(competition: Competition) {

    const modalRef = this.dialog.open(ModalCopyLotComponent, {
      width: '816px',
      height: '100%',
      panelClass: 'modal'
    });

    modalRef.componentInstance.competition = competition;
    modalRef.componentInstance.allCompetitions = this.competitions;

    modalRef.afterClosed().subscribe(result => {
      if (result) {
        this.competitions.forEach(competition => {
          result.competitions.forEach(competitionUpdate => {
            if (competition.id === competitionUpdate.id) {
              competition.lots = competitionUpdate.lots;
            }
          });
        });

        if (this.tablesLot) {
          this.tablesLot.forEach(tableLot => {
            tableLot.reloadItems();
          });
        }

        this.toastr.show(
          this.translate.TOASTR.COPY.DESCRIPTION,
          this.translate.TOASTR.COPY.TITLE,
          null,
          'success',
        );
      }
    });
  }
  
  
  public formatCurrency(value: string, currency: string): string {
    const unit = Utils.formatCurrency(value, currency);
    return unit ? unit : '';
  }

  private registerOnTranslate(): void {
    this.translationLoader.loadTranslations(english, portugues);
    const subscription = this.translateService
      .get('LOTS')
      .subscribe((response: Object) => {
        this.translate = response;
      });

    this.subscriptions.add(subscription);
  }

  private registerOnRouteChange(): void {
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.competitions = [];
          this.getCompetitions();
        }
      });
  }
}
