import { SubscriptionService } from './../../../../../core/services/subscription/subscription.service';
import { AuthenticationService } from './../../../../../core/services/authentication.service';
import { Lot } from './../../../../../core/model/lot';
import { Event } from './../../../../../core/model/event';
import { Component, OnInit, ElementRef, NgZone } from '@angular/core';
import { FuseConfigService } from '../../../../../core/services/config.service';
import { EventPageService } from '../../../../../core/services/event-page/event-page.service';
import { Title, DomSanitizer } from '@angular/platform-browser';
import { FuseTranslationLoaderService } from '../../../../../core/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthGuard } from '../../../../../core/guards/auth.guard';
import { Router } from '@angular/router';
import { MatIconRegistry, MatDialog } from '@angular/material';
import { EventsService } from '../../../../../core/services/event/events.service';
import { BreadcrumbsService } from '../../../../../core/components/breadcrumbs/breadcrumbs.service';
import { EventGuard } from '../../../../../core/guards/event.guard';
import { UserService } from '../../../../../core/services/user/user.service';
import { SEOService } from '../../../../../core/services/seo-service/seo.service';
import { GooglePlacesService } from '../../../../../core/services/google-places.service';
import { locale as english } from './i18n/en-US';
import { locale as portugues } from './i18n/pt-BR';
import { TableConfig } from '../../../../../core/components/table-x/table-x.component';
import { Utils } from '../../../../../core/utils';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { FormErrorStateMatcher } from '../../../../../core/utils/form-error-state-matcher';
import { CrossXDialogComponent } from '../../../../../core/components/cross-x-dialog/cross-x-dialog.component';
import { Errors } from '../../../../../core/model/errors';
import { Subscription } from '../../../../../core/model/subscription';

@Component({
  selector: 'app-registration-identification',
  templateUrl: './registration-identification.component.html',
  styleUrls: ['./registration-identification.component.scss']
})
export class RegistrationIdentificationComponent implements OnInit {

  event: Event = null
  lot: Lot = null
  coupon: any = null
  user: any = null

  loginForm: FormGroup
  registerForm: FormGroup
  matchers: any

  headerPlaceholder: string = './assets/images/backgrounds/background-myprofile.jpg'

  //Table
  tableConfig: TableConfig = {
    checkbox: false,
    paddingSide: true,
    massActions: null,
    massActionCountTitle: null
  }

  emailExist: boolean = false
  isLoadingLogin: boolean = false
  loginError: boolean = false
  isLoadingRegister: boolean = false

  // translate
  translate: any

  constructor(
    private fuseConfig: FuseConfigService,
    private eventPageService: EventPageService,
    private titleService: Title,
    private translationLoader: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private authGuard: AuthGuard,
    private router: Router,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private eventsService: EventsService,
    private breadcrumbsService: BreadcrumbsService,
    private eventGuard: EventGuard,
    public dialog: MatDialog,
    public userService: UserService,
    private seoService: SEOService,
    private googlePlacesService: GooglePlacesService,
    private el: ElementRef,
    private zone: NgZone,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private subscriptionService: SubscriptionService
  ) {

    this.translationLoader.loadTranslations(english, portugues);

    this.translateService.get("IDENTIFICATION").subscribe((res: any) => {
      this.translate = res
    })

    this.fuseConfig.setSettings({
      layout: {
        navigation: 'none',
        toolbar: 'below',      // 'above', 'below', none
        footer: 'none'
      }
    });

    // Get Event
    this.event = this.eventPageService.event
    this.lot = this.eventPageService.getLot()
    this.coupon = this.eventPageService.getCoupon()
    if (this.event === null || this.lot === null) this.router.navigate(['/event'])
    
    iconRegistry.addSvgIcon(
      'ic-info',
      sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/myprofile/ic-profile.svg')
    );

    // this.breadcrumbsService.removeAll()
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
    });
    
    this.registerForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email])
    });

    this.matchers = {
      email: new FormErrorStateMatcher(),
      password: new FormErrorStateMatcher(),
    }
  }

  logIn() {
    // validate all form fields
    if (!this.loginForm.valid) {
      Utils.formValid(this.loginForm);
      return;
    }
    this.isLoadingLogin = true
    this.authenticationService.login(this.loginForm.value.email, this.loginForm.value.password)
      .subscribe(
        result => {

          if (result.valid) {
            this.authGuard.storageSub.next({
              type: 'logged'
            });
            this.user = this.authGuard.getMe().user;
            this.verifyExistSubscription();
          }
          else {
            this.isLoadingLogin = false;
            this.loginError = true;
          }

        }, err => {
          console.log('err', err);
          this.loginError = true;
          this.isLoadingLogin = false;
        }
      );
  }

  verifyExistSubscription() {
    this.subscriptionService.getUserInEvent(this.lot.id, this.user.id)
      .subscribe(
        result => {
          this.isLoadingLogin = false
          if (result !== null) {
            const subscription = result
            if (subscription.status === 2) {
              this.showErrorSubscribe()
            }
            else {
              this.showDialogExistSubscription(subscription, this.lot, this.coupon)
            }
          } else {
            this.router.navigate(['/event-registration'])
          }
        }, err => {
          console.log("err", err);
          const errors: Errors = new Errors().deserialize((err.error as any))
          this.showErrorSubscribe(errors.getFullMessages())
        }
      )
  }

  showErrorSubscribe(description: string = null) {
    const dialogRef = this.dialog.open(CrossXDialogComponent, {
      width: '440px',
      disableClose: true
    });

    dialogRef.componentInstance.dialogConfirm = false
    dialogRef.componentInstance.cancelButton = 'FECHAR'
    dialogRef.componentInstance.type = 'error'
    dialogRef.componentInstance.titleDialog = this.translate.SUBSCRIPTION.TITLE
    dialogRef.componentInstance.dialogContent = description ? description : this.translate.SUBSCRIPTION.DESCRIPTION_COMPLETED

    dialogRef.afterClosed().subscribe(result => {
      this.router.navigate(['/event'])
    });
  }

  showDialogExistSubscription(subscription: any, lot: Lot, coupon: any) {
    const dialogRef = this.dialog.open(CrossXDialogComponent, {
      width: '440px',
      disableClose: true
    });

    dialogRef.componentInstance.dialogConfirm = true
    dialogRef.componentInstance.object = subscription
    dialogRef.componentInstance.cancelButton = 'EXCLUIR'
    dialogRef.componentInstance.actionButton = 'CONTINUAR'
    dialogRef.componentInstance.type = 'warning'
    dialogRef.componentInstance.titleDialog = this.translate.SUBSCRIPTION.TITLE
    dialogRef.componentInstance.dialogContent = this.translate.SUBSCRIPTION.DESCRIPTION

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const subscription = new Subscription().deserialize(result)
        this.eventPageService.setLot(result.lot)
        this.eventPageService.setCoupon(result.coupon)
        this.eventPageService.setSubscription(subscription)
        this.router.navigate(['/event-registration'])
      }
      // cancel
      else {
        this.cancelOldSubscription(subscription, lot, coupon)
      }
    });
  }

  cancelOldSubscription(subscription: any, lot: Lot, coupon: any) {
    this.subscriptionService.cancelOld(subscription.id)
      .subscribe(
        result => {
          this.eventPageService.setLot(lot)
          this.eventPageService.setCoupon(coupon)
          this.router.navigate(['/event-registration'])
        }, err => {
          console.log("err", err);
        }
      );
  }

  register() 
  {
    this.loginForm.controls['password'].setValidators(Validators.required);

    // validate all form fields
    if (!this.registerForm.valid) {
      Utils.formValid(this.registerForm);
      return;
    }
    const email = this.registerForm.value.email;
    
    this.isLoadingRegister = true;
    this.userService.searchUserByEmail(email)
      .subscribe(
        result => {
          this.isLoadingRegister = false;
          if (result) {
            this.registerForm.controls['email'].setErrors({
              exist: true
            });
          }
        }, err => {
          console.log('err', err);
          this.isLoadingRegister = false;
          this.eventPageService.setEmail(this.registerForm.value.email);
          this.router.navigate(['/event-registration']);
        }
      );
  }

  // forgotPassword
  forgotPassword() {
    this.loginForm.controls['password'].clearAsyncValidators()
    this.loginForm.controls['password'].clearValidators()

    // validate all form fields
    if (!this.loginForm.get('email').valid) {
      const control = this.loginForm.get('email');
      control.markAsTouched({ onlySelf: true });
      return
    }

    this.authenticationService.forgotPassword(this.loginForm.value.email)
      .subscribe(
        result => {

          const dialogRef = this.dialog.open(CrossXDialogComponent, {
            width: '440px'
          });

          dialogRef.componentInstance.dialogConfirm = false
          dialogRef.componentInstance.cancelButton = 'FECHAR'
          //dialogRef.componentInstance.actionButton = 'EXCLUIR'

          dialogRef.afterClosed().subscribe(resultDialog => {
          });

          if (result.valid) {
            dialogRef.componentInstance.type = 'success'
            dialogRef.componentInstance.titleDialog = this.translate.FORGOT.SUCCESS_TITLE
            this.translateService.get('IDENTIFICATION.FORGOT.SUCCESS_DESCRIPTION', { email: this.loginForm.value.email }).subscribe((res: string) => {
              dialogRef.componentInstance.dialogContent = res
            });
          } else {
            dialogRef.componentInstance.type = 'error'
            dialogRef.componentInstance.titleDialog = this.translate.FORGOT.ERROR_TITLE
            dialogRef.componentInstance.dialogContent = this.translate.FORGOT.ERROR_DESCRIPTION
          }

        }, err => {
          const errors: Errors = new Errors().deserialize((err.error as any))
          const dialogRef = this.dialog.open(CrossXDialogComponent, {
            width: '440px'
          });

          dialogRef.componentInstance.dialogConfirm = false
          dialogRef.componentInstance.cancelButton = 'FECHAR'
          //dialogRef.componentInstance.actionButton = 'EXCLUIR'
          dialogRef.componentInstance.type = 'error'
          dialogRef.componentInstance.titleDialog = this.translate.FORGOT.ERROR_TITLE
          dialogRef.componentInstance.dialogContent = this.translate.FORGOT.ERROR_EMAIL_NOT_FOUND

          dialogRef.afterClosed().subscribe(result => {
          });

        }
      );

  }

  formatCurrency(value: string, currency: string): string {
    return Utils.formatCurrency(value, currency)
  }

}
